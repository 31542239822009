import api from './api';
import msg from './msg';
import dialog from './dialog';

const candidate_ctrl = {
  sendOptIn: (candidate_id, options) => {

    if(options === undefined) {
      options = {};
    }

    api.get('/candidate/' + candidate_id + '/optin',{
      success: (res) => {
        if (options.success !== undefined) {
          options.success(res);
        }
        msg.success(t('candidate.optin_send_success'));

        if (typeof options.afterOptinSend === 'function') {
          options.afterOptinSend();
        }
      }
    });
  },

  dialogOptIn: (candidate_id, options) => {

    options = $.extend({},{
      title: 'Opt-In Mail senden?',
      body: 'Soll eine Opt-In Mail gesendet werden?',
      apply: () => {
        candidate_ctrl.sendOptIn(candidate_id, options);
      }
    },options);

    dialog.confirm(options);

  },

  dialogJobstatusMail: (candidate_id, options) => {
    options = $.extend({},{
      title: 'Job-Status Mail senden?',
      body: 'Soll eine Job-Status Mail an den Kandidaten gesendet werden?',
      apply: () => {
        options.apply();
      }
    },options);

    dialog.confirm(options);
  },

  modalTextOnly: (title, text, options) => {
    options = $.extend({},{
      title: title,
      body: text,
      apply: () => {
        options.apply();
      }
    },options);

    dialog.textOnly(options);
  }
};

export default candidate_ctrl;
