import media from '../media';
import api from '../api';
import msg from '../msg';
import helper from '../helper';
import time from '../time';
import routes from '../routes';

$.fn.uiOrganisationDetails = function(options) {

  // default options
  let plugin = this;
  let $element = $(this);
  let orga_status = [];

  options = $.extend({}, {
    title: false,
    onLoading: () => {
    },
    onFinish: () => {
    }
  }, options);

  let $form;

  /* fetch which orga_status have blacklist default */
  const orgaStatusUrl = '/grouped-resource/tag/select/orga_status';
  api.get(orgaStatusUrl, {
    success: (response) => {
      orga_status = response;
    }
  });

  plugin.initForm = () => {
    $form = $('<form></form>').uiForm({
      success: () => {
        msg.success(t('g.save_success'));
      },
      error: (res) => {
        if(res && res.responseJSON && res.responseJSON.error) {
          msg.error(t(res.responseJSON.error));
        } else if(res && res.responseJSON && res.responseJSON.errors){
          for (const key in res.responseJSON.errors) {
            if (res.responseJSON.errors.hasOwnProperty(key)) {
              msg.error(`${key} Error: ${res.responseJSON.errors[key].map(t).join(', ')}`);
            }
         }
        } else {
          msg.error(t('g.save_error'));
        }

      },
      url: '/agency_organisation',
      i18n_prefix: 'organisation.',
      fields: {
        'name': {
          text: 'Name',
          type: 'Text'
        },
        'parent': {
          text: 'Tochter-Unternehmen von',
          type: 'Select2',
          table: 'organisation',
          ajax: true,
          class: 'addmode'
        },
        'shortname': 'Text',
        'blacklisted': {
          type: 'Switch',
          onSwitchChange: (state) => {
            updateBlacklistCommentField();
          }
        },
        'blacklist_comment': 'Text',
        'organisationbranches': {
          type: 'TagSelect',
          table: 'organisationbranches',
          add: true,
          class: 'editmode',
          onAdd: () => {
            let id = options.organisation.id;

            if (helper.empty(id)) {
              msg.error(t('job.no_organisation_selected'));
            }
            else {
              let $save_btn = $('<button class="btn btn-default">Speichern</button>');

              let $form_ob = $('<form></form>').uiForm({
                i18n_prefix: 'organisationbranches.',
                url: '/organisationbranches',
                fields: {
                  'organisation_id': {
                    type: 'Hidden',
                    value: id
                  },
                  'promoaddress': 'Locationpicker',
                  /*
                  'address': {
                    type: 'Locationpicker',
                    additional: true
                  }
                  */
                },
                addSuccess: (res) => {
                  let formdata = $form_ob.serializeObject();

                  let newOption = new Option(formdata.promoaddress.city, res.id, true, true);
                  $form.find('[name="organisationbranches[]"]').append(newOption).trigger('change');

                  $subform.remove();
                }
              });

              let $subform = $('<div class="subform"></div>');
              let $orgabranches = $form.find('.form-group-organisationbranches');

              $form_ob.append($save_btn);
              $form_ob.attr('novalidate', 'novalidate');

              $subform.append($form_ob);
              $subform.insertAfter($orgabranches);
            }
          }
        },
        'organisationtype': {
          text: 'Branche',
          type: 'Select',
          table: 'organisationtype'
        },
        /*'orga_type': {
          text: 'Unterbranche',
          type: 'Select',
          table: {
            name: 'tag',
            type: 'orga_type'
          }
        },*/
        'email': 'Email',
        'phone': 'Phone',
        /*'fax': 'Phone',*/
        'website': 'Text',
        'social_linkedin': 'Text',
        'social_xing': 'Text',
        /*'temperature': {
          text: 'Temperatur Niveau',
          type: 'Range',
          min: 0,
          max: 3
        },*/
        'user': {
          text: 'Account-Manager',
          type: 'TagSelect',
          table: {
            name: 'user',
            type: 'worker_active'
          },
        },
        /* Hack because user field is not sent if empty, but we need that to remove all AMs */
        'user_field_is_present': {
          type: 'Hidden',
          value: true
        },
        'description_important': 'Textarea',
        'description': 'Textarea',
        'application_info': {
          type: 'Summernote',
          height: 300
        },
        'first_interview_tips': {
          type: 'Richtext',
          height: 100
        },
        'orga_status': {
          type: 'Select',
          table: {
            name: 'tag',
            type: 'orga_status'
          },
          onChange: (value, $select, $formX) => {
            /* /* on selecting a new orga_status, we update the blacklist switch
            for (const os of orga_status) {

              /* value gets us the Tag ID
              if (os.id == value) {
                const blacklistSwitch = $form.getFormElement('blacklisted');

                /* if blacklist switch changes show message
                if (blacklistSwitch.getValue() != os.default_on_blacklist) {
                  if (os.default_on_blacklist === "1") {
                    blacklistSwitch.enable();
                    msg.success(t('organisation.blacklist_added'));
                  } else {
                    blacklistSwitch.disable();
                    msg.success(t('organisation.blacklist_removed'));
                  }
                }

                updateBlacklistCommentField();

              }
            } */

          }
        },
        /*
        'social_linkedin': 'Text',
        'social_xing': 'Text',
        'social_facebook': 'Text',
        'social_instagram': 'Text',
        'social_youtube': 'Text',
        'social_twitter': 'Text',
        'social_mastodon': 'Text',
        */
        'submitted': 'Submit'
      }
    });

    $form.setValues(options.organisation);

    updateBlacklistCommentField();

    $element.append($form);
    $element.find(".form-group-parent.addmode").show();
  };

  const updateBlacklistCommentField = () => {
    const blacklistComment = $form.getFormElement('blacklist_comment');
    const blacklistSwitchState = $form.getFormElement('blacklisted').getValue();

    if (blacklistSwitchState == '1') {
      blacklistComment.show();
    } else {
      blacklistComment.hide();
    }

  }

  this.initialize = () => {

    plugin.initForm();

    return plugin;
  };

  return this.initialize();

};
