import api from '../api';
import button_save from '../../_compiled/components/button_save';
import msg from "../msg";
import session from '../session';
import helper from "../helper";

$.fn.uiForm = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  options = $.extend({},{
    i18n_prefix: '',
    url: $element.attr('action'),
    onAddMode: () => {},
    onEditMode: () => {},
    addSuccess: () => {},
    editSuccess: () => {},
    change: () => {},
    success: () =>{}
  }, options);

  if(options.$el_save === undefined) {
    options.$el_save = button_save.js();
  }

  if(options.$el_edit === undefined) {
    options.$el_edit = button_save.js();
  }

  if(options.additionalTitle === undefined) {
    options.additionalTitle = t('g.advanced');
  }

  if(options.additionalCollapseTitle === undefined) {
    options.additionalCollapseTitle = t('g.advanced');
  }

  let plugin = this;
  let formElements = [];
  let editId = null;
  let all_elements = [];
  let url;
  let fireForm = true;
  let mode = null;
  let btn_disable = false;

  plugin.options = options;

  plugin.current_data = {};

  plugin.disableSaveButton = () => {
    options.$el_save.attr('disabled', true);
    btn_disable = true;
  }

  plugin.enableSaveButton = () => {
    if(btn_disable) {
      options.$el_save.removeAttr('disabled');
      btn_disable = false;
    }
  }

  plugin.fire = () => {

    if (options.url_overwrite === true) {
      url = options.url;
    }
    else {
      url = '/resource/add' + options.url;
    }

    // Entfernen von alten validation errors
    $element.find('.is-invalid').removeClass('is-invalid');
    $element.find('.is-invalid-important').removeClass('is-invalid-important');
    $element.find('.error.invalid-feedback').remove();

    if(options.start !== undefined) {
      options.start();
    }

    plugin.disableSaveButton();

    api.post(url, plugin.getData(),{
      success: (res) => {
        options.addSuccess(res);
        options.success(res);
        plugin.enableSaveButton();
      },
      complete: options.complete,
      error: (res) => {
        plugin.handleErrors(res);
        plugin.enableSaveButton();
      },
      contentType: false,
      processData: false
    });
  };

  plugin.getMode = () => {
    return mode;
  };

  plugin.fireEdit = () => {

    if (options.url_overwrite === true) {
      url = options.url;
    }
    else {
      if (editId === undefined) {
        url = '/resource/update' + options.url;
      }
      else {
        url = '/resource/update' + options.url + '/' + editId;
      }
    }

    if(options.beforeEdit !== undefined) {
      options.beforeEdit();
    }

    // Entfernen von alten validation errors
    $element.find('.is-invalid').removeClass('is-invalid');
    $element.find('.is-invalid-important').removeClass('is-invalid-important');
    $element.find('.error.invalid-feedback').remove();

    api.post(url, plugin.getData(), {
      success: (res) => {
        options.editSuccess(plugin.objectify(), plugin.current_data);
        options.success(res);
      },
      complete: options.complete,
      error: (res) => {
        if(options.error !== undefined) {
          options.error(res);
        }
        plugin.handleErrors(res);
      },
      start: options.start,
      contentType: false,
      processData: false
    });
  };

  plugin.setEditMode = (data) => {
    mode = 'edit';
    $element.unbind('submit');
    $element.submit((ev) => {
      ev.preventDefault();
      plugin.fireEdit();
    });
    options.$el_save.hide();
    options.$el_edit.show();
    options.onEditMode(data);
    $element.find('.addmode').hide();
    $element.find('.editmode').show();

    // Entfernen von alten validation errors
    $element.find('.is-invalid').removeClass('is-invalid');
    $element.find('.is-invalid-important').removeClass('is-invalid-important');
    $element.find('.error.invalid-feedback').remove();

    let $readonly = [];
    //let $readonly_select2 = [];

    $readonly.push($element.find('.editreadonly').children('input, textarea'));
    if($readonly.length > 0) {
      $.each($readonly, (i, elem) => {
        $(elem).prop('readonly', true);
      });
    }

    /*$readonly_select2.push($element.find('select.editreadonly'));
    if ($readonly_select2.length > 0) {
      $.each($readonly_select2, (i, elem) => {
        $(elem).select2({disabled:true});
      });
    }*/
  };

  // wird bei der JobCandidate CV-Generierung benötigt
  plugin.setUrl = (newUrl) => {
    options.url = newUrl;
    options.url_overwrite = true;
  };

  plugin.disableSubmit = () => {
    fireForm = false;
  };

  plugin.enableSubmit = () => {
    fireForm = true;
  };

  plugin.setAddMode = () => {
    mode = 'add';
    $element.unbind('submit');
    $element.submit((ev) => {
      ev.preventDefault();
      if (fireForm) {
        plugin.fire();
      }
    });
    options.onAddMode();
    options.$el_save.show();
    options.$el_edit.hide();
    $element.find('.addmode').show();
    $element.find('.editmode').hide();

    // Entfernen von alten validation errors
    $element.find('.is-invalid').removeClass('is-invalid');
    $element.find('.is-invalid-important').removeClass('is-invalid-important');
    $element.find('.error.invalid-feedback').remove();

    let $readonly = [];
    //let $readonly_select2 = [];

    $readonly.push($element.find('.editreadonly').children('input, textarea'));
    if ($readonly.length > 0) {
      $.each($readonly, (i, elem) => {
        $(elem).prop('readonly', false);
      });
    }

    plugin.setDefaults();

    /*$readonly_select2.push($element.find('select.editreadonly'));
    if ($readonly_select2.length > 0) {
      $.each($readonly_select2, (i, elem) => {
        $(elem).select2({disabled:false});
      });
    }*/
  };

  plugin.getData = () => {

    return new FormData($element[0]);
  };

  plugin.handleErrors = (res) => {

    // Fehlermeldung ausgeben und fehlerhafte Formularfelder markieren
    if(res && res.responseJSON) {
      let err = res.responseJSON;

      if (typeof err.errors === 'object' && err.errors !== null) {

        for (let [key, value] of Object.entries(err.errors)) {

          // fieldWithError ermitteln
          let fieldWithError = plugin.getFieldWithError(key);

          // Standard Ausgabe
          if (fieldWithError == null || typeof formElements[fieldWithError].showError !== 'function') {
            let $input = $element.find(`[name="${key}"]`);
            let i18nErrMsg = value[0];
            let customErrMsg = '';
            
            if (value.length > 1) {
              customErrMsg = value[1];
            }
            
            $input.addClass('is-invalid');
            $input.parent().append(`<span class="error invalid-feedback">${t(i18nErrMsg) + customErrMsg}</span>`);
          }
          /*
           * fieldWithError beinhaltet den Feldnamen ggf. ohne "_id" am Ende, wichtig für selects.
           * In der showError-Methode des Elements nutzen wir dann aber den wirklichen Name, ggf. mit "_id" am Ende
           */
          else {
            formElements[fieldWithError].showError(key,  value[0]);
          }

        }

      }

      msg.error(t(err.message));
    }

    options.error;

  };

  plugin.objectify = () => {

    let formData = plugin.getData();

    let jsonObject = {};

    for (const [key, value]  of formData.entries()) {
      jsonObject[key] = value;
    }

    return jsonObject;

  };

  plugin.setValues = (data, changemode) => {

    plugin.current_data = data;

    editId = data.id;

    if(changemode !== false) {
      plugin.setEditMode(data);
    }

    /*
     * Wir müssen Form-Elemente mit Array abhandeln, z.B. radius[city]!
     */
    let formElementsWithArray = {};

    for (const [key, value] of Object.entries(data)) {

      if(key.indexOf('[') > -1) {
        let subelem = key.split('[');
        let subkey = subelem[0];
        let subvalue = subelem[1].substring(0, subelem[1].length - 1);

        if ( !(subkey in formElementsWithArray) ) {
          formElementsWithArray[subkey] = {};
        }
        formElementsWithArray[subkey][subvalue] = value;
      }

      else if (formElements[key] !== undefined && typeof formElements[key] !== 'function') {
        formElements[key].setValue(value);
      }
    }

    /*
     * Jetzt setzen wir für alle Form-Elemente mit Array das komplette Data-Array
     */
    for (const [key, value] of Object.entries(formElementsWithArray)) {
      if (formElements[key] !== undefined && typeof formElements[key] !== 'function') {
        formElements[key].setValue(value);
      }
    }

    /*
    Object.keys(data).forEach(key => {

      if(formElements[key] !== undefined) {
        formElements[key].setValue(data[key]);
      }

    });
    */

  };

  plugin.setValue = (field, value) => {
    formElements[field].setValue(value);
  };

  plugin.resetValues = () => {
    Object.keys(formElements).forEach(field => {

      formElements[field].resetValue();

    });

    plugin.setAddMode();

  };

  plugin.getInput = (name) => {
    return $('[name='+name+']');
  };

  plugin.getFormElement = (name) => {
    return formElements[name];
  }

  plugin.fireChange = () => {
    let data = $element.serializeObject();
    options.change(data);
  };

  /**
   * Ermittelt das Formularfeld, das einen Error verursacht
   * @param name
   */
  this.getFieldWithError = (name) => {

    let fieldWithError;

    if (formElements[name] && formElements[name] !== undefined) {
      fieldWithError = name;
    }
    // select hat ein _id angehängt, das brauchen wir hier nicht!
    else {
      if (name.substr(-3) == '_id') {
        let field = name.substring(0, name.length - 3);

        if (formElements[field] && formElements[field] !== undefined) {
          fieldWithError = field;
        }
      }
      else {
        fieldWithError = null;
      }
    }

    return fieldWithError;
  };

  plugin.setDefaults = () => {

    if(options.default !== undefined) {
      plugin.setValues(options.default, false);
    }

  };

  plugin.getEditId = () => {
    return editId;
  };

  // public methods
  this.initialize = () => {

    /*
     * disable autocomplete hack
     */
    //$element.attr('autocomplete', 'off');
    //$element.prepend('<input type="password" name="_auto_complete_killer_" style="" />');

    plugin.setAddMode();

    let first_additional = true;
    let first_additional_collapse = true;
    let form_element_slave = null;

    if (options.fields != null) {
      Object.keys(options.fields).forEach(key => {
        if(typeof options.fields[key] === 'string') {
          options.fields[key] = {
            type: options.fields[key],
            table: key
          };
        }

        if(options.fields[key].acl === undefined || session.hasAcl(options.fields[key].acl)) {

          options.fields[key].name = key;
          options.fields[key].i18n_prefix = options.i18n_prefix;
          options.fields[key].getEditId = () => {
            return editId;
          };

          formElements[key] = $('<div></div>')['uiForm'+options.fields[key].type](options.fields[key]);

          let addclass =(options.fields[key].class);

          if(addclass !== undefined) {
            formElements[key].addClass(addclass);
          }

          if(options.fields[key].additional !== undefined && options.fields[key].additional === true) {
            formElements[key].addClass('is-hidden is-additional');
            if(first_additional) {
              first_additional = false;

              formElements[key+'additional'] = $('<div></div>')['uiFormAdditionaltitle']({
                label: options.additionalTitle,
                name: key+'_additional'
              });
              $element.append(formElements[key+'additional']);

            }
          }

          if(options.fields[key].additional_collapse !== undefined && options.fields[key].additional_collapse === true) {
            formElements[key].addClass('is-hidden is-additional-collapse');
            if(first_additional_collapse) {
              first_additional_collapse = false;

              formElements[key+'additional_collapse'] = $('<div></div>')['uiFormAdditionalCollapsetitle']({
                label: options.additionalCollapseTitle,
                name: key+'_additional_collapse'
              });
              $element.append(formElements[key+'additional_collapse']);
            }
          }

          if(options.fields[key].select_same !== undefined) {
            if(options.fields[key].select_same === 'Master') {
              formElements[key].$input.change((ev) => {
                let $el = $(ev.target);
                
                let touchpoint_flag = false;
                let sel_id = $el.val().toString().trim();
                let values = formElements[key].values;
                values.forEach((obj) => {
                  let id = obj.id.toString().trim();
                  if(id === sel_id) {
                    touchpoint_flag = obj.is_active_job_source;
                    return;
                  }
                });

                if(touchpoint_flag) {
                  let touchpoint_id = '1-' + sel_id;
                  form_element_slave.setValue(touchpoint_id);
                }
              });
            }
            else if(options.fields[key].select_same === 'Slave') {    
              form_element_slave = formElements[key];
            }
          }

          $element.append(formElements[key]);

        }

      });
    };


    options.$el_save.click((ev) => {
      if (fireForm) {
        ev.preventDefault();
        plugin.fire();
      }
    });

    options.$el_edit.click((ev) => {
      ev.preventDefault();
      plugin.fireEdit();
    });

    setTimeout(() => {
      $element.find('input, select').change((ev) => {
        let update_state = true;
        if(ev.target.className !== undefined && ev.target.className.indexOf('jungwild-addresspicker') > -1) {
          update_state = false;
        }

        if(update_state) {
          plugin.fireChange();
        }
      });
    },1000);

    return plugin;

  };

  return this.initialize();
};
