import app from '../../app/app';;
import api from '../../app/api';;
import time from '../../app/time';;
import msg from '../../app/msg';

const admin_mailtemplates = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <p style="padding: 0px 10px 10px 10px;">Als Layout sollte normalerweise "default" gewählt werden, dann wird die E-Mail Signatur angehängt.<br /> Wenn keine Signatur benötigt wird, dann kann "system" gewählt werden.</p> <div class="col-md-12 admin-mailtemplates"> <div id="mailtemplates-list"></div> </div> </div></div>'; return out;
    },
    js: function(it) {
            app.setTitle('Mail-Templates verwalten'); app.setBreadcrumps({ 'Administration': '/agency/admin', 'Mail-Templates': '/agency/admin/mailtemplates' }); let $mailtemplates = $('#mailtemplates-list').uiDataTableScroll({ add: true, edit: true, delete: false, default_order: [0, 'asc'], i18n_prefix: 'mailtemplates.', pageLength: 12, cols: [ {name: 'id', text:'#', width: 10, hidden: true}, {name: 'name', text: 'Name'}, {name: 'subject', text: 'Betreff'}, {name: 'layout', text: 'Layout', width: 50}, {name: 'updated_at', hidden: true} ], render:{ 'id': (obj) => { return '<b>#' + obj.id + '</b>'; }, 'name': (obj) => { return '<b>' + obj.name + '</b>'; }, 'subject': (obj) => { return '<span>' + obj.subject + '</span>'; }, 'created_at': (obj) => { return '<span style="display:none;">' + obj.created_at + '</span><strong>' + time.md(obj.created_at) + '</strong>'; } }, table: ['mailtemplate', 'mailtemplates'], fields: { 'name': 'Text', 'description': 'Text', 'subject': 'Text', 'body_html': 'Textarea', 'sender_email': 'Text', 'sender_name': 'Text', 'copy_recipients': 'Text', 'mailtheme_id': { type: 'Select', table: 'mailtheme' }, 'layout': { type: 'Select', fieldname: 'layout', values: [ {id: 'default', name: 'default'}, {id: 'system', name: 'system'}, ] } } });
    }
};

export default admin_mailtemplates;
