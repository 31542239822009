import audio from './audio';
import app from './app';
import infosystem from './infosystem';

const suckit_actions = {

  'info_update': () => {
    infosystem.updateMsgCount();
  },

  'gong': () => {
    app.$wrapper.addClass('one-spin');
    setTimeout(() => {
      app.$wrapper.removeClass('one-spin');
    },3000);
    audio.playSound('gong.mp3');
  },

  'snowflakes': () => {
    helper.loadScript('/plugins/snowflakes/snowflakes.min.js', () => {
      new Snowflakes({
        color: '#fff', // Default: "#5ECDEF"
      });

      setTimeout(() => {
        audio.playSound('hohoho.mp3');
      },3000);

    });
  }
};

export default suckit_actions;
