$.fn.uiTable = function (options) {
  // support multiple elements
  if (this.length > 1){
    this.each(() => { $(this).uiTable(options) });
    return this;
  }

  // default options

  options = $.extend({},{
    title: false,
    render: {},
    i18n_prefix: '',
    table_options: {},
  }, options);

  // private variables
  let $header = null;
  let $body = null;
  let $loader = null;

  let plugin = this;

  let $element = $(this);
  // ...

  // private methods
  let foo = () => {
    // do something ...
  };
  // ...

  // public methods
  this.initialize = () => {

    $element.addClass('table table-hover');

    /*
     * add Title
     */
    if(options.cols !== false) {

      let $header_row = $('<tr></tr>');
      $.each(options.cols, (i, field) => {

        let $col = $('<th></th>');
        if(typeof field !== 'string') {
          $col.text(field.text);
          if(field.width !== undefined) {
            $col.css('width', field.width+'px');
          }
        }
        else {
          $col.text(t(options.i18n_prefix + field));
        }

        $header_row.append($col);
      });

      $header = $('<thead></thead>');
      $header.append($header_row);
      $element.append($header);

    }

    /*
     * add body
     */
    $body = $('<tbody></tbody>');
    $element.append($body);

    this.$body = $body;

    return this;
  };

  this.clear = () => {
    $body.html('');
  };

  this.addRowTool = ($btn) => {

  };

  this.setData = (data) => {
    $body.find('.data-row').remove();

    $.each(data, (i, row) => {
      let $row = $('<tr class="data-row"></tr>');

      $.each(options.cols, (i, field) => {
        let col = field;

        if(typeof col !== 'string') {
          col = field.name;
        }

        if(options.render[col] !== undefined) {

          let val = options.render[col](row);
          if(typeof val === 'string') {
            val = '<td>' + val + '</td>';
          }

          $row.append(val);
        }
        else {
          if(typeof row[col] === 'object') {
            $row.append('<td>' + row[col].name + '</td>');
          }
          else {
            $row.append('<td>' + row[col] + '</td>');
          }
        }
      });

      if(options.onRowClick !== undefined) {

        $row.find('td:not(:last-child)').addClass('has-cursor-pointer').click(() => {
          options.onRowClick(row);
        });
      }

      $body.append($row);

    });
  };

  return this.initialize();
};
