jQuery.fn.extend({
  autoHeight: function () {
    function autoHeight_(element) {
      return jQuery(element).css({
        'height': 'auto',
        'overflow-y': 'hidden'
      }).height((element.scrollHeight-10));
    }
    return this.each(function () {
      autoHeight_(this).on('input', function () {
        autoHeight_(this);
      });
    });
  }
});
