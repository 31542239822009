import api from '../api';

$.fn.uiFormPassword = function(options) {
  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $input;

  options = $.extend({},{
    i18n_prefix: '',
    default: ''
  }, options);

  let autocomplete = '';
  if (typeof options.autocomplete === 'string') {
    autocomplete = ' autocomplete="off"';
  }
  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);

  let plugin = this;

  // public methods
  this.initialize = () => {

    $label = $('<label>' + options.label + '</label>');
    $input = $('<input name="' + options.name + '"' + autocomplete + ' class="form-control" type="password" placeholder="' + options.placeholder + '" value="' + options.default + '" />');

    $element.addClass('form-group form-group-'+options.name).append($label).append($input);

    this.$input = $input;

    return plugin;

  };

  this.setValue = (value) => {
    $input.val(value);
    $label.text(options.label_edit);
    $input.attr('placeholder', options.placeholder_edit);
  };

  this.resetValue = () => {
    $input.val(options.default);
    $label.text(options.label);
    $input.attr('placeholder', options.placeholder);
  };

  return this.initialize();
};


