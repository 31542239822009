import media from '../media';
import session from '../session';
import api from '../api';
import msg from '../msg';
import time from '../time';
import helper from '../helper';
import fileicons from '../fileicons';
import history from '../history';
import button_save from "../../_compiled/components/button_save";
import modal from "../../_compiled/components/modal";
import cvdataform from "../cvdataform";

$.fn.uiTimeline = function(options) {

  // default options

  options = $.extend({},{
    title: false,
    success: () => {},
    timeline_id: null,
    timeline_ids: [],
    string_vars: {},
    type: 'default',
    hidden_types: []
  }, options);

  const icons = history.icons;
  const colors = history.colors;

  let $timeline, $comment;
  let $modal_date,  $form_date;
  let $modal_comment, $form_edit_comment;
  let edit_id = null;

  let getAttachmentText = (item) => {
    let attachmentText = "";
    // add attachment links
    if(typeof item['data_mail_file_attachment'] !== 'undefined') {
      attachmentText = "<br>";
      for (let count = 0; count < item['data_mail_file_attachment'].length; count++) {
        attachmentText += "<br> <i class='fas fa-paperclip'></i> <a target='_blank' href='/api/v1/candidatedocs/" + item['data_data']['candidate_id'] + "/open?path=" + item["data_mail_file_attachment"][count] +"'> " + item["data_mail_file_attachment"][count] + " </a>";
      }
    }
    return attachmentText;
  }

  const render = {

    'default': (item) => {

      let the_color = 'background-color: #FAFAFA; color: #6c757d;';
      if(colors[item.type] !== undefined) {
        the_color = colors[item.type];
      }

      let the_icon = 'fas fa-bell';
      if(icons[item.type] !== undefined) {
        the_icon = icons[item.type];
      }

      return $(`
        <div>
          <i class="` + the_icon + `" style="` + the_color + `"></i>

          <div class="timeline-item">
            <span class="time"><i class="far fa-clock"></i> ` + time.full(item.created_at) + ` <i class="fas fa-pencil-alt edit-date" data-id="${item.id}"></i></span>

            <h3 class="timeline-header">` + t('timeline.'+item.type, item) + `</h3>

            <div class="timeline-body" style="display: none;">

            </div>
            <div class="timeline-footer">
                <a data-id="` + item.id + `" href="#" class="delete-history btn btn-danger btn-xs"><i class="fas fa-times"></i> löschen</a>
            </div>
          </div>
        </div>  
    `);
    },

    'application': (item) => {
      let jobadvert = '';
      if (item.data_jobadvert !== undefined) {
        jobadvert = '<p>Auf uns aufmerksam geworden durch: ' + item.data_jobadvert + '</p>';
      }

      let jobcomment = '';
      if (item.data_jobcomment !== undefined) {
        jobcomment = '<p>Kommentar: ' + item.data_jobcomment + '</p>';
      }

      return $(`
        <div>
          <i class="fas fa-building" style="background-color: #FAFAFA; color: #6c757d;"></i>

          <div class="timeline-item">
            <span class="time"><i class="far fa-clock"></i> ${time.full(item.created_at)} <i class="fas fa-pencil-alt edit-date" data-id="${item.id}"></i></span>

            <h3 class="timeline-header">${t('timeline.'+item.type, item)}</h3>

            <div class="timeline-body">${jobadvert}${jobcomment}</div>
            
            <div class="timeline-footer">
                <a data-id="${item.id}" href="#" class="delete-history btn btn-danger btn-xs"><i class="fas fa-times"></i> löschen</a>
            </div>
          </div>
        </div>
    `);
    },

    'comment': (item) => {

      return $(`
        <div>
          <i class="fas fa-comment" style="background-color: #FAFAFA; color: #6c757d;"></i>

          <div class="timeline-item">
            <span class="time"><i class="far fa-clock"></i> ` + time.full(item.created_at) + ` <i class="fas fa-pencil-alt edit-date" data-id="${item.id}"></i></span>

            <h3 class="timeline-header">` + t('timeline.' + item.type + '_' + options.type, item) + `</h3>

            <div class="timeline-body">` + helper.nl2br(item.text) + `</div>
            <div class="timeline-footer">
              <a data-id="` + item.id + `" href="#" class="btn btn-primary btn-xs edit-comment-but">Bearbeiten</a>
              <a data-id="` + item.id + `" href="#" class="delete-history lll btn btn-danger btn-xs"><i class="fas fa-times"></i> löschen</a>
            </div>
          </div>
        </div>  
    `);
    },

    'upload_cdocs': (item) => {

      let doclist = '';

      let stringtype = '_one';

      if(item.data_count > 1) {
        stringtype = '_multiple';
      }

      if(item.data_files) {
        item.data_files.forEach((file) => {
          doclist += '<a data-file="'+file.name+'" class="list-group-item" href="#"><i class="' + fileicons.byFile(file.name) + '"></i> ' + file.name + '</a>';
        });
      }

      doclist = '<div class="doclist list-group">' + doclist + '</div>';

      let $return = $(`
        <div>
          <i class="fas fa-file-alt" style="background-color: #FAFAFA; color: #6c757d;"></i>

          <div class="timeline-item">
            <span class="time"><i class="far fa-clock"></i> ` + time.full(item.created_at) + ` <i class="fas fa-pencil-alt edit-date" data-id="${item.id}"></i></span>

            <h3 class="timeline-header">` + t('timeline.' + item.type + stringtype, item) + `</h3>

            <div class="timeline-body">
                
                ` + doclist + `
            
            </div>
            <div class="timeline-footer">
              <a data-id="` + item.id + `" href="#" class="delete-history btn btn-danger btn-xs"><i class="fas fa-times"></i> löschen</a>
            </div>
          </div>
        </div>  
    `);

      $return.find('.doclist a').click((ev) => {
        ev.preventDefault();

        let $this = $(ev.currentTarget);
        helper.popup('/api/v1' + options.docs_uri + '/open?' + $.param( {path:$this.data('file'), auth_token: session.getToken()} ));
      });

      return $return;

    },

    'add_cvcheck': (item) => {
      let doclist = '';

      if(item.data_storage_path) {
        doclist = '<a data-file="'+item.data_storage_name+'" class="list-group-item" href="#"><i class="' + fileicons.byFile(item.data_storage_name)
          + '"></i> ' + item.data_storage_name + '</a>';
      }

      doclist = '<div class="doclist list-group">' + doclist + '</div>';

      let $return = $(`
        <div>
          <i class="fas fa-file-alt" style="background-color: #FAFAFA; color: #6c757d;"></i>

          <div class="timeline-item">
            <span class="time"><i class="far fa-clock"></i> ` + time.full(item.created_at) + ` <i class="fas fa-pencil-alt edit-date" data-id="${item.id}"></i></span>

            <h3 class="timeline-header">` + t('timeline.' + item.type, item) + `</h3>

            <div class="timeline-body">
                ` + doclist + `
            </div>
            <div class="timeline-footer">
              <a data-id="` + item.id + `" href="#" class="delete-history btn btn-danger btn-xs"><i class="fas fa-times"></i> löschen</a>
            </div>
          </div>
        </div>  
    `);

      $return.find('.doclist a').click((ev) => {
        ev.preventDefault();

        let $this = $(ev.currentTarget);
        helper.popup('/api/v1' + options.docs_uri + '/opencvcheck?' + $.param( {path:$this.data('file'), auth_token: session.getToken()} ));
      });

      return $return;
    },

    'addtask': (item) => {

      let task_checkbox = '';
      if (item.task_done !== undefined){
        let status = '';
        if (item.task_done === 1) {
          status = ' checked="checked"';
        }

        task_checkbox = '<div class="form-check form-check-inline"><input type="checkbox" class="form-check-input timeline-task-checkbox" name="timeline-task-done-' + item.data_id
                      + '" value="' + item.data_id + '"' + status + ' /><label class="form-check-label">erledigt </label></div>';
      }

      return $(`
        <div>
          <i class="fas fa-tasks" style="background-color: #FAFAFA; color: #6c757d;"></i>

          <div class="timeline-item">
            <span class="time"><i class="far fa-clock"></i> ` + time.full(item.created_at) + ` <i class="fas fa-pencil-alt edit-date" data-id="${item.id}"></i></span>

            <h3 class="timeline-header">` + t('timeline.'+item.type, item) + `</h3>

            <div class="timeline-body">
                
                <div>
                  <strong><a href="/crm/task/${item.data_id}" class="router-link">${item.data_name}</a></strong>
                  <p class="text-muted">
                    ` + item.data_description + `
                  </p>
                  <strong><i class="far fa-clock"></i> ` + time.full(item.data_due) + `</strong>
              </div>
            
            </div>
            <div class="timeline-footer">
              ` + task_checkbox + `  
              <a data-id="` + item.id + `" href="#" class="delete-history btn btn-danger btn-xs"><i class="fas fa-times"></i> löschen</a>
            </div>
          </div>
        </div>  
    `);

    },

    'jobstatus_multiedit': (item) => {


      let jobs = [];

      if(item.data_jobs && item.data_jobs.length > 0) {

        item.data_jobs.forEach((job) => {
          jobs.push(`
          <li class="text-muted">
               <a href="https://jungwild.io/jobs/${job.slug}-${job.id}" target="_blank">${job.title}</a> <span style="color:${helper.calcFontColor(item.data_status_color)};background-color: ${item.data_status_color}" class="badge">${item.data_status_name}</span>
            </li>
          `);
        });

      }

      /* Titel des Timeline Eintrags */
      let trans = 'timeline.' + item.type;

      if (item.data_status_date !== undefined) {
        trans = 'timeline.' + item.type + '_status_date';
      }

      /* Status Kommentar */
      let status_comment = '';

      if (item.data_status_comment !== undefined && item.data_status_comment !== null) {
        status_comment = `<p><em>"${item.data_status_comment}"</em></p>`;
      }

      return $(`
        <div>
          <i class="fas fa-building" style="background-color: #FAFAFA; color: #6c757d;"></i>

          <div class="timeline-item">
            <span class="time"><i class="far fa-clock"></i>${time.full(item.created_at)}<i class="fas fa-pencil-alt edit-date" data-id="${item.id}"></i></span>

            <h3 class="timeline-header">${t(trans, item)}</h3>

            <div class="timeline-body">
                
              <ul>
                ${jobs.join('')}
              </ul>
              
              ${status_comment}
            
            </div>
            <div class="timeline-footer">
              <a data-id="${item.id}" href="#" class="delete-history btn btn-danger btn-xs"><i class="fas fa-times"></i> löschen</a>
            </div>
          </div>
        </div>  
    `);

    },

    'jobstatus_pool_add': (item) => {

      let jobs = [];

      if(item.data_jobs && item.data_jobs.length > 0) {

        item.data_jobs.forEach((job) => {
          jobs.push(`
          <li class="text-muted">
               <a href="https://jungwild.io/jobs/${job.slug}-${job.id}" target="_blank">${job.name}</a> <span style="color:${helper.calcFontColor(item.data_status_color)};background-color:${item.data_status_color}" class="badge">${item.data_status_name}</span>
            </li>
          `);
        });

      }

      let trans = 'timeline.' + item.type;

      if (item.data_status_date !== undefined) {
        trans = 'timeline.' + item.type + '_status_date';
      }

      /* Status Kommentar */
      let status_comment = '';

      if (item.data_status_comment !== undefined && item.data_status_comment !== null) {
        status_comment = `<p><em>"${item.data_status_comment}"</em></p>`;
      }

      return $(`
        <div>
          <i class="fas fa-building" style="background-color: #FAFAFA; color: #6c757d;"></i>

          <div class="timeline-item">
            <span class="time"><i class="far fa-clock"></i>${time.full(item.created_at)}<i class="fas fa-pencil-alt edit-date" data-id="${item.id}"></i></span>

            <h3 class="timeline-header">${t(trans, item)}</h3>

            <div class="timeline-body">
                
              <ul>
                  ${jobs.join('')}
              </ul>
              ${status_comment}
            
            </div>
            <div class="timeline-footer">
              <a data-id="${item.id}" href="#" class="delete-history btn btn-danger btn-xs"><i class="fas fa-times"></i> löschen</a>
            </div>
          </div>
        </div>  
    `);
    },

    'jobstatus_add': (item) => {

      let trans = 'timeline.' + item.type;

      if (item.data_status_date !== undefined) {
        trans = 'timeline.' + item.type + '_status_date';
      }

      /* Status Kommentar */
      let status_comment = '';

      if (item.data_status_comment !== undefined && item.data_status_comment !== null) {
        status_comment = `<p><em>"${item.data_status_comment}"</em></p>`;
      }

      return $(`
        <div>
          <i class="fas fa-building" style="background-color: #FAFAFA; color: #6c757d;"></i>

          <div class="timeline-item">
            <span class="time"><i class="far fa-clock"></i>${time.full(item.created_at)}<i class="fas fa-pencil-alt edit-date" data-id="${item.id}"></i></span>

            <h3 class="timeline-header">${t(trans, item)}</h3>

            <div class="timeline-body">
                
                <div>
                  <strong><i class="fas fa-building"></i>${item.data_organisation_name}</strong>
                  <p class="text-muted">
                    ${item.data_job_title} <span style="color:${helper.calcFontColor(item.data_status_color)};background-color: ${item.data_status_color}" class="badge">${item.data_status_name}</span>
                  </p>
                  ${status_comment}
              </div>
            
            </div>
            <div class="timeline-footer">
              <a data-id="${item.id}" href="#" class="delete-history btn btn-danger btn-xs"><i class="fas fa-times"></i> löschen</a>
            </div>
          </div>
        </div>  
    `);
    },

    'jobstatus_delete': (item) => {

      return $(`
        <div>
          <i class="fas fa-building" style="background-color: #FAFAFA; color: #6c757d;"></i>

          <div class="timeline-item">
            <span class="time"><i class="far fa-clock"></i> ` + time.full(item.created_at) + ` <i class="fas fa-pencil-alt edit-date" data-id="${item.id}"></i></span>

            <h3 class="timeline-header">` + t('timeline.'+item.type, item) + `</h3>

            <div class="timeline-body">
                
                <div>
                  <strong><i class="fas fa-building"></i> ` + item.data_organisation_name + `</strong>
                  <p class="text-muted">
                    ` + item.data_job_title + `
                  </p>
              </div>
            
            </div>
            <div class="timeline-footer">
              <a data-id="` + item.id + `" href="#" class="delete-history btn btn-danger btn-xs"><i class="fas fa-times"></i> löschen</a>
            </div>
          </div>
        </div>  
    `);
    },

    'sendmail': (item) => {

      let teasertext = '';
      /*if(item.text) {
        teasertext = item.text;
        if(teasertext.length > 100) {
          teasertext = item.text.substring(0,100) + '...';
        }
      }*/

      if (item.teaser) {
        teasertext = item.teaser;
        if(teasertext.length > 150) {
          teasertext = item.text.substring(0,150) + '...';
        }
      }

      let headline = '';

      if(parseInt(item.user_id) > 0) {

        if (item.hasOwnProperty('data_data')) {

          if (item.data_data.hasOwnProperty('send_as_name')) {
            item['send_as_name'] = item.data_data.send_as_name;
            headline = t('timeline.sendmail_as_another', item)
          }
          else {
            headline = t('timeline.sendmail', item)
          }

        }
        else {
          headline = t('timeline.sendmail', item)
        }

      }
      else {
        headline = t('timeline.sendmail_no_user', item)
      }

      /* add CC Recipients to headline */
      let cc_bcc = [];

      if (item.hasOwnProperty('data_data')) {
        if (item.data_data.hasOwnProperty('cc_recruiter')) {
          item.data_data.cc_recruiter.forEach((item) => {
            cc_bcc.push(item.name);
          });
        }
        if (item.data_data.hasOwnProperty('cc_extern')) {
          item.data_data.cc_extern.forEach((item) => {
            cc_bcc.push(item);
          });
        }
      }

      if (!helper.empty(cc_bcc)) {
        headline += ' (CC an: ' + cc_bcc.join(', ') + ')';
      }

      let attachmentText = getAttachmentText(item);


      return $(`
        <div>
          <i class="fas fa-envelope" style="background-color: #FAFAFA; color: #6c757d;"></i>

          <div class="timeline-item">
            <span class="time"><i class="far fa-clock"></i> ` + time.full(item.created_at) + ` <i class="fas fa-pencil-alt edit-date" data-id="${item.id}"></i></span>

            <h3 class="timeline-header">` + headline + `</h3>

            <div class="timeline-body scroll-600">
                <div class="teasertext">` + teasertext + `</div>
                <div style="display:none;" class="fulltext">` + helper.nl2br(item.text+'') + attachmentText +`</div>
            </div>
            <div class="timeline-footer">
                <a href="#" onclick="$(this).parent().parent().find('.teasertext').hide().parent().find('.fulltext').show();$(this).next().show().prev().hide();return false;" class="btn btn-primary btn-xs">mehr <i class="fas fa-caret-down"></i></a>
                <a onclick="$(this).parent().parent().find('.fulltext').hide().parent().find('.teasertext').show();$(this).prev().show().next().hide();return false;" style="display: none;" href="#" class="btn btn-primary btn-xs">weniger <i class="fas fa-caret-up"></i></a>
                <a data-id="` + item.id + `" href="#" class="delete-history btn btn-danger btn-xs"><i class="fas fa-times"></i> löschen</a>
            </div>
          </div>
        </div>  
    `);
    },

    'timed_email': (item) => {

      item['send_time'] = item.data_data.send_time;

      let teasertext = '';
      /*if(item.text) {
        teasertext = item.text;
        if(teasertext.length > 100) {
          teasertext = item.text.substring(0,100) + '...';
        }
      }*/

      if (item.teaser) {
        teasertext = item.teaser;
        if(teasertext.length > 150) {
          teasertext = item.text.substring(0,150) + '...';
        }
      }

      let headline = '';

      if(parseInt(item.user_id) > 0) {

        if (item.hasOwnProperty('data_data')) {

          if (item.data_data.hasOwnProperty('send_as_name')) {
            item['send_as_name'] = item.data_data.send_as_name;
            headline = t('timeline.timed_email_as_another', item)
          }
          else {
            headline = t('timeline.timed_email', item)
          }

        }
        else {
          headline = t('timeline.timed_email', item)
        }

      }
      else {
        headline = t('timeline.timed_email_no_user', item)
      }

      let delete_email = '';
      if(item.data_data.hasOwnProperty('mailing_id')) {
        delete_email = `<a data-id="` + item.data_data.mailing_id +
          `" href="#" class="delete-email btn btn-danger btn-xs"><i class="fas fa-times"></i> E-Mail löschen </a>`;
      }

      let cc_bcc = [];

      if (item.hasOwnProperty('data_data')) {
        if (item.data_data.hasOwnProperty('cc_recruiter')) {
          item.data_data.cc_recruiter.forEach((item) => {
            cc_bcc.push(item.name);
          });
        }
        if (item.data_data.hasOwnProperty('cc_extern')) {
          item.data_data.cc_extern.forEach((item) => {
            cc_bcc.push(item);
          });
        }
      }

      if (!helper.empty(cc_bcc)) {
        headline += ' (CC an: ' + cc_bcc.join(', ') + ')';
      }

      let attachmentText = getAttachmentText(item);

      return $(`
        <div>
          <i class="fas fa-envelope" style="background-color: #FAFAFA; color: #6c757d;"></i>

          <div class="timeline-item">
            <span class="time"><i class="far fa-clock"></i> ` + time.full(item.created_at) + ` <i class="fas fa-pencil-alt edit-date" data-id="${item.id}"></i></span>

            <h3 class="timeline-header">` + headline + `</h3>

            <div class="timeline-body scroll-600">
                <div class="teasertext">` + teasertext + `</div>
                <div style="display:none;" class="fulltext">` + helper.nl2br(item.text+'') + attachmentText + `</div>
            </div>
            <div class="timeline-footer">
                <a href="#" onclick="$(this).parent().parent().find('.teasertext').hide().parent().find('.fulltext').show();$(this).next().show().prev().hide();return false;" class="btn btn-primary btn-xs">mehr <i class="fas fa-caret-down"></i></a>
                <a onclick="$(this).parent().parent().find('.fulltext').hide().parent().find('.teasertext').show();$(this).prev().show().next().hide();return false;" style="display: none;" href="#" class="btn btn-primary btn-xs">weniger <i class="fas fa-caret-up"></i></a>
                
                ` + delete_email + `
            </div>
          </div>
        </div>  
    `);
    },

    'favorite_candidate': (item) => {

      let headline = '';

      if(parseInt(item.user_id) > 0) {
        item.display_name = item.data_display_name;
        item.fav_until = item.data_fav_until;
        headline = t('timeline.favorite_candidate', item);
      }

      return $(`
        <div>
          <i class="fas fa-envelope" style="background-color: #FAFAFA; color: #6c757d;"></i>

          <div class="timeline-item">
            <span class="time"><i class="far fa-clock"></i> ` + time.full(item.created_at) + ` <i class="fas fa-pencil-alt edit-date" data-id="${item.id}"></i></span>

            <h3 class="timeline-header">` + headline + `</h3>

            <div class="timeline-body"></div>
            <div class="timeline-footer">
                <a data-id="` + item.id + `" href="#" class="delete-history btn btn-danger btn-xs"><i class="fas fa-times"></i> löschen</a>
            </div>
          </div>
        </div>  
    `);
    },

    'unfavorite_candidate': (item) => {

      let headline = '';

      if(parseInt(item.user_id) > 0) {
        item.display_name = item.data_display_name;
        headline = t('timeline.unfavorite_candidate', item);
      }

      return $(`
        <div>
          <i class="fas fa-envelope" style="background-color: #FAFAFA; color: #6c757d;"></i>

          <div class="timeline-item">
            <span class="time"><i class="far fa-clock"></i> ` + time.full(item.created_at) + ` <i class="fas fa-pencil-alt edit-date" data-id="${item.id}"></i></span>

            <h3 class="timeline-header">` + headline + `</h3>

            <div class="timeline-body"></div>
            <div class="timeline-footer">
                <a data-id="` + item.id + `" href="#" class="delete-history btn btn-danger btn-xs"><i class="fas fa-times"></i> löschen</a>
            </div>
          </div>
        </div>  
    `);
    },

    'oo_mail_recieve': (item) => {

      let $return = $(`
        <div>
          <i class="fas fa-envelope" style="background-color: #FAFAFA; color: #6c757d;"></i>

          <div class="timeline-item">
            <span class="time"><i class="far fa-clock"></i> ` + time.full(item.created_at) + ` <i class="fas fa-pencil-alt edit-date" data-id="${item.id}"></i></span>

            <h3 class="timeline-header">` + t('timeline.'+item.type, item) + `</h3>

            <div class="timeline-body scroll-600">
                <div class="subject"><strong>` + item.data_subject + `</strong></div>
                <div class="teasertext">` + item.data_introduction + `...</div>
            </div>
            <div class="timeline-footer">
                <a href="https://office.jungwild.info` + item.data_message_url + `" target="_blank" class="btn btn-primary btn-xs">zur kompletten Mail</a>
                <a data-id="` + item.id + `" href="#" class="delete-history btn btn-danger btn-xs"><i class="fas fa-times"></i> löschen</a>
            </div>
          </div>
        </div>  
    `);

      return $return;

    },

    'oo_mail_sent': (item) => {

      let $return = $(`
        <div>
          <i class="fas fa-envelope" style="background-color: #FAFAFA; color: #6c757d;"></i>

          <div class="timeline-item">
            <span class="time"><i class="far fa-clock"></i> ` + time.full(item.created_at) + ` <i class="fas fa-pencil-alt edit-date" data-id="${item.id}"></i></span>

            <h3 class="timeline-header">` + t('timeline.'+item.type, item) + `</h3>

            <div class="timeline-body scroll-600">
                <div class="subject"><strong>` + item.data_subject + `</strong></div>
                <div class="teasertext">` + item.data_introduction + `...</div>
            </div>
            <div class="timeline-footer">
                <a href="https://office.jungwild.info` + item.data_message_url + `" target="_blank" class="btn btn-primary btn-xs">zur kompletten Mail</a>
                <a data-id="` + item.id + `" href="#" class="delete-history btn btn-danger btn-xs"><i class="fas fa-times"></i> löschen</a>
            </div>
          </div>
        </div>  
    `);

      return $return;

    },

    'receivemail': (item) => {

      let teasertext = '';
      if (item.teaser) {
        teasertext = item.teaser;
      }

      let btns_attachments = '';

      if(item.data_attachments && item.data_attachments.length > 0) {

        let dlink = [];
        item.data_attachments.forEach((attach) => {
          let attach_name = attach.split('/')[1];
          dlink.push('<a class="dropdown-item email-attachment" href="#" data-path="' + attach + '"><i class="' + fileicons.byFile(attach_name) + '"></i> ' + attach_name + '</a>');
        });

        btns_attachments = `
        <span class="dropdown attachment-download-dropdown">
          <button class="btn btn-secondary dropdown-toggle btn-xs" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-paperclip"></i> Anhänge <span class="badge badge-light">` + item.data_attachments.length + `</span>
          </button>
          <div class="dropdown-menu">
            ` + dlink.join('') + `
          </div>
        </span>`;
      }

      item.data_title_link = '#';

      let btn_answer = '';
      if(options.type === 'candidate') {
        btn_answer = '<a href="#" class="btn btn-primary btn-xs receivemail-answer"><i class="fas fa-caret-right"></i> antworten</a>';
      }


      let $return = $(`
        <div>
          <i class="fas fa-envelope" style="background-color: #FAFAFA; color: #6c757d;"></i>

          <div class="timeline-item">
            <span class="time"><i class="far fa-clock"></i> ` + time.full(item.created_at) + ` <i class="fas fa-pencil-alt edit-date" data-id="${item.id}"></i> <a class="delete-history" href="#" data-id="` + item.id + `"><i class="fas fa-times"></i></a></span>

            <h3 class="timeline-header">` + t('timeline.'+item.type, item) + `</h3>

            <div class="timeline-body scroll-600">
                <div class="subject"><strong>` + item.data_subject + `</strong></div>
                <div class="teasertext">` + teasertext + `</div>
                <div style="display:none;" class="fulltext">` + helper.nl2br(item.text+'') + `</div>
            </div>
            <div class="timeline-footer">
                <a href="#" class="btn btn-primary btn-xs receivemail-more">mehr <i class="fas fa-caret-down"></i></a>
                <a href="#" class="btn btn-primary btn-xs receivemail-less" style="display: none;">weniger <i class="fas fa-caret-up"></i></a>
                ` + btn_answer + `
                ` + btns_attachments + `
                <a data-id="` + item.id + `" href="#" class="delete-history btn btn-danger btn-xs"><i class="fas fa-times"></i> löschen</a>
            </div>
          </div>
        </div>  
    `);

      $return.find('.email-attachment').click((ev) => {
        ev.preventDefault();
        let $this = $(ev.currentTarget);
        helper.popup('/api/v1' + options.docs_uri + '/open?' + $.param( {path:$this.data('path'), auth_token: session.getToken()} ));
        helper.popup('/api/v1' + options.docs_uri + '/open?' + $.param( {path:$this.data('path'), auth_token: session.getToken()} ));
      });

      return $return;

    },

    'receivemail_to': (item) => {

      let teasertext = '';
      if (item.teaser) {
        teasertext = item.teaser;
      }

      let btns_attachments = '';

      if(item.data_attachments && item.data_attachments.length > 0) {

        let dlink = [];
        item.data_attachments.forEach((attach) => {
          let attach_name = attach.split('/')[1];
          dlink.push('<a class="dropdown-item email-attachment" href="#" data-path="' + attach + '"><i class="' + fileicons.byFile(attach_name) + '"></i> ' + attach_name + '</a>');
        });

        btns_attachments = `
        <span class="dropdown attachment-download-dropdown">
          <button class="btn btn-secondary dropdown-toggle btn-xs" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-paperclip"></i> Anhänge <span class="badge badge-light">` + item.data_attachments.length + `</span>
          </button>
          <div class="dropdown-menu">
            ` + dlink.join('') + `
          </div>
        </span>`;
      }

      item.data_title_link = '#';

      let btn_answer = '';
      if(options.type === 'candidate') {
        btn_answer = '<a href="#" class="btn btn-primary btn-xs receivemail-answer"><i class="fas fa-caret-right"></i> antworten</a>';
      }


      let $return = $(`
        <div>
          <i class="fas fa-envelope" style="background-color: #FAFAFA; color: #6c757d;"></i>

          <div class="timeline-item">
            <span class="time"><i class="far fa-clock"></i> ` + time.full(item.created_at) + ` <i class="fas fa-pencil-alt edit-date" data-id="${item.id}"></i> <a class="delete-history" href="#" data-id="` + item.id + `"><i class="fas fa-times"></i></a></span>

            <h3 class="timeline-header">` + t('timeline.'+item.type, item) + `</h3>

            <div class="timeline-body scroll-600">
                <div class="subject"><strong>` + item.data_subject + `</strong></div>
                <div class="teasertext">` + teasertext + `</div>
                <div style="display:none;" class="fulltext">` + helper.nl2br(item.text+'') + `</div>
            </div>
            <div class="timeline-footer">
                <a href="#" class="btn btn-primary btn-xs receivemail-more">mehr <i class="fas fa-caret-down"></i></a>
                <a href="#" class="btn btn-primary btn-xs receivemail-less" style="display: none;">weniger <i class="fas fa-caret-up"></i></a>
                ` + btn_answer + `
                ` + btns_attachments + `
                <a data-id="` + item.id + `" href="#" class="delete-history btn btn-danger btn-xs"><i class="fas fa-times"></i> löschen</a>
            </div>
          </div>
        </div>  
    `);

      $return.find('.email-attachment').click((ev) => {
        ev.preventDefault();
        let $this = $(ev.currentTarget);
        helper.popup('/api/v1' + options.docs_uri + '/open?' + $.param( {path:$this.data('path'), auth_token: session.getToken()} ));
        helper.popup('/api/v1' + options.docs_uri + '/open?' + $.param( {path:$this.data('path'), auth_token: session.getToken()} ));
      });

      return $return;

    },

    'receivemail_cc': (item) => {

      let teasertext = '';
      if (item.teaser) {
        teasertext = item.teaser;
      }

      let btns_attachments = '';

      if(item.data_attachments && item.data_attachments.length > 0) {

        let dlink = [];
        item.data_attachments.forEach((attach) => {
          let attach_name = attach.split('/')[1];
          dlink.push('<a class="dropdown-item email-attachment" href="#" data-path="' + attach + '"><i class="' + fileicons.byFile(attach_name) + '"></i> ' + attach_name + '</a>');
        });

        btns_attachments = `
        <span class="dropdown attachment-download-dropdown">
          <button class="btn btn-secondary dropdown-toggle btn-xs" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-paperclip"></i> Anhänge <span class="badge badge-light">` + item.data_attachments.length + `</span>
          </button>
          <div class="dropdown-menu">
            ` + dlink.join('') + `
          </div>
        </span>`;
      }

      item.data_title_link = '#';

      let btn_answer = '';
      if(options.type === 'candidate') {
        btn_answer = '<a href="#" class="btn btn-primary btn-xs receivemail-answer"><i class="fas fa-caret-right"></i> antworten</a>';
      }

      if (Array.isArray(item.data_to)) {
        item.original_receiver = `<a href="mailto:${item.data_to[0]}">${item.data_to[0]}</a>`;
      }

      let $return = $(`
        <div>
          <i class="fas fa-envelope" style="background-color: #FAFAFA; color: #6c757d;"></i>

          <div class="timeline-item">
            <span class="time"><i class="far fa-clock"></i> ` + time.full(item.created_at) + ` <i class="fas fa-pencil-alt edit-date" data-id="${item.id}"></i> <a class="delete-history" href="#" data-id="` + item.id + `"><i class="fas fa-times"></i></a></span>

            <h3 class="timeline-header">` + t('timeline.'+item.type, item) + `</h3>

            <div class="timeline-body scroll-600">
                <div class="subject"><strong>` + item.data_subject + `</strong></div>
                <div class="teasertext">` + teasertext + `</div>
                <div style="display:none;" class="fulltext">` + helper.nl2br(item.text+'') + `</div>
            </div>
            <div class="timeline-footer">
                <a href="#" class="btn btn-primary btn-xs receivemail-more">mehr <i class="fas fa-caret-down"></i></a>
                <a href="#" class="btn btn-primary btn-xs receivemail-less" style="display: none;">weniger <i class="fas fa-caret-up"></i></a>
                ` + btn_answer + `
                ` + btns_attachments + `
                <a data-id="` + item.id + `" href="#" class="delete-history btn btn-danger btn-xs"><i class="fas fa-times"></i> löschen</a>
            </div>
          </div>
        </div>  
    `);

      $return.find('.email-attachment').click((ev) => {
        ev.preventDefault();
        let $this = $(ev.currentTarget);
        helper.popup('/api/v1' + options.docs_uri + '/open?' + $.param( {path:$this.data('path'), auth_token: session.getToken()} ));
        helper.popup('/api/v1' + options.docs_uri + '/open?' + $.param( {path:$this.data('path'), auth_token: session.getToken()} ));
      });

      return $return;

    },

    'receivemail_bcc': (item) => {

      let teasertext = '';
      if (item.teaser) {
        teasertext = item.teaser;
      }

      let btns_attachments = '';

      if(item.data_attachments && item.data_attachments.length > 0) {

        let dlink = [];
        item.data_attachments.forEach((attach) => {
          let attach_name = attach.split('/')[1];
          dlink.push('<a class="dropdown-item email-attachment" href="#" data-path="' + attach + '"><i class="' + fileicons.byFile(attach_name) + '"></i> ' + attach_name + '</a>');
        });

        btns_attachments = `
        <span class="dropdown attachment-download-dropdown">
          <button class="btn btn-secondary dropdown-toggle btn-xs" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-paperclip"></i> Anhänge <span class="badge badge-light">` + item.data_attachments.length + `</span>
          </button>
          <div class="dropdown-menu">
            ` + dlink.join('') + `
          </div>
        </span>`;
      }

      item.data_title_link = '#';

      let btn_answer = '';
      if(options.type === 'candidate') {
        btn_answer = '<a href="#" class="btn btn-primary btn-xs receivemail-answer"><i class="fas fa-caret-right"></i> antworten</a>';
      }

      if (Array.isArray(item.data_to)) {
        item.original_receiver = `<a href="mailto:${item.data_to[0]}">${item.data_to[0]}</a>`;
      }

      let $return = $(`
        <div>
          <i class="fas fa-envelope" style="background-color: #FAFAFA; color: #6c757d;"></i>

          <div class="timeline-item">
            <span class="time"><i class="far fa-clock"></i> ` + time.full(item.created_at) + ` <i class="fas fa-pencil-alt edit-date" data-id="${item.id}"></i> <a class="delete-history" href="#" data-id="` + item.id + `"><i class="fas fa-times"></i></a></span>

            <h3 class="timeline-header">` + t('timeline.'+item.type, item) + `</h3>

            <div class="timeline-body scroll-600">
                <div class="subject"><strong>` + item.data_subject + `</strong></div>
                <div class="teasertext">` + teasertext + `</div>
                <div style="display:none;" class="fulltext">` + helper.nl2br(item.text+'') + `</div>
            </div>
            <div class="timeline-footer">
                <a href="#" class="btn btn-primary btn-xs receivemail-more">mehr <i class="fas fa-caret-down"></i></a>
                <a href="#" class="btn btn-primary btn-xs receivemail-less" style="display: none;">weniger <i class="fas fa-caret-up"></i></a>
                ` + btn_answer + `
                ` + btns_attachments + `
                <a data-id="` + item.id + `" href="#" class="delete-history btn btn-danger btn-xs"><i class="fas fa-times"></i> löschen</a>
            </div>
          </div>
        </div>  
    `);

      $return.find('.email-attachment').click((ev) => {
        ev.preventDefault();
        let $this = $(ev.currentTarget);
        helper.popup('/api/v1' + options.docs_uri + '/open?' + $.param( {path:$this.data('path'), auth_token: session.getToken()} ));
        helper.popup('/api/v1' + options.docs_uri + '/open?' + $.param( {path:$this.data('path'), auth_token: session.getToken()} ));
      });

      return $return;

    },

    'cvcheck_done': (item) => {
      return $(`
        <div>
          <i class="fas fa-user-check" style="background-color: #FAFAFA; color: #6c757d;"></i>

          <div class="timeline-item">
            <span class="time"><i class="far fa-clock"></i> ` + time.full(item.created_at) + ` <i class="fas fa-pencil-alt edit-date" data-id="${item.id}"></i></span>

            <h3 class="timeline-header">` + t('timeline.cvcheck_done', item) + `</h3>

            <div class="timeline-body"></div>
            
            <div class="timeline-footer">
              <a data-id="` + item.id + `" href="#" class="delete-history btn btn-danger btn-xs"><i class="fas fa-times"></i> löschen</a>
            </div>
          </div>
        </div>  
    `);

    },

    'anonymize_candidate': (item) => {

      let headline = 'Der Kandidat wurde anonymisiert';

      if(parseInt(item.user_id) > 0) {
        item.display_name = item.data_display_name;
        item.user_name = item.data_user_name;
        headline = t('timeline.anonymize_candidate', item);
      }

      return $(`
        <div>
          <i class="fas fa-eraser" style="background-color: #FAFAFA; color: #6c757d;"></i>

          <div class="timeline-item">
            <span class="time"><i class="far fa-clock"></i>${time.full(item.created_at)}<i class="fas fa-pencil-alt edit-date" data-id="${item.id}"></i></span>

            <h3 class="timeline-header">${headline}</h3>

            <div class="timeline-body">${item.text}</div>
            <div class="timeline-footer"></div>
          </div>
        </div>  
    `);
    },

    'fastapply': (item) => {
      item.data_job_id = item.data_data.job_id;
      item.data_job_title = item.data_data.job_title;
      item.data_job_slug = item.data_data.job_slug + '-' + item.data_job_id;

      let answers = [];

      for (const [key, value] of Object.entries(item.data_data)) {
        if (parseInt(key) >= 0) {
          answers.push(`<b>${value.text}:</b> ${value.answer}`);
        }
      }

      let fastapply_status = 'Nicht passend';
      let jobadvert = '';

      if (item.data_data.answer_status == true) {
        fastapply_status = 'Passend';
      }

      let jobcomment = '';
      if (item.data_data.jobcomment != null) {
        jobcomment = item.data_data.jobcomment;
      }

      if (item.data_data.jobadvert != null) {
        jobadvert = item.data_data.jobadvert;
      }

      answers.push('<b>Aufmerksam geworden durch: </b> ' + jobadvert);
      answers.push('<b>Kommentar: </b> ' + jobcomment);
      answers.push('<br /><b>Ermittelter Bewerbungsstatus: </b> ' + fastapply_status);

      return $(`
        <div>
          <i class="fas fa-building" style="background-color: #FAFAFA; color: #6c757d;"></i>

          <div class="timeline-item">
            <span class="time"><i class="far fa-clock"></i> ${time.full(item.created_at)} <i class="fas fa-pencil-alt edit-date" data-id="${item.id}"></i></span>

            <h3 class="timeline-header">${t('timeline.'+item.type, item)}</h3>

            <div class="timeline-body">${answers.join('<br />')}</div>
            
            <div class="timeline-footer">
                <a data-id="${item.id}" href="#" class="delete-history btn btn-danger btn-xs"><i class="fas fa-times"></i> löschen</a>
            </div>
          </div>
        </div>
    `);
    },
  };


  let max_id = 0;
  let plugin = this;
  let start = 0;
  let offset = 20;
  let table_ready_to_update = false;

  let $element = $(this);

  plugin.initItem = ($item) => {
    $item.find('.delete-history').click((ev) => {

      if(confirm(t('g.sure_delete'))) {
        $item.hide();
        let $this = $(ev.currentTarget);
        api.delete('/history/' + $this.data('id'),{
          success: () => {
            $item.remove();
          },
          error: () => {
            $item.show();
            msg.error(t('timeline.delete_fail'));
          }
        });
      }

    });

    $item.find('.timeline-task-checkbox').change((ev) => {

      let $cb = $(ev.target);
      let done = 0;

      if ($cb.is(":checked")) {
        done = 1;
      }

      api.post(`/resource/update/attr/task/${$cb.val()}`, {
        attr: 'done',
        value: done
       }, {
        success: () => {
          msg.success(t('g.save_success'));
        }
      });
    });

    $item.find('.delete-email').click((ev) => {

      if(confirm(t('g.sure_delete'))) {
        $item.hide();
        let $this = $(ev.currentTarget);

        api.delete('/resource/del/usermailings/' + $this.data('id'),{
          success: () => {
            $item.remove();
            msg.success(t('g.delete_success'));
          },
          error: () => {
            $item.show();
            msg.error(t('timeline.delete_fail'));
          }
        });
      }

    });
  };

  plugin.renderItem = (item) => {

    if(item.type !== undefined && options.hidden_types.indexOf(item.type) > -1) {
      return false;
    }

    item = $.extend({},item, options.string_vars);

    let $item;
    if(render[item.type] !== undefined) {
      $item = render[item.type](item);
    }
    else {
      $item = render.default(item);
    }

    return $item;
  };

  plugin.prependItems = (items) => {
    let item;
    for(item of items) {

      let $item = plugin.renderItem(item);
      if($item !== false) {
        $timeline.prepend($item);
        plugin.initItem($item);
        $item.find('.timeline-item').effect('highlight', {}, 3000);
        if(max_id < item.id) {
          max_id = item.id;
        }
      }
    }
  };

  plugin.appendItems = (items) => {
    let item;
    $timeline.find('.timeline-trailing-item').remove();
    for(item of items) {

      let $item = plugin.renderItem(item);
      if($item !== false) {
        $timeline.append($item);
        plugin.initItem($item);
        if(max_id < item.id) {
          max_id = item.id;
        }
      }
    }
    $timeline.append('<div class="timeline-trailing-item"><i class="far fa-clock bg-gray"></i></div>');

  };

  plugin.update = () => {
    api.get('/history/update/' + options.timeline_id + '/' + max_id,{
      success: (history) => {
        plugin.prependItems(history);
      }
    });
  };

  plugin.appendRequestItems = (history) => {
    plugin.appendItems(history);
    options.success();
    plugin.addEventHandlers();
    if (history.length > 0){
      table_ready_to_update = true
    }
  }

  plugin.getMultipleHistoryItems = () => {
    api.getData('/history/multiple/' + start + '/' + offset, {
      timeline_ids: options.timeline_ids
    }, {
      success: (history) => {
        plugin.appendRequestItems(history)
      }
    });
  }

  plugin.getHistoryItems = () => {
    api.get('/history/' + options.timeline_id + '/' + start + '/' + offset, {
      success: (history) => {
        plugin.appendRequestItems(history)
      }
    });
  }

  plugin.addEntriesOnScroll = () => {
    start += 20
    if (!helper.empty(options.timeline_ids)) {
      plugin.getMultipleHistoryItems()
    } else {
      plugin.getHistoryItems()
    }
  }

  plugin.refresh = () => {
    $timeline.empty();
    plugin.initialLoad();
  };

  plugin.initialLoad = () => {
    if (!helper.empty(options.timeline_ids)) {
      plugin.getMultipleHistoryItems()
    } else {
      plugin.getHistoryItems()
    }
  };

  plugin.scrollBottomReached = () => {
    return $(window).scrollTop() >= plugin.offset().top + plugin.outerHeight() - window.innerHeight;
  }

  plugin.addEventHandlers = () => {
    /*
     * Load entries on scroll at the end of timeline
     */
    $(window).on('scroll', function() {
      if (plugin.scrollBottomReached() && table_ready_to_update) {
        table_ready_to_update = false
        plugin.addEntriesOnScroll();
      }
    });

    /*
     * Receivemail More
     */
    $('.timeline-footer').on('click', 'a.receivemail-more', (ev) => {
      let $btn = $(ev.currentTarget);
      $btn.parent().parent().find('.teasertext').hide().parent().find('.fulltext').show();
      $btn.parent().find('a.receivemail-more').hide();
      $btn.parent().find('a.receivemail-less').show();
      return false;
    });

    /*
     * Receivemail Less
     */
    $('.timeline-footer').on('click', 'a.receivemail-less', (ev) => {
      let $btn = $(ev.currentTarget);
      $btn.parent().parent().find('.fulltext').hide().parent().find('.teasertext').show();
      $btn.parent().find('a.receivemail-more').show();
      $btn.parent().find('a.receivemail-less').hide();
      return false;
    });

    /*
     * Receivemail Answer
     */
    $('.timeline-footer').on('click', 'a.receivemail-answer', () => {

      let $timeline_subject = $(this).parent().parent().find('.subject').text();
      let $timeline_message = $(this).parent().parent().find('.fulltext').html();

      let regExp = new RegExp("^Re\:|^AW\:");
      if (regExp.test($timeline_subject) == false) {
        $timeline_subject = 'Re: ' + $timeline_subject;
      }

      let $message_parts = $timeline_message.split("\n");
      let $quoted_message = ['<br>'];
      $.each($message_parts, (i, line) => {
        $quoted_message.push(">" + line);
      });

      $('#candidate-compose-message').find('.message-subject').val($timeline_subject);
      $('#candidate-compose-message').find('.trumbowyg-textarea').trumbowyg('html', $quoted_message.join("\n"));

      $('.nav-item-messaging > a').trigger('click');
      return false;
    });

    /*
     * Edit Date
     */
    $('.timeline-item').on('click', '.edit-date', (ev) => {
      let $pen = $(ev.currentTarget);
      plugin.showModalDate($pen.data('id'));
    });

    /*
     * Edit Comment
     */
    $('.timeline-item').on('click', '.edit-comment-but', (ev) => {
      let $pen = $(ev.currentTarget);
      plugin.showModalComment($pen.data('id'));
    });
  };

  plugin.initModalDate = () => {

    let $btn_save_date = button_save.js({text: t('timeline.button_save_date')});

    $modal_date = modal.js({
      title: t('timeline.edit_date'),
      size: 'md'
    });

    $form_date = $('<form></form>').uiForm({
      i18n_prefix: 'timeline.',
      fields: {
        'created_at': {
          type: 'Datetime',
          drops: 'down'
        }
      },
      success: () => {
        msg.success(t('g.save_success'));
      },
      error: (res) => {
        if(res && res.responseJSON && res.responseJSON.error) {
          msg.error(t(res.responseJSON.error));
        }
        else {
          msg.error(t('g.save_error'));
        }
      }
    });

    $modal_date.$body.append($form_date);
    $modal_date.$footer.append($btn_save_date);

    $btn_save_date.on('click', () => {

      if (edit_id !== null) {
        api.post('/resource/update/history/' + edit_id, $form_date.serializeObject(), {
          success: () => {
            msg.success(t('g.save_success'));

            $modal_date.modal('hide');
            plugin.refresh();
          },
          error: (res) => {
            if(res && res.responseJSON && res.responseJSON.error) {
              msg.error(t(res.responseJSON.error));
            }
            else {
              msg.error(t('g.save_error'));
            }
          }
        });
      }

      edit_id = null;
    });
  };

  plugin.initModalEditComment = () => {

    let $btn_save_comment = button_save.js({text: t('timeline.button_save_comment')});

    $modal_comment = modal.js({
      title: t('timeline.edit_comment'),
      size: 'md'
    });

    $form_edit_comment = $('<form></form>').uiForm({
      i18n_prefix: 'timeline.',
      fields: {
        text: {
          type: 'Textarea'
        }
      },
      success: () => {
        msg.success(t('g.save_success'));
      },
      error: (res) => {
        if(res && res.responseJSON && res.responseJSON.error) {
          msg.error(t(res.responseJSON.error));
        }
        else {
          msg.error(t('g.save_error'));
        }
      }
    });

    $modal_comment.$body.append($form_edit_comment);
    $modal_comment.$footer.append($btn_save_comment);

    $btn_save_comment.on('click', () => {

      if (edit_id !== null) {
        console.log($form_edit_comment.serializeObject());
        let data = {
          type: "comment",
          message: $form_edit_comment.serializeObject()["text"]
        }
        api.post('/history/edit/' + edit_id, data, {
          success: () => {
            msg.success(t('g.save_success'));

            $modal_comment.modal('hide');
            plugin.refresh();
          },
          error: (res) => {
            if(res && res.responseJSON && res.responseJSON.error) {
              msg.error(t(res.responseJSON.error));
            }
            else {
              msg.error(t('g.save_error'));
            }
          }
        });
      }

      edit_id = null;
    });
  };

  plugin.showModalDate = (id) => {

    api.get('/resource/get/history/' + id, {
      success: (res) => {
        edit_id = id;
        $form_date.setValues(res);

        $modal_date.modal({
          show: true,
          backdrop: 'static',
          keyboard: false
        });
      }
    });

  };

  plugin.showModalComment = (id) => {

    api.get('/resource/get/history/' + id, {
      success: (res) => {
        edit_id = id;
        $form_edit_comment.setValues(res);

        $modal_comment.modal({
          show: true,
          backdrop: 'static',
          keyboard: false
        });
      }
    });

  };

  this.initialize = () => {

    plugin.initModalDate();
    plugin.initModalEditComment();

    $element.append(`
      <div class="timeline timeline-inverse m-0">

        <div class="mb-0">
          <i class="fas fa-comment" style="background-color: #FAFAFA; color: #6c757d;"></i>

          <div style="border:0;background: transparent;" class="timeline-item timeline-comment mr-4">

          </div>
        </div>
        
        <div class="timeline-items timeline timeline-inverse mb-5">
        
        </div>

      </div>
    `);

    $timeline = $element.find('.timeline-items');
    $comment = $element.find('.timeline-comment');

    plugin.initialLoad();

    /*
       * Kommentar
       */
    $comment.uiComment({
      submit: (message) => {
        api.post('/history/' + options.timeline_id,{
          type: 'comment',
          message: message
        },{
          error: () => {
            msg.error('error');
          },
          success: () => {
            plugin.update();
          }
        });
      }
    });

    return plugin;
  };

  return this.initialize();
};
