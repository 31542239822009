import app from '../../app/app';;
import media from '../../app/media';;
import routes from '../../app/routes';;
import session from '../../app/session';;
import api from '../../app/api';

const user_dropdown_menu = {
    tpl: function(it) {
        var out='<li id="user-dropdown-menu" class="nav-item dropdown user-menu"> <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown"> <img src="/img/avatar.png" class="user-image img-circle elevation-2" alt="'+(it.firstname)+'"> <span id="badge-profile-complete" style="display: none" class="badge badge-danger navbar-badge">E-Mail/<br />Signatur<br />fehlt!</span> </a> <ul class="dropdown-menu dropdown-menu-lg dropdown-menu-right dropdown-usermenu"> <li class="user-header"> <img src="/img/avatar.png" class="img-circle elevation-2" alt="'+(it.firstname)+'"> <p> '+(it.firstname)+' '+(it.lastname)+' <small>'+(it.agencies[session.user.active_agency_index].name)+'</small> </p> </li> <li class="user-body"> <!-- <div class="row"> <div class="col-4 text-center"> <a href="#">Followers</a> </div> <div class="col-4 text-center"> <a href="#">Sales</a> </div> <div class="col-4 text-center"> <a href="#">Friends</a> </div> </div> --> </li> <li class="user-footer"> <a href="/user/profile" class="btn btn-default router-link"><i class="fas fa-user-cog"></i> Profil-Einstellungen</a> <a href="/logout" class="btn btn-default float-right router-link"><i class="fas fa-sign-out-alt"></i> Logout</a> </li> </ul></li>'; return out;
    },
    js: function(it) {
             let html = user_dropdown_menu.tpl(it); let $user_menu = $(html); routes.initLinks($user_menu.find('.router-link')); api.get('/infosystem/profile/' + it.id, { success: (res) => { if  (res.email_account == false || res.signature == false) { $('#badge-profile-complete').show(); } else { $('#badge-profile-complete').hide(); } } }); return $user_menu;
    }
};

export default user_dropdown_menu;
