import app from '../../app/app';;
import api from '../../app/api';;
import time from '../../app/time';;
import msg from '../../app/msg';

const admin_mailcomponents = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <p style="padding: 0px 10px 10px 10px;">E-Mail Komponenten sind kleine HTML Bausteine die z.B. eine formattierte Überschrift, welche wiederverwendet werden können.</p> <div class="col-md-12 admin-mailcomponents"> <div id="mailcomponents-list"></div> </div> </div></div>'; return out;
    },
    js: function(it) {
            app.setTitle('Mail-Komponenten verwalten'); app.setBreadcrumps({ 'Administration': '/agency/admin', 'Mail-Templates': '/agency/admin/mailcomponents' }); let $mailcomponents = $('#mailcomponents-list').uiDataTableScroll({ add: true, edit: true, delete: false, default_order: [0, 'asc'], i18n_prefix: 'mailcomponents.', pageLength: 12, cols: [ {name: 'id', text:'#', width: 10, hidden: true}, {name: 'name', text: 'Name'}, {name: 'updated_at', hidden: true} ], render:{ 'id': (obj) => { return '<b>#' + obj.id + '</b>'; }, 'name': (obj) => { return '<b>' + obj.name + '</b>'; }, 'updated_at': (obj) => { return '<span style="display:none;">' + obj.created_at + '</span><strong>' + time.md(obj.created_at) + '</strong>'; } }, table: ['mailcomponent', 'mailcomponents'], fields: { 'name': 'Slug', 'content': 'Textarea' } });
    }
};

export default admin_mailcomponents;
