import api from '../api';

$.fn.uiFormLocationpicker = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $input;
  let address;

  options = $.extend({},{
    i18n_prefix: '',
    default: '',
    show_map: true
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);


  let plugin = this;

  this.showError = (name, error) => {
    name = name.replace('_additional', '');
    if (name == options.name) {
      let $error_input = $(document).find(`.form-group-${name}`).find('input').not(':hidden');
      $error_input.addClass('is-invalid');
      $(`<span class="error invalid-feedback">${t(error)}</span>`).insertAfter($error_input);
    }
  };


  // public methods
  this.initialize = () => {

    $label = $('<label>' + options.label + '</label>');
    $input = $('<input name="' + options.name + '" class="form-control" type="text" placeholder="' + options.placeholder + '" value="' + options.default + '" />');

    $element.addClass('form-group form-group-' + options.name).append($label).append($input);
    $input.addresspicker(options);

    this.$input = $input;

    return plugin;

  };

  plugin.getAddress = () => {
    return this.address;
  };

  this.setValue = (address) => {

    $input.resetAddress();

    if(address) {
      this.address = address;
      $input.setAddress(address);

      $label.text(options.label_edit);
      $input.attr('placeholder', options.placeholder_edit);
    }
  };

  this.resetValue = () => {

    $input.resetAddress();
    $label.text(options.label);
    $input.attr('placeholder', options.placeholder);
  };

  return this.initialize();
};
