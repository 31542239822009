import api from '../api';
import app from '../app';

$.fn.uiFormSortlist = function(options) {

  /*
  HANDLE OPTIONS
  */
  let $element = $(this);
  let $label;
  let $list;

  options = $.extend({},{
    i18n_prefix: 'g.',
    OnUpdate: () => {},
    OnCompleteAjax: () => {}
  }, options);

  if(options.label !== false) {
    options.label = t(options.i18n_prefix + 'lb_'+options.name);
  }

  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name)

  let plugin = this;

  /*
   Ausgabe
   */
  this.tpl = (obj) => {
    return `<li class="sortlist-entry">
      <span class="handle">
        <i class="fas fa-ellipsis-v"></i>
        <i class="fas fa-ellipsis-v"></i>
      </span>
      <div  class="icheck-primary d-inline ml-2">
        <input type="checkbox" value="${obj.value}" name="entry-${obj.id}" id="sortlist-entry-${obj.id}">
        <label for="sortlist-entry-${obj.id}"></label>
      </div>
      <span class="text">${obj.text}</span>
      ${obj.default_cv == 1 ? '<small class="badge badge-success">CV</small>' : ''}
    </li>`;
  };

  // public methods
  this.initialize = () => {

    if(options.table === undefined) {
      options.table = options.name;
    }

    if(!Array.isArray(options.table)) {
      options.table = [options.table, options.table];
    }

    $label = $('<label>' + options.label + '</label>');
    $list = $('<ul id="sortlist-' + options.name + '" class="todo-list"></ul>');

    if (options.values) {
      options.values.forEach((value) => {
        $list.append(this.tpl(value));
      });
    }

    $element.addClass('form-group form-group-' + options.name);
    if(options.label !== false) {
      $element.append($label);
    }

    // Liste sortiertbar machen
    $list.sortable({
      handle: '.handle',
      update: options.OnUpdate
    });

    $element.append($list);

    return plugin;

  };

  /*
   Daten per Ajax Request laden
   */
  this.loadByAjax = () => {

    if ($list.children().length == 0) {

      $list.addClass('fas fa-spinner fa-spin');

      api.get(options.ajax.url, {
        success: (response) => {
          $list.removeClass('fas fa-spinner fa-spin');

          if (response.listing.length == 0) {
            $list.parent().html(t('g.' + options.name + '_listing_empty'));
          }
          else {
            $.each(response.listing, (i, obj) => {
              $list.append(plugin.tpl(obj));
            });
          }
        },
        complete: options.OnCompleteAjax
      });

    }

  };

  this.setValue = (value) => {
    $input.val(value.id);
    $label.text(options.label_edit);
    $input.attr('placeholder', options.placeholder_edit);
  };

  this.resetValue = () => {
    $input.val('');
    $label.text(options.label);
    $input.attr('placeholder', options.placeholder);
  };

  return this.initialize();

};
