import api from '../../app/api';
import msg from '../../app/msg';
import button_save from "../../_compiled/components/button_save";
import modal from "../../_compiled/components/modal";
import helper from '../helper';

$.fn.uiFavorite = function(options) {
  // support multiple elements
  if (this.length > 1){
    this.each(() => { $(this).uiFavorite(options) });
    return this;
  }

  // default options

  options = $.extend({},{
    model: 'candidate',
    state: null
  }, options);

  if(options.i18n_prefix === undefined) {
    options.i18n_prefix = options.model+'.';
  }

  // private variables
  let $loader = null;
  let favorite_state = false;

  let plugin = this;

  let $element = $(this);
  let $form;
  let $modal;
  let $btn_favorite;
  let $btn_favorite_text;
  let $btn_favorite_icon;
  let uri, success_msg;
  // ...

  plugin.showLoading = () => {
    $btn_favorite_icon.removeClass('fa-bell').addClass('fa-sync fa-spin');
  };

  plugin.hideLoading = () => {
    $btn_favorite_icon.removeClass('fa-sync fa-spin').addClass('fa-bell');
  };

  plugin.setState = (state) => {

    if(!state) {
      favorite_state = false;
    }
    else if(state.favorited !== undefined) {
      favorite_state = state.favorited;

      if (state.favorites !== undefined && !helper.empty(state.favorites)) {
        favorite_state = true;
      }
    }

    if(!favorite_state) {
      $btn_favorite_text.text(t('g.favorite'));
      $btn_favorite.removeClass('btn-outline-secondary').addClass('btn-danger');
      $btn_favorite_icon.removeClass('far').addClass('fas');
    }
    else {
      $btn_favorite_text.text(t('g.favorited'));
      $btn_favorite.removeClass('btn-danger').addClass('btn-outline-secondary');
      $btn_favorite_icon.removeClass('fas').addClass('far');
    }
  };

  plugin.initForm = () => {
    $form = $('<form></form>').uiForm({
      i18n_prefix: 'g.',
      fields: {
        'fav_until': {
          type: 'Date',
          drops: 'bottom'
        }
      }
    });
  };

  plugin.initModal = () => {
    let $btn_save = button_save.js();

    $modal = modal.js({
      title: t('g.favorite'),
      size: 'md'
    });

    $modal.$body.append($form);
    $modal.$footer.append($btn_save);

    $btn_save.on('click', (ev) => {
      ev.preventDefault();

      plugin.showLoading();

      uri = '/resource/favorite/' + options.model + '/' + options.id;
      success_msg = t(options.i18n_prefix + 'favorite_success');

      api.post(uri, {
        fav_until: $form.find('[name="fav_until"]').val()
      }, {
        success: (state) => {
          $modal.modal('hide');
          $form.resetValues();
          plugin.setState(state);
          msg.info(success_msg);
          options.afterSetState();
        },
        complete: () => {
          plugin.hideLoading();
        }
      });
    });
  };

  plugin.initButton = () => {

    $btn_favorite = $('<button class="btn btn-danger btn-xs w-100"><i class="fas fa-star mr-2"></i><span>' + t('g.favorite') + '</span></button>');
    $btn_favorite_icon = $btn_favorite.find('i');
    $btn_favorite_text = $btn_favorite.find('span');

    $btn_favorite.on('click', (ev) => {

      ev.preventDefault();

      if ($btn_favorite.hasClass('btn-danger')) {
        $modal.modal({
          show: true,
          backdrop: 'static',
          keyboard: false
        });
      }

      else if ($btn_favorite.hasClass('btn-outline-secondary')) {
        if(favorite_state === true){
          uri = '/resource/unfavorite/' + options.model + '/' + options.id;
          success_msg = t(options.i18n_prefix + 'unfavorite_success');
        }

        api.get(uri, {
          success: (state) => {
            plugin.setState(state);
            msg.info(success_msg);
            options.afterSetState();
          },
          complete: () => {
            plugin.hideLoading();
          }
        });
      }

    });

    $element.append($btn_favorite);

  };

  plugin.update =() => {
    api.get('/resource/getfavorites/' + options.model + '/' + options.id, {
      success: (favorites) => {
        plugin.setState(favorites);
      }
    });
  };

  // public methods
  this.initialize = () => {

    plugin.initForm();

    plugin.initModal();

    plugin.initButton();

    plugin.update();

    return plugin;
  };

  return this.initialize();
};


