import api from '../api';
import time from '../time';

$.fn.uiHistoryLoader = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  options = $.extend({},{
    name: null,
    repo: null,
    id: null,
    i18n_prefix: null,
    setValue:() => {}
  }, options);

  if(!options.i18n_prefix) {
    options.i18n_prefix = options.table + '.';
  }

  let plugin = this;
  let $group;
  let $button, $btn_next, $btn_prev;
  let $menu;

  this.initHtml = () => {

    $group = $('<div class="btn-group"></div>');
    $button = $('<button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">ältere Version laden </button>');
    $menu = $('<div class="dropdown-menu"></div>');

    $btn_next = $('<button class="btn btn-default"><i class="fas fa-arrow-right"></i></button>');
    $btn_prev = $('<button class="btn btn-default"><i class="fas fa-arrow-left"></i></button>');

    $btn_next.on('click', () => {
      $('#selectionChamp option:selected').next().attr('selected', 'selected');
    });

    $group.append($button);
    $group.append($menu);

    $element.append($group);

  };

  this.initData = () => {

    api.get('/resource/history/listing/10/' + options.repo + '/' + options.name + '/' + options.id,{
      success: (response) => {

        $menu.empty();

        let filter_entries = [];

        response.forEach((entry) => {

          if(entry[options.name] && filter_entries.indexOf(entry[options.name]) === -1) {
            $menu.append('<a class="dropdown-item" href="#">Version ' + entry.version + ' (' + time.full_md(entry.date) + ')</a><textarea style="display:none;">' + entry[options.name] + '</textarea>');
            filter_entries.push(entry[options.name]);
          }

        });

        $menu.find('.dropdown-item').click((ev) => {

            ev.preventDefault();
            let $el = $(ev.currentTarget);
            options.setValue($el.next('textarea').val())

        });

      }
    });

  };



  // public methods
  this.initialize = () => {


    plugin.initHtml();
    plugin.initData();

    return plugin;

  };

  return this.initialize();
};


