import app from '../../app/app';;
import session from '../../app/session';;
import time from '../../app/time';;
import media from '../../app/media';;
import routes from '../../app/routes';;
import helper from '../../app/helper';;
import api from '../../app/api';;
import msg from '../../app/msg';;
import modal from '../../_compiled/components/modal';

const job_kanban = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12 projects"> <div class="form-group"> <label>Wähle ein Team</label> <select id="teamchooser" class="form-control"> </select> </div> <div id="job-kanban"></div> </div> </div></div>'; return out;
    },
    js: function(it) {
                 app.setTitle('Job Kanban Board'); app.setBreadcrumps({ 'Recruiting': '/recruiting', 'Jobs': '/recruiting/jobs' }); let $board = $('#job-kanban'); let $teamchooser = $('#teamchooser'); $board.uiKanbanBoard({ repo: 'job', repo_handles: 'placementstatus', uri_list: '/job/listactive/1', uri_move: '/job/placementstatus/move', fields: { 'name': 'Text' }, renderItem: (item) => { let members = ''; item.members.forEach((d) => { members += `<a title="` + d.name + `" href="#" class="router-link"><img alt="Avatar" class="table-avatar" src="` + media.image(d.avatar,'32x32', '/img/avatar5.png') + `"></a>` }); let $el = $('<div class="callout callout-info"><button class="btn btn-xs btn-default float-right"><i class="fas fa-edit"></i></button><h6>' + item.title + '</h6><div style="clear: both;"></div><hr /><div class="members"><p>' + members + '</p></div></div>'); return $el; } }); api.get('/job/listteams',{ success: (res) => { res.forEach((r) => { $teamchooser.append('<option value="' + r.id + '">' + r.name + '</option>'); }); $teamchooser.on('change', (ev) => { ev.preventDefault(); $board.loadFromUri('/job/listactive/' + $teamchooser.val()); }); } });
    }
};

export default job_kanban;
