
const menu = {

  init: (callback) => {

  },

  load: () => {

  },

  unload: () => {

  }
};

export default menu;
