import app from '../../app/app';;
import time from '../../app/time';;
import helper from '../../app/helper';

const organisationtypes = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12" id="orgtypes-management"> </div> </div></div>'; return out;
    },
    js: function(it) {
           app.setTitle('Branchen verwalten'); app.setBreadcrumps({ 'Administration': '/admin', 'Recruiting-Platformen': '/admin/organisationtypes' }); let $org_types = $('#orgtypes-management').adminScaffold({ i18n_prefix: 'organisationtype.', title: 'Branchen', table: ['organisationtype','organisationtypes'], fields: { 'name': 'Text', 'xing_industry_id': 'Number' }, cols: [{text:'#',name:'id',width:30}, 'name', 'xing_industry_id'], render: { 'id': (obj) => { return '<b>' + obj.id + '</b>'; }, 'name': (obj) => { return '<a>' + obj.name + '</a><br /><small>erstellt: ' + time.ago(obj.created_at) + '</small>'; }, 'xing_industry_id': (obj) => { let xing_industry_id = ''; if (!helper.empty(obj.xing_industry_id)) { xing_industry_id = obj.xing_industry_id; } return '<span>' + xing_industry_id + '</span>'; } } });
    }
};

export default organisationtypes;
