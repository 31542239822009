let plugins = {

  loadedScripts: [],

  loadScript: (src, callback) => {

    if(plugins.loadedScripts.indexOf(src) === -1) {

      var script = document.createElement('script');
      script.type = 'text/javascript';

      if(callback){
        script.onload = callback;
      }

      document.getElementsByTagName('head')[0].appendChild(script);
      const webpackHash = __webpack_hash__.substr(0, 8);
      script.src = src + '?' + webpackHash;
      plugins.loadedScripts.push(src);
    }
    else if(callback){
      callback();
    }

  },

  loadScripts: (sources, callback, i) => {

    if(i === undefined) {
      i = 0;
    }
    plugins.loadScript(sources[i], () => {
      i++;
      if(sources[i] !== undefined) {
        plugins.loadScripts(sources, callback, i);
      }
      else if(callback !== undefined){
        callback();
      }
    });

  },

  loadDefaults: (ready) => {

    plugins.loadScripts([
      '/plugins/serialize-object/serialize-object.min.js',
      '/plugins/bootstrap/js/bootstrap.bundle.min.js',
      '/plugins/select2/js/select2.full.min.js',
      '/plugins/select2/js/i18n/de.js',
      '/plugins/datatables/jquery.dataTables.js',
      '/plugins/datatables-bs4/js/dataTables.bootstrap4.js',
      '/plugins/bootstrap-colorpicker/js/bootstrap-colorpicker.min.js',
      //'/plugins/bootstrap-switch/js/bootstrap-switch.min.js',
      '/plugins/LC-switch-2.0.3/lc_switch.min.js',
      '/plugins/inputmask/min/jquery.inputmask.bundle.min.js',
      '/plugins/bootstrap-suggest/distr/bootstrap-suggest.min.js',
      '/plugins/trumbowyg/distr/trumbowyg.min.js',
      /*'/plugins/trumbowyg/distr/plugins/table/trumbowyg.table.js',*/
      '/plugins/summernote/summernote-bs4.min.js',
      '/plugins/toastr/toastr.min.js',
      '/plugins/moment/moment.min.js',
      '/plugins/daterangepicker/daterangepicker.js',
      '/plugins/leaflet/leaflet.js',
      '/plugins/leaflet/leaflet.fa.markers.js',
      '/plugins/jquery-addresspicker/jquery.addresspicker.js',
      '/plugins/quill/quill.min.js',
      /*'/plugins/quill-2.0.0-dev.3/quill.min.js',*/
      //'/plugins/quill-better-table/quill-better-table.js',
      /*'/plugins/quill-table-ui/index.js',*/
      /*'/plugins/push.js/push.min.js',*/
      '/plugins/socket.io/socket.io.min.js',
      '/plugins/jstree/jstree.min.js',
      '/plugins/chart.js/Chart.bundle.min.js',
      '/plugins/apexcharts/apexcharts.min.js'
    ], ready);
  },

  loadDataTables: (ready) => {
      plugins.loadScript('/plugins/datatables/jquery.dataTables.js', () => {
        plugins.loadScript('/plugins/datatables-bs4/js/dataTables.bootstrap4.js', ready);
      });
  }
};

export default plugins;
