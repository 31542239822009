import Navigo from 'navigo';
import app from './app';
import api from './api';
import home from '../_compiled/pages/home';
import nullpage from '../_compiled/pages/nullpage';

import active_sourcing from '../_compiled/pages/active_sourcing';

import remote_browser from '../_compiled/pages/remote_browser';
import am_agencies from '../_compiled/pages/am_agencies';
import user_profile from '../_compiled/pages/user_profile';
import jobs from '../_compiled/pages/jobs';
import job_kanban from '../_compiled/pages/job_kanban';
import job from '../_compiled/pages/job';
import candidates from '../_compiled/pages/candidates';
import orgtypes from '../_compiled/pages/organisationtypes';
import tags from '../_compiled/pages/tags';
import agency_organisations from '../_compiled/pages/agency_organisations';
import admin_agencysettings from '../_compiled/pages/admin_agency_settings';
import tasks from '../_compiled/pages/tasks';
import agency_organisation from '../_compiled/pages/agency_organisation';
import contacts from '../_compiled/pages/contacts';
import contact from '../_compiled/pages/contact';
import crm_task from '../_compiled/pages/crm_task';
import statuspage from '../_compiled/pages/status';
import status_details from '../_compiled/pages/status_details';
import agency_email from '../_compiled/pages/agency_email';
import agency_contacttypes from '../_compiled/pages/agency_contacttypes';
import candidate from '../_compiled/pages/candidate';
import platforms from '../_compiled/pages/platforms';
import persons from '../_compiled/pages/persons';
import page_jobpools from '../_compiled/pages/jobpools';
import user_roles from '../_compiled/pages/user_roles';
import user_rights from '../_compiled/pages/user_rights';
import page_users from '../_compiled/pages/users';
import prerender from '../_compiled/pages/prerender';
import fulltext_search from '../_compiled/pages/fulltext_search';
import info_timed_emails from '../_compiled/pages/info_timed_emails';
import info_favorite_candidates from '../_compiled/pages/info_favorite_candidates';
import info_candidates_process from '../_compiled/pages/info_candidates_process';
import plugin_not_found from '../_compiled/pages/plugin_not_found';

import admin_sessions from '../_compiled/pages/admin_sessions';
import admin_organisations from '../_compiled/pages/admin_organisations';
import admin_organisationblacklist from '../_compiled/pages/admin_organisationblacklist';
import admin_organisationbranches from '../_compiled/pages/admin_organisationbranches';
import admin_mailtemplates from '../_compiled/pages/admin_mailtemplates';
import admin_mailcomponents from '../_compiled/pages/admin_mailcomponents';
import admin_mailthemes from '../_compiled/pages/admin_mailthemes';
import admin_graduationtypes from '../_compiled/pages/admin_graduationtypes';
import admin_employments from '../_compiled/pages/admin_employments';
import admin_jobsubjects from '../_compiled/pages/admin_jobsubjects';
import admin_mailing_blacklist from '../_compiled/pages/admin_mailing_blacklist';
import admin_infosystem from '../_compiled/pages/admin_infosystem';
import admin_application_info from '../_compiled/pages/admin_application_info';
import admin_jobadvert from '../_compiled/pages/admin_jobadvert';
import admin_pages from '../_compiled/pages/admin_pages';
import admin_blog from '../_compiled/pages/admin_blog';
import admin_selectors from '../_compiled/pages/admin_selectors';

import settings_crm_orgainfo from '../_compiled/pages/settings_crm_orgainfo';
import settings_recruiting_jobinfo from '../_compiled/pages/settings_recruiting_jobinfo';

import cms_teammembers from '../_compiled/pages/cms_teammembers';

import logging from '../_compiled/pages/logging';

import agency_teams from '../_compiled/pages/agency_teams';
import not_found_404 from '../_compiled/pages/not_found_404';

import session from './session';
import login from './login';
import cache from './cache';

let routes = {
  router: null,
  last_url: null,
  last_uris: [],
  nextchange_callback_stack: [],
  after_callback_stack:{},

  init: () => {

    //routes.router = new Navigo(location.protocol + '//' + location.hostname, false, '#');
    routes.router = new Navigo('/');

    routes.router.on({
      '/': () => {

        routes.beforeHandler();
        app.loadPage(home, {
          is_dashboard: true
        });
      },
      '/null': () => {
        routes.beforeHandler();
        app.loadPage(nullpage);
      },
      '/login': () => {
        routes.beforeHandler();
      },
      '/logout': () => {
        routes.beforeHandler();
        session.destroy();
        login.setLoginRoute('/');
        $('.router-link').removeClass('active');
        routes.router.navigate('/');
      },
      '/user/profile': () => {
        routes.beforeHandler();
        app.loadPage(user_profile);
      },
      '/recruiting/candidates': () => {
        routes.beforeHandler();
        app.loadPage(candidates);
      },
      '/recruiting/jobs': () => {
        routes.beforeHandler();
        app.loadPage(jobs);
      },
      '/recruiting/job-kanban': () => {
        routes.beforeHandler();
        app.loadPage(job_kanban);
      },
      '/recruiting/job/:id': ({ data }) => {
        routes.beforeHandler();
        app.loadPage(job, {
          job_id: data.id
        });
      },
      '/crm/agency-organisations': () => {
        routes.beforeHandler();
        app.loadPage(agency_organisations);
      },
      '/crm/contacts': () => {
        routes.beforeHandler();
        app.loadPage(contacts);
      },
      '/crm/tasks': () => {
        routes.beforeHandler();
        app.loadPage(tasks);
      },
      '/crm/task/:id': ({ data }) => {
        routes.beforeHandler();
        app.loadPage(crm_task,{
          task_id: data.id
        });
      },
      '/crm/contact/:id': ({ data }) => {
        routes.beforeHandler();
        app.loadPage(contact, {
          contact_id: data.id
        });
      },
      '/crm/contact/:id/:tab': ({ data }) => {
        routes.beforeHandler();
        app.loadPage(contact, {
          contact_id: data.id,
          active_tab: data.tab
        });
      },
      /* '/active-sourcing/:platform': ({ data }) => {
        routes.beforeHandler();
        app.loadPage(active_sourcing,{
          platform: data.platform
        });
      }, */

      /* Settings */
      '/settings/recruiting/jobinfo': () =>{
        routes.beforeHandler();
        app.loadPage(settings_recruiting_jobinfo);
      },
      '/settings/crm/orgainfo': () =>{
        routes.beforeHandler();
        app.loadPage(settings_crm_orgainfo);
      },

      /* Admin */

      '/admin/remote-browser': () => {
        routes.beforeHandler();
        app.loadPage(remote_browser);
      },
      '/admin/sessions': () => {
        routes.beforeHandler();
        app.loadPage(admin_sessions);
      },
      '/admin/organisations': () => {
        routes.beforeHandler();
        app.loadPage(admin_organisations);
      },
      '/agency/admin/organisation-blacklist': () => {
        routes.beforeHandler();
        app.loadPage(admin_organisationblacklist);
      },
      '/agency/admin/organisationbranches': () => {
        routes.beforeHandler();
        app.loadPage(admin_organisationbranches);
      },
      '/admin/organisationtypes': () => {
        routes.beforeHandler();
        app.loadPage(orgtypes);
      },
      '/admin/mailtemplates': () => {
        routes.beforeHandler();
        app.loadPage(admin_mailtemplates);
      },
      '/admin/mailcomponents': () => {
        routes.beforeHandler();
        app.loadPage(admin_mailcomponents);
      },
      '/admin/mailthemes': () => {
        routes.beforeHandler();
        app.loadPage(admin_mailthemes);
      },
      '/agency/admin/applicationinfo': () =>{
        routes.beforeHandler();
        app.loadPage(admin_application_info);
      },
      '/agency/admin/jobadvert': () =>{
        routes.beforeHandler();
        app.loadPage(admin_jobadvert);
      },
      '/admin/agencies': () => {
        routes.beforeHandler();
        app.loadPage(am_agencies);
      },
      '/account-management/agencies': () => {
        routes.beforeHandler();
        app.loadPage(am_agencies);
      },
      '/admin/platforms': () => {
        routes.beforeHandler();
        app.loadPage(platforms);
      },
      '/admin/persons': () =>{
        routes.beforeHandler();
        app.loadPage(persons);
      },
      '/admin/tags/:type': ({ data }) =>{
        routes.beforeHandler();
        app.loadPage(tags,{
          type: data.type
        });
      },
      '/admin/user/roles': () =>{
        routes.beforeHandler();
        app.loadPage(user_roles);
      },
      '/admin/user/rights': () =>{
        routes.beforeHandler();
        app.loadPage(user_rights);
      },
      '/admin/user': () =>{
        routes.beforeHandler();
        app.loadPage(page_users);
      },
      '/admin/agency-settings': () =>{
        routes.beforeHandler();
        app.loadPage(admin_agencysettings);
      },
      '/admin/pages': () =>{
        routes.beforeHandler();
        app.loadPage(admin_pages);
      },
      '/admin/blog': () =>{
        routes.beforeHandler();
        app.loadPage(admin_blog);
      },
      '/agency/admin/teams': () =>{
        routes.beforeHandler();
        app.loadPage(agency_teams);
      },
      '/info/timed-emails': () =>{
        routes.beforeHandler();
        app.loadPage(info_timed_emails);
      },
      '/info/favorite-candidates': () =>{
        routes.beforeHandler();
        app.loadPage(info_favorite_candidates);
      },
      '/info/candidates-process': () =>{
        routes.beforeHandler();
        routes.after_callback_stack['/info/candidates-process'] = () => {
          $('body').removeClass('layout-boxed').addClass('layout-fixed').addClass('sidebar-collapse');
        };
        app.loadPage(info_candidates_process);
      },
      '/plugin': () =>{
        routes.beforeHandler();
        routes.openPlugin();
      },
      '/agency/admin/status': () =>{
        routes.beforeHandler();
        app.loadPage(statuspage);
      },
      '/agency/admin/status_details/:id': ({ data }) =>{
        routes.beforeHandler();
        app.loadPage(status_details, {
          status_id: data.id
        });
      },
      '/agency/admin/email': () =>{
        routes.beforeHandler();
        app.loadPage(agency_email);
      },
      '/agency/admin/contacttypes': () =>{
        routes.beforeHandler();
        app.loadPage(agency_contacttypes);
      },
      '/admin/graduationtypes': () =>{
        routes.beforeHandler();
        app.loadPage(admin_graduationtypes);
      },
      '/admin/employments': () =>{
        routes.beforeHandler();
        app.loadPage(admin_employments);
      },
      '/admin/selectors': () =>{
        routes.beforeHandler();
        app.loadPage(admin_selectors);
      },
      '/fulltext-search': () =>{
        routes.beforeHandler();
        app.loadPage(fulltext_search);
      },
      '/candidate/:id': ({ data }) => {
        routes.beforeHandler();
        app.loadPage(candidate, {
          candidate_id: data.id
        });
      },
      '/candidate/:id/:tab': ({ data }) => {
        routes.beforeHandler();
        app.loadPage(candidate, {
          candidate_id: data.id,
          active_tab: data.tab
        });
      },
      '/crm/agency-organisation/:id': ({ data }) => {
        routes.beforeHandler();
        app.loadPage(agency_organisation, {
          organisation_id: data.id
        });
      },
      '/crm/agency-organisation/:id/:tab': ({ data }) => {
        routes.beforeHandler();
        app.loadPage(agency_organisation, {
          organisation_id: data.id,
          active_tab: data.tab
        });
      },
      '/admin/jobpools': () => {
        routes.beforeHandler();
        app.loadPage(page_jobpools);
      },
      '/admin/caching': () =>{
        routes.beforeHandler();
        app.loadPage(prerender);
      },
      '/admin/subjects': () => {
        routes.beforeHandler();
        app.loadPage(admin_jobsubjects);
      },
      '/admin/mailing/blacklist': () => {
        routes.beforeHandler();
        app.loadPage(admin_mailing_blacklist);
      },
      '/admin/infosystem': () => {
        routes.beforeHandler();
        app.loadPage(admin_infosystem);
      },
      '/logging': () => {
        routes.beforeHandler();
        app.loadPage(logging);
      },

      /* website cms */
      '/cms/teammembers': () => {
        routes.beforeHandler();
        app.loadPage(cms_teammembers);
      },

      '/404': () => {
        routes.beforeHandler();
        app.loadPage(not_found_404);
      }
    });

    routes.router.notFound(function(){
      routes.beforeHandler();
      routes.router.navigate('/404');
    });

    routes.initLinks($('.router-link'));
    routes.router.navigate(app.getUrl());
    $('a.router-link[href="' + app.getUrl() + '"]').addClass('active');
  },

  initLinks: ($links) => {
    $links.unbind('click').click(function(ev){
      ev.preventDefault();
      let $el = $(this);
      $('.router-link').removeClass('active');
      $el.addClass('active');

      let new_url = $el.attr('href');
      let current_url = app.getUrl();

      window.location.hash = '';
      routes.router.navigate(new_url);

      /*
       * router fix wenn von hash url zur gleichen base url ohne hash gewechselt wird
       */
      if(current_url.indexOf('#') > -1 && current_url.substr(0,new_url.length) === new_url) {
        routes.refresh();
      }
    });
  },

  onNextChange: (callback) => {
    routes.nextchange_callback_stack.push(callback);
  },

  runChangeCallbackStack: () => {
    routes.nextchange_callback_stack.forEach((cb) => {
      cb();
    });
    routes.nextchange_callback_stack = [];
  },

  beforeHandler: (options) => {

    routes.last_uris.push(app.getUri());
    if(routes.last_uris.length > 10) {
      routes.last_uris = routes.last_uris.slice(1);
    }
    /*console.log(routes.last_uris);*/
    cache.setContext('');
    routes.runChangeCallbackStack();
    routes.cleanup();
  },

  cleanup: () => {
    $('body > .daterangepicker, #hiddenDownloader').remove();
    $('.popover').remove();
  },

  refresh: () => {
    let route = app.getUrl();
    routes.router.navigate('/null');
    routes.router.navigate(route);
  },

  replaceParams: (params_obj) => {

    window.history.replaceState(null, null, app.getUri() + '#' + $.param(params_obj));

  },

  setParams: (params_obj) => {

    let parameters = routes.getParams();

    let keys = Object.keys(params_obj);

    keys.forEach((key) => {
      parameters[key] = params_obj[key];
    });

    window.history.replaceState(null, null, app.getUri() + '#' + $.param(parameters));
    //routes.router.navigate(app.getUri() + '#' + $.param(parameters));

    //document.location.search = kvp.join('&');
  },

  replaceUrl: (url) => {
    window.history.replaceState(null, null, url);
  },

  setParam: (key, value) => {

    let parameters = routes.getParams();

    parameters[key] = value;

    window.history.replaceState(null, null, app.getUri() + '#' + $.param(parameters));
    //routes.router.navigate(app.getUri() + '#' + $.param(parameters));

    //document.location.search = kvp.join('&');
  },

  getParams: () => {
    let parts = window.location.href.split('#');
    let out = {};
    if(parts.length > 1) {
      out = routes.parseParams(parts[1]);
    }
    return out;
  },

  getParam: (key) => {

    let parameters = routes.getParams();

    return parameters[key];

  },

  hasParams: () => {
    if(app.getUrl().indexOf('#') > -1) {
      return true;
    }
    return false;
  },

  parseParams: (query) => {
    let re = /([^&=]+)=?([^&]*)/g;
    let decode = function(str) {
      return decodeURIComponent(str.replace(/\+/g, ' '));
    };
    let data = {}, e;
    if (query) {
      if (query.substr(0, 1) == '#') {
        query = query.substr(1);
      }
      while (e = re.exec(query)) {
        var _decode;

        var k = (_decode = decode(e[1])) === null || _decode === void 0 ? void 0 : _decode.replace('[]', '');
        var v = decode(e[2]);

        if (data[k] !== undefined) {
          if (!$.isArray(data[k])) {
            data[k] = [data[k]];
          }

          data[k].push(v);
        }
        else {
          data[k] = v;
        }
      }
      /*
      while (e = re.exec(query)) {
        var k = decode(e[1])?.replace('[]', '');
        var v = decode(e[2]);
        if (data[k] !== undefined) {
          if (!$.isArray(data[k])) {
            data[k] = [data[k]];
          }
          data[k].push(v);
        } else {
          data[k] = v;
        }
      }
      */
    }
    return data;
  },

  navigate: (uri, options) => {

    options = $.extend({},{
      callback: null,
    },options);

    if(options.callback) {
      let baseuri = uri.split('#')[0];
      if(routes.after_callback_stack[baseuri] === undefined) {
        routes.after_callback_stack[baseuri] = [];
      }
      routes.after_callback_stack[baseuri].push(options.callback);
    }

    routes.router.navigate(uri);
  },

  goBack: (alternative) => {
    if(routes.last_uris.length > 1) {
      routes.navigate(routes.last_uris[(routes.last_uris.length-2)]);
    }
    else if(alternative !== undefined) {
      routes.navigate(alternative);
    }
    else {
      routes.navigate('/');
    }

  },

  routerLink: (url) => {
    routes.navigate(url);
    return false;
  },

  openPlugin: () => {
    const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    if (isChrome) {
      open('https://chrome.google.com/webstore/detail/jungwild-hunter/ljeghfdjlmgglokllcnjhgalplemhhkb', '_blank');
      return;
    }

    // if (isFirefox) {
    //   TODO: Firefox Plugin Link
    //   return;
    // }

    app.loadPage(plugin_not_found);
  }
};


export default routes;
