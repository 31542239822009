import { format, register } from 'timeago.js';

var time = {

  init: () => {

    // register locale
    register('de_DE', (number, index, totalSec) => {
      return [
        ['gerade eben', 'jetzt'],
        ['vor %s Sekunden', 'in %s Sekunden'],
        ['vor 1 Minute', 'in 1 Minute'],
        ['vor %s Minuten', 'in %s Minuten'],
        ['vor einer Stunde', 'in einer hour'],
        ['vor %s Stunden', 'in %s Stunden'],
        ['vor einem Tag', 'in einem tag'],
        ['vor %s Tagen', 'in %s Tagen'],
        ['vor einer Woche', 'in einer Woche'],
        ['vor %s Wochen', 'in %s Wochen'],
        ['vor einem Monat', 'in einem Monat'],
        ['vor %s Monaten', 'in %s Monaten'],
        ['vor einem Jahr', 'in einem Jahr'],
        ['vor %s Jahren', 'in %s jahren']
      ][index];
    });


  },

  ago: (db_datetime) => {

    if(!db_datetime) {
      return '';
    }

    return format(db_datetime, 'de_DE');
  },

  mini: (db_datetime) => {

    if(!db_datetime) {
      return '';
    }

    let date = time.parseDatetime(db_datetime);

    return (date.getDate()) + '.' + (date.getMonth()+1)+'.';
  },

  md: (db_datetime) => {

    if(!db_datetime) {
      return '';
    }

    let date = time.parseDatetime(db_datetime);

    return date.getDate() + '.' + (date.getMonth()+1) + '.' + date.getFullYear();
  },

  md_time: (db_datetime) => {

    if(!db_datetime) {
      return '';
    }

    let date = time.parseDatetime(db_datetime);
    let date_now = new Date();

    let start_string = '';
    if(time.sameDay(date, date_now) === true) {
      start_string = t('g.today') + ' ';
    }
    else {
      let year = '';
      if(date.getFullYear() !== date_now.getFullYear()) {
        year = '' + date.getFullYear();
      }
      start_string = date.getDate() + '.' + (date.getMonth()+1) + '.' + year;
    }

    return start_string + ' ' + time.addZero(date.getHours()) + ':' + time.addZero(date.getMinutes()) + ' Uhr';
  },

  full: (db_datetime) => {

    if(!db_datetime) {
      return '';
    }

    let date = time.parseDatetime(db_datetime);
    let date_now = new Date();

    let start_string = '';
    if(time.sameDay(date, date_now) === true) {
      start_string = t('g.today') + ' ';
    }
    else {
      let year = '';
      if(date.getFullYear() !== date_now.getFullYear()) {
        year = date.getFullYear() + ' ';
      }
      start_string = t('g.dow_'+date.getDay()) + ', ' + date.getDate() + '. ' + t('g.month_' + date.getMonth()) + ' ' + year;
    }

    return start_string + time.addZero(date.getHours()) + ':' + time.addZero(date.getMinutes()) + ' Uhr';
  },

  full_md: (db_datetime) => {

    if(!db_datetime) {
      return '';
    }

    let date = time.parseDatetime(db_datetime);
    let date_now = new Date();

    let start_string = '';
    if(time.sameDay(date, date_now) === true) {
      start_string = t('g.today') + ' ';
    }
    else {
      let year = '';
      if(date.getFullYear() !== date_now.getFullYear()) {
        year = date.getFullYear() + ' ';
      }
      start_string = date.getDate() + '. ' + t('g.month_' + date.getMonth()) + ' ' + year;
    }

    return start_string + time.addZero(date.getHours()) + ':' + time.addZero(date.getMinutes()) + ' Uhr';
  },

  parseDatetime: (db_datetime) => {
    let dateObj = new Date(db_datetime.replace(/-/g,"/"));
    return dateObj;
  },

  time: (db_datetime) => {

    if(!db_datetime) {
      return '';
    }

    let date = time.parseDatetime(db_datetime);

    let min = date.getMinutes();

    if (min < 10) {
      min = '0' + min;
    }

    return date.getHours() + ':' + min + ' Uhr';
  },

  addZero: (i) => {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  },

  testString: (d) => {
    return d.getFullYear() + ':' + d.getMonth() + ':' + d.getDate();
  },

  sameDay: (date1, date2) => {
    if(time.testString(date1) === time.testString(date2)) {
      return true;
    }
    return false;
  }
};

export default time;
