import api from '../api';

$.fn.uiFormDatetime = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $input;

  let minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 100);

  options = $.extend({},{
    i18n_prefix: '',
    drops: 'up',
    autoUpdateInput: true,
    minDate: '1/1/' + minDate.getFullYear()
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);

  if (options.empty !== undefined && options.empty === true) {
    options.autoUpdateInput = false;
  }

  if (options.onlyFuture !== undefined && options.onlyFuture === true) {
    options.minDate = new Date();
  }

  let plugin = this;

  // public methods
  this.initialize = () => {

    $label = $('<label>' + options.label + '</label>');
    $input = $('<input autocomplete="off" name="' + options.name + '" class="form-control form-datepicker" type="text" placeholder="' + options.placeholder + '" />');

    $input.daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      drops: options.drops,
      timePicker: true,
      timePicker24Hour: true,
      timePickerIncrement: 5,
      autoUpdateInput: options.autoUpdateInput,
      minDate: options.minDate,
      locale: {
        applyLabel: 'Übernehmen',
        cancelLabel: 'Abbrechen',
        format: 'YYYY-MM-DD HH:mm:ss',
        daysOfWeek: [
          'So',
          'Mo',
          'Di',
          'Mi',
          'Do',
          'Fr',
          'Sa'
        ],
        monthNames: [
          'Januar',
          'Februar',
          'März',
          'April',
          'Mai',
          'Juni',
          'Juli',
          'August',
          'September',
          'Oktober',
          'November',
          'Dezember'
        ],
        firstDay: 1
      }
    });

    $element.addClass('form-group form-group-'+options.name).append($label).append($input);

    $('.daterangepicker').addClass('shadow');

    let $date_input = $element.find('[name="'+options.name+'"]');
    $date_input.on('apply.daterangepicker', (ev, picker) => {
      $date_input.val(picker.startDate.format('YYYY-MM-DD HH:mm:ss'));
    });

    this.$input = $input;

    return plugin;

  };

  this.setValue = (value) => {
    $input.val(value);
    $label.text(options.label_edit);
    $input.attr('placeholder', options.placeholder_edit);
  };

  this.resetValue = () => {
    $input.val('');
    $label.text(options.label);
    $input.attr('placeholder', options.placeholder);
  };

  return this.initialize();
};
