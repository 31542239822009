import api from '../api';

/*
 See: https://github.com/LCweb-ita/LC-switch
 */
$.fn.uiFormSwitch = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $input;
  let $wrapper;
  let $hidden;
  let $changed = false;

  options = $.extend({},{
    i18n_prefix: '',
    onText: 'ja',
    offText: 'nein',
    isUnique: false,
    on: false,
    change: () => {}
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);


  let plugin = this;

  this.showError = (key, value) => {
    $element.find('input').parent().addClass('is-invalid');
    $element.find('input').parent().append(`<span class="error invalid-feedback" style="clear: both;">${t(value)}</span>`);
  };

  // public methods
  this.initialize = () => {

    $label = $('<label>' + options.label + '</label>');

    $wrapper = $('<div class="switch-wrapper"></div>');

    $input = $('<input type="checkbox" data-toggle="toggle" />');
    $hidden = $('<input type="hidden" name="' + options.name + '" value="0" />');

    $wrapper.append($input);
    $wrapper.append($hidden);

    $element.addClass('form-group form-group-' + options.name).append($label).append($wrapper);

    lc_switch($input, {
      on_txt: options.onText,
      off_txt: options.offText
    });

    // checked
    $input.on('lcs-on', () => {
      $hidden.val(1);
      options.change(1);
    });

    // unchecked
    $input.on('lcs-off', () => {
      $hidden.val(0);
      options.change(0);
    });

    // state change
    $input.on('lcs-statuschange', () => {
      let state = $input.prop('checked');
      
      //if (options.OnSwitchChange) {  
      if (options.onSwitchChange) {  
        options.onSwitchChange(state);
      }
    });

    this.$input = $input;

    return plugin;
  };

  this.toggle = () => {
    lcs_toggle($input);
  };

  this.enable = () => {
    lcs_on($input);
  };

  /*
   * lcs_off() funktioniert nicht, darum manuell!
   * Ich vermute es funktioniert nicht, da lcs_switch() davon ausgeht direkt alle inputs übergeben zu bekommen,
   * bin mir aber nicht sicher.
   */
  this.disable = () => {
    $element.find('.lcs_switch').removeClass('lcs_on').addClass('lcs_off');
    $input.prop('checked', false);
    $hidden.val(0);
    options.change(0);
    //lcs_off($input);
  };

  this.getValue = () => {
    return $hidden.val();
  }

  this.setValue = (value) => {
    if(value !== undefined) {
      $(document).ready(() => {
        if(value === true || parseInt(value) === 1) {
          lcs_on($input);
        }
        else {
          this.disable();
        }
      });
    }
  };

  this.resetValue = () => {
    lcs_off($input);
  };

  return this.initialize();
};


