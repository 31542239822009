import app from '../../app/app';;
import api from '../../app/api';

const persons = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12" id="create-person"> <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#createPersonModal"> Neue Person anlegen </button> <div class="modal fade" id="createPersonModal" tabindex="-1" role="dialog" aria-labelledby="createPersonModalLabel" aria-hidden="true"> <div class="modal-dialog" role="document"> <div class="modal-content"> <div class="modal-header"> <h5 class="modal-title" id="createPersonModalLabel">Person anlegen</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> </div> <div class="modal-body"> <form id="form-create-person"> <div class="form-group"> <label for="createPersonInputFirstname">Vorname</label> <input type="text" class="form-control" id="createPersonInputFirstname" placeholder="Vorname"> </div> <div class="form-group"> <label for="createPersonInputLastname">Nachname</label> <input type="text" class="form-control" id="createPersonInputLastname" placeholder="Nachname"> </div> <div class="form-group"> <label for="createPersonInputEmail">E-Mail</label> <input type="email" class="form-control" id="createPersonInputEmail" placeholder="E-Mail"> </div> <button type="submit" class="btn btn-primary">anlegen</button> </form> </div> </div> </div> </div> </div> </div> <div class="row"> <div class="col-md-12" id="persons-management"> </div> </div></div>'; return out;
    },
    js: function(it) {
          app.setTitle('Personen verwalten'); app.setBreadcrumps({ 'Administration': '/admin', 'Personen': '/admin/persons' }); api.get('/persons', { success: (response) => { let persons = []; $.each(response.persons, (i, person) => { persons[i] = person.firstname + ' ' + person.lastname + ' ' + person.email; }); $('#persons-management').html(persons.join('<br />')); } }); $('#form-create-person').submit(function (event) { event.preventDefault(); var person = { firstname: $('#createPersonInputFirstname').val(), lastname: $('#createPersonInputLastname').val(), email: $('#createPersonInputEmail').val() }; api.post('/persons', { person: person }); });
    }
};

export default persons;
