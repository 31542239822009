import api from '../api';

$.fn.uiFormDatacomp = function(options) {

  let $element = $(this);
  let $label;
  let $input;

  options = $.extend({},{
    i18n_prefix: '',
    drops: 'up'
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);

  let plugin = this;

  // public methods
  this.initialize = () => {

    $label = $('<label>' + options.label + '</label>');
    $input = $('<input autocomplete="off" name="' + options.fieldname + '" class="form-control" type="text" placeholder="' + options.placeholder + '" readonly="readonly" />');

    $element.addClass('form-group form-group-'+options.name).append($label).append($input);

    this.$input = $input;

    return plugin;

  };

  this.setValue = (value) => {
    $input.val(value);
    $label.text(options.label_edit);
    $input.attr('placeholder', options.placeholder_edit);
  };

  this.resetValue = () => {
    $input.val('');
    $label.text(options.label);
    $input.attr('placeholder', options.placeholder);
  };

  return this.initialize();
};
