import t from './i18n';
import app from './app';
import fetch from "node-fetch";

const prefixCodes = [
  "1",   // USA, Canada, and several Caribbean nations
  "44",  // United Kingdom
  "49",  // Germany
  "33",  // France
  "34",  // Spain
  "39",  // Italy
  "31",  // Netherlands
  "32",  // Belgium
  "353", // Ireland
  "41",  // Switzerland
  "43",  // Austria
  "45",  // Denmark
  "46",  // Sweden
  "47",  // Norway
  "48",  // Poland
  "358", // Finland
  "372", // Estonia
  "371", // Latvia
  "370", // Lithuania
  "351", // Portugal
  "352", // Luxembourg
  "356", // Malta
  "357", // Cyprus
  "359", // Bulgaria
  "420"  // Czech Republic
];

// Function to add a "+" sign to the beginning of a phone number if it starts with a prefix code
function addPlusPrefix(phoneNumber) {
  const regex = new RegExp(`^(${prefixCodes.join("|")})`);
  if (regex.test(phoneNumber)) {
    return `+${phoneNumber}`;
  }
  return phoneNumber;
}

let helper = {
  loaded_src: [],
  pupup_windows: {},
  stylesheet: null,
  nl2br: (str, is_xhtml) => {
    if (typeof str === 'undefined' || str === null) {
      return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
  },

  tpl: (str, replace_object) => {
    Object.keys(replace_object).forEach(key => {
      str = str.replace('{{' + key + '}}', replace_object[key]);
    });
    return str;
  },

  calcFontColor: (background_color) => {

    let rgb = helper.hexToRgb(background_color);

    if(rgb === null || typeof rgb.r === "undefined") {
      return '#000';
    }

    if ((rgb.r*0.299 + rgb.g*0.587 + rgb.b*0.114) > 186) {
      return '#000';
    }
    else {
      return '#fff';
    }
  },

  hexToRgb: (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : '#000';
  },

  formatBytes: (bytes, decimals = 2) => {
    bytes = parseInt(bytes);
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  },

  getWidth: () => {
    return window.innerWidth;
  },

  isMobile: () => {

    if(helper.getWidth() <= 1024) {
        return true;
    }

    return false;
  },

  downloadFile: (url, callback) => {

    if(url.substring(0,1) === '/') {
      url = document.location.origin + url;
    }

    //url = 'http://hunter.jungwild.io/';

    let iframe;
    iframe = document.getElementById("hiddenDownloader");
    if (iframe == null) {
      iframe = document.createElement('iframe');
      iframe.id = "hiddenDownloader";
      iframe.style.visibility = 'hidden';
      iframe.style.width = '1px';
      iframe.style.height = '1px';
      document.body.appendChild(iframe);
    }

    iframe.src = url;


    return false;
  },

  popup: (url, window_id) => {

    if(!window_id) {
      window_id = 'default';
    }

    if(url.substring(0,1) === '/') {
      url = document.location.origin + url;
    }

    var width = 600;
    var height = 720;

    var top = 0;
    var left = screen.width-width;

    if(helper.pupup_windows[window_id] === undefined || helper.pupup_windows[window_id].closed) {
      helper.pupup_windows[window_id] = window.open( url, '_blank', 'location=yes,height=' + height + ',width=' + width + ',scrollbars=yes,status=yes,top='+top+',left='+left);
    }
    else {
      helper.pupup_windows[window_id].location = url;
      helper.pupup_windows[window_id].focus();
    }
  },

  empty: (vari) => {
    if(vari === undefined) {
      return true;
    }
    if(vari === '') {
      return true;
    }
    if(vari === null) {
      return true;
    }
    if(!vari) {
      return true;
    }
    if(Array.isArray(vari) && vari.length === 0) {
      return true;
    }

    return false;
  },

  loadScript: function(src, callback) {

    if(helper.loaded_src.indexOf(src) == -1) {

      helper.loaded_src.push(src);

      $.ajax({
        url: src,
        dataType: 'script',
        success: callback,
        async: true
      });

    }
  },

  purgeObjectArrays: (object) => {
    let out = {};
    for (var property in object) {
      if (object.hasOwnProperty(property)) {

        if(property.indexOf('[') > -1) {
          let prop = property.split('[')[0];
          if(object[prop] === undefined) {
            out[prop] = object[property];
            if(!Array.isArray(out[prop])) {
              out[prop] = [out[prop]];
            }
          }
        }
        else {
          out[property] = object[property];
        }
      }
    }

    return out;
  },

  purgeObject: (object, allowed_fields) => {
    let out =  {};

    allowed_fields.forEach((field) => {
      if(object[field] !== undefined) {
        out[field] = object[field];
      }
    });

    return out;
  },

  initials: (name, letter_count) => {
    if(letter_count === undefined) {
      letter_count = 2;
    }

    if (!name) {
      return '?';
    }

    let parts = name.split(' ');
    let out = '';
    if(parts.length < letter_count) {
      return name.substring(0,letter_count).toUpperCase();
    }
    else {
      for(let i =0;i<letter_count;i++) {
        out += parts[i].substring(0,1);
      }
      return out.toUpperCase();
    }
  },

  copyToClip: (text) => {

    let $el = $('<input type="text" id="copyTarget" value="' + text + '" />');
    app.$body.append($el);
    let elem = $el[0];

    // create hidden text element, if it doesn't already exist
    var targetId = "_hiddenCopyText_";
    var isInput = elem.tagName === "INPUT" || elem.tagName === "TEXTAREA";
    var origSelectionStart, origSelectionEnd;
    if (isInput) {
      // can just use the original source element for the selection and copy
      target = elem;
      origSelectionStart = elem.selectionStart;
      origSelectionEnd = elem.selectionEnd;
    } else {
      // must use a temporary form element for the selection and copy
      target = document.getElementById(targetId);
      if (!target) {
        var target = document.createElement("textarea");
        target.style.position = "absolute";
        target.style.left = "-9999px";
        target.style.top = "0";
        target.id = targetId;
        document.body.appendChild(target);
      }
      target.textContent = elem.textContent;
    }
    // select the content
    var currentFocus = document.activeElement;
    target.focus();
    target.setSelectionRange(0, target.value.length);

    // copy the selection
    var succeed;
    try {
      succeed = document.execCommand("copy");
    } catch(e) {
      succeed = false;
    }
    // restore original focus
    if (currentFocus && typeof currentFocus.focus === "function") {
      currentFocus.focus();
    }

    if (isInput) {
      // restore prior selection
      elem.setSelectionRange(origSelectionStart, origSelectionEnd);
    } else {
      // clear temporary content
      target.textContent = "";
    }

    $el.remove();

    return succeed;
  },

  removeAllCssRules: () => {
    if(helper.stylesheet) {
      document.head.removeChild(helper.stylesheet);
      helper.stylesheet = null;
    }
  },

  addCssRules: (css_rules) => {

    if(!helper.stylesheet) {
      helper.stylesheet = document.createElement('style');
      document.head.appendChild(helper.stylesheet);
    }

    let styleSheet = helper.stylesheet.sheet;

    // Insert CSS Rule
    styleSheet.insertRule(css_rules, styleSheet.cssRules.length);
  },

  randomString: () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  },

  slugify: function(text, slashes)
  {

    if(slashes === undefined) {
      slashes = false;
    }

    text = text.toLowerCase()
      .replace('ä','ae')
      .replace('ö','oe')
      .replace('ü', 'ue')
      .replace('ß','ss')
      .replace('_','-')
      .replace(' ', '-')
      .replace(/\s+/g, '-');           // Replace spaces with -

    if(slashes) {
      text = text.replace(/[^\w\-\/]+/g, '');
    }
    else {
      text = text.replace(/[^\w\-]+/g, '');
    }

    // Remove all non-word chars
    text = text.replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '').trim();             // Trim - from start of text
    //.replace(/-+$/, '');            // Trim - from end of text

    return text;
  },

  htmlEntities: (str) => {
    return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
  },

  str_short: (text, length) => {
    if(text.length > length) {
      return text.substring(0, length)+'...';
    }
    return text;
  },

  getLastWeekAsDateForInput: () => {
    let today = new Date();
    let week_ago = new Date();
    week_ago.setDate(today.getDate()-7);

    let today_string = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
    let week_string = week_ago.getFullYear() + '-' + (week_ago.getMonth()+1) + '-' + week_ago.getDate();

    return week_string + ' - ' + today_string;
  },

  getTodayAsDateForInput: () => {
    let today = new Date();
    return today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
  },

  getMwSt: () => {
    return 19;
  },

  /* returns html code with initials icons of all assigned recruiters and account managers */
  getEmployeeBadges: (job) => {
    let htmlCode = '';
    for (const emp of job.account_manager.concat(job.recruiter)) {
      const tmp_name = `${emp.firstname} ${emp.lastname}`;
      htmlCode += `
      <a 
        data-id="${emp.id}" 
        data-name="${emp.text}" 
        title="${t('helper.responsibleEmployee')}${emp.text}" 
        href="#" 
        class="avatar-circle-small mr-1 set-user-gap"
        style="background-color:${emp.color}"
      >
        <span class="initials-small">${helper.initials(tmp_name)}</span>
      </a>`;
    }
    return htmlCode;
  },

  /* returns html code with all available mail/phone data */
  getMailAndPhones: (obj) => {

    const phoneSnippet = (value, title) => {
      let c = '';
      if (value) {
        value = addPlusPrefix(value);
        c += '<i class="fas fa-phone mr-1"></i>';
        c += `<a href="#" class="copy-to-clip" data-text="${value}" title="${title}">${value}</a><br>`;
      }
      return c;
    }

    let html = '';

    if (obj.email) {
      html += '<i class="fas fa-at mr-1"></i>';
      html += `<a href="mailto:${obj.email}">${obj.email}</a><br>`;
    }

    html += phoneSnippet(obj.phone, 'Telefon');
    html += phoneSnippet(obj.phone_mobile, 'Mobil');
    html += phoneSnippet(obj.phone_private, 'Privat');

    return html;
  },

  sendMattermost: (message, token) => {
    /*  tokens:
        bf43qtj8pjg4zpoam4d9eh1zao: Peter
        ipdpxdb7stgf8y3jxd7cwhcs8y: User/Kunden-Anfragen
        hiauchpwf3bjtn5g1tdsnnx7no: Julian - Test
    */

    const url = 'https://chat.jungwild.info/hooks/' + token;
    const data = {"text": message};

    fetch(url, {
      method: 'POST',
      mode: 'no-cors',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    });
  },

  /* cut text after limit. If break is inside of word, the full word is returned */
  truncTextAfterWord: (text, limit) => {
    if (text.length <= limit) {
      return text;
    }

    let restText = text.substring(limit);

    let a = restText.indexOf(' ');
    let b = restText.indexOf('<br');

    let index = -1;

    if ((a == -1) && (b != -1)) {
      index = b;
    }

    if ((a != -1) && (b == -1)) {
      index = a;
    }

    if ((a != -1) && (b != -1)) {
      index = Math.min(a, b);
    }

    if (index == -1) {
        return text;
    }

    let truncatedText = text.substring(0, limit + index);

    return truncatedText;
  },
  isHTML: (text) => {
    /* Checks if a text is plaintxt or html */
    var doc = new DOMParser().parseFromString(text, 'text/html');
    return Array.from(doc.body.childNodes).some(function(node) {
      return node.nodeType === 1;
    });
  },
  /* removes whitespaces inside of html tags */
  removeWhitespaceInTags: (html) => {
    return html.replace(/<[^>]*>/g, function(match) {
        return match.replace(/\s+/g, '');
    });
  },

};

export default helper;
