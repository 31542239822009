import api from '../api';
import session from '../session';
import routes from '../routes';
import helper from '../helper';
import modal from '../../_compiled/components/modal';
import button_save from '../../_compiled/components/button_save';
import msg from '../../app/msg';

$.fn.uiDataTable = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $header = null;

  options = $.extend({},{
    add: true,
    edit: true,
    delete: true,
    hide_search: false,
    i18n_prefix: null,
    table_options: {},
    onFinishLoading: () => {},
    render: {},
    success: (data) => {},
    afterLoad: () => {},
    uri: '',
    addSuccess: (res) => {},
    editSuccess: (new_data, old_data) => {},
    onAddMode:()=>{},
    onEditMode:(data)=>{},
    pageLength: 10,
    optionButtons: [],
    class: ' table-striped',
    additionalTitle: t('g.advanced'),
    resetFilters: false,
    resetFiltersUrl: null,
    autosearch: true,
    useModalEdit: true,
    onEditClick: (id)=>{},
  }, options);

  if(!options.i18n_prefix) {
    options.i18n_prefix = options.table[0] + '.';
  }

  if(options.model === undefined) {
    options.model = options.table[1].substring(0,1).toUpperCase()+options.table[1].substring(1);
  }

  let plugin = this;
  let $table = null;
  let table_options = null;
  let $card = null;
  let $card_tools = null;
  let $card_title = null;
  let $loader = null;
  let additional_query = {};
  let $filter_input = null;
  let $form = null;
  let $modal = null;
  let $btn_new = null;
  let $btn_save = null;
  let $btn_save_edit = null;
  let $box_result_count = null;
  let $search_field = null;
  let $btn_search = null;
  let $btn_reset_filter = null;
  let filter_orig = null;
  let current_order = null;
  let current_page = null;
  let update_wait = false;
  let filter_changed = false;
  let search_wait = false;
  let $filter_loader = null;
  let $filter_loading_timer = null;

  plugin.options = options;

  plugin.setQuery = (key, value) => {
    additional_query[key] = value;
  };

  plugin.update = () => {
    $table.DataTable().ajax.reload(() => { }, false);

    if (options.afterDataUpdate !== undefined) {
      options.afterDataUpdate();
    }
    /*
     hack!

    $filter_input.val('eee');
    $filter_input.trigger('keyup');
    $filter_input.val('');
    $filter_input.trigger('keyup');
    */
  };

  plugin.addOptionButton = (btn) => {
    options.optionButtons.push(btn);
  };

  this.initCard = () => {
    $card = $(`
      <div class="card">
        <div class="card-header border-bottom-0">
          <div class="card-title">
            <div class="input-group input-group-sm">
              <input type="text" class="form-control float-right card-tool-search" placeholder="` + t(options.i18n_prefix + 'ph_search') + `">
              <div class="input-group-append">
                <button type="submit" class="btn btn-default"><i class="fas fa-search"></i></button>
              </div>
            </div> 
            <small class="box-result-count text-small"></small>          
          </div>

          <div class="card-tools" id="candidate-card-tools">
                            
          </div>
        </div>
        
        <div class="card-body p-0 border-top-0">

        <table class="m-0 table table-data-list` + options.class + `" ` + ((options.col_width_percent === true)?'style="table-layout: fixed;"':'') + `></table>

        </div>
        
        <div class="loading-indicator overlay" style="display: none">
            <i class="fas fa-2x fa-sync-alt"></i>
        </div>

        <div class="card-footer clearfix">
            
        </div>

      </div>`);

    $table = $card.find('.card-body table.table-data-list');
    $loader = $card.find('.loading-indicator');
    $card_tools = $card.find('.card-tools');
    $card_title = $card.find('.card-title');
    $box_result_count = $card.find('.box-result-count');
    $search_field = $card.find('.card-tool-search');

    $element.append($card);

    if (options.hide_search && options.hide_search == true) {
      $('.card-tool-search, .input-group-append').hide();
    }
  };

  this.initEditDelete = () => {

    if (options.optionButtons.length === 0) {
      return;
    }

    options.cols.push({
      name: '__options',
      text: ' ',
      width: 15
    });

    options.render.__options = (obj) => {

      let btngrp = '';

      options.optionButtons.forEach((btn) => {

        if(!btn.class) {
          btn.class = 'btn-default';
        }

        if(btn.render) {
          btngrp += btn.render(obj,btn);
        }
        else {
          btngrp += '<button data-id="' + obj.id + '" type="button" class="btn btn-sm dt-btn-' + btn.name + ' ' + btn.class + '"><i class="' + btn.icon + '"></i></button>';
        }
      });

      return '<div class="btn-group btn-group-datatable" role="group">' + btngrp + '</div>';

    };

  };

  this.initTable = () => {

    plugin.initEditDelete();

    /*
     * add Title
     */
    let coloums_send = [];
    let appended_columns = [];
    if(options.cols !== false) {

      let $header_row = $('<tr></tr>');

      /*
       render options
     */

      $.each(options.cols, (i, field) => {

        let $col = $('<th></th>');
        let field_key = null;
        if(typeof field !== 'string') {

          field_key = field.name;

          if(field.hidden === undefined || field.hidden !== true) {
            $col.text(field.text);
            if(field.width !== undefined) {
              $col.css('width', field.width + ((options.col_width_percent === true)?'%':'px'));
            }
            coloums_send.push(field.name);
          }
          else {
            appended_columns.push(field.name);
            return;
          }
        }
        else {
          field_key = field;
          $col.text(t(options.i18n_prefix + field));
          coloums_send.push(field);
        }

        $header_row.append($col);
      });

      $header = $('<thead></thead>');
      $header.append($header_row);
      $table.append($header);
    }


    let columns_object = [];

    $.each(coloums_send, (i, col) => {

      let orderable = true;

      if(col.substr(0,2) === '__') {
        orderable = false;
      }

      if(options.render[col] !== undefined) {
        columns_object.push({
          orderable: orderable,
          data: col,
          render: (data, type, row) => {
            return options.render[col](row);
          }
        });
      }
      else {
        columns_object.push({data: col, orderable: orderable});
      }

    });

    $.each(appended_columns, (i, col) => {
      coloums_send.push(col);
    });

    let pageLength = options.pageLength;

    /*
     * default filter for init call
     */
    if(options.filter && options.filter.default) {
      for (var property in options.filter.default) {
        if (options.filter.fields.hasOwnProperty(property)) {
          plugin.setQuery('filter[' + property + ']', options.filter.default[property]);
        }
      }
    }

    let datatable_url = '/api/v1/resource/datatable/' + options.table[0];

    /* Damit kann abhängig von einem anderen Feld Inhalt geladen werden, siehe Niederlassungen */
    if (options.fkey_id && options.fkey_id !== undefined) {
      datatable_url = '/api/v1/grouped-resource/' + options.table[0] + '/datatable/' + options.fkey_id;
    }

    table_options = $.extend({},options.table_options,{
      pageLength: pageLength,
      //lengthChange: false,
      //searching: false,
      columns: columns_object,
      autoWidth: false,
      drawCallback: () => {
        setTimeout(() => {
          plugin.updateOptionButtons();
        },50);
      },
      language: {
        //processing: '<i class="fa fa-spinner fa-spin fa-2x fa-fw"></i>',
        lengthMenu: 'Zeige _MENU_ Einträge pro Seite',
        zeroRecords: 'Nichts gefunden (ㆆ _ ㆆ)',
        info: 'Seite _PAGE_ von _PAGES_',
        infoEmpty: 'Nichts gefunden (ㆆ _ ㆆ)',
        infoFiltered: '(von insgesamt _MAX_ Einträgen)',
        processing: false,
        paginate: {
          next: '<i class="fas fa-caret-right"></i>',
          previous: '<i class="fas fa-caret-left"></i>'
        }
      }
    },{
      'processing': true,
      'serverSide': true,
      'ajax': {
        /*
         * add authorization header
         */
        beforeSend: function (request) {
          request.setRequestHeader('Authorization', 'Bearer ' + session.getToken());
        },
        url: datatable_url,
        type: 'POST',
        data: (d) => {
          /*if (options.resetFilters) {
            if (filter_orig == null) {
              filter_orig = Object.assign({}, d);
            }

            if (do_filter_reset) {
              return $.extend( {}, filter_orig);
            }
          }*/
          if(options.additionalParams) {
            additional_query = options.additionalParams;
          }

          return $.extend( {}, d, additional_query);
        },
        dataSrc: (json) => {
          plugin.hideFilterLoading();
          search_wait = false;

          return json.data;
        },
        error: (xhr, error, code) => {
          api.handleError(xhr, error, code);
          if(search_wait) {
            plugin.hideFilterLoading();
            search_wait = false;
          }
        }
      }
    });

    if(options.default_order !== undefined) {
      table_options.order = [options.default_order];
    }

    let url_params = routes.getParams();

    if(url_params.page !== undefined) {
      table_options.displayStart = parseInt(url_params.page)*pageLength;
    }

    if(url_params.sort_col !== undefined && !helper.empty(url_params.sort_col)) {
      table_options.order = [parseInt(url_params.sort_col), url_params.sort_type];
    }

    if(!helper.empty(url_params.query)) {
      table_options.search = {
        search: url_params.query.trim()
      };
      /*$search_field.val(url_params.query.trim());*/
    }

    /*
       * Error Handling
       */
    $.fn.dataTable.ext.errMode = 'none';
    $table.on( 'error.dt', ( e, settings, techNote, message ) => {
      console.log( 'An error has been reported by DataTables: ', message );
      if(search_wait) {
        plugin.hideFilterLoading();
        search_wait = false;
      }
    });

    $table.DataTable(table_options);
    /* @todo dirty */
    setTimeout(() => {
      options.afterLoad();
    },800);
  };

  /*
  plugin.reloadData = () => {
    $table.DataTable().destroy();

    $table.DataTable(table_options);
    setTimeout(() => {
      options.afterLoad();
    },800);
  };
  */

  this.updateOptionButtons = () => {

    options.optionButtons.forEach((btn) => {

      if(btn.click) {
        $('.dt-btn-' + btn.name).on('click', (ev) => {
          //ev.preventDefault();
          let $btn = $(ev.currentTarget);
          let $tr = $btn.parent().parent().parent();
          let id = $btn.data('id');

          btn.click(id, $btn, $tr, ev);

        });
      }

      if(btn.mouseover) {
        $('.dt-btn-' + btn.name).mouseover((ev) => {
          let $btn = $(ev.currentTarget);
          let $tr = $btn.parent().parent().parent();
          let id = $btn.data('id');
          btn.mouseover(id, $btn, $tr);
        });
      }

    });
  };

  this.beautify = () => {

    /*
      search field
     */
    $card.find('.dataTables_filter').parent().parent().hide();
    $filter_input = $card.find('.dataTables_filter input');

    $search_field.keyup( (ev) => {
      let $el = $(ev.target);
      $filter_input.val($el.val());
      $filter_input.trigger('keyup');
    });

    /*
    pagination
    */
    let $paginate = $card.find('.dataTables_paginate');
    let $footer = $card.find('.card-footer');
    $paginate.parent().parent().hide();

    setTimeout(() => {
      let $pagination_list = $paginate.find('.pagination');
      $pagination_list.addClass('pagination-sm float-right m-0');
      $pagination_list.appendTo($footer);

      let param_timeout = false;

      $table.on('xhr.dt', (e, settings, json, xhr) => {
        if (!update_wait) {
          $box_result_count.text(t('g.result_count', {count: Number(json.recordsFiltered).toLocaleString()}));
          update_wait = true;
          window.setTimeout(function(){
            update_wait = false;
          }, 600);
        }
                options.success(json);
        setTimeout(()=> {
          $pagination_list = $paginate.find('.pagination');
          $pagination_list.addClass('pagination-sm float-right m-0');
          $footer.empty();
          $pagination_list.appendTo($footer);
          $loader.hide();
          routes.initLinks($table.find('.router-link'));

          let current_order = $table.DataTable().order();
          let info = $table.DataTable().page.info();

          if(param_timeout !== false) {
            clearTimeout(param_timeout);
          }
          param_timeout = setTimeout(() => {
            param_timeout = false;
            routes.setParams({
              query: $search_field.val(),
              sort_col: current_order[0][0],
              sort_type: current_order[0][1],
              page: info.page
            });

            options.afterLoad();

          },1000);


        },800);

      });

      $table.on('preXhr.dt', (opt1, opt2, opt3, opt4) => {
        //$loader.show();
      });

      routes.initLinks($table.find('.router-link'));

    },4000);
  };

  this.initAddButton = () => {
    $btn_new = $('<button type="button" class="btn btn-primary btn-sm"><i class="fas fa-plus"></i> ' + t(options.i18n_prefix + 'add_' + options.table[0]) + '</button>');

    $btn_new.click(() => {
      $form.resetValues();
      $modal.modal({
        show: true,
        backdrop: 'static',
        keyboard: false
      });

      if (options.loadContent) {
        options.loadContent();
      }
    });

    $card_tools.append($btn_new);

  };

  this.initModal = () => {

    $btn_save = button_save.js();
    $btn_save_edit = button_save.js();

    /*
     * Add New Modal
     */
    $modal = modal.js({
      title: t(options.i18n_prefix + 'add_' + options.table[0]),
      size: 'lg'
    });


    $modal.$footer.append($btn_save);
    $modal.$footer.append($btn_save_edit);

    plugin.$modal = $modal;

  };

  this.initForm = () => {

    $form = $('<form action="' + '/' + options.table[0] + '"></form>').uiForm({
      fields: options.fields,
      $el_save: $btn_save,
      $el_edit: $btn_save_edit,
      url: '/' + options.table[0],
      i18n_prefix: options.i18n_prefix,
      additionalTitle: options.additionalTitle,
      addSuccess: (res) => {
        options.addSuccess(res);
      },
      editSuccess: (new_data, old_data) => {
        options.editSuccess(new_data, old_data);
      },
      success: (res) => {
        plugin.update();
        $modal.modal('hide');
        $form.resetValues();
      },
      onAddMode: () => {
        $modal.$title.text(t(options.i18n_prefix +'add_' + options.table[0]));
        options.onAddMode();
      },
      onEditMode: (data) => {
        $modal.$title.text(t(options.i18n_prefix +'edit_' + options.table[0], data));
        options.onEditMode(data);
      },
      complete: () => {
        options.onFinishLoading();
        $btn_save_edit.find('i').removeClass('fa-spin fa-cog').addClass('fa-save');
        $btn_save.find('i').removeClass('fa-spin fa-cog').addClass('fa-save');
      },
      start: () => {
        $btn_save_edit.find('i').removeClass('fa-save').addClass('fa-spin fa-cog');
        $btn_save.find('i').removeClass('fa-save').addClass('fa-spin fa-cog');
        if(typeof options.inLoading === 'function') {
          options.onLoading();
        }

      },
      default: options.default
    });

    $modal.$body.append($form);

  };

  this.initFilter = () => {

    if(options.filter !== undefined) {

      let i18n_prefix = options.i18n_prefix;
      if(options.filter.i18n_prefix) {
        i18n_prefix = options.filter.i18n_prefix;
      }

      let $filter_form;

      if (options.autosearch == false) {

        $filter_form = $('<form></form>').uiForm({
          i18n_prefix: i18n_prefix,
          fields: options.filter.fields,
          change: (data) => {
            filter_changed = true;
          }
        });

      }
      else {

        $filter_form = $('<form></form>').uiForm({
          i18n_prefix: i18n_prefix,
          change: (data) => {

            let pararms = routes.getParams();

            let new_params = helper.purgeObject(pararms, ['query', 'sort_col', 'sort_type', 'page', 'mark_unseen']);

            let final_params = helper.purgeObjectArrays($.extend({}, new_params, data));

            additional_query = {};

            for (var property in options.filter.fields) {
              if (options.filter.fields.hasOwnProperty(property)) {
                if (typeof final_params[property] === 'object') {

                  for (const [key, value] of Object.entries(final_params[property])) {
                    let key_name = property + '[' + key + ']';
                    plugin.setQuery('filter[' + property + '][' + key + ']', final_params[property][key]);
                  }

                } else if (final_params[property] !== undefined) {
                  plugin.setQuery('filter[' + property + ']', final_params[property]);
                } else if (final_params[property + '_id'] !== undefined) {
                  plugin.setQuery('filter[' + property + '_id]', final_params[property + '_id']);
                } else {
                  plugin.setQuery('filter[' + property + ']', null);
                }
              }
            }

            routes.replaceParams(final_params);

            plugin.update();
          },
          fields: options.filter.fields
        });

      }

      /*
       * Suchen Button
       */
      if (options.autosearch == false) {
        $btn_search = $('<button class="btn btn-primary dosearch">Suchen</button>');
        if(options.search_interval != undefined && options.search_interval >= 1000) {
          $btn_search.attr('hidden', 'hidden');
        }
        $filter_form.append($btn_search);

        $btn_search.off('click').on('click', (ev) => {
          ev.preventDefault();

          additional_query = {};

          let data = $filter_form.serializeObject();

          let pararms = routes.getParams();

          let new_params = helper.purgeObject(pararms, ['query', 'sort_col', 'sort_type', 'page', 'mark_unseen']);

          let final_params = helper.purgeObjectArrays($.extend({}, new_params, data));

          for (var property in options.filter.fields) {
            if (options.filter.fields.hasOwnProperty(property)) {
              if (typeof final_params[property] === 'object') {

                for (const [key, value] of Object.entries(final_params[property])) {
                  let key_name = property + '[' + key + ']';
                  plugin.setQuery('filter[' + property + '][' + key + ']', final_params[property][key]);
                }

              } else if (final_params[property] !== undefined) {
                plugin.setQuery('filter[' + property + ']', final_params[property]);
              } else if (final_params[property + '_id'] !== undefined) {
                plugin.setQuery('filter[' + property + '_id]', final_params[property + '_id']);
              } else {
                plugin.setQuery('filter[' + property + ']', null);
              }
            }
          }

          routes.replaceParams(final_params);
          
          if( options.search_interval >= 1000 ) {
            $filter_loader = $filter_form.parent().parent().find('.overlay:first');
          }

          plugin.update();
        });

      }

      /*
       * add filter reset button when the option flag is set
       */
      if (options.resetFilters && options.resetFiltersUrl != null) {
        $btn_reset_filter = $('<a class="btn btn-danger" href="' + options.resetFiltersUrl + '">Filter zurücksetzen</a> ');
        $filter_form.append($btn_reset_filter);
      }

      plugin.$filter_form = $filter_form;

      options.filter.$element.append($filter_form);

      /*
       * init form params
       */
      let params = routes.getParams();

      let check = false;
      for (var property in options.filter.fields) {
        if (options.filter.fields.hasOwnProperty(property)) {

          if(params[property] !== undefined || params[property+'[]'] !== undefined) {
            check = true;
          }
        }
      }

      if(check) {
        //params = helper.purgeObjectArrays(params);
        $filter_form.setValues(params);
        setTimeout(() => {
          //$filter_form.fireChange();

          if(options.filter.onLoad) {
            options.filter.onLoad();
          }

        },2000);
      }
      else if(options.filter.default) {
        params = helper.purgeObjectArrays(params);
        $filter_form.setValues(options.filter.default);
        setTimeout(() => {
          //$filter_form.fireChange();

          if(options.filter.onLoad) {
            options.filter.onLoad();
          }

        },200);
      }

      if(options.autosearch == false && options.search_interval >= 1000) {
        setInterval(() => {
          if(filter_changed && !search_wait) {
            filter_changed = false;
            search_wait = true;

            plugin.showFilterLoading();
            this.doSearch();
          }
        }, options.search_interval);
      }
    }
  };
  
  plugin.errorSearchFilter = () => {
    if(search_wait) {
      plugin.hideFilterLoading();
      search_wait = false;
    }
    msg.error('Suchzeitüberschreitung.');
  }

  plugin.showFilterLoading = () => {
    if($filter_loader !== null) {
      $filter_loader.show();
      if($filter_loading_timer === null) {
        $filter_loading_timer = setTimeout(() => {
          plugin.errorSearchFilter();
        }, 4000);
      }
    }   
  };

  plugin.hideFilterLoading = () => {
    if($filter_loader !== null) {
      if($filter_loading_timer !== null){
        clearTimeout($filter_loading_timer);
        $filter_loading_timer = null;
      }
      $filter_loader.hide();
    }
  };

  this.doSearch = () => {
    $table.DataTable().page(0);
    
    additional_query = {};

    let data = plugin.$filter_form.serializeObject();

    let pararms = routes.getParams();

    let new_params = helper.purgeObject(pararms, ['query', 'sort_col', 'sort_type', 'page', 'mark_unseen']);

    let final_params = helper.purgeObjectArrays($.extend({}, new_params, data));

    for (var property in options.filter.fields) {
      if (options.filter.fields.hasOwnProperty(property)) {
        if (typeof final_params[property] === 'object') {

          for (const [key, value] of Object.entries(final_params[property])) {
            let key_name = property + '[' + key + ']';
            plugin.setQuery('filter[' + property + '][' + key + ']', final_params[property][key]);
          }

        } else if (final_params[property] !== undefined) {
          plugin.setQuery('filter[' + property + ']', final_params[property]);
        } else if (final_params[property + '_id'] !== undefined) {
          plugin.setQuery('filter[' + property + '_id]', final_params[property + '_id']);
        } else {
          plugin.setQuery('filter[' + property + ']', null);
        }
      }
    }

    routes.replaceParams(final_params);

    plugin.update();    
  }

  this.initFields = () => {

    /*
     * falls acl abhängigkeit da ist ggf. entfernen
     */
    for (var property in options.fields) {
      if (options.fields.hasOwnProperty(property)) {
        if(options.fields[property].acl && !acl(options.fields[property].acl)) {
          delete options.fields[property];
        }
      }
    }
  };

  plugin.triggerNew = () => {
    $form.resetValues();
    $modal.modal({
      show: true,
      backdrop: 'static',
      keyboard: false
    });
  };

  plugin.triggerEdit = (id) => {
    api.get(options.uri + '/resource/get/' + options.table[0] + '/' + id,{
      success: (item) => {
        $form.setValues(item);
        $modal.modal({
          show: true,
          backdrop: 'static',
          keyboard: false
        });
      }
    });
  };

  // public methods
  this.initialize = () => {

    this.initFields();

    if (options.edit) {
      options.optionButtons.push({
        name: (options.identify_option_btn)?('update-'+ options.table[0]):'update',
        icon: 'fas fa-pencil-alt',
        class: 'btn-default',
        click: (id, $btn, $tr, callback) => {
          if(options.useModalEdit === true) {
            api.get(options.uri + '/resource/get/' + options.table[0] + '/' + id, {
              success: (item) => {
                $form.setValues(item);
                $modal.modal({
                  show: true,
                  backdrop: 'static',
                  keyboard: false
                });

                /*if (callback) {
                  callback();
                }*/

              }
            });
          }
          else {
            options.onEditClick(id);
          }
        }
      });
    }

    if (options.delete) {
      options.optionButtons.push({
        name: (options.identify_option_btn)?('delete-'+ options.table[0]):'delete',
        icon: 'fas fa-trash-alt',
        class: 'btn-danger',
        click: (id, $btn, $tr, callback) => {

          if (!callback) {
            callback = () => {
            };
          }

          // api.get(options.uri + '/resource/' + options.model + '/' + id + '?table[0]=' + options.table[0] + '&table[1]=' + options.table[1],{

          if (confirm(t(options.i18n_prefix + 'delete_confirm'))) {
            $tr.hide();
            api.delete(options.uri + '/resource/del/' + options.table[0] + '/' + id, {
              success: () => {
                if (options.onDeleteCallback) {
                  options.onDeleteCallback(id, $btn);
                }
                $tr.remove();
              },
              error: () => {
                $tr.show();
              },
              complete: () => {
                //callback();
              }
            });
          } else {
            //callback();
          }

        }
      });
    }

    plugin.initCard();
    plugin.initTable();
    plugin.beautify();

    plugin.initModal();
    plugin.initForm();

    if (options.add) {
      plugin.initAddButton();
    }

    plugin.$form = $form;

    plugin.initFilter();

    return plugin;

  };

  return this.initialize();
};


