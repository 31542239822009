import api from '../api';
import session from "../session";

$.fn.uiFormTagSelect = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $input;
  let $btn_add;
  let $label_wrapper;
  let url_tagselect, url_tagselectitem;

  options = $.extend({},{
    i18n_prefix: '',
    remove: true
  }, options);

  if(options.table === undefined) {
    options.table = options.name;
  }

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);

  if (options.table.name && options.table.name !== undefined && options.table.type && options.table.type !== undefined) {
    url_tagselect = '/grouped-resource/' + options.table.name + '/tagselect/' + options.table.type;
    url_tagselectitem = '/grouped-resource/' + options.table.name + '/tagselect/items/' + options.table.type;
  }
  else {
    url_tagselect = '/resource/tagselect/' + options.table;
    url_tagselectitem = '/resource/tagselect/items/'+options.table;
  }

  let plugin = this;

  this.showError = (key, value) => {
    $element.find('.select2-selection').addClass('is-invalid-important');
    $element.append(`<span class="error invalid-feedback">${t(value)}</span>`);
  };

  // public methods
  this.initialize = () => {

    $input = $('<select name="' + options.name + '[]" class="form-control select2bs4" data-placeholder="' + options.placeholder + '" multiple="multiple"></select>');

    if (options.add && options.add !== undefined) {

      $btn_add = $('<a href="#" class="btn btn-default ml-3 btn-xs tagselect-add" style="display:none">+</a>');

      if (options.class == 'editmode' && options.class !== undefined) {
        $btn_add.show();
      }

      $label = $('<span>' + options.label + '</span>');
      $label_wrapper = $('<label></label>');

      $label_wrapper.append($label);
      $label_wrapper.append($btn_add);

      $element.addClass('vertical-input-group');
      $element.addClass('form-group form-group-'+options.name).append($label_wrapper).append($input);

      $btn_add.click(() => {
        options.onAdd();
      });
    }
    else {
      $label = $('<label>' + options.label + '</label>');

      $element.addClass('form-group form-group-'+options.name).append($label).append($input);
    }
    let select2Options = {
      theme: 'bootstrap4',
      language: 'de'
    };

    if (options.doSearch === undefined || options.doSearch) {
      let ajax_options = $.extend({}, api.default_options, {
        url: api.prepareUrl(url_tagselect),
        type: 'get',
        delay: 250,
        data: function (params) {
          return {
            q: params.term,
            p: params.page
          };
        },
        processResults: function (response) {
          let candidate_data = [];
          if (options.candidate !== undefined && options.candidate.email !== null) {
            let candidate_default_data = {
              "id": "0",
              "text": options.candidate.email,
              "email": options.candidate.email,
              "selected": "selected"
            };

            candidate_data.push(candidate_default_data);
            response.unshift(candidate_default_data);

            return {
              results: response
            };
          } else {
            return {
              results: response
            };
          }
        }
      });
      select2Options.ajax = ajax_options;
    }

    $input.select2(select2Options);

    $input.on('afterValueSet', () => {
      if (options.afterValueSet && options.afterValueSet !== undefined) {
        options.afterValueSet();
      }
    });



    /*
     Verhindert das Entfernen von Tags, wird z.B. bei Niederlassungen von Unternehmen verwendet
     */
    if (options.remove === false && options.remove !== undefined) {
      $input.on("select2:unselecting", function (e) {
          e.preventDefault();
      });
    }

    if(options.candidate !== undefined && options.candidate.email !== null) {
      var option_default = new Option(options.candidate.email, "0", true, true);
      $input.append(option_default).trigger('change');
    }

    return plugin;

  };


  this.loadDependentData = (data) => {
    $input.select2({
      ajax: {
        url: '/api/v1/resource/select2/' + options.table,
        data: (params) => {
          let query = {
            search: params.term,
            q: data.id
          }
          return query;
        },
        delay: 200,
        dataType: 'json',
        headers: {
          'Authorization' : 'Bearer ' + session.getToken(),
        },
        processResults: (data) => {
          return {
            results: data
          };
        }
      }
    });
  };

  this.openAddModal = () => {

  };

  this.setValue = (data) => {
    $input.val(null);
    $input.children().remove();

    if(Array.isArray(data) && typeof data[0] !== 'object') {

      api.post(url_tagselectitem,{ids: data},{
        success: (res) => {
          res.forEach((d) => {
            var option = new Option(d.text, d.id, false, true);
            $input.append(option);
          });
          $input.trigger('change');
          $input.trigger('afterValueSet');
        }
      });
    }
    else {
      if(Array.isArray(data)) {
        data.forEach((d) => {
          var option = new Option(d.text, d.id, false, true);
          $input.append(option);
        });
        $input.trigger('change');
        $input.trigger('afterValueSet');
      }
    }

  };

  this.resetValue = () => {
    $input.val(null);
    $input.children().remove();
    $input.trigger('change');
  };

  return this.initialize();
};

