import api from '../api';

$.fn.uiFormDate = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $input;

  options = $.extend({},{
    i18n_prefix: '',
    drops: 'up',
    useAsStartDate: false,
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);


  let plugin = this;

  // public methods
  this.initialize = () => {

    $label = $('<label>' + options.label + '</label>');
    $input = $('<input autocomplete="off" name="' + options.name + '" class="form-control form-datepicker" type="text" placeholder="' + options.placeholder + '" />');

    $input.daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      drops: options.drops,
      locale: {
        format: 'YYYY-MM-DD',
        daysOfWeek: [
          'So',
          'Mo',
          'Di',
          'Mi',
          'Do',
          'Fr',
          'Sa'
        ],
        monthNames: [
          'Januar',
          'Februar',
          'März',
          'April',
          'Mai',
          'Juni',
          'Juli',
          'August',
          'September',
          'Oktober',
          'November',
          'Dezember'
        ],
        firstDay: 1
      },
      autoUpdateInput: false
    }, function(chosen_date) {
      $input.val(chosen_date.format('YYYY-MM-DD'));
    });

    // enables the choice of the current day
    $input.on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('YYYY-MM-DD'));
    });

    $element.addClass('form-group form-group-'+options.name).append($label).append($input);

    $('.daterangepicker').addClass('shadow');

    $('.daterangepicker').each((i, el) => {
      let $el = $(el);
      $el.addClass('shadow');
      let $btns = $el.find('.resetBtn');
      if($btns.length === 0) {
        let $btn_clear = $('<button class="resetBtn btn btn-sm btn-default" type="button">' + t('g.delete') + '</button>').click(() => {
          $el.find('.cancelBtn').trigger('click');
          $element.find('input').val('').trigger('change');
        });
        $el.find('.applyBtn').before($btn_clear);
      }
    });

    this.$input = $input;

    return plugin;

  };

  this.setValue = (value) => {
    $input.val(value);
    $label.text(options.label_edit);
    $input.attr('placeholder', options.placeholder_edit);

    if (options.useAsStartDate === true) {
      $input.data('daterangepicker').setStartDate(value);
    }
  };

  this.resetValue = () => {
    $input.val('');
    $label.text(options.label);
    $input.attr('placeholder', options.placeholder);
  };

  return this.initialize();
};
