import app from '../../app/app';;
import time from '../../app/time';;
import helper from '../../app/helper';;
import msg from '../../app/msg';;
import api from '../../app/api';;
import modal from '../../_compiled/components/modal';

const admin_sessions = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12" id="user-management"> </div> </div></div>'; return out;
    },
    js: function(it) {
              app.setTitle('Aktive Sessions'); app.setBreadcrumps({ 'Administration': '/admin', 'Aktive Sessions': '/admin/sessions' }); let $user_management = $('#user-management').uiDataTableScroll({ add: false, edit: false, delete: true, title: 'Aktive Sessions', i18n_prefix: 'active_sessions.', table: ['activesession','activesessions'], fields: { 'key': 'Text', 'email': 'Email', 'firstname': 'Text', 'lastname': 'Text', 'role': { type: 'Select', table: 'role' }, 'roles': { type: 'TagSelect', table: 'role' }, 'password': 'Password' }, cols: [ {text:'#', name: 'id', width:30},         {text: 'Agency_id', name:'agency_id'}, {text: 'Role', name: 'role_name'}, {text: 'User name', name: 'name'},         {text: 'Token', name: 'key'}, {text: 'Läuft ab', name: 'access_token_expires_at'}, ], render: { 'id': (obj) => { return '<b>' + obj.id + '</b>'; }, 'name': (obj) => { return '<a>' + obj.firstname + ' ' + obj.lastname + '</a><br /><small>' + (obj.email || '&nbsp;') + '</small>'; } } });
    }
};

export default admin_sessions;
