$.fn.uiFormHiddensetable = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $input;

  options = $.extend({},{
  }, options);


  let plugin = this;

  // public methods
  this.initialize = () => {

    $input = $('<input name="' + options.name + '" class="form-control" type="hidden" value="' + options.value + '" />');

    $element.append($input);

    this.$input = $input;

    return plugin;

  };

  this.setValue = (value) => {
    $input.val(value);
  };

  this.resetValue = () => {
    $input.val('');
  };

  return this.initialize();
};


