import app from '../../app/app';;
import time from '../../app/time';;
import helper from '../../app/helper';;
import msg from '../../app/msg';;
import api from '../../app/api';

const user_roles = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12" id="role-management"> </div> </div></div>'; return out;
    },
    js: function(it) {
             app.setTitle('Rechte-Management & Benutzer Rollen'); app.setBreadcrumps({ 'Administration': '/admin', 'Benutzer-Rollen': '/admin/rights' }); let $role_management = $('#role-management').adminScaffold({ title: 'Benutzer Rollen', table: ['role','roles'], fields: { 'name': 'Text', 'display_name': 'Text', 'is_employee': 'Switch', 'permissions': { type: 'CheckboxGroups', table: 'permission' } }, cols: [ { text: '#', name: 'id', width: 30 },       'name', { text: t('role.is_employee'), name: 'is_employee' } ], render: { 'id': (obj) => { return '<b>' + obj.id + '</b>'; }, 'name': (obj) => { return '<a>' + obj.display_name + '</a><br /><small>' + obj.name + '</small>'; }, 'is_employee': (obj) => { return obj.is_employee ? t('g.yes') : t('g.no'); } } });
    }
};

export default user_roles;
