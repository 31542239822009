import media from '../media';
import api from '../api';
import time from '../time';
import helper from '../helper';
import msg from '../msg';
import routes from '../routes';
import history from '../history';

$.fn.uiHistory = function(options) {

  // default options

  options = $.extend({},{
    title: false,
    cardloader: false
  }, options);
  
  const icons = history.icons;
  const colors = history.colors;

  const render = {

    'comment': (item) => {

      let text = '';


      if(item.text && item.text+'' !== 'null') {
        text = '<p>' + item.text + '</p>';
      }

      return $(`
        <div class="post clearfix">
                      <div class="user-block">
                        <img class="img-circle img-bordered-sm" src="` + media.image(item.candidate_avatar, '40x40', '/img/avatar5.png') + `" alt="` + item.candidate_firstname + `">
                        <span class="username">
                          <a class="router-link" href="/candidate/` + item.candidate_id + `">` + item.candidate_name + `</a>
                        </span>
                        <span class="description">` + t('timeline.'+item.type, item) + ` - ` + time.ago(item.created_at) + `</span>
                      </div>
                      <!-- /.user-block -->
                      
                        ` + text + `
                      
                      
                      <form class="form-horizontal candidate-comment">
                        <div class="input-group input-group-sm mb-0">
                          <input data-id="` + item.candidate_id + `" name="message" class="form-control form-control-sm" placeholder="Antwort...">
                          <div class="input-group-append">
                            <button type="submit" class="btn btn-primary"><i class="fab fa-telegram-plane"></i></button>
                          </div>
                        </div>
                      </form>
                      
                    </div> 
    `);

    },

    'default': (item) => {


      return $(`
        <div class="post clearfix pb-0">
                      <div class="user-block">
                        <img class="img-circle img-bordered-sm" src="` + media.image(item.candidate_avatar, '40x40', '/img/avatar5.png') + `" alt="` + item.candidate_firstname + `">
                        <span class="username">
                          <a class="router-link" href="/candidate/` + item.candidate_id + `">` + item.candidate_name + `</a>
                        </span>
                        <span class="description">` + t('timeline.'+item.type, item) + ` - ` + time.ago(item.created_at) + `</span>
                      </div>
                      <!-- /.user-block -->
                    </div> 
    `);
    }
  };


  let max_id = 0;
  let plugin = this;
  let start = 0;
  let offset = 20;

  let $element = $(this);

  plugin.renderItem = (item) => {

    let $item;
    if(render[item.type] !== undefined) {
      $item = render[item.type](item);
    }
    else {
      $item = render.default(item);
    }

    /*
     * candidate comment event...
     */
    $item.find('.candidate-comment').submit((ev) => {
      ev.preventDefault();
      let $form = $(ev.currentTarget);
      let $input = $form.find('input[name=message]');

      if($input.val().trim() !== '') {
        api.post('/history/' + item.candidate_id,{
          type: 'comment',
          message: $input.val()
        },{
          error: () => {
            msg.error(t('comment.post_fail'));
          },
          success: () => {
            msg.success(t('comment.post_success'));
            plugin.refresh();
          }
        });
      }
    });

    return $item;
  };

  plugin.prependItems = (items) => {
    let item;
    for(item of items) {

      let $item = plugin.renderItem(item);
      $element.prepend($item);
      $item.find('.timeline-item').effect('highlight', {}, 3000);
      if(max_id < item.id) {
        max_id = item.id;
      }
    }
  };

  plugin.appendItems = (items) => {
    let item;

    for(item of items) {

      let $item = plugin.renderItem(item);
      routes.initLinks($item.find('.router-link'));
      $element.append($item);
      if(max_id < item.id) {
        max_id = item.id;
      }
    }
  };

  plugin.update = () => {

    if(options.cardloader !== false) {
      options.cardloader.showLoading();
    }

    api.get('/history/update/' + options.candidate.id + '/' + max_id,{
      success: (history) => {
        plugin.prependItems(history);
      },
      complete: () => {
        if(options.cardloader !== false) {
          options.cardloader.hideLoading();
        }
      }
    });
  };

  plugin.refresh = () => {
    $element.empty();
    plugin.initialLoad();
  };

  plugin.initialLoad = () => {
    start = 0;
    offset = 20;

    if(options.cardloader !== false) {
      options.cardloader.showLoading();
    }

    api.get('/history/',{
      success: (history) => {
        plugin.appendItems(history);
      },
      complete: () => {
        if(options.cardloader !== false) {
          options.cardloader.hideLoading();
        }
      }
    });
  };


  this.initialize = () => {

    plugin.initialLoad();

    return plugin;
  };

  return this.initialize();
};

