import app from '../../app/app';;
import api from '../../app/api';;
import time from '../../app/time';;
import msg from '../../app/msg';

const admin_jobsubjects = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12"> <div id="jobsubject-filter"></div> </div> </div> <div class="row"> <div class="col-md-12 admin-jobsubjects"> <div id="jobsubject-list"></div> </div> </div></div>'; return out;
    },
    js: function(it) {
            app.setTitle('Fachbereiche verwalten'); app.setBreadcrumps({ 'Administration': '/admin', 'Fachbereiche': '/admin/jobsubjects' }); let $jobsubjects = $('#jobsubject-list').uiDataTableScroll({ add: true, edit: true, delete: true, hide_search: false, default_order: [0, 'asc'], i18n_prefix: 'jobsubject.', pageLength: 12, cols: [ {name: 'id', text:'#', width: 10 }, {name: 'name', text: 'Name'} ], render:{ 'id': (obj) => { return '<b>#' + obj.id + '</b>'; }, 'name': (obj) => { return obj.name; } }, table: ['jobsubject', 'jobsubjects'], fields: { 'name': 'Text' } });
    }
};

export default admin_jobsubjects;
