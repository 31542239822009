import app from '../../app/app';;
import api from '../../app/api';;
import time from '../../app/time';;
import msg from '../../app/msg';

const admin_mailthemes = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12 admin-mailthemes"> <div id="mailthemes-list"></div> </div> </div></div>'; return out;
    },
    js: function(it) {
            app.setTitle('E-Mail Themes verwalten'); app.setBreadcrumps({ 'Administration': '/admin', 'Webseiten': '/admin/mailthemes' }); let $orgs = $('#mailthemes-list').uiDataTableScroll({ add: true, edit: true, delete: true, default_order: [0, 'desc'], i18n_prefix: 'mailtheme.', pageLength: 12, cols: [ {name: 'id', text:'#', width: 10}, {name: 'name', text: 'Name'} ], render:{ 'id': (obj) => { return '<b>#' + obj.id + '</b>'; }, 'slug': (obj) => { return '<span class="text-bold">' + obj.name + '</span><br /><small>' + time.md(obj.created_at) + '</small>'; } }, afterLoad: () => { }, table: ['mailtheme', 'mailthemes'], fields: { 'name': 'Text', 'body_html': 'Textarea', 'body_text': 'Textarea' }, });
    }
};

export default admin_mailthemes;
