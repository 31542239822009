import app from '../../app/app';

const search_result_tasks = {
    tpl: function(it) {
        var out='<div class="col-12 col-sm-12 col-md-12 d-flex align-items-stretch"> <div class="card bg-light candidate-card" style="min-width: 100%;"> <div class="card-header text-muted border-bottom-0">     </div> <div class="card-body pt-0"> <div class="container-fluid">         <div class="row">           <div class="col-1 col-sm-1 col-md-1"> <div id="done-area"> </div>           </div> <div class="col-2 col-sm-2 col-md-2"> <div id="taskcategory-id-area"> </div>           </div> <div class="col-6 col-sm-6 col-md-6"> <div id="name-area">             </div> </div> <div class="col-3 col-sm-3 col-md-3"> <div id="___user-area">             </div> </div> </div> <div class="row">           <div class="col-3 col-sm-3 col-md-3"> <div id="creator-id-area">             </div> </div>           <div class="col-6 col-sm-6 col-md-6"> <div id="due-area">             </div> </div> <div class="col-3 col-sm-3 col-md-3"> <div id="button-area"> </div> </div> </div> </div> </div> </div></div>'; return out;
    },
    js: function(it) {
         let $el = $(search_result_tasks.tpl(it)); return $el;
    }
};

export default search_result_tasks;
