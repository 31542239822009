import app from '../../app/app';

const info_timed_emails = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12" id="timed-emails"> </div> </div></div>'; return out;
    },
    js: function(it) {
         app.setTitle(t('usermailings.title')); app.setBreadcrumps({ 'Übersicht': '/info', 'Terminierte E-Mails': '/info/timed-emails' }); let $timed_emails = $('#timed-emails').adminScaffold({ title: 'Terminierte E-Mails', table: ['usermailings','usermailings'], disable_add: true, fields: { 'mail_subject': 'Text', 'mail_body_html': { type: 'Richtext', height: 200 }, 'send_time': { type: 'Datetime', drops: 'up', onlyFuture: true }, 'send_as': { type: 'Select', table: 'user', fieldname: 'send_as' } }, cols: [{text:'#',name:'id',width:30}, 'status_name', 'candidate_name', 'mail_subject', 'send_time'], render: { 'id': (obj) => { return '<b>' + obj.id + '</b>'; }, 'status_name': (obj) => { let status_name = ''; if (!helper.empty(obj.status_name)) { status_name = obj.status_name; } return '<span>' + status_name + '</span>'; }, 'candidate_name': (obj) => { let candidate_link = `<a href="/candidate/${obj.candidate_id}" class="text-bold">${obj.candidate_name} </a>`; return candidate_link; }, 'mail_subject': (obj) => { return '<span>' + obj.mail_subject + '</span>'; }, 'send_time': (obj) => { return '<span>' + obj.send_time + '</span>'; } } });
    }
};

export default info_timed_emails;
