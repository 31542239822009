import '../styles/index.scss';

import './i18n';
import routes from './routes';
import menu from './menu';
import session from './session';
import login from './login';
import plugins from './plugins';
import time from './time';
import './ui/_import';
import msg from './msg';
import dialog from './dialog';
import acl from './acl';
import helper from './helper';
import suckit from './suckit';
import browser_notification from "./browser_notification";
/* import globalsearch from "./globalsearch"; */
import audio from "./audio";
import infosystem from "./infosystem";
import chatbot from "./chatbot";
window.acl = acl;

let app = {

  $main: null,
  $title: null,
  $body: null,
  $wrapper: null,
  $breadcrumps: null,
  $version_number: null,
  godmode: false,


  init: () => {

    window.helper = helper;
    window.routes = routes;

    app.$wrapper = $('#member-page');
    app.$main = $('#content-main');
    app.$title = $('#content-title');
    app.$html_title = $('head title');
    app.$breadcrumps = $('#breadcrumps');
    app.$body = $('body');
    app.$html = $('html');
    app.$version_number = $('#version_number');

    dialog.init();
    plugins.loadDefaults(() => {

      time.init();
      suckit.init();
      menu.init();
      audio.init();
      /* globalsearch.init(); */

      /*
       * bootstrap static modals by default
       *
        $.fn.modal.prototype.constructor.Constructor.DEFAULTS.backdrop = 'static';
        $.fn.modal.prototype.constructor.Constructor.DEFAULTS.keyboard = false;

       */



      session.onStart(() => {
        suckit.connect();
        menu.load();
        browser_notification.init();
        /* globalsearch.load(); */
        infosystem.init();
        chatbot.init(session.user);
      });

      session.onEnd(() => {
        suckit.disconnect();
        menu.unload();
        /* globalsearch.unload(); */
        chatbot.unloadChatbot();
      });

      session.init();

      $('#bootloader').remove();

      routes.init();
      login.init();
      msg.init();

      const webpackHash = __webpack_hash__.substr(0, 8);
      app.$version_number.text(VERSION + ' - ' + webpackHash);

      /*
       * erstes klick Event
       */

      app.$body.bind('click', () => {
        audio.preload();
        app.$body.unbind('click');
      });

    });
  },

  getUri: () => {
    return window.location.pathname;
  },

  getUrl: () => {
    let parts = window.location.href.split('#');
    let params = '';
    if(parts.length > 1) {
      params = '#' + parts[1];
    }

    return window.location.pathname + params;
  },

  clearMain: () => {

    app.$main.html('');

  },

  setMain: (content) => {
    app.$main.html(content);
  },

  setTitle: (title) => {
    app.$title.text(title);
    jQuery("#header-titel-container").empty().append("<h1>" + title + "</h1>");
    app.$html_title.text('jungwild Hunter | ' + title);
  },

  setBreadcrumps: (breadcrumps) => {
    /*
    app.$breadcrumps.html('');
    $.each( breadcrumps, function( key, value ) {
      app.$breadcrumps.append('<li class="breadcrumb-item"><a href="' + value + '" class="router-link">' + key + '</a></li>');
    });
    let $last = app.$breadcrumps.children().last();
    $last.addClass('active').text($last.text());
    routes.initLinks(app.$breadcrumps.find('.router-link'));
    */
  },

  loadPage: (page, options) => {

    options = $.extend({},{
      params: {}
    },options);

    if(session.isLoggedIn()) {
      if(options['is_dashboard'] && !acl('browse_dashboard')) {
        routes.router.navigate('/recruiting/candidates');
        return;
      }
      app.clearMain();
      app.setMain(page.tpl(options));
      page.js(options);
    }
    else {
      login.setLoginRoute(app.getUri());
      routes.router.navigate('/login');
    }

    if(routes.after_callback_stack[app.getUri()] !== undefined) {
      routes.after_callback_stack[app.getUri()].forEach((cb) => {
        cb();
      });
      //routes.after_callback_stack[app.getUri()]();
      delete routes.after_callback_stack[app.getUri()];
    }
    else {
      /*
       * "Kandidaten im Prozess" hat ein besonderes Layout, wir wollen für
       *  alle anderen Seiten wieder das Standard Layout
       */
      if ( $('body').hasClass('layout-boxed') == false ) {
        $('body').removeClass('layout-fixed').removeClass('sidebar-collapse').addClass('layout-boxed');
      }
    }

  },

  getDomId: ($element, id_name) => {
    let id = $element.attr('id');
    if(id === undefined) {

      let base = id_name + '-';
      let number = 1;
      let id = base + number;

      while ($('#' + id ).length > 0) {
        number++;
        id = base + number;
      }
    }

    return id;
  },
  deleteLastHistory: () => {
    document.location.replace();
  }
};

export default app;
