import api from '../api';
import msg from '../msg';

$.fn.uiFormJobmatching = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $wrapper;
  let $container;
  let $list;

  options = $.extend({},{
    i18n_prefix: ''
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);


  let plugin = this;

  // public methods
  this.initialize = () => {
    $label = $('<label>' + options.label + '</label>');

    $wrapper = $('<div class="row"></div>');
    $container = $('<div class="col-md-12"></div>');

    $list = $(`<div class="card">
      <div class="card-body p-0">
        <ul class="products-list product-list-in-card pl-2 pr-2">
        </ul>
      </div>
    </div>`);

    $container.append($list);
    $wrapper.append($container);
    $element.append($label).append($wrapper);

    return plugin;

  };

  this.renderItem = (item) => {
    let params = {};
    
    if (item.data.filter !== undefined) {
      params = item.data.filter;
    }

    /*
    if(!params['query']) {
      params = {};
    }
    */

    /* debug */
    console.log('item :>> ', item);

    if (item.query !== undefined) {
      params['query'] = item.query;
    }

    params['sort_col'] = item.sort_col;
    params['sort_type'] = item.sort_type;
    params['page'] = 0;
    params['dosearch'] = 1;
    params['search_combo'] = item.data.filter.search_combo;
    //params['mark_unseen'] = 1;

    return `<li class="item" data-id="${item.id}">
      <div class="product-info">
        <a href="/recruiting/candidates#${$.param(params)}" class="router-link text-bold">${item.name}</a>
        <span class="badge badge-info float-right has-cursor-pointer del-matching"><i class="fas fa-trash-alt"></i></span>
        <span class="product-description">
          ${item.candidates} ${t('matching.candidates')}
        </span>
      </div>
    </li>`;
  };

  this.addEvents = () => {
    $element.find('.del-matching').on('click', (ev) => {
      let $item = $(ev.currentTarget).parent().parent();
      let matching_id = $item.data('id');

      if (confirm(t('g.sure_delete'))) {
        api.delete('/matching/del/' + matching_id + '/' + options.getEditId(), {
          success: () => {
            $item.remove();
            msg.success(t('g.delete_success'));
          },
          error: () => {
            msg.error(t('g.error'));
          }
        });
      }
    });
  };

  this.setValue = (value) => {

    if (Array.isArray(value)) {
      if (value.length > 0) {
        $list.find('ul').find('li').remove();

        value.forEach((item) => {
          $list.find('ul').append(this.renderItem(item));
        });

        this.addEvents();
      }
    }

  };

  this.resetValue = () => {

  };

  return this.initialize();

};
