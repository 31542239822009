import api from '../api';
import modal from '../../_compiled/components/modal';
import msg from '../msg';
import helper from '../helper';

$.fn.uiFormObjectlist = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $modal, $add_btn, $save_btn, $editsave_btn;
  let $list, $table, $footer;
  let $form = $('<form></form>');

  options = $.extend({},{
    i18n_prefix: '',
    default: '',
    optionButtons: [],
    url: '/' + options.name,
    url_list: '/resource/list/' + options.name,
    url_edit: '/' + options.name,
    url_overwrite: false,
    columns: [],
    firstFieldDependencies: false,
    firstFieldSrc: '',
    cv_id: 0,
    onEditMode:()=>{},
    css: ($form) => {},
    render: (item) => {
      return $(`<tr><td>${item.name}</td></tr>`);
    }
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);

  /*
   * Wenn wir eine cv_id > 0 haben gehen wir davon aus, dass sich alle Daten auf einen
   * speziellen CV beziehen und somit erweitern wir die url_edit um die cv_id
   */
  if(parseInt(options.cv_id, 10) > 0) {
    options.url_edit = options.url_edit + '/' + options.cv_id;
  }

  let plugin = this;

  this.refresh = () => {
    $list.empty();

    api.get(options.url_list,{
      success: (items) => {
        plugin.setValue(items);
      }
    });
  };

  this.initModal = () => {

    $editsave_btn = $('<button class="btn btn-default">Speichern</button>');
    $save_btn = $('<button class="btn btn-default">Speichern</button>');

    $modal = modal.js({
      title: t(options.i18n_prefix + 'add'),
      size: 'lg'
    });

    $modal.$footer.append($save_btn);
    $modal.$footer.append($editsave_btn);

    // reset the form and show it in the modal overlay
    $add_btn.click((ev) => {
      ev.preventDefault();
      $form[0].reset();
      $form.find('[name="id"]').val('');

      // Adresse resetten
      if ($form.find('.address-field-locality').length > 0) {
        $form.getFormElement('address').resetValue();
      }

      if (options.firstFieldDependencies === true) {
        plugin.triggerOnChangeAdd(0);
      }

      $modal.modal({
        show: true,
        backdrop: 'static',
        keyboard: false
      });
    });
  };

  this.initView = () => {
    let $header = $('<thead></thead>');
    let $header_row = $('<tr></tr>');
    let $header_columns = '';

    $list = $('<table class="table table-hover cv-list"></table>');

    options.columns.forEach(column => {
      $header_columns += '<th>' + column + '</th>';
    });
    $header_columns += '<th></th>';

    $add_btn = $('<button class="btn btn-primary">Hinzufügen</button>');

    $list.append( $header.append( $header_row.append($header_columns) ) );
    $list.append($add_btn).wrap('<div class="table-responsive"></div>');

    plugin.initOptionButtons();
  };

  this.initForm = () => {
    $form = $('<form></form>').uiForm({
      success: () => {
        msg.success(t('g.add_success'));
        plugin.refresh();
        $modal.modal('hide');
      },
      error: (res) => {
        if (res && res.responseJSON && res.responseJSON.error) {
          msg.error(t(res.responseJSON.error));
        } else {
          msg.error(t('g.save_error'));
        }

      },
      url: options.url,
      url_overwrite: options.url_overwrite,
      i18n_prefix: options.i18n_prefix + options.name + '_',
      fields: options.fields,
      $el_save: $save_btn,
      $el_edit: $editsave_btn,
      onEditMode: (data) => {
        options.onEditMode();
      },
    });

    $modal.$body.append($form);
  };

  /*
   * Triggers the change event of the first select element to show/hide dependent fields
   */
  this.triggerOnChangeAdd = (value) => {
    let $select = $form.find('.form-group').slice(0, 1).find('select');

    if ($select !== undefined) {
      $select.val(value);
      $select.trigger('change');
    }
  };

  this.triggerOnChangeEdit = (fieldname, value) => {
    let $select = $form.find('.form-group-'+fieldname).find('select');

    if ($select !== undefined) {
      $select.val(value);
      $select.trigger('change');
    }
  };

  this.initOptionButtons = () => {

    options.optionButtons.push({
      name: 'update',
      icon: 'fas fa-pencil-alt',
      class: 'btn-default',
      click: (id, $btn, $tr, callback) => {

        api.get(options.url_edit + '/' + id,{
          success: (item) => {
            $form[0].reset();
            $form.setValues(item);

            if (options.firstFieldSrc) {
              plugin.triggerOnChangeEdit(options.firstFieldSrc, item[options.firstFieldSrc]);
            }

            /*
             * ist dann nicht mehr allgemein zu benutzen, aber wird ja eh nur für die CV-Daten genutzt
             */
            // Praxiserfahrung
            if (!helper.empty(item.type_group)) {
              plugin.triggerOnChangeEdit('type_group', item.type_group);
            }
            // Bildungsweg
            else if (!helper.empty(item.educationtype_id)) {
              plugin.triggerOnChangeEdit('educationtype', item.educationtype_id);
            }

            $modal.modal({
              show: true,
              backdrop: 'static',
              keyboard: false
            });

            if(callback) {
              callback();
            }

          }
        });
      }
    });

    options.optionButtons.push({
      name: 'delete',
      icon: 'fas fa-trash-alt',
      class: 'btn-danger',
      click: (id, $btn, $tr, callback) => {

        if(!callback) {
          callback = () => {};
        }

        if(confirm(t(options.i18n_prefix + options.name + '_delete_confirm'))) {
          $tr.hide();
          api.delete(options.url_edit + '/' + id,{
            success: () => {
              $tr.remove();
            },
            error: () => {
              $tr.show();
            },
            complete: () => {
              callback();
            }
          });
        }
        else {
          callback();
        }

      }
    });

  };

  this.updateOptionsButtons = () => {
    options.optionButtons.forEach((btn) => {

      if (btn.click) {
        $('.ol-btn-' + btn.name + '-' + options.name).click((ev) => {
          ev.preventDefault();

          let $btn = $(ev.currentTarget);
          let $tr = $btn.parent().parent();
          let id = $btn.data('id');
          btn.click(id, $btn, $tr);

        });
      }
    });
  };

  // public methods
  this.initialize = () => {

    $label = $('<label>' + options.label + '</label>');

    plugin.initView();
    plugin.initModal();
    plugin.initForm();
    options.css($form);

    $element.addClass('form-group form-group-' + options.name).append($label).append($list).append($add_btn);

    return plugin;
  };

  this.setValue = (value) => {

    if(value._all !== undefined) {
      value = value._all;
    }

    if(value && value.length > 0) {
      value.forEach((item) => {
        $list.append(options.render(item));

        let btngrp = '';

        options.optionButtons.forEach((btn) => {

          if(!btn.class) {
            btn.class = 'btn-default';
          }

          btngrp += `<button data-id="${item.id}" type="button" class="btn btn-sm ol-btn-${btn.name}-${options.name} ${btn.class}"><i class="${btn.icon}"></i></button>`;
        });

        $(this).find('td').last().parent().append('<td>' + btngrp + '</td>');
      });

      /*
       * Nach zeichnen aller Tabellen Zeilen Button Events hinzufügen
       */
      plugin.updateOptionsButtons();
    }

    $label.text(options.label_edit);

  };

  this.resetValue = () => {
    $list.empty();
    $label.text(options.label);
  };

  return this.initialize();
};


