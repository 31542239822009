import app from '../../app/app';;
import api from '../../app/api';;
import time from '../../app/time';;
import msg from '../../app/msg';

const admin_mailing_blacklist = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12 admin-blacklist"> <div id="email-blacklist"></div> </div> </div></div>'; return out;
    },
    js: function(it) {
            app.setTitle('E-Mail Blacklist verwalten'); app.setBreadcrumps({ 'Administration': '/admin', 'Mailing': '/admin/mailing', 'Blacklist': '/admin/mailing/blacklist' }); let $blacklist = $('#email-blacklist').adminScaffold({ title: 'E-Mail Blacklist Patterns', table: 'mailingblacklist', fields: { 'pattern': 'Text' }, i18n_prefix: 'mailingblacklist.', pageLength: 12, cols: [ {name: 'pattern', text: 'E-Mail Adresse'}  ], render:{ 'id': (obj) => { return '<b>#' + obj.id + '</b>'; }, 'pattern': (obj) => { return obj.pattern; }, 'created_at': (obj) => { return '<span style="display:none;">' + obj.created_at + '</span><strong>' + time.md(obj.created_at) + '</strong>'; } } });
    }
};

export default admin_mailing_blacklist;
