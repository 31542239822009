import api from '../api';

$.fn.uiFormRadiussearch = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $input;
  let $locationpicker;
  let $hiddenradius;
  let $reset;

  options = $.extend({}, {
    i18n_prefix: '',
    step: 10,
    min: 5,
    max: 600
  }, options);

  options.label = t(options.i18n_prefix + 'lb_' + options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_' + options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_' + options.name);


  let plugin = this;

  // public methods
  this.initialize = () => {

    $label = $('<label>' + options.label + '</label>');
    $locationpicker = $(`<input name="${options.name}" class="form-control" type="text" placeholder="${options.placeholder}" value="${options.default}" />`);
    $input = $(`<input name="${options.name}" class="custom-range" type="range" min="${options.min}" max="${options.max}" value="${options.min}" step="${options.step}" />`);
    $hiddenradius = $('<input type="hidden" class="address-field-radius" name="radius[km]" value="" />');
    $reset = $(`<button class="btn btn-sm btn-primary">${t('filter.radius_reset')}</button>`);

    $input.on('change, input', () => {
      this.updateRangeText();
    });

    $reset.click((ev) => {
      ev.preventDefault();

      this.resetValue();
    });

    $element.addClass('form-group form-group-'+options.name).append($label).append($input).append($locationpicker)
      .append($reset).append($hiddenradius);

    this.$input = $input;

    $locationpicker.addresspicker(options);

    plugin.updateRangeText();

    return plugin;

  };

  this.updateRangeText = () => {
    let label = $label.text();
    label = label.split('(')[0].trim();
    $label.text(label + ' ('+$input.val()+' km)');
    $hiddenradius.val($input.val());
  };

  this.setValue = (address) => {
    $locationpicker.resetAddress();

    if(address) {
      // Kandidaten-Filter nutzt teilweise andere Namen?!
      if (!address.hasOwnProperty('state_code') && (address.hasOwnProperty('state'))) {
        address.state_code = address.state;
      }
      if (!address.hasOwnProperty('country_code') && (address.hasOwnProperty('country'))) {
        address.country_code = address.country;
      }

      $locationpicker.setAddress(address);
      $input.val(address['km']).trigger('input');

      $locationpicker.attr('placeholder', options.placeholder_edit);
    }
  };

  this.resetValue = () => {
    $locationpicker.resetAddress();
    $input.val(options.min).trigger('input');
    //$label.text(options.label);
    this.updateRangeText();
    $locationpicker.attr('placeholder', options.placeholder);
    if(options.onReset !== undefined) {
      options.onReset();
    }
  };

  return this.initialize();

};
