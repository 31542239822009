const icons = {
  'statusupdate': 'fas fa-bell',
  'addcandidate': 'fas fa-user-plus',
  'comment': 'fas fa-comment',
  'sendmail': 'fas fa-envelope',
  'addcandidate_linkedin': 'fab fa-linkedin-in',
  'upload_cdocs': 'fas fa-file-alt',
  'application': 'fas fa-building',
  'registration': 'fas fa-cog'
};

const colors = {
  'statusupdate': 'background-color: #FAFAFA; color: #6c757d;'
};

let history = {
  icons: icons,
  colors: colors
};

export default history;

