
const cvdataform = {

  cv_id: null,

  setCvId: (cv_id) => {
    cvdataform.cv_id = cv_id;
  },

  getFieldsPersonalPerson: () => {
    return {
      'person_firstname': {
        text: 'firstname',
        type: 'Text',
        datacomp: 'left'
      },
      'person_lastname': {
        text: 'lastname',
        type: 'Text',
        datacomp: 'left'
      },
      'person_email': {
        text: 'E-Mail',
        type: 'Text',
        datacomp: 'left'
      },
      'person_phone': {
        text: 'Telefon',
        type: 'Text',
        datacomp: 'left'
      },
      'person_phone_mobile': {
        text: 'Mobil',
        type: 'Text',
        datacomp: 'left'
      },
      'person_address': {
        type: 'Text',
        datacomp: 'left'
      }
    }
  },

  getFieldsPersonalOther: () => {
    return {
      'other_firstname': {
        text: 'firstname',
        type: 'Text',
        datacomp: 'right'
      },
      'other_lastname': {
        text: 'lastname',
        type: 'Text',
        datacomp: 'right'
      },
      'other_email': {
        text: 'E-Mail',
        type: 'Text',
        datacomp: 'right'
      },
      'other_phone': {
        text: 'Telefon',
        type: 'Text',
        datacomp: 'right'
      },
      'other_phone_mobile': {
        text: 'Mobil',
        type: 'Text',
        datacomp: 'right'
      },
      'other_address': {
        type: 'Text',
        datacomp: 'right'
      }
    }
  },

  getFieldsPersonalFinal: () => {
    return {
      'final_firstname': {
        text: 'firstname',
        type: 'Text',
        datacomp: 'none'
      },
      'final_lastname': {
        text: 'lastname',
        type: 'Text',
        datacomp: 'none'
      },
      'final_email': {
        text: 'E-Mail',
        type: 'Text',
        datacomp: 'none'
      },
      'final_phone': {
        text: 'Telefon',
        type: 'Text',
        datacomp: 'none'
      },
      'final_phone_mobile': {
        text: 'Mobil',
        type: 'Text',
        datacomp: 'none'
      },
      'final_address': {
        type: 'Locationpicker',
        datacomp: 'none'
      }
    }
  },

  getFieldsLifestep: () => {
    return {
      'type': {
        type: 'Select',
        fieldname: 'type',
        values: [
          {id: 'education', name: 'Bildungsweg'},
          {id: 'practice', name: 'Praxiserfahrung'},
        ],
        onChange: (value, $select, $form) => {
          cvdataform.handleSelectLifestepType(value, $select, $form);
        }
      },
      'cv_id': {
        type: 'Hidden',
        value: cvdataform.cv_id
      },
      'id': {
        type: 'Hidden',
        class: 'editmode'
      },
      'educationtype': {
        type: 'Select',
        table: 'educationtype',
        onChange: (value, $select, $form) => {
          cvdataform.handleSelectEducation(value, $select, $form);
        }
      },
      'type_group': {
        type: 'Select',
        fieldname: 'type_group',   // das select bekommt diesen namen (hier wichtig, da sonst group_id, db-feld name ist aber group!)
        values: [
          {id: 'employment', name: 'Vollzeit'},
          {id: 'part-time', name: 'Teilzeit'},
          {id: 'practice', name: 'Praktikum'},
          {id: 'self-employed', name: 'Selbstständig'},
          {id: 'freelance', name: 'Freiberuflich'},
          {id: 'owner', name: 'Inhaber:in'},  // 'Inhaber:in / Gesellschafter:in / Partner:in'
          {id: 'partnershareholder', name: 'Partner:in / Gesellschafter:in'},
          {id: 'shareholder', name: 'Gesellschafter:in'},
          {id: 'partner', name: 'Partner:in'},  // 'Inhaber:in / Gesellschafter:in / Partner:in'
          {id: 'official', name: 'Verbeamtet'},
          {id: 'training', name: 'Ausbildung'},
          {id: 'boardmember', name: 'Vorstandsmitglied'},
          {id: 'dualstudy', name: 'Duales Studium'},
          {id: 'workingstudies', name: 'Werkstudium'},
          {id: 'limited', name: 'Befristet'},
          {id: 'volunteering', name: 'Ehrenamt / FSJ / FÖJ'},
          {id: 'recruiter', name: 'Personalvermittler:in'},
          {id: 'engagement', name: 'Engagement'},
          {id: 'award', name: 'Auszeichnung'},
          {id: 'foreigncountry', name: 'Auslandserfahrung'}
        ],
        onChange: (value, $select, $form) => {
          cvdataform.handleSelectPractice(value, $select, $form);
        }
      },
      'start': 'Selectmonthyear',
      'end': 'Selectmonthyear',
      'organisation': 'Text'
    }
  },

  getFieldsEducation: () => {
    return {
      'subject': 'Text',
      'graduationtype': {
        type: 'Select',
        table: 'graduationtype'
      },
      'graduation_grade': {
        type: 'Number',
          min: 0,
          max: 6,
          step: 0.1
      },
      'focus': 'Text',
      'graduationwork': 'Text',
      'graduationwork_grade': {
        type: 'Number',
        min: 0,
        max: 6,
        step: 0.1
      }
    }
  },
  
  getFieldsPractice: () => {
    return {
      'address': 'Locationpicker',
      'name': 'Text',
      'practicetype': 'Text',
      'country': 'Text',
      'description': 'Textarea'
    }
  },

  /*
   * Hide everything when type is not selected
   */
  handleSelectLifestepType: (value, $select, $form) => {

    let $all_but_first = $form.find('.form-group').not('.form-group-type');
    let $educationtype = $form.find('.form-group-educationtype');
    let $group = $form.find('.form-group-type_group');

    let $select_educationtype = $educationtype.find('select');
    let $select_group = $group.find('select');

    switch (value) {
      case '0':
        $all_but_first.hide();
        $select_educationtype.val(0);
        $select_group.val(0);
        break;

      case 'education':
        $all_but_first.hide();
        $educationtype.show();
        $select_group.val(0);
        break;

      case 'practice':
        $all_but_first.hide();
        $group.show();
        $select_educationtype.val(0);
        break;
    }

  },

  /*
   * dependencies for the education select
   */
  handleSelectEducation: (value, $select, $form) => {
    let educationtype_id = parseInt(value, 10);

    let $start = $form.find('.form-group-start');
    let $end = $form.find('.form-group-end');
    let $graduationtype = $form.find('.form-group-graduationtype');
    let $graduation_grade = $form.find('.form-group-graduation_grade');
    let $focus = $form.find('.form-group-focus');
    let $graduationwork = $form.find('.form-group-graduationwork');
    let $graduationwork_grade = $form.find('.form-group-graduationwork_grade');
    let $subject = $form.find('.form-group-subject');
    let $organisation = $form.find('.form-group-organisation');
    let $description = $form.find('.form-group-description');

    let $lb_organisation = $organisation.children('label').first();
    let $lb_subject = $subject.children('label').first();
    let $lb_graduationtype = $graduationtype.children('label').first();
    let $lb_focus = $focus.children('label').first();
    let $lb_graduationwork = $graduationwork.children('label').first();
    let $lb_description = $description.children('label').first();

    let $ph_organisation = $organisation.children('input').first();
    let $ph_subject = $subject.children('input').first();
    let $ph_graduation = $graduationtype.children('input').first();
    let $ph_focus = $focus.children('input').first();
    let $ph_graduationwork = $graduationwork.children('input').first();
    let $ph_description = $description.children('textarea').first();

    $start.show();
    $end.show();
    $graduationtype.show();
    $graduation_grade.show();
    $focus.show();
    $graduationwork.show();
    $graduationwork_grade.show();
    $subject.show();
    $organisation.show();
    $description.show();

    switch (educationtype_id) {

      // Bitte wählen
      case 0:
        $start.hide();
        $end.hide();
        $graduationtype.hide();
        $graduation_grade.hide();
        $focus.hide();
        $graduationwork.hide();
        $graduationwork_grade.hide();
        $subject.hide();
        $organisation.hide();
        $description.hide();
        break;

      // Studium
      case 1:
        $lb_organisation.html(t('cvdata.lb_study_organisation'));
        $lb_subject.html(t('cvdata.lb_study_subject'));
        $lb_graduationtype.html(t('cvdata.lb_study_graduation'));
        $lb_focus.html(t('cvdata.lb_study_focus'));
        $lb_graduationwork.html(t('cvdata.lb_study_graduationwork'));
        $ph_organisation.attr('placeholder', t('cvdata.ph_study_organisation'));
        $ph_subject.attr('placeholder', t('cvdata.ph_study_subject'));
        $ph_graduation.attr('placeholder', t('cvdata.ph_study_graduation'));
        $ph_focus.attr('placeholder', t('cvdata.ph_study_focus'));
        $ph_graduationwork.attr('placeholder', t('cvdata.ph_study_graduationwork'));

        $description.hide();

        break;

      // Auslandssemester
      case 2:
        $lb_organisation.html(t('cvdata.lb_study_organisation'));
        $lb_subject.html(t('cvdata.lb_study_subject'));
        $lb_graduationtype.html(t('cvdata.lb_study_graduation'));
        $lb_focus.html(t('cvdata.lb_study_focus'));
        $ph_organisation.attr('placeholder', t('cvdata.ph_study_organisation'));
        $ph_subject.attr('placeholder', t('cvdata.ph_study_subject'));
        $ph_graduation.attr('placeholder', t('cvdata.ph_study_graduation'));
        $ph_focus.attr('placeholder', t('cvdata.ph_study_focus'));

        $graduationwork.hide();
        $graduationwork_grade.hide();
        $description.hide();

        break;

      // Berufsausbildung
      case 3:
        $lb_organisation.html(t('cvdata.lb_internship_organisation'));
        $lb_subject.html(t('cvdata.lb_internship_subject'));
        $lb_description.html(t('cvdata.lb_internship_description'));
        $ph_organisation.attr('placeholder', t('cvdata.ph_internship_organisation'));
        $ph_subject.attr('placeholder', t('cvdata.ph_internship_subject'));
        $ph_description.attr('placeholder', t('cvdata.ph_internship_description'));

        $graduationtype.hide();
        $graduation_grade.hide();
        $focus.hide();
        $graduationwork.hide();
        $graduationwork_grade.hide();

        break;

      // Weiterführende Schule
      case 4:
        $lb_organisation.html(t('cvdata.lb_school_organisation'));
        $lb_graduationtype.html(t('cvdata.lb_school_graduation'));
        $ph_organisation.attr('placeholder', t('cvdata.ph_school_organisation'));
        $ph_graduation.attr('placeholder', t('cvdata.lb_school_graduation'));

        $focus.hide();
        $graduationwork.hide();
        $graduationwork_grade.hide();
        $subject.hide();
        $description.hide();

        break;
    }
  },

  /*
   * dependencies for the practice select
   */
  handleSelectPractice: (value, $select, $form) => {

    let $start = $form.find('.form-group-start');
    let $end = $form.find('.form-group-end');
    let $name = $form.find('.form-group-name');
    let $practicetype = $form.find('.form-group-practicetype');
    let $organisation = $form.find('.form-group-organisation');
    let $country = $form.find('.form-group-country');
    let $address = $form.find('.form-group-address');
    let $description = $form.find('.form-group-description');

    let $lb_practicetype = $practicetype.children('label').first();
    let $lb_name = $name.children('label').first();
    let $lb_organisation = $organisation.children('label').first();

    let $ph_practicetype = $practicetype.children('input').first();
    let $ph_name = $name.children('input').first();
    let $ph_organisation = $organisation.children('input').first();
    let $ph_description = $description.children('textarea').first();

    $start.show();
    $end.show();
    $name.show();
    $practicetype.show();
    $organisation.show();
    $country.show();
    $address.show();
    $description.show();

    switch (value) {

      // Bitte wählen
      case '0':
        $start.hide();
        $end.hide();
        $name.hide();
        $practicetype.hide();
        $organisation.hide();
        $country.hide();
        $address.hide();
        $description.hide();

        break;

      // Praktikum
      case 'practice':
      case 'employment':
        $lb_name.html(t('cvdata.lb_practice_name'));
        $lb_practicetype.html(t('cvdata.lb_practice_practicetype'));
        $lb_organisation.html(t('cvdata.lb_practice_organisation'));
        $ph_name.attr('placeholder', t('cvdata.ph_practice_name'));
        $ph_practicetype.attr('placeholder', t('cvdata.ph_practice_practicetype'));
        $ph_organisation.attr('placeholder', t('cvdata.ph_practice_organisation'));
        $ph_description.attr('placeholder', t('cvdata.ph_practice_description'));

        $country.hide();
        break;

      // Engagement
      case 'engagement':
        $lb_practicetype.html(t('cvdata.lb_engagement_practicetype'));
        $lb_name.html(t('cvdata.lb_engagement_name'));
        $lb_organisation.html(t('cvdata.lb_engagement_organisation'));
        $ph_practicetype.attr('placeholder', t('cvdata.ph_engagement_practicetype'));
        $ph_name.attr('placeholder', t('cvdata.ph_engagement_name'));
        $ph_organisation.attr('placeholder', t('cvdata.ph_engagement_organisation'));

        $country.hide();
        $description.hide();

        break;

      // Auszeichnung
      case 'award':
        $lb_name.html(t('cvdata.lb_award_name'));
        $lb_organisation.html(t('cvdata.lb_award_organisation'));
        $ph_name.attr('placeholder', t('cvdata.ph_award_name'));
        $ph_organisation.attr('placeholder', t('cvdata.ph_award_organisation'));

        $practicetype.hide();
        $country.hide();
        $description.hide();

        break;

      // Auslandserfahrung
      case 'foreigncountry':
        $lb_practicetype.html(t('cvdata.lb_foreigncountry_practicetype'));
        $lb_name.html(t('cvdata.lb_foreigncountry_name'));
        $ph_practicetype.attr('placeholder', t('cvdata.ph_foreigncountry_practicetype'));
        $ph_name.attr('placeholder', t('cvdata.ph_foreigncountry_name'));

        $organisation.hide();
        $description.hide();
        $address.hide();

        break;
    }
  }
};

export default cvdataform;
