import api from '../api';

$.fn.uiFormMultiemail = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label, $label_wrapper;
  let $input;
  let $input_group;
  let $btn_add;

  options = $.extend({},{
    i18n_prefix: '',
    filterValue: (obj) => {
      return obj.email;
    }
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);


  let plugin = this;

  // public methods
  this.initialize = () => {

    $btn_add = $('<a href="#" class="btn btn-default ml-3 btn-xs">+</a>');
    $label = $('<span>' + options.label + '</span>');
    $label_wrapper = $('<label></label>');

    $input = $('<input autocomplete="' + helper.randomString() + '" name="' + options.name + '[]" class="form-control" type="email" />');

    $input_group = $('<div class="input-group"><div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-at"></i></span></div></div>');
    $input_group.append($input);

    $label_wrapper.append($label);
    $label_wrapper.append($btn_add);
    $element.addClass('vertical-input-group');
    $element.addClass('form-group form-group-'+options.name).append($label_wrapper).append($input_group);

    $btn_add.click(() => {
      let $new_input_group = $('<div class="input-group input-group-additional"><div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-at"></i></span></div></div>');
      let $new_input = $('<input autocomplete="' + helper.randomString() + '" name="' + options.name + '[]" class="form-control" type="email" />');
      $new_input_group.append($new_input);
      $element.append($new_input_group);
    });

    this.$input = $input;

    return plugin;

  };

  this.setValue = (value) => {

    $input.val('');

    $element.find('.input-group-additional').remove();

    if(Array.isArray(value)) {
      value.forEach((email) => {
        plugin.addEmail( options.filterValue(email) );
      });
    }

    $label.text(options.label_edit);
    $input.attr('placeholder', options.placeholder_edit);
  };

  this.resetValue = () => {
    $input.val('');
    $element.find('.input-group-additional').remove();
    $label.text(options.label);
    $input.attr('placeholder', options.placeholder);
  };

  this.addEmail = (email) => {

    if($input.val() === '') {
      $input.val(email);
    }
    else {
      let $new_input_group = $('<div class="input-group input-group-additional"><div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-at"></i></span></div></div>');
      let $new_input = $('<input autocomplete="' + helper.randomString() + '" name="' + options.name + '[]" class="form-control" type="email" />');
      $new_input_group.append($new_input);
      $new_input.val(email);
      $element.append($new_input_group);
    }
  };

  return this.initialize();
};


