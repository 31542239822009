import button_save from '../../_compiled/components/button_save';

$.fn.uiCard = function(options) {
  // support multiple elements
  if (this.length > 1){
    this.each(() => { $(this).uiCard(options) });
    return this;
  }

  // default options

  options = $.extend({},{
    title: false,
    collapse: false,
    header: true,
  }, options);

  // private variables
  let $header = null;
  let $footer = null;
  let $body = null;
  let $loader = null;
  let $tools = null;
  let element_id = null;

  let plugin = this;

  let $element = $(this);
  // ...

  // private methods
  let foo = () => {
    // do something ...
  };

  plugin.showLoading = () => {
    $loader.show();
  };

  plugin.hideLoading = () => {
    $loader.hide();
  };

  plugin.addTool = ($tool) => {
    $tools.append($tool);
  };

  plugin.table = (options) => {
    $body.addClass('table-responsive p-0');
    let $table = $('<table></table>');
    $body.append($table);
    $table.uiTable(options);

    return $table;
  };

  plugin.generateId = () => {

    let base = 'card-';
    let number = 1;
    let id = base + number;

    while ($('#' + id ).length > 0) {
      number++;
      id = base + number;
    }

    return id;
  };

  plugin.btnFooter = (opt) => {

    opt = $.extend({},{
      text: t('g.save')
    }, opt);

    let $button = button_save.js(opt);
    $button.addClass('float-right');

    plugin.$footer.append($button);
    plugin.$footer.show();

    return $button;
  };

  // public methods
  this.initialize = () => {

    element_id = $element.attr('id');
    if(element_id === undefined) {
      element_id = plugin.generateId();
    }

    let header_id = element_id + '-header';
    let body_id = element_id + '-body';
    let collapse_id = element_id + '-collapse';

    $element.addClass('card');
    $header = $('<div class="card-header"></div>');
    $element.append($header);

    /*
     * add Title
     */
    if(options.title !== false) {

      let title = options.title;
/*
      if(options.collapse) {
        title = '<a class="card-title collapse-link collapsed" data-toggle="collapse" href="#' + collapse_id + '" role="button" aria-expanded="false" aria-controls="' + collapse_id + '">' + options.title + '</a>';
      }
*/
      $header.append('<h4 class="card-title">' + title + '</h4>');
    }

    $tools = $('<div class="card-tools"></div>');



    $header.append($tools);

    if(!options.header) {
      $header.hide();
    }

    /*
     * add body
     */
    $body = $('<div class="card-body"></div>');

    /*
    if(options.collapse) {
      let $collapse = $('<div class="collapse" id="' + collapse_id + '"></div>');
      $collapse.append($body);
      $element.append($collapse);
    }
    else {

    }
    */

    if(options.collapse) {
      $body.hide();
      $tools.append('<button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-plus"></i></button></div>');
      $element.addClass('collapsed-card');
    }

    $element.append($body);

    /*
     * Footer
     */
    $footer = $('<div class="card-footer clearfix" style="display:none"></div>');
    $element.append($footer);

    /*
     * add loader
     */
    $loader = $('<div style="display: none" class="overlay"><i class="fas fa-2x fa-sync-alt fa-spin"></i></div>');
    $element.append($loader);

    plugin.$body = $body;
    plugin.$header = $header;
    plugin.$footer = $footer;
    plugin.$tools = $tools;

    return plugin;
  };

  return this.initialize();
};


