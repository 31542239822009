import api from '../api';

$.fn.uiFormSelectmonthyear = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $input;
  let $select_month, $select_year;

  options = $.extend({},{
    i18n_prefix: '',
    wrapper: true,
    default: false,
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);


  let plugin = this;
  let today;
  let monthnames = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
    'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];

  // public methods
  this.initialize = () => {
    today = new Date();

    $label = $(`<label>${options.label}</label>`);
    $select_month = $(`<select name="${options.name}_month" class="form-control"></select>`);
    $select_year = $(`<select name="${options.name}_year" class="form-control"></select>`);
    
    $select_month.append(`<option value="0">${t('g.please_choose')}</option>`);
    for (var i=1; i<=12; i++) {
      $select_month.append(`<option value="${i}">${monthnames[i-1]}</option>`);
    }

    $select_year.append(`<option value="0">${t('g.please_choose')}</option>`);
    for(var i=(today.getFullYear()-25);i<=today.getFullYear();i++) {
      $select_year.append(`<option value="${i}">${i}</option>`);
    }

    $input = $select_month.outerHTML() + $select_year.outerHTML();

    if(options.wrapper) {
      $element.addClass('form-group form-group-' + options.name).append($label).append($input);
    }
    else {
      $element.append($input);
    }

    this.$input = $input;

    return plugin;

  };

  this.setValue = (value) => {

    if(value) {
      let date = value.split("/");
      let month = parseInt(date[0], 10);
      let year = parseInt(date[1], 10);

      if (year >= 60 && year <= 99) {
        year = parseInt('19'+year, 10);
      }
      else if (year > 0 && year < 10) {
        year = parseInt('200'+year, 10);
      }
      else if (year >= 10 && year < 60) {
        year = parseInt('20'+year, 10);
      }
      else if (year == 0) {
        year = 2000;
      }

      $element.find('select[name="'+options.name+'_month"]').val(month);
      $element.find('select[name="'+options.name+'_year"]').val(year);
    }

  };

  this.resetValue = () => {
    $element.find('select[name="'+options.name+'_month"]').val(0);
    $element.find('select[name="'+options.name+'_year"]').val(0);
  };

  return this.initialize();
};

$.fn.outerHTML = function() {
  return $('<select />').append(this.eq(0).clone()).html();
};
