import msg from '../msg';
import routes from '../routes';
import session from "../session";

$.fn.uiFormDndupload = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label, $label_add;
  let $add, $uploading;
  let $dnd;
  let $input;
  let accept;
  let $uploading_fail;

  options = $.extend({},{
    i18n_prefix: '',
    multiple: true
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);


  let plugin = this;

  // public methods
  this.initialize = () => {

    if (options.accept && options.accept !== undefined) {
      accept = ` accept="${options.accept}"`;
    }

    $label = $('<label>' + options.label + '</label>');
    $dnd = $('<div id="dndupload-drop"></div>');

    $add = $('<i class="fas fa-plus dndupload-add"></i>');
    $uploading = $(`<div class="dndupload-uploading">
        <i class="fas fa-circle-notch fa-spin"></i> ${t('dndupload.uploading')}
    </div>`);

    $uploading_fail = $(`<div class="dndupload-uploading">
        <i class="fas fa-circle-notch"></i> ${t('dndupload.uploading_fail')}
    </div>`);

    if (options.multiple === true) {
      $input = $(`<input name="${options.name}[]" class="form-control" type="file"${accept} multiple />`);
      $label_add = $(`<label for="${options.name}[]">${t('dndupload.label_add_multiple')}</label>`)
    }
    else {
      $input = $(`<input name="${options.name}" class="form-control" type="file"${accept} />`);
      $label_add = $(`<label for="${options.name}">${t('dndupload.label_add_single')}</label>`)
    }

    $dnd.append($add).append($label_add).append($input).append($uploading).append($uploading_fail);

    $element.addClass('form-group form-group-'+options.name).append($label).append($dnd);

    this.$input = $input;

    plugin.addEvents();

    return plugin;

  };

  plugin.addEvents = () => {

    /* file dialog öffnen */
    $element.find('#dndupload-drop').find('label > strong').on('click', () => {
      $element.find('#dndupload-drop').find('input').click();
    });

    /* file input abschicken */
    $element.find('#dndupload-drop').find('input').on('change', (ev) => {
      this.upload(ev.currentTarget.files);
    });

    /* drag & drop */
    $element.find('#dndupload-drop').on('drag dragstart dragend dragover dragenter dragleave drop', (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
    })
    .on('dragenter dragover', () => {
      $element.find('#dndupload-drop').addClass('highlight');
      $add.hide();
      $label_add.hide();
    })
    .on('dragleave drop', () => {
      $element.find('#dndupload-drop').removeClass('highlight');
      $add.show();
      $label_add.show();
    })
    .on('drop', (ev) => {
      this.upload(ev.originalEvent.dataTransfer.files);
    });

  };

  this.upload = (files) => {

    $add.hide();
    $label_add.hide();
    $uploading.show();

    let $form = $('<form method="post" enctype="multipart/form-data"></form>');
    let form_data = new FormData();

    $.each(files, (i, file) => {

      if (options.multiple === true) {
        form_data.append(options.name+'[]', file);
      }
      else {
        form_data.append(options.name, file);
      }
      
    });

    $.ajax({
      url: '/api/v1' + options.url,
      type: 'POST',
      data: form_data,
      dataType: 'json',
      headers: {
        'Authorization' : 'Bearer ' + session.getToken(),
      },
      cache: false,
      contentType: false,
      processData: false,
      success: (id) => {
        $uploading.hide();
        $add.show();
        $label_add.show();

        msg.success(t('dndupload.success'));

        let $modal_close = $(document).find('.modal.show').find('button.close');

        if ($modal_close) {
          $modal_close.click();
        }

        if (options.afterUpload && typeof options.afterUpload === 'string') {
          routes.router.navigate(options.afterUpload + '/' + id);
        }

        if (options.afterUpload && typeof options.afterUpload === 'function') {
          options.afterUpload();
        }
      },
      error: (error) => {
        $uploading.hide();
        $uploading_fail.show();
        let message = '';
        if (error) {
          if(error.responseJSON) {
            if (error.responseJSON.errors.email) {
              message = ' ' + t(error.responseJSON.errors.email[0]);
              msg.error(t('dndupload.error') + message);
            }
            else {
              msg.error(t(error.responseJSON.message));
            }
          }
          else if(error.status === 413 && error.statusText === 'Request Entity Too Large') {
            msg.error(t('g.upload_limit_max_size'));
          }
          else {
            msg.error(t('g.upload_failed'));
          }
        }
        else {
          msg.error(t('g.upload_failed'));
        }
      }
    });
  };

  this.setValue = (value) => {
    /*$input.val(value);
    $label.text(options.label_edit);
    $input.attr('placeholder', options.placeholder_edit);*/
  };

  this.resetValue = () => {
    /*$input.val('');
    $label.text(options.label);
    $input.attr('placeholder', options.placeholder);*/
    $uploading_fail.hide();
    $add.show();
    $label_add.show();
  };

  return this.initialize();
};


