import app from '../../app/app';;
import api from '../../app/api';;
import msg from '../../app/msg';;
import btn_save from '../../_compiled/components/button_save';

const admin_agency_settings = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12" id="agentur-settings"> </div> </div></div>'; return out;
    },
    js: function(it) {
            app.setTitle('Agentur Einstellungen'); let $btn_save = new btn_save.js(); var $form = $('#agentur-settings').uiForm({ i18n_prefix: 'admin_agency_settings.', url: "/resouce/agency", fields: { 'agency_name': { type: 'Text' }, 'logo': { type: 'Text' }, 'font_color': { type: 'Colorpicker' }, 'background_color': { type: 'Colorpicker' }, 'highlight_color': { type: 'Colorpicker' }, 'a_record': { type: 'Text' }, } }); api.get('/agency/getclientform', { success: (result) => { $form.getFormElement('agency_name').setValue(result.data.name); $form.getFormElement('font_color').setValue(result.data.font_color); $form.getFormElement('background_color').setValue(result.data.background_color); $form.getFormElement('highlight_color').setValue(result.data.highlight_color); $form.getFormElement('logo').setValue(result.data.logo); $form.getFormElement('a_record').setValue(result.data.dns_a_record); }, error: () => { msg.error(t('g.error')); } });  $btn_save.click((ev) => { ev.preventDefault(); let value = { name: jQuery($form.getFormElement('agency_name')).find("input").eq(0).val(), font_color: jQuery($form.getFormElement('font_color')).find("input").eq(0).val(), background_color: jQuery($form.getFormElement('background_color')).find("input").eq(0).val(), highlight_color: jQuery($form.getFormElement('highlight_color')).find("input").eq(0).val(), dns_a_record: jQuery($form.getFormElement('a_record')).find("input").eq(0).val(), logo: jQuery($form.getFormElement('logo')).find("input").eq(0).val() }; api.post('/agencysetting/update', { data: value }, { success: () => { msg.success(t('g.save_success')); } }) }); $form.append($btn_save);
    }
};

export default admin_agency_settings;
