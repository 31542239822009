
const icons = {
  image: 'far fa-file-image',
  pdf: 'far fa-file-pdf',
  word: 'far fa-file-word',
  powerpoint: 'far fa-file-powerpoint',
  excel: 'far fa-file-excel',
  csv: 'far fa-file-csv',
  audio: 'far fa-file-audio',
  video: 'far fa-file-video',
  archive: 'far fa-file-archive',
  code: 'far fa-file-code',
  text: 'far fa-file-alt',
  file: 'far fa-file'
};

const extensions = {
  gif: icons.image,
  jpeg: icons.image,
  jpg: icons.image,
  png: icons.image,

  pdf: icons.pdf,

  doc: icons.word,
  docx: icons.word,
  odt: icons.word,

  ppt: icons.powerpoint,
  pptx: icons.powerpoint,

  xls: icons.excel,
  xlsx: icons.excel,

  csv: icons.csv,

  aac: icons.audio,
  mp3: icons.audio,
  ogg: icons.audio,

  avi: icons.video,
  flv: icons.video,
  mkv: icons.video,
  mp4: icons.video,

  gz: icons.archive,
  tgz: icons.archive,
  zip: icons.archive,

  css: icons.code,
  html: icons.code,
  xml: icons.code,
  js: icons.code,

  txt: icons.text
};

const mimeTypes = {
  'image/gif': icons.image,
  'image/jpeg': icons.image,
  'image/png': icons.image,

  'application/pdf': icons.pdf,

  'application/msword': icons.word,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': icons.word,

  'application/mspowerpoint': icons.powerpoint,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': icons.powerpoint,

  'application/msexcel': icons.excel,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': icons.excel,

  'text/csv': icons.csv,

  'audio/aac': icons.audio,
  'audio/wav': icons.audio,
  'audio/mpeg': icons.audio,
  'audio/mp4': icons.audio,
  'audio/ogg': icons.audio,

  'video/x-msvideo': icons.video,
  'video/mpeg': icons.video,
  'video/mp4': icons.video,
  'video/ogg': icons.video,
  'video/quicktime': icons.video,
  'video/webm': icons.video,

  'application/gzip': icons.archive,
  'application/zip': icons.archive,

  'text/css': icons.code,
  'text/html': icons.code,
  'text/javascript': icons.code,
  'application/javascript': icons.code,

  'text/plain': icons.text,
  'text/richtext': icons.text,
  'text/rtf': icons.text
};

let fileicons = {

  byExt: (ext) => {
    if(extensions[ext] !== undefined) {
      return extensions[ext];
    }
    return icons.file;
  },

  byFile: (filename) => {
    let parts = filename.split('.');
    let ext = parts[parts.length-1].toLowerCase();

    return fileicons.byExt(ext);
  },

  byType: (type) => {
    if(mimeTypes[type] !== undefined) {
      return mimeTypes[type];
    }

    return icons.file;
  }

};


export default fileicons;
