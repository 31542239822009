import api from '../api';
import helper from "../helper";
import button_save from "../../_compiled/components/button_save";
import modal from "../../_compiled/components/modal";

$.fn.uiKanbanBoard = function(options) {

    // private variables
    let $container = null;
    let handles = {};
    let $shadow = $('<div style="display: none" class="kanban-dropshadow"></div>');
    let $form, $modal, $btn_save, $btn_save_edit;

    /*
    HANDLE OPTIONS
     */
    options = $.extend({},{
      repo: false,
      repo_handles: false,
      item_name_prop: 'name',
      i18n_prefix: null,
      addSuccess: (res) => {},
      editSuccess: (new_data, old_data) => {},
      onAddMode:()=>{},
      onEditMode:(data)=>{},
      renderItem: (item) => {

        let $el = $('<div class="callout">\n' +
          '                  <h5>' + item.name + '</h5>\n' +
          '\n' +
          '                  <p></p>\n' +
          '                </div>');

        return $el;

      }
    }, options);

    if(!options.i18n_prefix) {
      options.i18n_prefix = options.repo + '.';
    }

    if(options.status_prop === undefined){
      options.status_prop = options.repo_handles + '_id';
    }

    if(options.uri_list === undefined) {
      options.uri_list = '/resource/list/' + options.repo
    }

    /*
    HANDLE OPTIONS END
     */

    let plugin = this;

    let $element = $(this);

    this.initElements = () => {

      $element.addClass('kanban-board');

      $container = $('<div class="kanban-inner"></div>');

      $element.append($container);

    };

    this.initModal = () => {

      $btn_save = button_save.js();
      $btn_save_edit = button_save.js();

      /*
       * Add New Modal
       */
      $modal = modal.js({
        title: t(options.i18n_prefix + 'add_' + options.repo),
        size: 'lg'
      });


      $modal.$footer.append($btn_save);
      $modal.$footer.append($btn_save_edit);

      plugin.$modal = $modal;

    };

    this.initForm = () => {

      $form = $('<form action="' + '/' + options.repo + '"></form>').uiForm({
        fields: options.fields,
        $el_save: $btn_save,
        $el_edit: $btn_save_edit,
        url: '/' + options.repo,
        i18n_prefix: options.i18n_prefix,
        additionalTitle: options.additionalTitle,
        addSuccess: (res) => {
          options.addSuccess(res);
        },
        editSuccess: (new_data, old_data) => {
          options.editSuccess(new_data, old_data);
        },
        success: (res) => {
          plugin.update();
          $modal.modal('hide');
          $form.resetValues();
        },
        onAddMode: () => {
          $modal.$title.text(t(options.i18n_prefix +'add_' + options.repo));
          options.onAddMode();
        },
        onEditMode: (data) => {
          $modal.$title.text(t(options.i18n_prefix +'edit_' + options.repo, data));
          options.onEditMode(data);
        },
        complete: () => {
          options.onFinishLoading();
          $btn_save_edit.find('i').removeClass('fa-spin fa-cog').addClass('fa-save');
          $btn_save.find('i').removeClass('fa-spin fa-cog').addClass('fa-save');
        },
        start: () => {
          $btn_save_edit.find('i').removeClass('fa-save').addClass('fa-spin fa-cog');
          $btn_save.find('i').removeClass('fa-save').addClass('fa-spin fa-cog');
          if(typeof options.inLoading === 'function') {
            options.onLoading();
          }

        }
      });

      $modal.$body.append($form);

    };

    this.renderHandle = (handle) => {

      let $el = $(
      '<div class="card card-kanbanhandle kanbanhandle-' + handle.id + '">' +
        '<div class="card-header">' +
          '<h3 class="card-title">' + handle.name + '</h3>' +
          '<div class="card-tools"><i class="loader-icon fas fa-sync-alt fa-spin"></i></div>' +
        '</div>' +
        '<div class="card-body">' +
      '</div></div>');

      return $el;

    };

    this.render = (uri) => {

      $container.empty();

      for (let index in handles) {
        if (handles.hasOwnProperty(index)) {
          let $handle = plugin.renderHandle(handles[index]);
          $container.append($handle);

          plugin.appendItems($handle, handles[index]);

        }
      }
    }

    this.loadFromUri = (uri) => {

      $container.empty();

      for(let prop in handles) {
        if (handles.hasOwnProperty(prop)) {
          handles[prop].items = {};
        }
      }

      api.get(uri,{
        success: (items) => {

          items.forEach((item) => {

            if(item[options.status_prop]) {
              handles[parseInt(item[options.status_prop])].items[parseInt(item.id)] = item;
            }

          });

          plugin.render();

        }
      });
    };

    this.initStatus = () => {

      api.get('/resource/list/' + options.repo_handles, {

        success: (resp) => {

          resp.forEach((r) => {
            handles[parseInt(r.id)] = r;
            handles[parseInt(r.id)].items = {};
            helper.addCssRules('.kanbanhandle-' + r.id + ' .callout{border-left-color:' + r.color + '}')
          });

          api.get(options.uri_list,{
            success: (items) => {

              items.forEach((item) => {

                if(item[options.status_prop]) {
                  handles[parseInt(item[options.status_prop])].items[parseInt(item.id)] = item;
                }

              });

              plugin.render();

            }
          });
        }

      });

    };

    this.setItemBefore = (moved, target) => {

      $('.kanbanhandle-' + target.handle_id).addClass('is-loading');

      if(target.handle_id !== moved.handle_id) {
        handles[target.handle_id].items[moved.id] = handles[moved.handle_id].items[moved.id];
        delete handles[moved.handle_id].items[moved.id];
      }

      let $moved = $container.find('.kanban-item-' + moved.id);
      $container.find('.kanban-item-' + target.id).before($moved);
      setTimeout(() => {
        $moved.effect('highlight', {}, 3000);
      },10);


      $shadow.hide();

      api.post(options.uri_move,{
        job_id: moved.id,
        placementstatus_id: target.handle_id
      },{
        complete: () => {
          $('.kanbanhandle-' + target.handle_id).removeClass('is-loading');
        }
      });

    };

    this.appendItems = ($handle, handle) => {

      let $body = $handle.find('.card-body');
      let moved_id = null;
      for (let index in handle.items) {
        if (handle.items.hasOwnProperty(index)) {
          let $item = options.renderItem(handle.items[index]);

          console.log(handle.items[index]);
          $item.data('id', handle.items[index].id);
          $item.data('handle_id', handle.items[index][options.status_prop]);
          $item.addClass('kanban-item-' + handle.items[index].id);

          $item.draggable({
            start: function() {
            },
            stop: function() {
              $shadow.hide();
            },
            revert: true,
            revertDuration: 0,
            containment: $element,
            scroll: false,
            zIndex: 100,
            cursor: 'move'
          });


          $item.droppable({
            drop: function(event, ui) {

              let id = $(this).data('id');
              let handle_id = $(this).data('handle_id');

              plugin.setItemBefore({
                id: ui.draggable.data('id'),
                handle_id: ui.draggable.data('handle_id')
              }, {
                id: id,
                handle_id: handle_id
              });

              $shadow.hide();

            },
            over: function(event, ui) {
              $(this).before($shadow);
              $shadow.show();
            },
            out: function(event, ui) {
              $shadow.hide();
            }
          });

          $body.append($item);

        }

      }

      let $item = $('<div style="opacity: 0;" class="callout callout-info kanban-item-0 ui-draggable ui-draggable-handle ui-droppable"></div>');
      $item.droppable({
        drop: function(event, ui) {

          $shadow.hide();

        },
        over: function(event, ui) {
          $(this).before($shadow);
          $shadow.show();
        },
        out: function(event, ui) {
          $shadow.hide();
        }
      });
      $body.append($item);

    };

    this.initialize = () => {

      this.initModal();
      this.initForm();
      this.initElements();
      this.initStatus();

    };

    return this.initialize();
};


