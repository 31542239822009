import api from '../api';

$.fn.uiFormCheckboxGroups = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $list;
  let $list_groups = {};
  let loaded = false;
  let initial_values = null;

  options = $.extend({},{
    i18n_prefix: '',
    default: '',
    group_field: 'table_name',
    name_field: 'key',
    id_field: 'id'
  }, options);

  if(options.url_list === undefined) {
    options.url_list = '/resource/list/' + options.table;
  }

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);


  let plugin = this;
  /*
  this.showErrors = (name, error) => {
    if (options.name == name) {
      $input.addClass('is-invalid');
      $element.find(`input[name="${name}"]`).parent().append(`<span class="error invalid-feedback">${error}</span>`);
    }
  };
  */

  // public methods
  this.initialize = () => {
    $label = $('<label>' + options.label + '</label>');
    $list = $('<ul class="checkboxgroup"></ul>');

    $element.addClass('form-group form-group-' + options.name).append($label).append($list);

    this.$list = $list;

    api.get(options.url_list, {
      success: (items) => {
        plugin.renderGroups(items);
        loaded = true;
        if(initial_values) {
          plugin.setValue(initial_values);
        }
      }
    });

    return plugin;

  };

  this.renderGroups = (items) => {

    items.forEach((item) => {

      let group = item[options.group_field];
      if(!group || group === '') {
        group = '-';
      }

      if($list_groups[group] === undefined) {
        $list_groups[group] = plugin.renderGroup(group);
        $list.append($list_groups[group]);
      }

      $list_groups[group].find('ul').append(plugin.renderItem(item));
    });

    $list.find('input.checkbox-group').change((ev) => {
      let $el = $(ev.currentTarget);
      $el.parent().parent().find('input.checkbox-group-' + $el.attr('value')).each((i, cb) => {
        cb.checked = $el[0].checked;

      });

    });

  };

  this.renderItem = (item) => {
    return $(`<li class="form-check"><label class="form-check-label"><input name="` + options.name + `[]" type="checkbox" class="form-check-input checkbox-group-` + (item[options.group_field] || '-') + `" data-name="` + item[options.name_field] + `" value="` + item[options.id_field] + `" /> ` + item[options.name_field] + `</label></li>`);
  };

  this.renderGroup = (name) => {

    let $li = $('<li class="form-check"></li>');
    $li.append('<label class="form-check-label"><input type="checkbox" class="form-check-input checkbox-group" value="' + name + '" /> <strong>' + name + '</strong></label>');
    $li.append('<ul></ul>');

    return $li;
  };

  this.setValue = (value) => {

    $label.text(options.label_edit);

    $list.find('input').each((i, cb) => {
      cb.checked = false;
    });

    if(!loaded) {
      initial_values = value;
      return false;
    }

    value.forEach((val) => {

      $list.find('input[value="' + val[options.id_field] + '"]')[0].checked = true;

    });

  };

  this.resetValue = () => {

    $label.text(options.label);

    $list.find('input').each((i,cb) => {
      cb.checked = false;
    });

  };

  return this.initialize();
};


