import helper from './helper';
import audio from './audio';
import browser_notification from './browser_notification';

let msg = {
  init: () => {

  },
  success: (text, options) => {
    msg.toast('success', text, options);
  },
  info: (text, options) => {
    msg.toast('info', text, options);
  },
  error: (text, options) => {
    msg.toast('error', text, options);
  },
  successSave: () => {
    msg.success(t('g.save_success'));
  },
  toast: (type, text, options) => {

    options = $.extend({},{
      sound: false,
      browserNotification: false
    }, options);

    text = helper.htmlEntities(text);

    switch (type) {
      case 'info':
        toastr.info(text);
        break;

      case 'success':
        toastr.success(text);
        break;

      case 'error':
        toastr.error(text);
        break;

      default:
        toastr.info(text);
        break;
    }

    if(options.sound === true) {
      if(type === 'error') {
        audio.playSound('bump.mp3');
      }
      else {
        //audio.playSound('coin.mp3');
      }
    }

    if(options.browserNotification) {
      browser_notification.showNotification(text);
    }
  },

  handleSuckit: (options) => {
    msg.toast(options.type, options.text,{sound: true, browserNotification: true});
  }
};

export default msg;
