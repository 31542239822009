import app from '../../app/app';;
import api from '../../app/api';;
import msg from '../../app/msg';;
import btn_save from '../../_compiled/components/button_save';

const cms_teammembers = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12" id="teammembers-settings"> </div> </div></div>'; return out;
    },
    js: function(it) {
            app.setTitle('Neues Teammitglied anlegen'); let $btn_save = new btn_save.js(); let $main = $('#teammembers-settings'); let $form = $('<form></form>').uiForm({ i18n_prefix: 'teammember.', fields: { 'name': { type: 'Text' }, 'slug': { type: 'Text' }, 'image_big': { type: 'Text' }, 'mail': { type: 'Text' }, 'xing': { type: 'Text' }, 'linkedin': { type: 'Text' }, 'quote': { type: 'Text' }, 'meta_description': { type: 'Textarea', maxlength: 300 }, 'text': { type: 'Textarea' }, } });  $btn_save.click((ev) => { ev.preventDefault(); let data = $form.serializeObject(); api.post('/page/teammember', data, { error: (res) => { msg.error(t('g.save_error')); }, success: () => { msg.success(t('g.save_success')); } }); }); $form.append($btn_save); $main.append($form);
    }
};

export default cms_teammembers;
