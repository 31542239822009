import app from '../../app/app';

const address = {
    tpl: function(it) {
        var out=' '+(it.street)+' '+(it.number)+'<br /> '+(it.zip)+' '+(it.city)+'<br />'; return out;
    },
    js: function(it) {
        
    }
};

export default address;
