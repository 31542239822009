import media from '../media';
import api from '../api';
import msg from '../msg';
import helper from '../helper';
import time from '../time';
import routes from '../routes';

$.fn.uiContactDetails = function(options) {

  // default options
  let plugin = this;
  let $element = $(this);

  options = $.extend({}, {
    title: false,
    onLoading: () => {
    },
    onFinish: () => {
    }
  }, options);

  let $form;

  plugin.initForm = () => {
    $form = $('<form></form>').uiForm({
      success: () => {
        msg.success(t('g.save_success'));
      },
      error: (res) => {
        if(res && res.responseJSON && res.responseJSON.error) {
          msg.error(t(res.responseJSON.error));
        }
        else {
          msg.error(t('g.save_error'));
        }

      },
      url: '/contact',
      i18n_prefix: 'contact.',
      fields: {
        'organisations': {
          type: 'Multiselect',
          table: 'agency_organisation',
          ajax: true
        },
        'important': 'Switch',
        'salutation': {
          type: 'Select',
          table: 'salutation'
        },
        'firstname': 'Text',
        'lastname': 'Text',
        'email': 'Email',
        'phone': 'Phone',
        'phone_mobile': 'Phone',
        'phone_private': 'Phone',
        'users': {
          type: 'TagSelect',
          table: {
            name: 'user',
            type: 'worker_active'
          },
        },
        'employments': {
          name: 'employments',
          type: 'TagSelect',
          table: 'employment'
        },

        /*'contacttype': {
          type: 'Select',
          table: 'contacttype'
        },*/
        'position': 'Text',
        'social_linkedin': 'Text',
        'social_xing': 'Text',
        'description': 'Textarea',
        /*'tags': {
          type: 'TagSelect',
          table: {
            name: 'tag',
            type: 'crm'
          }
        },*/
        /*
        'social_facebook': 'Text',
        'social_instagram': 'Text',
        'social_youtube': 'Text',
        'social_twitter': 'Text',
        'social_mastodon': 'Text',
        */
        'submitted': 'Submit'
      }
    });

    $form.setValues(options.contact);

    $element.append($form);
  };

  this.initialize = () => {

    plugin.initForm();

    return plugin;
  };

  return this.initialize();

};
