import api from '../api';

$.fn.uiFormHidden = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $input;

  options = $.extend({},{
  }, options);


  let plugin = this;

  // public methods
  this.initialize = () => {

    if (options.value === undefined) {
      options.value = '';
    }

    $input = $('<input name="' + options.name + '" class="form-control" type="hidden" value="' + options.value + '" />');

    $element.append($input);

    this.$input = $input;

    return plugin;

  };

  this.setValue = (value) => {

    // nur wenn wir einen Datensatz bearbeiten wollen wir das value hier setzen, da ansonsten options.value
    // aus der initialize-Methode mit einem leeren String überschrieben wird!
    if (options.class == 'editmode') {
      $input.val(value);
    }

  };

  this.resetValue = () => {
    //$input.val('');
  };

  return this.initialize();
};


