import media from '../media';
import api from '../api';
import msg from '../msg';
import helper from '../helper';
import time from '../time';
import routes from '../routes';

$.fn.uiJobDetails = function(options) {

  // default options
  let plugin = this;
  let $element = $(this);

  options = $.extend({}, {
    title: false,
    onLoading: () => {
    },
    onFinish: () => {
    }
  }, options);

  this.initialize = () => {

    $('#job-title').text(options.job.title);
    $element.html(options.job.description);

    return plugin;
  };

  return this.initialize();

};
