import api from "../api";
import plugins from '../plugins';

const chart_candidates_timeline = {

  chart: null,
  name: null,

  update: (stats) => {
    /*
     * Wir erstellen dynamisch die Annotations für die Jahre
     */
    let start = 2019;
    let date = new Date();
    let years = date.getFullYear() - start;
    let annotations = [];

    for (let i=0; i<=years; i++) {

      let label = {
        x: new Date('1 Jan ' + (start+i)).getTime(),
        strokeDashArray: 0,
        borderColor: '#775DD0',
        label: {
          borderColor: '#775DD0',
          style: {
            color: '#fff',
            background: '#775DD0'
          },
          text: (start+i).toString()
        }
      };

      annotations.push(label);
    }

    chart_candidates_timeline.chart.updateOptions({
      annotations: {
        xaxis: annotations
      }
    });

    /*
     * Wir updates die Daten des Charts
     */
    chart_candidates_timeline.chart.updateSeries([{
      name: 'Gesamt',
      data: stats.total
    }, {
      name: 'diesen Monat',
      data: stats.monthly
    }]);
  },

  create: () => {
    let options = {
      chart: {
        height: 350,
        type: 'area',
        background: '#fff',
        stacked: false
      },
      stroke: {
        curve: 'smooth'
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#81D4FA', '#D6963A'],
      series: [],
      xaxis: {
        type: 'datetime',
        labels: {
          formatter: (value) => {
            let date2 = new Date(value * 1000);
            //add one day coz of UTC issues, we get 23:00 of the previous day.
            date2.setDate(date2.getDate() + 1);
            return ('0' + (date2.getUTCMonth()+1)).slice(-2) + '-' + date2.getUTCFullYear();
          }
        }
      },
      yaxis: [
        {
          opposite: true,
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
            color: '#81D4FA'
          },
          title: {
            text: 'Gesamt'
          },
          labels: {
            formatter: (value, index) => {
              return value.toLocaleString('de-DE');
            }
          }
        },
        {
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
            color: '#D6963A'
          },
          title: {
            text: 'pro Monat'
          },
          labels: {
            formatter: (value, index) => {
              return value.toLocaleString('de-DE');
            }
          }
        }
      ],
      title: {
        align: 'center',
        text: 'Kandidaten pro Monat und Gesamt',
      },
      markers: {
        size: [0, 5],
        strokeWidth: [0, 3]
      },
      noData: {
        text: 'Lade...'
      }
    };

    chart_candidates_timeline.chart = new ApexCharts(document.querySelector(`#chart-${chart_candidates_timeline.name}`), options);
    chart_candidates_timeline.chart.render();
  },

  init: (name) => {

    chart_candidates_timeline.name = name;

    chart_candidates_timeline.create();

    api.get('/statistics/candidates_timeline', {
      success: (data) => {
        chart_candidates_timeline.update(JSON.parse(data));
      }
    });

  }

};

export default chart_candidates_timeline;
