$.fn.uiFormHtml = function(options) {

  let $element = $(this);
  let $container, $label;

  options = $.extend({},{
    i18n_prefix: 'infotext.',
    format: 'callout',       /* alert, callout */
    style: 'info'            /* danger, info, warning, success */
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);

  let plugin = this;

  // public methods
  this.initialize = () => {

    $label = $('<label>' + options.label + '</label>');

    $container = $(`<div></div>`);

    $element.addClass('form-group form-group-' + options.name).append($label).append($container);

    return plugin;

  };

  this.setValue = (value) => {
    $container.empty().html(value);
  };

  this.resetValue = () => {
  };

  return this.initialize();

};
