import api from '../api';

$.fn.uiFormMultitext = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label, $label_wrapper;
  let $input;
  let $input_group;
  let $btn_add;

  options = $.extend({},{
    i18n_prefix: ''
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);


  let plugin = this;

  // public methods
  this.initialize = () => {

    $btn_add = $('<a href="#" class="btn btn-default ml-3 btn-xs">+</a>');
    $label = $('<span>' + options.label + '</span>');
    $label_wrapper = $('<label></label>');

    $input = $('<input autocomplete="' + helper.randomString() + '" name="' + options.name + '[]" class="form-control" type="text" />');

    $label_wrapper.append($label);
    $label_wrapper.append($btn_add);
    $element.addClass('vertical-input-group');
    $element.addClass('form-group form-group-'+options.name).append($label_wrapper).append($input);

    $btn_add.click(() => {
      let $new_input = $('<input autocomplete="' + helper.randomString() + '" name="' + options.name + '[]" class="form-control" type="text" />');
      $element.append($new_input);
    });

    this.$input = $input;

    return plugin;

  };

  this.setValue = (value) => {

    $input.val('');

    if(Array.isArray(value)) {
      $input.remove();

      value.forEach((v) => {
        plugin.addInput(v);
      });
    }
  };

  this.resetValue = () => {
    $input.val('');
    $label.text(options.label);
    $input.attr('placeholder', options.placeholder);
  };

  this.addInput = (value) => {

    if(value != '') {
      let $new_input = $('<input autocomplete="' + helper.randomString() + '" name="' + options.name + '[]" class="form-control" type="text" />');
      $new_input.val(value);
      $element.append($new_input);
    }
  };

  return this.initialize();
};


