const store = {
  set: (key, value) => {
    window.localStorage.setItem(key, JSON.stringify({d:value}));
  },

  get: (key) => {
    let item = window.localStorage.getItem(key);


    if(item)
    {
      item = JSON.parse(item).d;
      return item;
    }

    return null;
  },
  remove: (key) => {
    store.set(key, null);
    window.localStorage.removeItem(key);
  },
  push: (key, value, no_dublicates) => {

    let tmp = store.get(key);

    if(!tmp) {
      tmp = [];
    }

    if(no_dublicates && tmp.indexOf(value) != -1) {
      return false;
    }

    tmp.push(value);

    store.set(key, tmp);
  }
};

window.$store = {
  set: (key, value) => {
    window.localStorage.setItem(key, JSON.stringify({d:value}));
  },

  get: (key) => {
    let item = window.localStorage.getItem(key);

    if(item)
    {
      item = JSON.parse(item).d;
      return item;
    }

    return null;
  },
  remove: (key) => {
    store.set(key, null);
    window.localStorage.removeItem(key);
  }
}

export default store;
