import api from '../api';

$.fn.uiFormTrumbowyg = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $input;

  options = $.extend({},{
    i18n_prefix: '',
    wrapper: true
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);


  let plugin = this;

  // public methods
  this.initialize = () => {

    $label = $('<label>' + options.label + '</label>');
    //$input = $('<textarea name="' + options.name + '" class="form-control"></textarea>');
    $input = $('<div id="' + options.name + '"></div>');

    if(options.wrapper) {
      $element.addClass('form-group form-group-'+options.name).append($label).append($input);
    }
    else {
      $element.append($input);
    }

    $element.find('#' + options.name).trumbowyg({
      btns: [['viewHTML'],['undo','redo'],['strong', 'em',], ['unorderedList','orderedList']],
      autogrow: true,
      lang: 'de'
    }).on('tbwchange', (ev) => {

    });

    this.$input = $input;

    return plugin;

  };

  this.setValue = (value) => {
    $input.trumbowyg('html',value);
    $label.text(options.label_edit);
  };

  this.resetValue = () => {
    $input.trumbowyg('html','<p></p>');
    $label.text(options.label);
  };

  return this.initialize();
};


