import api from '../api';

$.fn.uiFormNumber = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $input;

  options = $.extend({},{
    i18n_prefix: '',
    min: -99999999,
    max: 99999999,
    step: 1,
    readonly: false
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);

  let plugin = this;

  // public methods
  this.initialize = () => {

    let value = '';
    let readonly = '';

    if (options.value !== undefined) {
      value = ` value="${options.value}"`;
    }

    if (options.readonly == true) {
      readonly = ' readonly="readonly"';
    }

    $label = $(`<label>${options.label}</label>`);
    $input = $(`<input name="${options.name}" class="form-control" type="number" min="${options.min}" max="${options.max}" step="${options.step}" placeholder="${options.placeholder}" autocomplete="off"${value}${readonly} />`);

    $element.addClass('form-group form-group-' + options.name).append($label).append($input);

    this.$input = $input;

    return plugin;

  };

  this.setValue = (value) => {
    $input.val(value);
    $label.text(options.label_edit);
    $input.attr('placeholder', options.placeholder_edit);
  };

  this.resetValue = () => {
    $input.val('');
    $label.text(options.label);
    $input.attr('placeholder', options.placeholder);
  };

  return this.initialize();
};


