const propicons = {
  phone: 'fas fa-phone',
  phone_mobile: 'fas fa-phone',
  phone_private: 'fas fa-phone',
  fax: 'fas fa-phone',
  email: 'fas fa-at',
  website: 'fas fa-wifi',
  description: 'fas fa-newspaper',
  description_important: 'fas fa-newspaper',
  status: 'fas fa-compass',
  users: 'fas fa-user-friends',
  employments: 'fas fa-tasks',
  position: 'fas fa-user-tie',
};

export default propicons;
