import api from '../api';
import media from '../media';
import time from '../time';
import helper from '../helper';

$.fn.uiProcessSettings = function(options) {

  // default options
  let plugin = this;
  let $element = $(this);

  options = $.extend({},{
    table: false,
    optionButtons: []
  }, options);

  plugin.initAdminScaffold = () => {

    let fields = {
      'color': 'Colorpicker',
      'name': 'Text',
      'description': 'Textarea'
    };

    if (options.additional_fields !== undefined) {
      for (const [key, value] of Object.entries(options.additional_fields)) {
        fields[key] = value;
      }
    }

    $element.uiDataTable({
      class: '',
      add: false,
      edit: false,
      delete: false,
      hide_search: true,
      optionButtons: [{
        name:'info',
        icon:'fas fa-info',
        click: (id, $btn, $tr, ev) => {

          ev.preventDefault();
          console.log(id);

          let $icon = $btn.find('.fa-info');

          $icon.removeClass('fa-info').addClass('fa-sync-alt fa-spin');

          api.get('/resource/get/jobstatus_history/' + id, {
            complete: () => {
              $icon.removeClass('fa-sync-alt fa-spin').addClass('fa-info');
            },
            success: (jh) => {

              let title = 'Info';
              let $content = $('<div></div>');

              if (!helper.empty(jh.account_manager)) {
                let am = [];

                jh.account_manager.forEach((item) => {
                  am.push(item.firstname + ' ' + item.lastname);
                });

                $content.append(`
                    <div>
                        <strong>Account-Manager</strong>
                        <p class="text-muted">${am.join(', ')}</p>
                    </div>
                `);
              }

              $content.append(`
                  <div>
                      <strong>Quelle</strong>
                      <p class="text-muted">${jh.quelle}</p>
                  </div>
              `);

              let historie = [];
              historie.push(`<strong>Gesendet am: </strong>${jh.jobstatus_history.gesendet_am}`);
              historie.push(`<strong>1. Gespräch: </strong>${jh.jobstatus_history.gespraech_1}`);
              historie.push(`<strong>2. Gespräch: </strong>${jh.jobstatus_history.gespraech_2}`);
              historie.push(`<strong>Finales Gespräch: </strong>${jh.jobstatus_history.gespraech_final}`);
              historie.push(`<strong>Vertrag: </strong>${jh.jobstatus_history.gespraech_vertrag}`);

              $content.append(`
                  <div>
                      <strong>Historie</strong>
                      <p class="text-muted">${historie.join('<br />')}</p>
                  </div>
              `);

              $content.append(`
                  <div>
                      <strong>Tage im Prozess</strong>
                      <p class="text-muted">${jh.days_in_process}</p>
                  </div>
              `);

              $btn.popover({
                title: title,
                content: $content,
                placement: 'left',
                html: true,
                trigger: 'focus',
              });
              $btn.popover('show');
            }
          });

        }
      }],
      default_order: [2, 'desc'],
      i18n_prefix: 'candidate.',
      cols: [
        {name: 'id', text:'#', width: 60, hidden: true},
        {text:t('candidate.avatar'),name:'avatar', width: 30},
        {name:'name', text: 'Name'},
        {name:'job', text: 'Job'},
        {name: 'created_at', text: 'Datum', width: 60},
      ],
      render:{
        'id': (obj) => {
          return '<b>' + obj.id + '</b>';
        },
        'avatar': (obj) => {
          return '<div><a href="/candidate/' + obj.candidate.id + '" class="router-link"><img alt="Avatar" class="table-avatar" src="' + media.image(obj.avatar, '40x40', '/img/avatar5.png') + '" /></a></div>';
        },
        'name': (obj) => {
          return `<a href="/candidate/${obj.candidate.id}" class="router-link text-bold">${obj.candidate.name}</a>`;
        },
        'job': (obj) => {
          return `<a href="https://jungwild.io/jobs/${obj.job.slug}-${obj.id}" target="_blank" class="text-bold" data-slug="${obj.job.slug}">${obj.job.title}</a><br /><small>${obj.job.organisation}</small>`;
        },
        'created_at': (obj) => {
          return '<span style="display:none;">' + obj.created_at + '</span><strong>' + time.md(obj.created_at) + '</strong><br /><small>' + time.time(obj.created_at) + '</small>';
        }
      },
      table: ['jobstatus_history', 'jobstatus_history'],
      fkey_id: options.jobstatus_id,
      fields: {
        'firstname': 'Text',
        'lastname': 'Text'
      }
    });

    plugin.$element = $element;
  };

  this.initialize = () => {

    //plugin.initNewStatus();
    plugin.initAdminScaffold();

    return plugin;
  };

  return this.initialize();

};
