import media from '../media';
import api from '../api';
import helper from '../helper';

$.fn.uiCardCandidateInfos = function(options) {
  // support multiple elements
  if (this.length > 1) {
    this.each(() => { $(this).uiCardAvatar(options) });
    return this;
  }

  // default options

  options = $.extend({},{
    title: t('c.contact'),
    collapse: false
  }, options);

  let plugin = this;
  let statuslist = {};

  let $element = $(this);

  let $contact, $location, $skills, $notes, has_contact_info;

  let $quitting_info = $('<div></div>');

  // ...

  plugin.renderContact = () => {

    has_contact_info = false;

    $contact = $('<div></div>');

    if(!helper.empty(options.candidate.email)) {
      has_contact_info = true;
      $contact.append(`<div><strong><i class="fas fa-at mr-1"></i> E-Mail Adresse</strong><p class="text-muted"><a href="mailto:${options.candidate.email}">${options.candidate.email}</p></div>`);
    }

    if(!helper.empty(options.candidate.phone) || !helper.empty(options.candidate.phone_mobile)) {
      if(!helper.empty(options.candidate.phone)) {
        has_contact_info = true;
        $contact.append(`<div><strong><i class="fas fa-phone mr-1"></i> Telefon</strong><p class="text-muted"><a href="tel:${options.candidate.phone}">${options.candidate.phone}</a></p></div>`);
      }

      if(!helper.empty(options.candidate.phone_mobile)) {
        has_contact_info = true;
        $contact.append(`<div><strong><i class="fas fa-phone mr-1"></i> Mobil</strong><p class="text-muted"><a href="tel:${options.candidate.phone_mobile}">${options.candidate.phone_mobile}</a></p></div>`);
      }
    }
  };

  plugin.renderLocation = () => {

    let location_parts = [];

    if(!helper.empty(options.candidate.address)) {
      if(options.candidate.address.city) {
        location_parts.push(options.candidate.address.city);
      }
      if(options.candidate.address.state && options.candidate.address.city !== options.candidate.address.state) {
        location_parts.push(options.candidate.address.state);
      }
      else if(options.candidate.address.country) {
        location_parts.push(options.candidate.address.country);
      }

      $location = $('<div><strong><i class="fas fa-map-marker-alt mr-1"></i> Standort</strong><p class="text-muted">' + location_parts.join(', ') + '</p></div>');
    }
  };

  plugin.renderNotes = () => {
    if(!helper.empty(options.candidate.notes)) {
      $notes = $('<div><strong><i class="fas fa-pencil-alt mr-1"></i> Sonstige Notizen</strong><p class="text-muted">' + helper.nl2br(options.candidate.notes) + '</p></div>');
    }
  };

  plugin.renderQuittingInfo = () => {
    let quitting = this.formatQuitting();
    let avail = this.formatAvailable();
    if(!helper.empty(quitting)) {
      $quitting_info.append($('<div><strong><i class="fas fa-calendar-alt mr-1"></i> Kündigungsfrist</strong><p class="text-muted">' + this.formatQuitting() + '</p></div>'));
    }
    if(!helper.empty(avail)) {
      $quitting_info.append($('<div><strong><i class="fas fa-calendar-alt mr-1"></i> Verfügbar ab</strong><p class="text-muted">' + this.formatAvailable() + '</p></div>'));
    }
  };

  /**
   * Formats the quitting timespan to a string
   */
  this.formatQuitting = () => {

    // quitting period not set
    if (options.candidate.quitting_info.quitting_amount == null || options.candidate.quitting_info.quitting_period == null || options.candidate.quitting_info.quitting_span == null ||
        options.candidate.quitting_info.quitting_amount == 0 || options.candidate.quitting_info.quitting_period == 0 || options.candidate.quitting_info.quitting_span == 0) {
      return '';
    }

    // build quitting period string
    let quitting = options.candidate.quitting_info.quitting_amount;

    if (options.candidate.quitting_info.quitting_period == 1) {
      quitting += ' Woche(n)';
    }
    else if (options.candidate.quitting_info.quitting_period == 4) {
      quitting += ' Monat(e)';
    }

    quitting += ' zum';

    if (options.candidate.quitting_info.quitting_span == 1) {
      quitting += ' Monatsende';
    }
    else if (options.candidate.quitting_info.quitting_span == 3) {
      quitting += ' Quartalsende';
    }
    else if (options.candidate.quitting_info.quitting_span == 6) {
      quitting += ' Halbjahr';
    }

    return quitting;
  };

  this.formatAvailable = () => {
    let available = options.candidate.free_after;

    if (options.candidate.free_after == null) {
      available = '';
    }

    if (options.candidate.quitting_date_immediatly == 1) {
      available = 'ab sofort';
    }

    return available;
  }

  this.initialize = () => {
    $element.uiCard({
      header: false
    });

    plugin.renderLocation();
    plugin.renderNotes();
    plugin.renderContact();
    plugin.renderQuittingInfo();

    if(has_contact_info) {
      $element.$body.append($contact);
      $element.$body.append('<hr />');
    }

    if($location) {
      $element.$body.append($location);
      $element.$body.append('<hr />');
    }

    if($quitting_info) {
      $element.$body.append($quitting_info);
      $element.$body.append('<hr />');
    }
    if($notes) {
      $element.$body.append($notes);
      $element.$body.append('<hr />');
    }

    $element.$body.find('hr').last().remove();

    return plugin;

  };

  return this.initialize();
};

