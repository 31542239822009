import app from '../../app/app';;
import api from '../../app/api';;
import helper from '../../app/helper';;
import msg from '../../app/msg';

const fulltext_search = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row" id="fulltext-search-results"> </div> <!-- /.row --></div><!-- /.container-fluid -->'; return out;
    },
    js: function(it) {
            app.setTitle('Volltext-Suche (Alpha)'); app.setBreadcrumps({ 'Volltext-Suche': '/fulltext-search' }); let queryString = window.location.search; let params = new URLSearchParams(queryString); let search = params.get('s'); if (!helper.empty(search)) { api.get('/search/fulltext/' + encodeURIComponent(search), { success: (res) => { msg.success('g.success'); if (res.length > 0) { res.forEach((entry) => { let $card = $(` <div class="col-md-6"> <div class="card"> <div class="card-header"> <h3 class="card-title"><a href="/candidate/${entry.candidate.id}" target="_blank">${entry.candidate.name}</a></h3> </div> <div class="card-body" id="fulltext-search-result-${entry.candidate.id}"> </div> </div> </div>`); if (entry.hits.length > 0) { entry.hits.forEach((hit) => { let $list = $('<ul class="fulltext-search-result-context"></ul>'); $list.append(`<li><h1>${hit.path}</h1></li>`); if (hit.context !== null && typeof hit.context === 'object') { for (const [key, value] of Object.entries(hit.context)) { $list.append(`<li><strong>${key}</strong>: ${value}</li>`); } } else { $list.append(`<li>${hit.context}</li>`); } $card.find('#fulltext-search-result-'+entry.candidate.id).append($list); }); } $('#fulltext-search-results').append($card); }); } }, error: () => { msg.error('g.error'); } }); }
    }
};

export default fulltext_search;
