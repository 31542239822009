import button_save from '../../_compiled/components/button_save';

$.fn.uiIconBox = function(options) {
  // support multiple elements
  if (this.length > 1){
    this.each(() => { $(this).uiCard(options) });
    return this;
  }

  // default options

  options = $.extend({},{
    title: '',
    text: '',
    icon: 'far fa-star',
    color: 'info'
  }, options);

  let $icon_wrapper;
  let $icon;
  let $body_wrapper;
  let $title;
  let $text;

  // private variables


  let plugin = this;

  let $element = $(this);
  // ...

  plugin.setColor = (color) => {
    $icon_wrapper.removeClass();
    $icon_wrapper.addClass('info-box-icon bg-' + color);
  };

  plugin.setColorSuccess = () => {
    plugin.setColor('success');
  };

  plugin.setColorDanger = () => {
    plugin.setColor('danger');
  };

  // public methods
  this.initialize = () => {

    $element.addClass('info-box');

    $icon_wrapper = $(`<span class="info-box-icon bg-` + options.color + `"></span>`);
    $icon = $('<i></i>');
    $icon.addClass(options.icon);
    $icon_wrapper.append($icon);

    $element.append($icon_wrapper);

    $body_wrapper = $('<div class="info-box-content"></div>');
    $title = $('<span class="info-box-text"></span>');
    $text = $('<span class="info-box-number"></span>');

    $body_wrapper.append($title);
    $body_wrapper.append($text);

    $title.text(options.title);
    $text.text(options.text);

    $element.append($body_wrapper);


    plugin.$icon = $icon;
    plugin.$title = $title;
    plugin.$text = $text;

    return plugin;
  };

  return this.initialize();
};


