let media = {
  image: (img, process, fallback) => {

    if(img) {
      if(img.substring(0,1) !== '/') {
        img = '/' + img;
      }

      return '/storage' + img;
    }

    if(fallback !== undefined) {
      return fallback;
    }

    return null;

    //return '/media/' + process + img;
    //return '/img/avatar.png';
  },

  getBase64Image: (src, callback, outputFormat) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      let dataURL;
      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth;
      ctx.drawImage(img, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
    };

    img.src = src;
    if (img.complete || img.complete === undefined) {
      img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      img.src = src;
    }
  }
};

export default media;
