import api from '../api';
import session from "../session";

$.fn.uiFormMultiselect = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label, $label_wrapper;
  let $input;
  let $input_group;
  let $btn_add;
  let select2_options;

  options = $.extend({},{
    i18n_prefix: '',
    filterValue: (obj) => {
      return obj.name;
    }
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);

  if(options.fieldname === undefined) {
    options.fieldname = options.name;
  }

  let plugin = this;
  let url;
  let listing = null;

  // public methods
  this.initialize = () => {

    $btn_add = $('<a href="#" class="btn btn-default ml-3 btn-xs">+</a>');
    $label = $('<span>' + options.label + '</span>');
    $label_wrapper = $('<label></label>');

    $input = $('<select name="' + options.name + '[]" class="form-control"><option></option></select>');

    $input_group = $('<div class="input-group"></div>');
    $input_group.append($input);

    $label_wrapper.append($label);
    $label_wrapper.append($btn_add);


    this.initListing($input);
    $input.select2(select2_options);


    $element.addClass('vertical-input-group');
    $element.addClass('form-group form-group-'+options.name).append($label_wrapper).append($input_group);

    $btn_add.click(() => {
      let $new_input_group = $('<div class="input-group input-group-additional"></div>');
      let $new_input = $('<select name="' + options.name + '[]" class="form-control"><option></option></select>');

      this.initListing($new_input);

      $new_input_group.append($new_input);
      $new_input.select2(select2_options);

      $element.append($new_input_group);
    });

    //this.$input = $input;

    return plugin;

  };

  this.setValue = (value) => {

    $element.find('.input-group-additional').remove();

    if(Array.isArray(value)) {
      $element.find('.input-group').remove();
      value.forEach((item) => {
        plugin.addItem( item );
      });
    }

    $label.text(options.label_edit);
    $input.attr('placeholder', options.placeholder_edit);
  };

  this.resetValue = () => {
    $input.val('');
    $element.find('.input-group-additional').remove();
    $label.text(options.label);
    $input.attr('placeholder', options.placeholder);
  };

  this.addItem = (item) => {

    let $new_input_group = $('<div class="input-group input-group-additional"></div>');
    let $new_input = $('<select name="' + options.name + '[]" class="form-control"><option></option></select>');

    $new_input_group.append($new_input);
    $new_input.select2(select2_options);

    var newOption = new Option(item.name, item.id, true, true);
    $new_input.append(newOption).trigger('change');

    $element.append($new_input_group);
  };

  this.initListing = ($input) => {

    if(options.table !== undefined && !options.ajax) {
      url = '/resource/select/' + options.table;

      if (options.limit && options.limit !== undefined) {
        url += '/' + options.limit.table + '/' + options.limit.value;
      }

      api.get(url, {
        success: (response) => {

          let listing = response.listing;
          if(listing === undefined) {
            listing = response;
          }

          $.each(listing, (i, obj) => {

            let text = obj.name;
            if(text === undefined) {
              text = obj.title;
            }

            $input.append('<option value="' + obj.id + '">' + text + '</option>');
          });

          if(options.success !== undefined) {
            options.success(response);
          }
        }
      });
    }

    select2_options = {
      theme: 'bootstrap4',
      language: 'de',
      placeholder: options.placeholder,
      allowClear: true
    };

    if(options.ajax) {
      select2_options.ajax = {
        url: '/api/v1/resource/select2/' + options.table,
        delay: 200,
        dataType: 'json',
        headers: {
          'Authorization' : 'Bearer ' + session.getToken(),
        },
        processResults: (data) => {
          return {
            results: data
          };
        }
      };
    };

  };

  return this.initialize();
};
