import i18n from '../../tmp/i18n.json';

let language = 'de';

const t = (key, vars) => {


  if(i18n[language][key] === undefined) {
    return key;
  }

  if(vars !== undefined) {
    let out = i18n[language][key];
    Object.keys(vars).forEach(key => {
      out = out.replace('{{' + key + '}}', vars[key]);
    });
    return out;
  }

  return i18n[language][key];
};

window.t = t;
export default t;
