const candidate = {
  name: 'Name',
  avatar: 'Bild',
  add_candidate: 'Kandidat hinzufügen',
  edit_candidate: 'Kandidat bearbeiten',

  create_job_dossier: 'Dossier erstellen',
  create_dossier: 'Dossier erstellen',

  create_application_info: 'Stelleinfos anzeigen',

  lb_cvupload: 'Kandidat durch Lebenslauf-Upload erstellen',
  ph_cvupload: '',

  lb_title: 'Titel',
  ph_title: 'Titel',

  lb_firstname: 'Vorname',
  ph_firstname: 'Vorname',

  lb_lastname: 'Nachname',
  ph_lastname: 'Nachname',

  lb_phone: 'Telefon',
  ph_phone: '+49 123 456',

  lb_email: 'E-Mail Adresse',
  ph_email: 'peter@lustig.de',

  lb_emails: 'Zusätzliche E-Mail Adressen',
  ph_emails: '',

  delete_confirm: 'Kanditat wirklich entfernen?',

  lb_job_id: 'Job',
  lb_job: 'Job',
  lb_status: 'Status',
  ph_job: '',

  lb_employed: 'z.Z. angestellt',
  lb_notes: 'Bemerkungen',
  ph_notes: '',

  lb_platform: 'Platformen',
  ph_platform: 'LinkedIn, Xing, etc.',

  job_options: 'Job Optionen',
  job_matching: 'Job Matchings',
  use_job_match: 'als Job übernehmen',

  lb_salary_min: 'Mindestfixgehalt',
  lb_salary_max: 'Gehaltswunsch',
  lb_salary: 'max. Gehalt',

  ph_salary_min: 'Jahresgehalt z.B. 55000',
  ph_salary_max: 'Jahresgehalt z.B. 55000',
  ph_salary: '',

  lb_hourly_rate: 'max. Stundensatz',
  ph_hourly_rate: '',

  lb_free_after: 'Verfügbar ab',
  lb_employed_until: 'Kündigungsfrist',
  ph_free_after: 'YYYY-MM-DD',
  ph_employed_until: 'YYYY-MM-DD',

  lb_submitted: '<i class="far fa-save"></i>&nbsp; Änderungen speichern',
  lb_city: 'Wohnort',
  ph_city: 'Hamburg, Berlin oder Köln',

  lb_status_date: 'Job-Status Datum',
  ph_status_date: '',

  lb_notes: 'Sonstige Notizen',
  ph_notes: '',
  ph_search: 'Name, E-Mail, Telefon...',

  lb_jobstatus: 'Job-Status',
  lb_first_comment: 'Kommentar',
  ph_first_comment: '',
  lb_candidatestatus: 'Status',
  ph_candidatestatus: '',
  lb_employments: 'Anstellungsart',
  ph_employments: '',
  lb_date: 'Zeitraum',
  ph_date: '',

  lb_recruiter: 'Recruiter*innen',
  ph_recruiter: '',

  lb_organisation: 'Unternehmen',
  ph_organisation: '',
  lb_fjobstatus: 'Job-Status (aktuell)',
  ph_fjobstatus: '',

  lb_quitting_info: 'Kündigungsfrist',
  ph_quitting_info: '',

  lb_birthday: 'Geburtstag',
  ph_birthday: 'YYYY-MM-DD',

  lb_birthcity: 'Geburtsort',
  ph_birthcity: 'Geburtsort',

  lb_quitting_date_immediatly: 'ab sofort',
  ph_quitting_date_immediatly: '',

  lb_workexperience: 'Relevante Berufserfahrung',
  ph_workexperience: '',

  lb_graduation: 'Höchster Abschluss',
  ph_graduation: '',

  lb_evaluations: 'Ersteinschätzungen',
  ph_evaluations: '',

  lb_evaluations_label: 'Name',
  ph_evaluations_label: '',

  lb_evaluations_salary: 'Gehaltswunsch inkl. aller Komponenten',
  ph_evaluations_salary: '',

  lb_evaluations_hourly_rate: 'Stundensatz',
  ph_evaluations_hourly_rate: '',

  lb_evaluations_evaluation: 'Ersteinschätzung',
  evaluation_last_modified: 'zuletzt bearbeitet am',
  evaluation_delete: 'Ersteinschätzung wirklich löschen?',

  task_add_confirm: 'Möchtest Du eine Aufgabe für die Wiedervorlage anlegen?',
  task_add_entry: 'Aufgabe hinzufügen',
  task_add_lb_name: 'Name der Aufgabe',
  task_add_ph_name: '',
  task_add_lb_due: 'Fällig am',
  task_add_ph_due: '',
  task_add_lb_description: 'Beschreibung',
  task_add_ph_description: '',
  task_add_lb_job: 'Job',
  task_add_ph_job: '',
  ph_quitting_date_immediatly: '',

  lb_tags: 'Tags',
  ph_tags: '',
  ph_tag: '',

  lb_submit: 'Speichern',
  ph_submit: '',

  lb_brutto: 'Jahresbruttogehalt',
  ph_brutto: '',
  lb_provision: 'Provision in %',
  ph_provision: '',
  lb_mwst: 'MwSt. in %',
  ph_mwst: '',
  lb_payment_amount: 'eingegangene Zahlung',
  ph_payment_amount: '',
  lb_payment_date: 'Datum ZE',
  ph_payment_date: '',
  lb_payment_due: 'fällig am',
  ph_payment_due: '',
  lb_start_date: 'Einstiegsdatum',
  ph_start_date: '',
  lb_probation_date: 'Probezeit bis',
  ph_probation_date: '',
  edit_candidateprocess: 'Abrechnungsinformationen',

  appinfo_next: 'weiter',

  status_id_outofbounds: 'Ungültiger Status',
  firstname_required: 'Vorname ist ein Pflichtfeld',
  firstname_alpha: 'Vorname enthält ungültige Zeichen',
  firstname_stringlengthmin: 'Vorname ist zu kurz',
  firstname_stringlengthmax: 'Vorname ist zu lang',
  lastname_required: 'Nachname ist ein Pflichtfeld',
  lastname_alpha: 'Nachname enthält ungültige Zeichen',
  lastname_stringlengthmin: 'Nachname ist zu kurz',
  lastname_stringlengthmax: 'Nachname ist zu lang',
  email_email: 'Ungültige E-Mail Adresse',
  email_exists: 'Ein anderer Kandidat hat bereits diese E-Mail Adresse',
  email_stringlengthmax: 'E-Mail Adresse ist zu lang',
  phone_stringlengthmax: 'Telefon ist zu lang',
  city_stringlengthmin: 'Stadt ist zu kurz',
  city_stringlengthmax: 'Stadt ist zu lang',
  salary_min_numeric: 'Gehaltswunsch min. muss ein Zahlenwert sein',
  salary_max_numeric: 'Gehaltswunsch max. muss ein Zahlenwert sein',
  employed_between: 'Ungültiger Wert',
  job_id_outofbounds: 'Ungültiger Job',
  jobstatus_id_outofbounds: 'Ungültiger Status',
  job_jobstatus_missing: 'Job-Status muss gesetzt sein',
  job_status_date_missing: 'Datum für Job-Status muss gesetzt sein',
  first_comment_stringlengthmax: 'Kommentar ist zu lang',
  quitting_info_callback: 'Unvollständige Kündigungsfrist',
  quitting_date_immediatly_between: 'Ungültiger Wert',
  free_after_datevalidator: 'Falsches Format (YYYY-MM-DD nötig)',
  notes_stringlengthmin: 'Sonstige Notizen sind zu kurz',
  notes_stringlengthmax: 'Sonstige Notizen sind zu lang',
  tags_outofbounds: 'Ungültige Tags',

  lb_social_url: 'Social URL (Xing, LinkedIn etc.)',
  ph_social_url: 'https://...',

  lb_cover: 'Deckblatt anzeigen',
  lb_style_jw: 'CV im Jungwild Stil',
  lb_anonym: 'CV anonymisieren',
  lb_skills_pdf: 'Folgende Kenntnisse & Fähigkeiten in PDF übernehmen',

  lb_evaluation: 'Berufliche Einschätzung',
  ph_evaluation: '',

  lb_appinfo_evaluation: 'Berufliche Einschätzung',
  ph_appinfo_evaluation: '',

  lb_files: 'Folgende Dokumente an das PDF anhängen',

  tasks: 'Aufgaben',
  lb_jobpool: 'Job-Pool',
  ph_jobpool: '',
  optin_send_success: 'Opt-In Mail wurde erfolgreich versendet',
  lb_address: 'Adresse',

  optin_no_mail: 'Keine E-Mail Adresse für Optin vorhanden',
  statusmail_no_mail: 'Keine E-Mail Adresse für Status E-Mail vorhanden',
  statusmail_send_success: 'Status E-Mail wurde erfolgreich versendet',

  duplicate: 'Mögliches Duplikat',
  duplicate_info: 'Info',
  lb_is_no_duplicate: '',
  is_no_duplicate: 'Kandidat ist kein Duplikat',

  lb_radius: 'Umkreissuche',
  lb_phone_mobile: 'Mobil',
  ph_phone_mobile: '+49 123 456',
  lb_first_source: 'Ursprüngliche Bewerberquelle',
  choose_application_source: 'Touchpoint auswählen...',
  lb_mailing_blacklisted: 'Vom Mail-Sync ausschließen',

  cvcheck: 'Der Historie des Kandidaten wird der Eintrag "CV-Check durchgeführt" hinzugefügt',
  cvcheck_done: 'Der CV-Check wurde für diesen Kandidaten bereits durchgeführt',
  follow_success: 'Du folgst jetzt diesem Kandidaten.',
  unfollow_success: 'Du folgst diesem Kandidaten jetzt nicht mehr.',
  favorite_success: 'Der Kandidat ist jetzt als Favorit markiert',
  unfavorite_success: 'Der Kandidat ist jetzt kein Favorit mehr',

  lb_favorite: 'als Favorit markiert',
  lb_jobstatus_history: 'Job-Status (historisch)',
  ph_jobstatus_history: '',
  lb_cv_file: 'Nur Kandidaten mit vorhandenem CV?',
  lb_search_combo: 'Suche in allen Informationen',
  ph_search_combo: 'Finance AND (Controlling OR Controller) +SAP -Steuer...',

  rename_file: 'Datei umbenennen',
  lb_filename: 'Dateiname',
  ph_filename: '',

  title_process: 'Kandidaten im Prozess'
};

exports.candidate = candidate;
