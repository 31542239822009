import app from '../../app/app';;
import api from '../../app/api';;
import session from '../../app/session';;
import time from '../../app/time';;
import routes from '../../app/routes';;
import helper from '../../app/helper';

const job = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-3"> <div id="google-status"></div> <!-- Passende Jobs (Beta) --> <div class="card"> <div class="card-header"> <h3 class="card-title">'+(t('job.candidate_matching'))+'</h3> </div> <!-- /.card-header --> <div class="card-body" id="job-candidatematching"> </div> <!-- /.card-body --> </div> </div> <!-- /.col --> <div class="col-md-9"> <div class="card"> <div class="card-header p-2"> <h3 class="card-title" id="job-title"></h3> </div> <div class="card-body" id="job-details" style="min-height: 100px"> <!-- /.tab-content --> </div><!-- /.card-body --> </div> <!-- /.nav-tabs-custom --> </div> <!-- /.col --> </div> <!-- /.row --></div><!-- /.container-fluid -->'; return out;
    },
    js: function(it) {
              app.setTitle('Job'); app.setBreadcrumps({ 'Recruiting': '/recruiting', 'Job': '/recruiting/job/' + it.job_id }); api.get('/jobs/' + it.job_id, { success: (job) => { $('#job-details').uiJobDetails({ job: job });  $('#job-candidatematching').uiJobCandidatematching({ job: job });  let $googleStatus = $('#google-status').uiCard({ title: 'Google for Jobs' }); let $pre = $('<pre></pre>'); let $codeOutput = $('<code></code>'); $pre.append($codeOutput); let $btnStatus = $('<button class="btn-xs btn btn-default w-100">Status</button>'); let $btnAdd = $('<button class="btn-xs btn btn-secondary w-100">Update</button>'); let $btnDelete = $('<button class="btn-xs btn btn-danger w-100">Löschen</button>'); let $btns = $('<div class="btn-group d-flex"></div>'); $btns.append($btnStatus); $btns.append($btnAdd); $btns.append($btnDelete); $googleStatus.$body.addClass('p-0'); $googleStatus.$body.append($pre); $googleStatus.$footer.append($btns); $googleStatus.$footer.addClass('p-0'); $googleStatus.$footer.show(); $btnStatus.click(() => { $googleStatus.showLoading(); api.get('/jobs/google/status/' + job.id,{ success: (res) => { $codeOutput.text(JSON.stringify(res.google_response, null, 2)); $googleStatus.hideLoading(); } }) }); $btnAdd.click(() => { $googleStatus.showLoading(); api.get('/jobs/google/add/' + job.id,{ success: (res) => { $codeOutput.text(JSON.stringify(res.google_response, null, 2)); $googleStatus.hideLoading(); } }) }); $btnDelete.click(() => { $googleStatus.showLoading(); api.get('/jobs/google/delete/' + job.id,{ success: (res) => { $codeOutput.text(JSON.stringify(res.google_response, null, 2)); $googleStatus.hideLoading(); } }) }); } });
    }
};

export default job;
