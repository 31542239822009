import api from '../api';
import session from "../session";

$.fn.uiFormSelect2 = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $input;
  let url;

  options = $.extend({},{
    i18n_prefix: '',
    wrapper: true,
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);

  if(options.fieldname === undefined) {
    options.fieldname = options.name;
  }

  let plugin = this;

  this.showError = (key, value) => {
    $element.find('.select2-selection--single').addClass('is-invalid-important');
    $element.append(`<span class="error invalid-feedback">${t(value)}</span>`);
  };

  // public methods
  this.initialize = () => {

    $label = $('<label>' + options.label + '</label>');

    if (options.allowInput) {
      $input = $('<select name="' + options.fieldname + '" id="select2-' + options.name + '" class="form-control"><option></option></select>');
    }
    else {
      $input = $('<select name="' + options.name + '_id" id="select2-' + options.name + '_id" class="form-control"><option></option></select>');
    }

    if (options.class && options.class !== undefined) {
      $input.addClass(options.class);
    }

    if(options.table !== undefined && !options.ajax) {
      url = '/resource/select/' + options.table;

      if (options.limit && options.limit !== undefined) {
        url += '/' + options.limit.table + '/' + options.limit.value;
      }

      api.get(url, {
        success: (response) => {

          let listing = response.listing;
          if(listing === undefined) {
            listing = response;
          }

          $.each(listing, (i, obj) => {

            let text = obj.name;
            if(text === undefined) {
              text = obj.title;
            }

            $input.append('<option value="' + obj.id + '">' + text + '</option>');
          });

          if(options.success !== undefined) {
            options.success(response);
          }
        }
      });
    }

    if(options.wrapper) {
      $element.addClass('form-group form-group-' + options.name).append($label).append($input);
    }
    else {
      $element.append($input);
    }

    let select2_options = {
      theme: 'bootstrap4',
      language: 'de',
      placeholder: options.placeholder,
      allowClear: true
    };

    if(options.ajax) {
      select2_options.ajax = {
        url: '/api/v1/resource/select2/' + options.table,
        delay: 200,
        dataType: 'json',
        headers: {
          'Authorization' : 'Bearer ' + session.getToken(),
        },
        processResults: (data) => {
          return {
            results: data
          };
        }
      };
    };

    if (options.allowInput) {
      select2_options.tags = true;
    }

    $input.select2(select2_options);

    /* onChange */
    if (options.onChange && options.onChange !== undefined) {
      $input.on('select2:select', (e) => {
        let data = e.params.data;
        options.onChange(data);
      });
    }

    /* onClear */
    if (options.onClear && options.onClear !== undefined) {
      $input.on('select2:clear', (e) => {
        let data = e.params.data;
        options.onClear(data);
      });
    }

    this.$input = $input;

    if(options.subtitle) {
      let $subtitle = $('<small class="form-text text-muted">'+options.subtitle+'</small>');
      $element.append($subtitle);
    }

    return plugin;

  };

  this.setValue = (value) => {
    if (value === null) {
      plugin.resetValue();
    }
    else {

      if (value) {

        if (typeof value === 'string') {
          var newOption = new Option(value, value, true, true);
          $input.append(newOption).trigger('change');
          $input.prop('disabled', true);
          $element.append(`<input type="hidden" name="name" value="${value}" />`);
        }

        /* Status-Templates */
        else if (value.hasOwnProperty(0)) {
          if (Object.keys(value).length > 0) {
            $input.find('option').remove();
            $input.append('<option></option>');

            for (const [key, value] of Object.entries(value)) {
              $input.append(`<option value="${value.id}">${value.name}</option>`);

              if (value.selected && value.selected == true) {
                $input.val(value.id);
              }
            }

          }
        }
        else if (value.id) {
          // die option existiert bereits, also nicht erneut hinzufügen!
          if ($input.find("option[value='" + value.id + "']").length) {
            $input.val(value.id).trigger('change');
          }
          else {
            var newOption = new Option(value.name, value.id, true, true);
            $input.append(newOption).trigger('change');
          }

        }
      }

    }

  };

  this.resetValue = () => {
    $input.val('').trigger('change');
  };

  return this.initialize();
};


