import api from '../../app/api';
import msg from '../../app/msg';

$.fn.uiFavoriteButton = function(options) {
  
  // default options
  options = $.extend({},{
    repo: 'organisation',
    attr: 'is_favorite',
    value: 0
  }, options);

  // private variables
  let plugin = this;
  let $element = $(this);

  let $btn_favorite;
  let $btn_favorite_text;
  let $btn_favorite_icon;

  plugin.options = options;

  plugin.setFavorite = (favorite) => {

    if( !favorite ) {
      $btn_favorite_text.text(t('g.favorite'));
      $btn_favorite.removeClass('btn-outline-secondary').addClass('btn-danger');
      $btn_favorite_icon.removeClass('far').addClass('fas');
    }
    else {
      $btn_favorite_text.text(t('g.favorited'));
      $btn_favorite.removeClass('btn-danger').addClass('btn-outline-secondary');
      $btn_favorite_icon.removeClass('fas').addClass('far');
    }
  };

  plugin.initButton = () => {

    $btn_favorite = $('<button class="btn btn-danger btn-xs w-100"><i class="fas fa-star mr-2"></i><span>' + t('g.favorite') + '</span></button>');
    $btn_favorite_icon = $btn_favorite.find('i');
    $btn_favorite_text = $btn_favorite.find('span');

    plugin.setFavorite((plugin.options.value === 1)?true:false);

    $btn_favorite.on('click', (ev) => {

      ev.preventDefault();

      // set favorite
      if ($btn_favorite.hasClass('btn-danger')) {
        api.post(`/resource/update/attr/${plugin.options.repo}/${plugin.options.id}`, { 
          attr: plugin.options.attr,
          value: 1,
         }, {
          success: () => {
            plugin.setFavorite(true);
            msg.success(t('g.save_success'));
          },
          error: () => {
            msg.error(t('g.error'));
          }
        });
      }
      // unset favorite
      else if ($btn_favorite.hasClass('btn-outline-secondary')) {
        api.post(`/resource/update/attr/${plugin.options.repo}/${plugin.options.id}`, { 
          attr: plugin.options.attr,
          value: 0,
         }, {
          success: () => {
            plugin.setFavorite(false);
            msg.success(t('g.save_success'));
          },
          error: () => {
            msg.error(t('g.error'));
          }
        });
      }

    });

    $element.append($btn_favorite);

  };


  // public methods
  this.initialize = () => {

    plugin.initButton();

    return plugin;
  };

  return this.initialize();
};
