import api from '../api';

$.fn.uiFormAdditionaltitle = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $input;
  options = $.extend({},{
    i18n_prefix: '',
    default: '',
    name: 'additional'
  }, options);

  let plugin = this;

  // public methods
  this.initialize = () => {

    $input = $('<input class="mr-2" type="checkbox" name="' + options.name + '" value="1" />');
    $label = $('<label class="cursor-pointer">' + options.label + '</label>');

    $label.prepend($input);
    $element.addClass('form-group form-group-' + options.name).append($label);

    $input.change(() => {
      if($input[0].checked) {
        $element.parent().find('.is-additional').removeClass('is-hidden');
      }
      else {
        $element.parent().find('.is-additional').addClass('is-hidden');
      }
    });

    this.$input = $input;

    return plugin;

  };

  this.setValue = (value) => {

  };

  this.resetValue = () => {

  };

  return this.initialize();
};


