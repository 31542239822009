import media from '../media';
import api from '../api';
import msg from '../msg';
import helper from '../helper';
import candidate_ctrl from '../candidate_ctrl';
import time from '../time';
import routes from '../routes';

$.fn.uiCandidateDetails = function(options) {

  // default options
  let plugin = this;
  let $element = $(this);
  let autosave = null;
  let autosave_mailchange = false;
  let data_old = null;
  let submit_form = false;
  let session_datetime = Date.now();
  let $loader = $('<div class="overlay"><i class="fas fa-2x fa-sync-alt fa-spin"></i></div>');

  $loader.hide();

  options = $.extend({}, {
    title: false,
    onLoading: () => {
    },
    onFinish: () => {
    }
  }, options);

  let $form;

  plugin.showLoading = () => {
    $loader.show();
  };

  plugin.hideLoading = () => {
    $loader.hide();
  };

  this.initForm = () => {
    $form = $('<form></form>').uiForm({
      beforeEdit: () => {
        plugin.showLoading();
      },

      success: () => {
        //msg.success(t('g.save_success'));
        //let $btn_submit = $element.find('[name="submitted"]');
        //let btn_submit_html = $btn_submit.html();

        setTimeout(() => {
          plugin.hideLoading();
          msg.success(t('g.save_success'));
        }, 500);
      },
      error: (res) => {
        plugin.hideLoading();

        if(res && res.responseJSON && res.responseJSON.error) {
          msg.error(t(res.responseJSON.error));
        } else if(res && res.responseJSON && res.responseJSON.errors){
          for (const key in res.responseJSON.errors) {
            if (res.responseJSON.errors.hasOwnProperty(key)) {
              msg.error(`${key} Error: ${res.responseJSON.errors[key].map(t).join(', ')}`);
            }
          }
        } else {
          msg.error(t('g.save_error'));
        }
      },
      url: '/candidate',
      i18n_prefix: 'candidate.',
      fields: {
        'firstname': 'Text',
        'lastname': 'Text',
        'birthday': {
          type: 'Date',
          drops: 'down'
        },
        'birthcity': 'Text',
        'social_url': 'Text',
        'email': 'Email',
        'emails': 'Multiemail',
        'phone': 'Phone',
        'phone_mobile': 'Phone',
        'address': 'Locationpicker',
        'first_source': {
          type: 'Select',
          table: 'touchpoint'
        },
        'employments': {
          name: 'employments',
          type: 'TagSelect',
          table: 'employment'
        },
        'employed': 'Switch',
        'quitting_info': {
          text: 'Kündigungsfrist',
          type: 'Quittinginfo'
        },
        'free_after': 'Date',
        'quitting_date_immediatly': 'Switch',
        'workexperience': 'Text',
        'evaluations': 'Multievaluation',
        'salary_min': {
          type: 'Number'
        },
        'notes': {
          type: 'Textarea',
          del_button: {
            show: true,
            table: 'candidate',
            attr: 'notes',
            id: options.candidate.id
          }
        },
        /*'tags': {
          type: 'TagSelect',
          table: {
            name: 'tag',
            type: 'job'
          }
        },*/
        'mailing_blacklisted': {
          type: 'Switch',
          name: 'mailing_blacklisted',
          acl: 'browse_agencysettings'
        },
        'submitted': 'Submit'
      },
      editSuccess: (data_new, data_old) => {
        if(data_new.email !== data_old.email) {
          if (autosave_mailchange) {
            candidate_ctrl.dialogOptIn(data_old.id, {
              body: 'Du hast die E-Mail Adresse von ' + data_new.firstname + ' ' + data_new.lastname + ' geändert, soll eine Opt-In Mail gesendet werden?',
              afterOptinSend: () => {
                options.afterOptinSend();
              }
            });
            autosave_mailchange = false;
          }
        }

        api.get('/candidate/getevaluations/' + options.candidate.id, {
          success: (retval) => {
            /*
             * Wenn wir evaluation ids zurückbekommen, dann fügen wir diese in die Ersteinschätzungen ein.
             * Das ist wichtig, damit eine neue Ersteinschätzung nicht bei jedem autosave neu angelegt wird!
             */
            if (retval.length > 0) {
              $form.find('.form-group-evaluations').find('input[name="evaluations_id[]"]').each((index, elem) => {
                $(elem).val(retval[index]["id"]);
              });
            }
          }
        });
        // Renew session datetime to make autosave active again on this screen
        session_datetime = Date.now();
        api.post(
          '/resource/update/attr/candidate/' + options.candidate.id,
          {
            attr: 'session',
            value : session_datetime
          }
        );

      }
    });

    $form.setValues(options.candidate);

    $element.append($form);

    api.post(
      '/resource/update/attr/candidate/' + options.candidate.id,
      {
        attr: 'session',
        value : session_datetime
      }
    );
  };

  plugin.refresh = () => {

    api.get('/candidate/' + options.candidate.id, {
      success: (response) => {
        $form.setValues(response);

        if (!helper.empty(response.evaluations)) {
          $form.getFormElement('evaluations').setValue(response.evaluations);
        }
      }
    });

  };

  this.saveOldFormData = () => {
    data_old = $form.serializeObject();

    data_old.address_wrapper = $element.find('.address-wrapper > p').text();

    data_old.quitting = {
      amount: $element.find('.quitting_amount').val(),
      period: $element.find('.quitting_period').val(),
      span: $element.find('.quitting_span').val()
    };

    let $additional_emails = $element.find('.form-group-emails').find('input[type="email"]');
    if ($additional_emails.length > 0) {
      data_old['email_addresses'] = {};

      $additional_emails.each((i, elem) => {
        data_old['email_addresses'][i] = $(elem).val();
      });
    }

  };

  this.autosave = () => {

    $('.candidate-profile').find('.nav-item-candidate-details').click(() => {
      this.saveOldFormData();

      autosave = setInterval(() => {

        for (const [key, value] of Object.entries(data_old)) {
          if (key == 'address' || key == 'address_wrapper') {
            if ($element.find('.address-wrapper > p').text() != data_old.address_wrapper) {
              submit_form = true;
            }
          }
          else if (key == 'quitting_info' || key == 'quitting') {
            if ($element.find('.quitting_amount').val() != data_old.quitting.amount ||
              $element.find('.quitting_period').val() != data_old.quitting.period ||
              $element.find('.quitting_span').val() != data_old.quitting.span) {
                submit_form = true;
            }
          }
          else if (key == 'emails' || key == 'email_addresses') {
            $element.find('.form-group-emails').find('input[type="email"]').each((i, elem) => {
              if (data_old.email_addresses[i] != $(elem).val()) {
                submit_form = true;
              }
            });
          }
          else if (key == 'evaluations_id') {
            $element.find('.form-group-evaluations').find('input[name="evaluations_id[]"]').each((i, elem) => {
              if (data_old.evaluations_id[i] != $(elem).val()) {
                submit_form = true;
              }
            });
          }
          else if (key == 'evaluations_evaluation') {
            $element.find('.form-group-evaluations_evaluation').find('input[type="hidden"]').each((i, elem) => {
              if (data_old.evaluations_evaluation[i] != $(elem).val()) {
                submit_form = true;
              }
            });
          }
          else if (key == 'evaluations_label') {
            $element.find('.form-group-evaluations').find('input[name="evaluations_label[]"]').each((i, elem) => {
              if (data_old.evaluations_label[i] != $(elem).val()) {
                submit_form = true;
              }
            });
          }
          else if (key == 'evaluations_salary') {
            $element.find('.form-group-evaluations_salary').find('input').each((i, elem) => {
              if (data_old.evaluations_salary[i] != $(elem).val()) {
                submit_form = true;
              }
            });
          }
          else if (key == 'evaluations_hourly_rate') {
            $element.find('.form-group-evaluations_hourly_rate').find('input').each((i, elem) => {
              if (data_old.evaluations_hourly_rate[i] != $(elem).val()) {
                submit_form = true;
              }
            });
          }
          else {
            if ($form.getInput(key).val() != value) {
              submit_form = true;

              if (key == 'email') {
                autosave_mailchange = true;
              }
            }
          }
        }

        if (submit_form) {
          this.saveOldFormData();
          //$form.submit();

          let data = $form.serializeObject();
          let evals = {};

          // wir speichern die Ersteinschätzungen separat und löschen sie aus den Formulardaten
          if (data.evaluations_evaluation.length > 0) {
            evals['evaluations_id'] = data.evaluations_id;
            delete data.evaluations_id;
          }
          if (data.evaluations_evaluation.length > 0) {
            evals['evaluations_evaluation'] = data.evaluations_evaluation;
            delete data.evaluations_evaluation;
          }
          if (data.evaluations_label.length > 0) {
            evals['evaluations_label'] = data.evaluations_label;
            delete data.evaluations_label;
          }
          if (data.evaluations_salary.length > 0) {
            evals['evaluations_salary'] = data.evaluations_salary;
            delete data.evaluations_salary;
          }
          if (data.evaluations_hourly_rate.length > 0) {
            evals['evaluations_hourly_rate'] = data.evaluations_hourly_rate;
            delete data.evaluations_hourly_rate;
          }

          data.session = session_datetime;

          // wir speichern die Daten des Kandidaten ohne Ersteinschätzung
          api.post('/resource/update/candidate/' + options.candidate.id, data, {
            success: (response) => {

              // und jetzt noch separat die Ersteinschätzung
              api.post('/candidate/evaluations', {
                evaluations: evals,
                candidate_id: options.candidate.id
              }, {
                success: (retval) => {
                  /*
                   * Wenn wir evaluation ids zurückbekommen, dann fügen wir diese in die Ersteinschätzungen ein.
                   * Das ist wichtig, damit eine neue Ersteinschätzung nicht bei jedem autosave neu angelegt wird!
                   */
                  if (retval.length > 0) {
                    $form.find('.form-group-evaluations').find('input[name="evaluations_id[]"]').each((index, elem) => {
                      $(elem).val(retval[index]);
                    })
                  }
                }
              });

            }
          });

        }

      }, 30000);

    });

    $('.nav-item').not('.nav-item-candidate-details').click(() => {
      clearInterval(autosave);
    });

  };

  this.initialize = () => {

    plugin.initForm();
    plugin.autosave();
    $element.parent().parent().parent().append($loader);

    return plugin;
  };

  return this.initialize();

};
