import api from '../api';

$.fn.uiFormColorpicker = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $input;
  let $colorCircle;

  options = $.extend({},{
    i18n_prefix: '',
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);


  let plugin = this;

  // public methods
  this.initialize = () => {
    $label = $('<label>' + options.label + '</label>');
    let $color = $('<i class="fas fa-circle hunter-color-show" style="font-size:1.7em; margin-left:10px; display:none; color:#000000"></i>');
    $input = $('<input name="' + options.name + '" class="form-control" type="text" placeholder="' + options.placeholder + '" />');
    $input = $('<input name="' + options.name + '" class="form-control" type="text" placeholder="' + options.placeholder + '" />');

    $element.addClass('form-group form-group-'+options.name).append($label).append($color).append($input);

    this.$input = $input;

    $input.colorpicker();

    $colorCircle = jQuery($element).find('.hunter-color-show');
    jQuery($element).find('input').on('change', function() {
      console.log("SDFSDF");
      console.log($(this).val());
      $colorCircle.css("color", $(this).val()).show();
    });

    return plugin;

  };

  this.setValue = (value) => {
    $input.val(value);
    $label.text(options.label_edit);
    $input.attr('placeholder', options.placeholder_edit);
    $colorCircle.css("color", value).show();
  };

  this.resetValue = () => {
    $input.val('');
    $label.text(options.label);
    $input.attr('placeholder', options.placeholder);
    $colorCircle.hide();
  };

  return this.initialize();
};


