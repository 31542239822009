import api from '../api';

$.fn.uiFormSubmit = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $input;

  options = $.extend({},{
    i18n_prefix: '',
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);


  let plugin = this;

  // public methods
  this.initialize = () => {

    $input = $('<button name="' + options.name + '" class="btn btn-primary" type="submit">' + options.label + '</button>');

    $element.addClass('form-group').append($input);

    this.$input = $input;

    $input.click((ev) => {
      ev.preventDefault();
      $input.closest('form').submit();
    });

    return plugin;

  };

  this.setValue = (value) => {
    $input.val(value);
    $input.attr('placeholder', options.placeholder_edit);
  };

  this.resetValue = () => {
    $input.val('');
    $input.attr('placeholder', options.placeholder);
  };

  return this.initialize();
};


