/*
 * Benutzt Quill: https://quilljs.com/
 */
import api from '../api';
//import QuillBetterTable from '../../../plugins/quill-better-table/quill-better-table';
/*import * as QuillTableUI from '../../../plugins/quill-table-ui/index';*/

$.fn.uiFormRichtext = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $history;
  let $label;
  let $input;
  let $input_html;
  let quill;
  let modules;

  options = $.extend({},{
    i18n_prefix: '',
    wrapper: true,
    history: false,
    toolbar: [  // angezeigt Toolbar Buttons
      [{'header': [1, 2, false]}],
      ['bold', 'italic', 'underline'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['link'],
      ['clean']
    ],
    formats: ['header', 'bold', 'italic', 'underline', 'list', 'link'],  // erlaubte Formate!!
    autosave: null

  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  if (options.overwrite_label) {
    const label = t(options.i18n_prefix + 'lb_'+options.name) + 
      ' (' + 
      t(options.i18n_prefix + 'evaluation_last_modified') + 
      ' ' + 
      options.overwrite_label + 
      ')';
    options.label = label;
    options.label_edit = label;
  }

  let plugin = this;
  let dynamic_height = true;

  if (options.height && options.height !== undefined) {
    dynamic_height = false;
  }

  this.showError = (key, value) => {
    $element.find('.ql-toolbar').addClass('is-invalid-important');
    $element.find('.ql-container').addClass('is-invalid-important-noicon');
    $element.find('.ql-container').parent().append(`<div class="error invalid-feedback quill-input-error">${t(value)}</div>`);
  };

  // public methods
  this.initialize = () => {

    // Das hidden field ist nötig, damit der Text mit dem Formular abgeschickt werden
    $label = $(`<label>${options.label}</label>`);

    if (dynamic_height) {
      $input = $(`<div id="scrolling-container">
                    <div class="richtext-${options.name}" class="quill-container"></div>
                </div>`);
      $input_html = $(`<input type="hidden" name="${options.name}" />`);
    }
    else {
      $input = $(`<div class="richtext-${options.name}" class="quill-container"></div>`);
      $input_html = $(`<input type="hidden" name="${options.name}" />`);
    }

    if(options.wrapper) {
      $element.addClass('form-group form-group-' + options.name).append($label).append($input).append($input_html);
    }
    else {
      $element.append($input).append($input_html);
    }

    if(options.history) {
      $history = $('<div></div>').uiHistoryLoader({
        plugin: plugin,
        repo: options.history.repo,
        id: options.history.id,
        name: options.name,
        setValue: plugin.setValue
      });
      $element.append($history);
    }

    this.$input = $input;

    plugin.initRichtext();
    plugin.addEvents();

    return plugin;

  };

  this.initModules = () => {

    /*let QuillBetterTable = quillBetterTable;*/

    modules = {
      toolbar: options.toolbar,
      clipboard: {
        matchVisual: false      // Refer to: https://github.com/quilljs/quill/issues/1379#issuecomment-396114612
      }
    };

    /*
     * Autosave (siehe https://codepen.io/quill/pen/RRYBEP)
     */
    if (options.autosave !== null) {

      modules['autosave'] = {
        url: options.autosave
      };

      Quill.register('modules/autosave', (quill, opt) => {
        var Delta = Quill.import('delta');

        var change = new Delta();
        quill.on('text-change', (delta) => {
          change = change.compose(delta);
        });

        // Save periodically
        setInterval(() => {
          if (change.length() > 0) {
            let url = '/resource/autosave' + opt.url;

            if(options.getEditId()) {
              url += '/' + options.getEditId();
            }

            api.post(url, {
              content: quill.container.firstChild.innerHTML
            });

            change = new Delta();
          }
        }, 5 * 1000);

      });
    }

  };

  this.initRichtext = () => {

    plugin.initModules();

    // toolbar icons anpassen
    let icons = Quill.import('ui/icons');
    icons['clean'] = '<i class="fa fa-broom" aria-hidden="true"></i>';

    // Quill instanzieren
    quill = new Quill($element.find('.richtext-'+options.name)[0], {
      modules: modules,
      formats: options.formats,
      theme: 'snow'
    });

    // set to auto-height in quill-custom.css
    if (dynamic_height == false) {
      $element.find('.ql-editor').css('height', options.height);
    }

    //resize status mail window
    if (options.resize && options.resize !== undefined) {
      $element.find('.ql-editor').css('resize', 'vertical');
    }    

    // icon tooltips hinzufügen
    plugin.initTooltips();

  };

  /*
  https://github.com/quilljs/quill/issues/650#issuecomment-541002924
  */
  this.initTooltips = () => {

    // Textstil wird nicht angezeigt als Tooltip, da ein Submenu mit allen Textstilen hinzugefügt wird. Dieses Submenu
    // hat display:hidden und deswegen ist es für quill disabled und der title tag wird nicht zugelassen
    let toolbarTooltips = {
      'header': 'Texstil',
      'bold': 'Fett',
      'italic': 'Kursiv',
      'underline': 'Unterstrichen',
      'list': {
        'ordered':'nummerierte Liste',
        'bullet': 'Aufzählungsliste'
      },
      'indent': {
        '-1': 'Einrückung entfernen',
        '+1': 'einrücken'
      },
      'link': 'Link einfügen',
      'clean': 'Format entfernen'
    };

    let showTooltip = (which,el) => {
      if (which=='button'){
        var tool = el.className.replace('ql-', '');
      }
      else if (which=='span'){
        var tool = el.className.replace('ql-','');
        tool=tool.substr(0,tool.indexOf(' '));
      }
      if (tool){
        //if element has value attribute.. handling is different
        //buttons without value
        if (el.value ==''){
          if (toolbarTooltips[tool])
            el.setAttribute('title',toolbarTooltips[tool]);
        }
        //buttons with value
        else if (typeof el.value !=='undefined'){
          if (toolbarTooltips[tool][el.value])
            el.setAttribute('title',toolbarTooltips[tool][el.value]);
        }
        //default
        else
          el.setAttribute('title',toolbarTooltips[tool]);
      }
    };

    let $toolbarElement = $element.find('.ql-toolbar');
    if ($toolbarElement) {
      let $matchesButtons = $toolbarElement.find('button');
      for (let el of $matchesButtons) {
        showTooltip('button',el);
      }
      //for submenus inside
      let $matchesSpans = $toolbarElement.find('.ql-toolbar > span > span');
      for (let el of $matchesSpans) {
        showTooltip('span',el);
      }
    }
  };

  this.addEvents = () => {
    // wenn sich der Text im Editor ändern, dann schreiben wir den neuen Text in das hidden field
    quill.on('text-change', () => {
      let current_html = quill.root.innerHTML;

      if (current_html == '<p><br></p>') {
        $input_html.val('');
      }
      else {
        $input_html.val(current_html);
      }
    });
  };

  this.getValue = () => {
    return quill.root.innerHTML;
  };

  this.setValue = (value) => {
    quill.root.innerHTML = value;
    $(`[name="${options.name}"]`).val(value);
    $label.text(options.label_edit);
    $input.attr('placeholder', options.placeholder_edit);
  };

  this.resetValue = () => {
    quill.setContents([]);
    $label.text(options.label);
    $input.attr('placeholder', options.placeholder);
  };

  return this.initialize();
};
