import media from '../media';
import api from '../api';
import time from '../time';

$.fn.uiComment = function(options) {

  // default options
  let plugin = this;
  let $element = $(this);
  let $form_added = false;

  options = $.extend({},{
    title: false,
    submit: (msg) => {}
  }, options);

  let $input_message, $input_message_wrapper, $btn_submit, $form;


  plugin.initMessageField = () => {
    $input_message_wrapper = $('<div class="form-group-comment form-group text-right"></div>');
    $form = $('<form></form>');
    $input_message = $('<textarea rows="1" class="form-control" placeholder="Schreibe einen Kommentar..."></textarea>');
    $btn_submit = $('<button type="submit" class="btn btn-sm btn-primary btn-comment">Senden</button>');

    $input_message_wrapper.append($input_message);
    $input_message_wrapper.append($btn_submit);

    $form.append($input_message_wrapper);

    if(!$form_added){
      $element.append($form);
      $form_added = true;
    }

    api.get('/users/mentions',{
      success: (users) => {

        let mentions = [];
        for (let nickname in users) {
          if (users.hasOwnProperty(nickname)) {
            mentions.push(nickname);
          }
        }

      $input_message.css({'height':'39px','resize':'none'}).suggest('@', {
          data: mentions
        }).autoHeight();

        $element.find('.note-statusbar').hide();
        $element.find('.note-editor.note-frame.card').addClass('form-control');
      }
    });


  };

  this.initialize = () => {

    plugin.initMessageField();

    $form.submit((ev) => {
      ev.preventDefault();

      options.submit($input_message.val());
      $input_message.val('');
    });



    return plugin;
  };

  return this.initialize();
};



/*



 */

