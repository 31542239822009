import api from './api';
import msg from './msg';

const browser_notification = {

  data: null,
  enabled: true,

  init: () => {

    //console.log('init browser push');

    helper.loadScript('/plugins/pusher/handler.js',() => {

      //console.log('script loaded!');

      serviceworker_onPushData = () => {
        browser_notification.updateData();
      };
    });

  },

  updateData: () => {
    api.post('/webpush/setdata', {
      data: serviceworker_webpushdata
    });
  },

  showNotification: (message, options) => {

    if(!browser_notification.enabled) {
      return false;
    }

    options = $.extend({},{
      title: 'jungwild.io',
      icon: '/img/icon.png',
      badge: '/img/logo-white.png'
    }, options);

    try {
      new Notification(options.title, {
        body: message,
        icon: options.icon,
        badge: options.badge
      });
    }
    catch (e) {
      console.error(e);
      browser_notification.enabled = false;
    }


  }
};

export default browser_notification;
