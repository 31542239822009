import app from '../../app/app';;
import api from '../../app/api';;
import time from '../../app/time';;
import msg from '../../app/msg';

const admin_infosystem = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12 admin-blacklist"> <div id="infosystem-test"></div> </div> </div></div>'; return out;
    },
    js: function(it) {
            app.setTitle('Infosystem playground'); app.setBreadcrumps({ 'Administration': '/admin', 'Infosystem': '/admin/infosystem' }); let $form = $('<form action="/infosystem/test"></form>').uiForm({ fields: { 'message': 'Hidden', 'user': 'TagSelect', 'team': { type: 'TagSelect', table: 'team' }, 'type': { type: 'Select', values: [ { id: 'info', name: 'Information (info)'}, { id: 'success', name: 'Erfolgsmeldung (success)'}, { id: 'error', name: 'Fehlermeldung (error)'} ] } }, url: '/infosystem/test', url_overwrite: true, i18n_prefix: 'infosystem.', success: () => { }, error: () => { msg.error('Nachricht konnte nicht gesendet werden!'); }, start: () => { } }); let $card = $('#infosystem-test').uiCard({ title: 'Nachrichten über WebSockets', footer: true }); let $msg_submitter = $(`<form action="#" method="post"> <div class="input-group"> <input type="text" name="message" placeholder="Nachricht..." class="form-control"> <span class="input-group-append"> <button type="submit" class="btn btn-primary">Senden</button> </span> </div> </form>`); $msg_submitter.submit((ev) => { ev.preventDefault(); let message = $msg_submitter.find('[name=message]').val(); $form.getInput('message').val(message); $form.fire(); }); $card.$footer.show(); $card.$footer.append($msg_submitter); $card.$body.append($form);
    }
};

export default admin_infosystem;
