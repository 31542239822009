import api from './api';

const chatbot = {

    $btn_chat_bot: null,
    user: null,

    init: (user) => {
        chatbot.user = user;
        chatbot.$btn_chat_bot = $('#btn-show-help-chat');
        
        chatbot.getUserPhone();
        chatbot.initEventLoader();

        (function () {
            function onTidioChatApiReady() {
                window.tidioChatApi.show();
                window.tidioChatApi.open();

                //add phone
                window.tidioChatApi.setVisitorData({phone: chatbot.user.phone});

                //add visitor tag
                let agencyId = chatbot.user.agency_id.toString();
                let userId = chatbot.user.id.toString();
                window.tidioChatApi.addVisitorTags([
                    'Agency_id: ' + agencyId, 
                    'User_id: ' + userId
                ]);
                
                chatbot.$btn_chat_bot.hide();
            }
            if (window.tidioChatApi) {
                window.tidioChatApi.on('ready', onTidioChatApiReady);
            } else {
                document.addEventListener('tidioChat-ready', onTidioChatApiReady);
            }
        })();
    },

    getUserPhone: () => {
        api.get('/user/profile/sign',{
            success: (res) => {
                if(res.data) {
                    if(res.data.hasOwnProperty('sign_phone')){
                        if(res.data.sign_phone !== "") {
                            chatbot.user.phone = res.data.sign_phone;
                        }
                    }
                    if(res.data.hasOwnProperty('sign_mobile_phone')){
                        if(res.data.sign_mobile_phone !== "") {
                            chatbot.user.phone = res.data.sign_mobile_phone;
                        }
                    }
                }
            },
            error: () => {
                console.log('get user phone failed');
            }
        });
    },

    printUserID: (format, ...values) => {
        let index = 0;
        return format.replace(/%(\d+)d/g, function (match, width) {
            const paddedValue = String(values[index]).padStart(width, '0');
            index++;
            return paddedValue;
        });
    },

    loadChatbot: () => {
        let userId = chatbot.printUserID('%08d-%08d', chatbot.user.agency_id, chatbot.user.id);
        let userName = chatbot.user.firstname + ' ' + chatbot.user.lastname;

        document.tidioIdentify = {  
            distinct_id: userId,        // Unique visitor ID in your system 
            email: chatbot.user.email,  // visitor email  
            name: userName,             // Visitor name
            phone: "",
        };

        document.tidioChatLang = document.querySelector('html').getAttribute('lang');
        document.tidioChatLang = 'de';

        //dynamically load tidio code.
        let scriptElement = document.createElement('script');
        scriptElement.id = 'chatbot-tidio';
        scriptElement.src = '//code.tidio.co/oc7fekqfazswmrq5nvbzftc6qto578ag.js';
        document.body.appendChild(scriptElement);
    },
    unloadChatbot: () => {
        if(window.tidioChatApi) {
            window.tidioChatApi.hide();
            window.tidioChatApi.close();
        }
        
        //chatbot.$btn_chat_bot.show();
    },

    initEventLoader: () => {
        chatbot.$btn_chat_bot.click(() => {
        if(!window.tidioChatApi) {
            chatbot.loadChatbot();
            chatbot.$btn_chat_bot.hide();
        }
        });
    }
};

export default chatbot;