$.fn.uiFormDivider = function(options) {

  let $element = $(this);
  let $container;

  options = $.extend({},{
    i18n_prefix: 'infotext.',
    direction: 'horizontal',       // vertical not implemented yet
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);

  let plugin = this;

  // public methods
  this.initialize = () => {

    if (options.direction === undefined || options.direction === 'horizontal') {
      $container = $(`<hr/>`);
    }

    $element.addClass('form-group form-group-' + options.name).append($container);

    return plugin;

  };

  this.setValue = (value) => {

  };

  this.resetValue = () => {
  };

  return this.initialize();

};
