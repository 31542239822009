import api from './api';
import time from './time';
import routes from './routes';
import dialog from './dialog';
import helper from './helper';
import media from './media';
import audio from "./audio";

const infosystem = {

  $badge_tasks: null,
  $badge_info_complete: null,
  $badge_msg_complete: null,
  $info_main_head: null,
  //$info_main_tasks: null,
  $info_main_body: null,
  $info_main_foot: null,

  $info_msg_body: null,
  $info_msg_wrapper_head: null,
  $info_msg_wrapper_body: null,
  $info_msg_wrapper_foot: null,
  $btn_info_msg: null,
  $btn_info_reminder: null,

  current_info_count: 0,
  current_msg_count: 0,

  infos_loaded: false,
  msg_loaded: false,

  init: () => {
    infosystem.$badge_tasks = $('#badge-tasks');
    infosystem.$badge_info_complete = $('#badge-info-complete');
    infosystem.$badge_msg_complete = $('#badge-msg-complete');
    infosystem.$info_main_head = $('#info-main-head');
    //infosystem.$info_main_tasks = $('#info-main-tasks');
    infosystem.$info_main_body = $('#info-main-body');
    infosystem.$info_main_foot = $('#info-main-foot');

    infosystem.$info_msg_body = $('#info-main-msg-body');

    infosystem.$info_msg_wrapper_head = $('#info-main-msg-body .info-msg-wrapper-head');
    infosystem.$info_msg_wrapper_body = $('#info-main-msg-body .info-msg-wrapper-body');
    infosystem.$info_msg_wrapper_foot = $('#info-main-msg-body .info-msg-wrapper-foot');

    infosystem.$btn_info_msg = $('#btn-header-info-msg');
    infosystem.$btn_info_reminder = $('#btn-header-info-reminder');

    infosystem.$info_msg_wrapper_head.find('.mark-all-as-readed').click((ev) => {
      ev.preventDefault();
      dialog.confirm({
        title: 'Nachrichten als gelesen markieren?',
        body: 'Sollen wirklich alle Nachrichten als gelesen markiert werden?',
        apply: () => {
          api.post('/infosystem/messages/mark-all-as-read',{});
          infosystem.resetMessages();
        }
      });
    });

    infosystem.$info_main_head.find('.mark-all-notif-as-read').click((ev) => {
      ev.preventDefault();
      dialog.confirm({
        title: 'Benachrichtigungen als gelesen markieren?',
        body: 'Sollen wirklich alle Benachrichtigungen als gelesen markiert werden?',
        apply: () => {
          api.post('/infosystem/messages/mark-all-notif-as-read',{});
          infosystem.resetNotifications();
        }
      });
    });


    infosystem.updateMsgCount();

    infosystem.initMsgLoader();

  },

  updateMsgCount: () => {

    api.get('/infosystem/msgcount', {

      success: (msgcount) => {
        infosystem.updateInfoCount(msgcount);
      }

    });

  },

  update: () => {

    api.get('/infosystem/update', {

      success: (infos) => {
        infosystem.updateInfos(infos);
      }

    });

  },

  updateInfoCount: (infos) => {

    /*infosystem.handleMessageBell(infos.messages.count);*/

    infosystem.current_info_count = infos.info.count;
    infosystem.current_msg_count = infos.msg.count;
    infosystem.printInfoCount(infos.info.count);
    infosystem.printMessageCount(infos.msg.count);

    /*
     * Reminders
     */
    infosystem.$badge_tasks.text(infos.reminders.count);

    infosystem.reminderLoaderShow();

    if(infos.reminders.count > 0) {
      infosystem.$badge_tasks.show();
      infosystem.$info_main_foot.empty().append(`<div class="dropdown-divider"></div><span class="dropdown-item dropdown-footer">` + infos.reminders.count + ` neue Benachrichtigungen</span>`);
    }
    else {
      infosystem.$info_main_head.hide();
      infosystem.$badge_tasks.hide();
      infosystem.$info_main_foot.empty().append(`<div class="dropdown-divider"></div><span class="dropdown-item dropdown-footer">Keine neue Benachrichtigungen</span>`);
    }

    /*
     * Messages
     */
    infosystem.msgLoaderShow();
    if(infos.messages.count > 0) {
      infosystem.$info_msg_wrapper_foot.empty().append(`<div class="dropdown-divider"></div><span class="dropdown-item dropdown-footer">` + infos.messages.count + ` neue Nachrichten</span>`);
    }
    else {
      infosystem.$badge_msg_complete.hide();
      infosystem.$info_msg_wrapper_foot.empty().append(`<div class="dropdown-divider"></div><span class="dropdown-item dropdown-footer">Keine neuen Nachrichten</span>`);
    }
  },

  updateInfos: (infos) => {

    /*infosystem.handleMessageBell(infos.messages.count);*/

    infosystem.current_info_count = infos.info.count;
    infosystem.current_msg_count = infos.msg.count;
    infosystem.printInfoCount(infos.info.count);
    infosystem.printMessageCount(infos.msg.count);

    /*
     * Reminders
     */
    infosystem.$badge_tasks.text(infos.reminders.count);

    infosystem.$info_main_body.empty();

    if(infos.reminders.count > 0) {
      infosystem.$badge_tasks.show();
      infosystem.$info_main_foot.empty().append(`<div class="dropdown-divider"></div><span class="dropdown-item dropdown-footer">` + infos.reminders.count + ` neue Benachrichtigungen</span>`);
      infosystem.printReminders(infos.reminders.listing);
    }
    else {
      infosystem.$info_main_head.hide();
      infosystem.$badge_tasks.hide();
      infosystem.$info_main_foot.empty().append(`<div class="dropdown-divider"></div><span class="dropdown-item dropdown-footer">Keine neue Benachrichtigungen</span>`);
    }

    /*
     * Messages
     */
    infosystem.$info_msg_wrapper_body.empty();
    if(infos.messages.count > 0) {
      infosystem.printMessages(infos.messages.listing);
      infosystem.$info_msg_wrapper_foot.empty().append(`<div class="dropdown-divider"></div><span class="dropdown-item dropdown-footer">` + infos.messages.count + ` neue Nachrichten</span>`);
    }
    else {
      infosystem.$badge_msg_complete.hide();
      infosystem.$info_msg_wrapper_foot.empty().append(`<div class="dropdown-divider"></div><span class="dropdown-item dropdown-footer">Keine neuen Nachrichten</span>`);

    }

  },

  printMessageCount: (count) => {
    if(count === undefined) {
      count = infosystem.current_msg_count;
    }

    infosystem.$badge_msg_complete.text(count);
    if(count > 0) {
      infosystem.$info_msg_wrapper_head.show();
      infosystem.$badge_msg_complete.show();
      infosystem.$info_msg_wrapper_foot.empty().append('<div class="dropdown-divider"></div><span class="dropdown-item dropdown-footer">' + count + ' neue Nachrichten</span>');
    }
    else {
      infosystem.$info_msg_wrapper_head.hide();
      infosystem.$badge_msg_complete.hide();
      infosystem.$info_msg_wrapper_foot.empty().append('<div class="dropdown-divider"></div><span class="dropdown-item dropdown-footer">keine neuen Nachrichten</span>');
    }

  },

  resetMessages: () => {
    infosystem.$info_msg_wrapper_head.hide();
    infosystem.$info_msg_wrapper_body.empty();
    infosystem.$badge_msg_complete.hide();
    infosystem.$info_msg_wrapper_foot.empty().append('<div class="dropdown-divider"></div><span class="dropdown-item dropdown-footer">keine neuen Nachrichten</span>');
  },

  resetNotifications: () => {
    infosystem.$info_main_head.hide();
    infosystem.$info_main_body.empty();
    infosystem.$badge_info_complete.hide();
    infosystem.$info_main_foot.empty().append(`<div class="dropdown-divider"></div><span class="dropdown-item dropdown-footer">Keine neue Benachrichtigungen</span>`);
  },

  printInfoCount: (count) => {

    if(count === undefined) {
      count = infosystem.current_info_count;
    }

    /*
     * Main Info
     */
    infosystem.$info_main_foot.text(count + ' Benachrichtigungen');
    infosystem.$badge_info_complete.text(count);
    if(count > 0) {
      infosystem.$badge_info_complete.show();
    }
    else {
      infosystem.$badge_info_complete.hide();
    }

  },

  printReminders: (reminders) => {

    infosystem.$info_main_body.empty();

    reminders.forEach((reminder) => {
      infosystem.$info_main_body.append('<div class="dropdown-divider"></div>');
      infosystem.$info_main_body.append(infosystem.tplReminder(reminder));
    });
    
    infosystem.$info_main_body.find('.click-reminder').on('click', (ev) => {
      ev.preventDefault();
      let $item = $(ev.currentTarget);
      routes.navigate($item.data('uri'))
    });

    infosystem.$info_main_body.find('.hide-reminder').on('click', (ev) => {
      ev.stopPropagation();

      dialog.confirm({
        title: 'Benachrichtigung ausblenden?',
        body: 'Wenn Du die Benachrichtigung ausblendest wirst Du nicht erneut daran erinnert.',
        apply: () => {
          let $item = $(ev.currentTarget);
          api.get(`/infosystem/hide/${$item.data('id')}`, {
            success: () => {
              $('#info-reminder-' + $item.data('id')).remove();
              infosystem.current_info_count--;
              infosystem.printInfoCount();
            }
          });
        }
      });
    });

    infosystem.$info_main_body.find('.reminder-task-checkbox').on('click', (ev) => {
      ev.stopPropagation();

      let $item = $(ev.currentTarget);
      let done = 0;
      let $process = 0;

      if ($item.is(":checked")) {
        done = 1;
      }

      dialog.confirm({
        title: 'Ist die Aufgabe erledigt?',
        body: 'Wenn Du die Aufgabe auf erledigt setzt, wirst du nicht erneut daran erinnert.',
        apply: () => {

          api.post(`/resource/update/attr/task/${$item.val()}`, {
            attr: 'done',
            value: done
            }, {
            success: () => {
              if(done === 1) {
                $process = 1;
                infosystem.update();
              }
            }
          });
        }
      });

      if(!$process) {
        $item.prop('checked', false);
      }
    });



  },

  printMessages: (messages) => {

    messages.forEach((message) => {
      infosystem.$info_msg_wrapper_body.append('<div class="dropdown-divider"></div>');
      infosystem.$info_msg_wrapper_body.append(infosystem.tplMessage(message));
    });

    infosystem.$info_msg_wrapper_body.find('.dropdown-item').on('click', (ev) => {
      ev.preventDefault();
      let $item = $(ev.currentTarget);
      //routes.navigate();
      window.open('https://hunter.jungwild.io' + $item.data('uri'), '_blank').focus();
    });

    infosystem.$info_msg_wrapper_body.find('.hide-message').on('click', (ev) => {
      ev.stopPropagation();

      let $item = $(ev.currentTarget);
      api.get('/infosystem/history/hide/' + $item.data('id'));

      let $elitem = $('#info-message-' + $item.data('id'));
      $elitem.prev('.dropdown-divider').remove();
      $elitem.remove();

      infosystem.current_msg_count--;
      infosystem.printMessageCount();

    });

  },

  tplMessage: (message) => {

    let $msg = $(`
      <div id="info-message-` + message.id + `" data-uri="` + message.uri + `" class="dropdown-item dropdown-item-message">
        <div class="media">
          <img src="` + media.image(message.candidate_avatar, '128x128', '/img/avatar5.png') + `" alt="` + message.candidate_name + `" class="img-size-50 mr-3 img-circle">
          <div class="media-body">
            <h3 class="dropdown-item-title">
              ` + message.candidate_name + `
              <a href="#" data-id="` + message.id + `" class="hide-message float-right text-sm text-muted"><i class="fas fa-times"></i></a>
            </h3>
            <p class="text-sm">` + helper.str_short(message.text.trim(), 250) + `</p>
            <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> ` + time.md_time(message.time) + `</p>
          </div>
        </div>
      </div>
    `);

    return $msg;

  },

  tplReminder: (reminder) => {

    let reminder_icon = 'fas fa-tasks';
    let task_checkbox = '';

    if (reminder.type == 'candidate_favorite') {
      reminder_icon = 'fas fa-star';
    }

    if (reminder.type == 'jobmatch') {
      reminder_icon = 'fas fa-random';
    }

    if (reminder.type == 'task') {
      let match_number = reminder.uri.match(/\d+/);
      let task_id = match_number ? match_number[0] : null;

    task_checkbox = '<div class="form-check form-check-inline"><input data-id="' + reminder.id + '" type="checkbox" class="form-check-input reminder-task-checkbox" name="reminder-task-done-'
        + reminder.id + '" value="' + task_id + '" /><label class="form-check-label">erledigt </label></div>';
    }

    let $reminder = '';    
    if(reminder.type == 'jobmatching_new_match' || reminder.type == 'job_no_matching') {
      let content_first = '';
      let content_second = '';
      let data_info = JSON.parse(reminder.data);

      if(reminder.type == 'jobmatching_new_match') {
        let item = reminder.matching;     
        let params = item.data.filter;
    
        params['query'] = item.query;
        params['sort_col'] = item.sort_col;
        params['sort_type'] = item.sort_type;
        params['page'] = 0;
        params['dosearch'] = 1;
        params['search_combo'] = item.data.filter.search_combo;
  
        reminder.uri = '/recruiting/candidates' + '#' + $.param(params);

        content_first = 'Ein neues Job-Matching für ' + data_info.job_title + ' - #' + data_info.job_id + ' / ';
        content_second = ' wurde gefunden.';
      }
      else {
        reminder.uri = '/recruiting/jobs#query=' + data_info.job_id;
        content_first = '';
        content_second = ' - #' + data_info.job_id + ' ist seit mindestens 2 Tagen online und hat noch kein Jobmatching!';
      }
      
      $reminder = $(`<div id="info-reminder-` + reminder.id + `" class="dropdown-item dropdown-item-reminder">
        <div class="media">
            <i class="${reminder_icon} mr-1 reminder-task-icon"></i>
            <div class="media-body">
            <h3 class="dropdown-item-title">` + content_first + `<a href="` + reminder.uri + `" data-id="` + reminder.id +  
            `" target="_blank" rel="noopener noreferrer" class="text-bold">` 
            + reminder.name + ` </a>` + content_second + `</h3>
            <p style="display: none;" class="text-sm">` + reminder.description + `</p><p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> ` + time.md(reminder.time) 
            + `</p> </div>
          <div><i class="fas fa-times hide-reminder" data-id="` + reminder.id + `"></i></div>
        </div>
      </div>`);
    }
    else {
      $reminder = $(`<div id="info-reminder-` + reminder.id + `" data-uri="` + reminder.uri + `" class="dropdown-item dropdown-item-reminder click-reminder">
        <div class="media">
            <i class="${reminder_icon} mr-1 reminder-task-icon"></i>
            <div class="media-body">
            <h3 class="dropdown-item-title">` + reminder.name + ` <a href="#" data-id="` + reminder.id + `" class="float-right text-sm text-muted"></a></h3>
            <p style="display: none;" class="text-sm">` + reminder.description + `</p><p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> ` + time.md(reminder.time) 
            + `</p>`+  task_checkbox +  
          `</div>
          <div><i class="fas fa-times hide-reminder" data-id="` + reminder.id + `"></i></div>
        </div>
      </div>`);
    }

    return $reminder;
  },

  handleMessageBell: (message_count) => {

    if(infosystem.current_msg_count > 0 && message_count > infosystem.current_msg_count) {
      audio.playSound('coin.mp3');
    }

  },

  reminderLoaderHide: () => {
    infosystem.$info_main_body.empty();
  },

  msgLoaderHide: () => {
    infosystem.$info_msg_wrapper_body.empty();
  },

  reminderLoaderShow: () => {

    infosystem.$info_main_body.html('<div class="dropdown-divider"></div><div class="headerinfo-loader"><i class="fas fa-spinner fa-spin"></i></div>');
    infosystem.infos_loaded = false;
  },

  msgLoaderShow: () => {
    infosystem.$info_msg_wrapper_body.html('<div class="dropdown-divider"></div><div class="headerinfo-loader"><i class="fas fa-spinner fa-spin"></i></div>');
    infosystem.msg_loaded = false;
  },

  initMsgLoader: () => {

    /*
     * init reminders btn
     */
    infosystem.$btn_info_reminder.click(() => {
      if(infosystem.infos_loaded === false) {
        console.log('load reminders...');
        api.get('/infosystem/listreminders',{
          success: (reminders) => {
            infosystem.reminderLoaderHide();
            infosystem.printReminders(reminders.listing);
            infosystem.infos_loaded = true;
          }
        });
      }
    });


    /*
     * init msg btn
     */
    infosystem.$btn_info_msg.click(() => {
      console.log('load msg...');
      if(infosystem.msg_loaded === false) {
        api.get('/infosystem/listmsg',{
          success: (msgs) => {
            infosystem.msgLoaderHide();
            infosystem.printMessages(msgs.listing);
            infosystem.msg_loaded = true;
          }
        });
      }
    });
  }
};

export default infosystem;
