import api from '../api';
import msg from '../msg';

$.fn.uiFormFastapply = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;

  let $btn_add_text;
  let $btn_add_select;
  let $btn_add_option;
  let $label_wrapper;

  let count_text = 0;
  let count_select = 0;
  let count_option = 1;

  options = $.extend({},{
    i18n_prefix: ''
  }, options);

  options.i18n_prefix = 'fastapply';
  options.label = t(options.i18n_prefix+'.lb_'+options.name);
  options.placeholder = t(options.i18n_prefix+'.ph_'+options.name);


  let plugin = this;

  // public methods
  this.initialize = () => {

    count_text = 0;
    count_select = 0;
    count_option = 1;

    $btn_add_text = $('<a href="#" class="btn btn-default ml-3 btn-xs add-text">+ Text</a>');
    $btn_add_select = $('<a href="#" class="btn btn-default ml-3 btn-xs add-select">+ Auswahl</a>');

    $label = $('<span>' + options.label + '</span>');
    $label_wrapper = $('<label></label>');

    $label_wrapper.append($label).append($btn_add_text).append($btn_add_select);

    $element.addClass('vertical-input-group').addClass('form-group form-group-'+options.name).append($label_wrapper);

    this.addEvents();

    return plugin;
  };

  /*
   * Neues Text Feld
   */
  this.addText = (ev) => {
    count_text++;

    let $container = $(ev.target).parent('label').parent('div');

    let $new_input_group = $('<div class="subform input-group-additional"></div>');
    let $new_input = $(`<label>${t(`${options.i18n_prefix}.lb_text`)}</label>
        <div class="input-group">        
        <input autocomplete="${helper.randomString()}" name="${options.name}_text_${count_text}" class="form-control"
            type="text" data-type="text" placeholder="${t(`${options.i18n_prefix}.ph_text`)}"
        />
        <button type="button" style="margin-left: 10px;" class="btn btn-primary del-field"><i class="fas fa-trash-alt mr-1"></i>Löschen</button>        
      </div>`);

    $new_input_group.append($new_input);

    $container.append($new_input_group);

    this.addEvents();
  };

  /*
   * Neues Select Feld
   */
  this.addSelect = (ev) => {
    count_select++;
    count_option = 0;

    let $container = $(ev.target).parent('label').parent('div');
    let $btn_add_option = $(`<a href="#" class="btn btn-default ml-3 btn-xs add-option" data-count="${count_select}">+ Option</a>`);

    let $new_label = $(`<span>${t(`${options.i18n_prefix}.lb_select`)}</span>`);
    let $new_label_wrapper = $('<label></label>');

    $new_label_wrapper.append($new_label).append($btn_add_option);

    let $new_input_group = $('<div class="subform input-group-additional"></div>');
    let $new_input = $(`<div class="input-group">
        <input autocomplete="${helper.randomString()}" name="${options.name}_select_${count_select}" class="form-control"
            type="text" data-type="select" placeholder="${t(`${options.i18n_prefix}.ph_select`)}"
        />
        <button type="button" style="margin-left: 10px;" class="btn btn-primary del-field"><i class="fas fa-trash-alt"></i>Löschen</button>
      </div>`);

    $new_input_group.append($new_label_wrapper).append($new_input);

    $container.append($new_input_group);

    $btn_add_option.off('click').on('click', (ev) => {
      ev.preventDefault();
      this.addOption(ev);
    });

    this.addEvents();
  };

  /*
   * Neue Option
   */
  this.addOption = (ev) => {
    //count_option++;
    let the_select_count = $(ev.target).data('count');
    let $container = $(ev.target).parent('label').parent('div');

    // noch keine Option vorhanden
    if ($container.find('.subsubform').find('input[type="text"]').length == 0) {
      count_option = 0;
    }
    // wir holen uns den counter der letzten option
    else {
      count_option = parseInt($container.find('.subsubform').find('input[type="text"]').last().data('count'));
    }
    count_option++;

    let $new_input_group = $(`<div class="subsubform input-group-additional select${the_select_count}"></div>`);

    let $new_input = $(`<div class="row">
          <div class="col-sm-9">
              <label>${t(`${options.i18n_prefix}.lb_option`)}</label>
              <input autocomplete="${helper.randomString()}" data-count="${count_option}" name="${options.name}_select_${the_select_count}_option_${count_option}" 
                  class="form-control" type="text" data-type="option" data-select="${the_select_count}"
                  placeholder="${t(`${options.i18n_prefix}.ph_option`)}"
              />
          </div>
          <div class="col-sm-3">
              <span style="margin-left: 25px;" ><label>${t(`${options.i18n_prefix}.lb_checkbox`)}</label></span>
              <div class="input-group">
              <input name="${options.name}_select_${the_select_count}_option_${count_option}_check" 
                  class="form-control" type="checkbox" data-type="checkbox" data-select="${the_select_count}"
              />
              <button type="button" class="btn btn-primary del-field"><i class="fas fa-trash-alt"></i>Löschen</button>
              </div>    
          </div>
      </div>`);

    $new_input_group.append($new_input);

    $container.append($new_input_group);

    this.addEvents();
  };

  this.addEvents = () => {

    /*
     * Neues Text Feld
     */
    $element.find('.add-text').off('click').on('click', (ev) => {
      ev.preventDefault();
      this.addText(ev);
    });

    /*
     * Neues Select Feld
     */
    $element.find('.add-select').off('click').on('click', (ev) => {
      ev.preventDefault();
      this.addSelect(ev);
    });

    /*
     * Neue Option
     */
    $element.find('.add-option').off('click').on('click', (ev) => {
      ev.preventDefault();
      this.addOption(ev);
    });

    /*
     * Löschen Button
     */
    $element.find('.del-field').off('click').on('click', (ev) => {
      ev.preventDefault();

      let $container = $(ev.target).parent('div');
      let $count = $container.find('label > a').data('count');

      // Select soll gelöscht werden, also alle dazugehörigen Options löschen
      if ($count) {
        $element.find('.subsubform.select'+$count).remove();
      }

      // Text bzw. Select an sich löschen
      $container.remove();
    });

  };

  this.renderText = (item) => {
    count_text = item.id;

    let $new_input_group = $('<div class="subform input-group-additional"></div>');
    let $new_input = $(`<label>${t(`${options.i18n_prefix}.lb_text`)}</label>
      <div class="input-group">      
      <input autocomplete="${helper.randomString()}" name="${options.name}_text_${count_text}" class="form-control"
          type="text" data-type="text" placeholder="${t(`${options.i18n_prefix}.ph_text`)}" value="${item.text}"
      />
      <button type="button" style="margin-left: 10px;" class="btn btn-primary del-field"><i class="fas fa-trash-alt"></i>Löschen</button></div>`);   

    $new_input_group.append($new_input);

    $element.append($new_input_group);
  };

  this.renderSelect = (item) => {
    count_select = item.id;

    let $btn_add_option = $(`<a href="#" class="btn btn-default ml-3 btn-xs add-option" data-count="${count_select}">+ Option</a>`);
    let $new_label = $(`<span>${t(`${options.i18n_prefix}.lb_select`)}</span>`);
    let $new_label_wrapper = $('<label></label>');

    $new_label_wrapper.append($new_label).append($btn_add_option.clone(true));

    let $new_input_group = $('<div class="subform input-group-additional"></div>');
    let $new_input = $(`<div class="input-group">
        <input autocomplete="${helper.randomString()}" name="${options.name}_select_${count_select}" class="form-control"
            type="text" data-type="select" placeholder="${t(`${options.i18n_prefix}.ph_select`)}" value="${item.text}"
        />
        <button type="button" style="margin-left: 10px;" class="btn btn-primary del-field"><i class="fas fa-trash-alt"></i>Löschen</button>
      </div>`);

    $new_input_group.append($new_label_wrapper).append($new_input);

    $element.append($new_input_group);
  };

  this.renderOption = (item_option) => {
    count_option = item_option.id;

    let $container = $element.find('[data-count="'+count_select+'"]').parent('label').parent('div');

    let $new_input_group = $(`<div class="subsubform input-group-additional select${count_select}"></div>`);
    let $checked = '';

    if (item_option.musthave == true) {
      $checked = ' checked="checked"';
    }

    let $new_input = $(`<div class="row">
        <div class="col-sm-9">
            <label>${t(`${options.i18n_prefix}.lb_option`)}</label>
            <input autocomplete="${helper.randomString()}" data-count="${count_option}" name="${options.name}_select_${count_select}_option_${count_option}" 
                class="form-control" type="text" data-type="option" data-select="${count_select}"
                placeholder="${t(`${options.i18n_prefix}.ph_option`)}" value="${item_option.text}"
            />
        </div>
        <div class="col-sm-3">            
            <span style="margin-left: 25px;" ><label>${t(`${options.i18n_prefix}.lb_checkbox`)}</label></span>
            <div class="input-group">
            <input name="${options.name}_select_${count_select}_option_${count_option}_check"
                class="form-control" type="checkbox" data-type="checkbox" data-select="${count_select}"${$checked}      
            />
            <button type="button" class="btn btn-primary del-field"><i class="fas fa-trash-alt"></i>Löschen</button>
            </div>            
        </div>           
    </div>`);

    $new_input_group.append($new_input);

    $container.append($new_input_group);
  };

  this.setValue = (value) => {

    $element.find('div').remove();

    count_text = 0;
    count_select = 0;
    count_option = 1;

    if (Array.isArray(value)) {
      if (value.length > 0) {

        value.forEach((item) => {

          if (item.type == 'text') {
            //$element.append( this.renderText(item) );
            this.renderText(item);
          }
          else if (item.type == 'select') {
            //$element.append( this.renderSelect(item) );
            this.renderSelect(item);

            if (item.options.length > 0) {
              item.options.forEach((item_option) => {
                //$element.append( this.renderOption(item_option) );
                this.renderOption(item_option);
              });
            }
          }

        });
      }
    }

    this.addEvents();
  };

  this.resetValue = () => {
    $element.find('div').remove();

    count_text = 0;
    count_select = 0;
    count_option = 1;
  };

  return this.initialize();

};
