import app from '../../app/app';;
import api from '../../app/api';;
import msg from '../../app/msg';;
import btn_save from '../../_compiled/components/button_save';

const settings_crm_orgainfo = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12" id="settings-crm-orgainfo"> </div> </div></div>'; return out;
    },
    js: function(it) {
            app.setTitle('Unternehmensinfos'); app.setBreadcrumps({ 'Einstellungen': '/settings', 'CRM': '/crm', 'Unternehmensinfos': '/orgainfo' }); let $btn_save = new btn_save.js(); let $form = $('#settings-crm-orgainfo').uiForm({ i18n_prefix: 'application_info.', fields: { 'organisation': { type: 'Summernote', height: 300 }, 'job': { type: 'Summernote', height: 500 } } }); $form.append($btn_save); api.get('/agencysetting/application_info', { success: (result) => { $form.setValues(result.data); $form.getFormElement('job').hide(); }, error: () => { msg.error(t('g.error')); } });  $btn_save.click((ev) => { ev.preventDefault(); let value = { job: $form.getFormElement('job').getHtml(), organisation: $form.getFormElement('organisation').getHtml() }; api.post('/agencysetting/application_info', { value: value }, { success: () => { msg.success(t('g.save_success')); } }) });
    }
};

export default settings_crm_orgainfo;
