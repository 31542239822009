import api from '../../app/api';

let first_load = true;

function findSecondaryColor(color) {
  return findFontBlackOrWhitBasedOnBackground(color);
}

function findFontBlackOrWhitBasedOnBackground(bg_color) {
  var bg_rgb = hexToRgb(bg_color);
  var bg_luminance = luminance(bg_rgb);
  if (bg_luminance > 0.5) {
    return '#000000';
  }
  return '#ffffff';
}

function hexToRgb(hex_color) {
  var r = parseInt(hex_color.substring(1, 3), 16);
  var g = parseInt(hex_color.substring(3, 5), 16);
  var b = parseInt(hex_color.substring(5, 7), 16);
  return {r: r, g: g, b: b};
}

function luminance(rgb) {
  var r = rgb.r / 255;
  var g = rgb.g / 255;
  var b = rgb.b / 255;
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

const custom_barchart = {

  fillDateWithLastWeek: (field) => {
    let today = new Date();
    let week_ago = new Date();
    week_ago.setDate(today.getDate()-7);

    let today_string = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
    let week_string = week_ago.getFullYear() + '-' + (week_ago.getMonth()+1) + '-' + week_ago.getDate();

    $(field).val(week_string + ' - ' + today_string);
  },
  initFilter: () => {

    let $form = $('<form></form>').uiForm({
      i18n_prefix: 'statistics.',
      fields: {
        candidatejobstatus_recruiter: {
          type: 'TagSelect',
          table: {
            name: 'user',
            type: 'worker_active'
          },
          autocomplete: "off",
        },
        candidatejobstatus_date: {
          type: 'Daterange',
          drops: 'down'
        },
        candidatejobstatus_job: {
          type: 'Selectgroup',
          table: 'job',
          group_maxlength: 40,
          text_maxlength: 40
        },
        candidatejobstatus_status: {
          type: 'TagSelect',
          table: 'jobstatus',
          autocomplete: "off"
        },
        job_accumulatestatus: {
          type: 'Switch'
        }
      }
    });

    $(`#filter-jobstatus-history`).append($form);

    /*
     * Filter mit aktuellem Datum füllen und Aussehen konfigurieren
     */
    custom_barchart.fillDateWithLastWeek($form.find('[name="candidatejobstatus_date"]'));

    $form.find('.form-group-custom_barchart').addClass('col-sm-5');
    $form.find('.form-group-candidatejobstatus_date').addClass('col-sm-3');
    $form.find('.form-group-candidatejobstatus_job').addClass('col-sm-4');
  },
  findMaxItemInData: (data) => {
    let maxItem = 0;
    data.forEach((item) => {
      // eslint-disable-next-line radix
      if (parseInt(item.job_count) > parseInt(maxItem)) {
        // eslint-disable-next-line radix
        maxItem = parseInt(item.job_count);
      }
    });
    return maxItem;
  },
  appendJob: (item, max_item) => {
    let main_cont1 = jQuery('<div class="job-bars-container" style="cursor: pointer; display:flex; flex-direction: column;margin:15px"></div>');
    let main_bar_container = jQuery('<div class="main-bar-container" filled_data="false" job_id="' + item.job_id + '"></div>');
    main_bar_container.prepend('<div class="bar-title-side" style="width:20%">' + item.job_title + '</div>');
    let bar_container = jQuery('<div style="width:80%"></div>');
    let percentage = parseInt(item.job_count) * 100 / max_item;
    if (percentage < 5) {
      percentage = 5;
    }
    bar_container.append('<div class="mainbar bar" count="' + item.count + '" barname="' + item.job_title + '" style="--bar-value-abs:' + item.job_count  + '; --bar-value:' + percentage +'%;"><div class="bar-inner-text">' + item.job_count  + '</div></div>');
    main_bar_container.append(bar_container);
    const subbars = jQuery("<div class='subbars-container'></div>");
    main_cont1.append(subbars);
    main_cont1.append(main_bar_container);

    $("#multigrafic").append(main_cont1);
  },
  reloadgraph: (data) => {
    $("#multigrafic").empty();
    $("#multigrafic").append("<h3 class=\"multigrafic-text\">Anzahl an Kandidaten Pro Stelle</h3>");
    const maxItem = custom_barchart.findMaxItemInData(data);

    data.forEach(function(item){
      custom_barchart.appendJob(item, maxItem);
    });
    window.setTimeout(function(){
      jQuery(".mainbar.bar").addClass("expanded-main-bars");
    }, 100);

    $(".main-bar-container").click(function() {
      const data = {
        job_id_detail: $(this).attr("job_id"),
        candidatejobstatus_date: jQuery("input[name=candidatejobstatus_date]").val(),
        candidatejobstatus_status: jQuery("select[name='candidatejobstatus_status[]']").val(),
        current_status: jQuery(".form-group-job_accumulatestatus input[type='checkbox']").eq(0).is( ":checked" ),
        show_closed: jQuery("input[name='show-inactive']").eq(0).is(":checked")
      };

      /* Make sure all dates look the same */
      function dateFormatField(date){
        let parts1 = date.split(" ");
        let firstDate = parts1[0].split("-");
        if(firstDate[1].length === 1){
          firstDate[1] = "0" + firstDate[1]
        }
        if(firstDate[2].length === 1){
          firstDate[2] = "0" + firstDate[2]
        }
        firstDate = firstDate.join("-")

        let secondDate = parts1[2].split("-");
        if(secondDate[1].length === 1){
          secondDate[1] = "0" + secondDate[1]
        }
        if(secondDate[2].length === 1){
          secondDate[2] = "0" + secondDate[2]
        }
        secondDate = secondDate.join("-")
        return firstDate + " - " + secondDate;

      }
      function getCandidatesUrl(jobStatusId = null){
        let  baseUrl = "/recruiting/candidates#query=&sort_col=&sort_type=&page=0&favorite=0&date=[DATES][JOBSTATUS]&salary=&job=[JOBID][RECRUITERS]&cv_file=0&radius%5Bstreet%5D=&radius%5Bnumber%5D=&radius%5Bzip%5D=&radius%5Bcity%5D=&radius%5Bstate%5D=&radius%5Bcountry%5D=&radius%5Blat%5D=&radius%5Blong%5D=&radius%5Bkm%5D=300&search_combo="
        baseUrl = baseUrl.replace("[JOBID]", data.job_id_detail);

        let recruiterList = jQuery("select[name='candidatejobstatus_recruiter[]']").val();
        let jobstatusQuery = "";
        let jobhistoryString = "&jobstatus_history[]=";

        if(data.current_status){
          jobhistoryString = "&fjobstatus[]=";
          baseUrl = baseUrl.replace("[DATES]", "");
        } else {
          const date = dateFormatField(data.candidatejobstatus_date);
          baseUrl = baseUrl.replace("[DATES]", date);
        }

        if(jobStatusId === null){
          for (let key in data.candidatejobstatus_status) {
            jobstatusQuery = jobstatusQuery + jobhistoryString + data.candidatejobstatus_status[key]
          }
        } else {
          jobstatusQuery = jobstatusQuery + jobhistoryString + jobStatusId;
        }

        baseUrl = baseUrl.replace("[JOBSTATUS]", jobstatusQuery);

        let recruitersQuery = "";
        let recruitersstring = "&recruiter[]=";
        for (let key in recruiterList) {
          recruitersQuery = recruitersQuery + recruitersstring + recruiterList[key]
        }
        baseUrl = baseUrl.replace("[RECRUITERS]", recruitersQuery);

        return baseUrl;
      }
      function fillSubBars(data, mainBar){
        mainBar.siblings().each(function(index, element ){
          if ($(element).hasClass("subbars-container")){
            let subbars = jQuery(element);
            getCandidatesUrl();
            let tooltipText = jQuery(".form-group-job_accumulatestatus input[type='checkbox']").eq(0).is( ":checked" ) ? "" : custom_barchart.toolTipText;
            subbars.append('<h6 class="subbar-title"><a class="dashboard-tooltip" target="_blank" href ="' + getCandidatesUrl() + '">Zeige alle Kandidaten' + tooltipText + '</a></h6>');
            /* if(data.sources.length > 0){
              subbars.append('<h6 class="subbar-title">Quellen der Bewerbung</h6>');
              let max_sources_item = 0;
              data.sources.forEach(function(sub_item) {
                if (parseInt(sub_item.value) > max_sources_item){
                  max_sources_item = parseInt(sub_item.value);
                }
              });

              data.sources.forEach(function(sub_item) {
                let subbars_item = $('<div class="subbars" style=" flex-direction: row;">');
                let percentage = parseInt(sub_item.value) * 100 / max_sources_item;
                if (percentage < 5) {
                  percentage = 5;
                }
                subbars_item.prepend('<div class="bar-title-side" style="width:20%">' + sub_item.platform_name + ' </div>');
                subbars_item.append('<div style="width:80%"><div class="mainbar bar" style="background-color:' + sub_item.color + '; --bar-value:' + percentage + '%;"><div class="bar-inner-text">' + sub_item.value + '</div></div></div>');
                subbars.append(subbars_item);
              });
            } */

            if(data.jobstatus.length > 0) {

              subbars.append('<h6 class="subbar-title">Status der Bewerbungen</h6>');

              let max_item = 0;
              data.jobstatus.forEach(function(sub_item) {
                if (parseInt(sub_item.value) > max_item){
                  max_item = parseInt(sub_item.value);
                }
              });

              data.jobstatus.forEach(function(sub_item) {
                if(sub_item.value > 0){
                  let percentage = parseInt(sub_item.value) * 100 / max_item;

                  if (percentage < 5) {
                    percentage = 5;
                  }
                  let tooltipText = jQuery(".form-group-job_accumulatestatus input[type='checkbox']").eq(0).is( ":checked" ) ? "" : custom_barchart.toolTipText;
                  let subbars_item = $('<div class="subbars" style=" flex-direction: row;">');
                  subbars_item.prepend('<div class="bar-title-side" style="width:20%">'
                    + sub_item.jobstatus_name + '' +
                    '<a class="dashboard-tooltip" target="_blank"  href="' + getCandidatesUrl(sub_item.id) + '">&nbsp&nbsp<i class="fas fa-search"></i>' + tooltipText + '</a></div>');
                  subbars_item.append('<div style="width:80%"><div class="mainbar bar" style="background-color:' + sub_item.color + '; --bar-value:' + percentage + '%;"><div class="bar-inner-text" style="color:' + findSecondaryColor(sub_item.color) + '">' + sub_item.value + '</div></div></div>');
                  subbars.append(subbars_item);
                }
              });
            }
            mainBar.parent().append(subbars);
          }

        });

      }

      if ($(this).attr("filled_data") !== "true") {
        api.post('/statistics/get-recruiter-jobdetails', data, {
          success: (result) => {
            fillSubBars(result, $(this));
            $(this).attr("filled_data", "true");
          }
        });
      }

      $(this).siblings().each(function(){

        let el = jQuery(this);
        if(el.hasClass("subbars-container")) {
          if (el.hasClass("subbar-expanded")) {
            el.removeClass("subbar-expanded");
            el.removeClass("bar-expand");
            return;
          }
          el.addClass("subbar-expanded");
          window.setTimeout(function(){
            el.addClass("bar-expand");
          }, 100);


        }
      })
    });
  },


  init: (element) => {
    custom_barchart.initFilter();
    function update(){
      function updateRecruiterJobs(data){
        /* show message on first load */
        if (first_load) {
          first_load = false;
          $("#multigrafic").append("<p class=\"multigrafic-text\">Bitte Filtern auswahlen um Statistiken zu sehen</p>");
          return;
        }
        api.post('/statistics/get-recruiter-jobs', data, {
          success: (result) => {
            custom_barchart.reloadgraph(result, data);
          }
        });
      };

      let i_data = {
        candidatejobstatus_recruiter: jQuery("select[name='candidatejobstatus_recruiter[]']").val(),
        candidatejobstatus_date: jQuery("input[name=candidatejobstatus_date]").val(),
        candidatejobstatus_job_id: jQuery("select[name=candidatejobstatus_job_id]").val(),
        candidatejobstatus_status: jQuery("select[name='candidatejobstatus_status[]']").val(),
        current_status: jQuery(".form-group-job_accumulatestatus input[type='checkbox']").eq(0).is(":checked"),
        show_closed: jQuery("input[name='show-inactive']").eq(0).is(":checked")
      };

      updateRecruiterJobs(i_data);

    }
    $(`#filter-jobstatus-history`).find('input').bind('change', (ev) => {
      update();
    });

    $(`#filter-jobstatus-history`).find('select').bind('change', (ev) => {
      update();
    });

    $(`#filter-jobstatus-history .lcs_switch`).bind('click', (ev) => {
      update();
    });

    jQuery(".lcs_switch").on(
      "click",
      function() {
        jQuery(".form-group-candidatejobstatus_date").toggle();
        update();
      });
  },
  toolTipText: "<span class=\"dashboard-tooltiptext\">Die Gesamtzahl der Suchergebnisse kann abweichen, wenn Auswertungen in der Vergangenheit gefiltert werden, da die Suchergebnisse nur den aktuellen Stand abbilden können</span>"
}

export default custom_barchart;
