import app from '../../app/app';;
import api from '../../app/api';;
import time from '../../app/time';;
import msg from '../../app/msg';;
import prerender from '../../app/prerender';

const admin_pages = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12 admin-pages"> <div id="pages-list"></div> </div> </div></div>'; return out;
    },
    js: function(it) {
             app.setTitle('Webseiten verwalten'); app.setBreadcrumps({ 'Administration': '/admin', 'Webseiten': '/admin/pages' }); let $orgs = $('#pages-list').uiDataTableScroll({ add: false, edit: true, delete: true, hide_search: true, default_order: [3, 'desc'], i18n_prefix: 'page.', pageLength: 500, cols: [ {name: 'id', text:'#', width: 10}, {name: 'meta_title', text: 'Title'}, {name: 'slug', text: 'Slug'}, {name: 'updated_at', text: 'Update'} ], render:{ 'id': (obj) => { return '<b>#' + obj.id + '</b>'; }, 'title': (obj) => { return '<b>' + obj.meta_title + '</b>'; }, 'slug': (obj) => { return '<small><a href="https://jungwild.io' + obj.slug + '" target="_blank" data-slug="' + obj.slug + '">' + obj.slug + '</a></small>'; }, 'updated_at': (obj) => { return '<span style="display:none;">' + obj.updated_at + '</span><small>' + time.ago(obj.updated_at) + '</small>'; } }, afterLoad: () => { }, table: ['page', 'pages'], fields: { 'slug': { type: 'Slug', slugify: false }, 'meta_title': 'Text', 'meta_description': { type: 'Textarea', maxlength: 300 }, 'body': 'Summernote', 'sitemap': 'Switch', 'priority': 'Number', 'seo_follow': 'Switch', 'seo_robots': 'Switch' }, optionButtons: [ { name: 'pages-refresh', icon: 'fas fa-sync-alt', click: (id, $btn, $tr, ev) => { ev.preventDefault(); var btn = $btn; let slug = `${$btn.parent().parent().parent().children('td').eq(2).find('a').data('slug')}`; let $icon = $btn.find('i'); $icon.addClass('fa-spin'); prerender.prerender_single_slug(slug, $btn, $icon); } } ], addSuccess: (res) => { prerender.prerender_single_slug(res.slug); }, editSuccess: (new_data, old_data) => {  if (old_data.slug !== new_data.slug) { prerender.prerender_remove_slug(old_data.slug); } prerender.prerender_single_slug(new_data.slug); }, onDeleteCallback: (id, $btn) => { let slug = `${$btn.parent().parent().parent().children('td').eq(2).find('a').data('slug')}`; prerender.prerender_remove_slug(slug); }, });
    }
};

export default admin_pages;
