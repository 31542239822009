import app from '../../app/app';

const not_found_404 = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12"> <img src="/img/404.jpg" class="w-100 rounded" alt="404 - Error" /> </div> </div></div>'; return out;
    },
    js: function(it) {
         app.setTitle('Fehler'); app.setBreadcrumps({ 'Fehler 404': '/404' });
    }
};

export default not_found_404;
