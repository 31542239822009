const organisation = {

  ph_search: 'Name...',
  add_organisation: 'Unternehmen hinzufügen',
  add_agency_organisation: 'Unternehmen hinzufügen',
  edit_agency_organisation: 'Unternehmen bearbeiten',
  edit_organisation: 'Unternehmen bearbeiten',
  delete_confirm: 'Unternehmen wirklich löschen?',
  lb_name: 'Name',
  ph_name: 'Geflügel Heinrich GmbH',
  lb_shortname: 'Kurzname',
  ph_shortname: '',
  lb_blacklisted: 'Auf der Blacklist',
  lb_blacklist_comment: 'Kommentar für die Blacklist',
  ph_blacklist_comment: 'z.B. Bitte keine HRler anschreiben, andere Berufe sind ok.',
  lb_email: 'E-Mail Adresse',
  ph_email: 'info@unternehmen.de',
  lb_phone: 'Telefon',
  ph_phone: '+49 123 456',
  lb_phone_mobile: 'Mobil',
  ph_phone_mobile: '+49 123 456',
  lb_phone_private: 'Telefon-Privat',
  ph_phone_private: '+49 123 456',
  lb_submitted: '<i class="far fa-save"></i>&nbsp; Änderungen speichern',
  lb_fax: 'Fax',
  ph_fax: '',
  lb_website: 'Website',
  ph_website: 'https://www...',
  ph_search: 'Suche...',
  lb_temperature: 'Temperatur-Niveau',
  lb_parent_id: 'Tochter-Unternehmen von',
  lb_user_id: 'Account-Manager',
  lb_description: 'Sonstige Notizen',
  ph_description: '',
  lb_description_important: 'Wichtige Hinweise für Kandidatenvorstellung',
  ph_description_important: '',
  lb_social_linkedin: 'LinkedIn',
  ph_social_linkedin: '',
  lb_social_xing: 'Xing',
  ph_social_xing: '',
  lb_social_facebook: 'facebook',
  ph_social_facebook: '',
  lb_social_instagram: 'Instagram',
  ph_social_instagram: '',
  lb_social_youtube: 'Youtube',
  ph_social_youtube: '',
  lb_social_twitter: 'Twitter',
  ph_social_twitter: '',
  lb_social_mastodon: 'Mastodon',
  ph_social_mastodon: '',
  lb_parent: 'Tochter von',
  lb_user: 'Account-Manager',
  ph_parent: '',
  ph_user: '',
  lb_organisationtype: 'Branche',
  lb_tags: 'Tags',
  ph_tags: '',
  lb_orga_status: 'Status',
  ph_orga_status: '',
  lb_orga_type: 'Unterbranche',
  ph_orga_type: '',
  lb_status: 'Status',
  ph_status: '',
  lb_application_info: 'Unternehmensinfos',
  ph_application_info: '',
  lb_organisationbranches: 'Adresse / Niederlassungen',
  ph_organisationbranches: '',
  lb_first_interview_tips: 'Erstgespräch Tipps',
  lb_is_favorite: 'Favorit',
  one_of_my_favorites: 'gehört zu meinen Favoriten',

  name_required: 'Name ist ein Pflichtfeld',
  name_stringlengthmin: 'Name ist zu kurz',
  name_stringlengthmax: 'Name ist zu lang',
  name_outofbounds: 'Unternehmen ungültig',
  name_alreadyexists: 'Unternehmen wurde bereits hinzugefügt',

  shortname_required: 'Kurzname ist ein Pflichtfeld',
  shortname_stringlengthmin: 'Name ist zu kurz',
  shortname_stringlengthmax: 'Name ist zu lang',

  organisationtype_id_outofbounds: 'Ungültige Branche',
  email_email: 'Ungültige E-Mail',
  email_stringlengthmin: 'E-Mail ist zu kurz',
  email_stringlengthmax: 'E-Mail ist zu lang',
  phone_stringlengthmin: 'Telefon ist zu kurz',
  phone_stringlengthmax: 'Telefon ist zu lang',
  fax_stringlengthmin: 'Fax ist zu kurz',
  fax_stringlengthmax: 'Fax ist zu lang',
  website_stringlengthmin: 'Website ist zu kurz',
  website_stringlengthmax: 'Website ist zu lang',
  temperature_outofbounds: 'Ungültiges Temperatur-Niveau',
  parent_id_outofbounds: 'Ungültiges Unternehmen',
  user_id_outofbounds: 'Ungültiger Account-Manager',
  description_stringlengthmin: 'Beschreibung ist zu kurz',
  description_stringlengthmax: 'Beschreibung ist zu lang',
  description_important_stringlengthmin: 'Wichtige Hinweise ist zu kurz',
  description_important_stringlengthmax: 'Wichtige Hinweise ist zu lang',
  tags_outofbounds: 'Ungültige Tags',

  info_state: 'Status',
  info_state_unknown: 'unbekannt',
  info_state_blacklist: 'Auf der Blacklist',
  info_last_update: 'Letztes Update',
  info_orga_type: 'Branche',
  info_address: 'Adresse',
  info_parent: 'Muttergesellschaft',

  important_notes_short: 'Wichtige Hinweise',
  important_notes: 'Wichtige Hinweise für Kandidatenvorstellung',
  blacklist_hover: 'Dieses Unternehmen ist auf der Blacklist',
  blacklist_added: 'Unternehmen auf Blacklist gesetzt',
  blacklist_removed: 'Unternehmen von Blacklist entfernt',
  no_linkedin_hover: 'Link zu LinkedIn fehlt',
  no_xing_hover: 'Link zu Xing fehlt'

};

exports.organisation = organisation;
