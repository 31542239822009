import app from '../../app/app';;
import time from '../../app/time';

const tags = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12" id="tags-management"> </div> </div></div>'; return out;
    },
    js: function(it) {
          app.setTitle(t(`tag.${it.type}_title`)); app.setBreadcrumps({ 'Administration': '/admin', [t(`tag.${it.type}_title`)]: '/admin/tags/' + it.type }); function getColsByTagType(tagType) {  let defaultCols = [{text:'#',name:'id',width:30}, 'name'];  if (tagType === 'orga_status') { defaultCols.push('default_on_blacklist'); defaultCols.push('is_default'); return defaultCols; } return defaultCols } function getFieldsByTagType(tagType) {      let defaultFields = { 'type': { type: 'Hidden', value: it.type }, 'name': 'Text', 'is_default': { type: 'Switch', onText: 'ja', offText: 'nein' } };  if (tagType === 'orga_status') { defaultFields['default_on_blacklist'] = { type: 'Switch', onText: 'ja', offText: 'nein' }; return defaultFields }  return defaultFields } let $org_types = $('#tags-management').adminScaffold({ i18n_prefix: 'g.', title: t(`tag.${it.type}_title`), table: ['tag','tags'], type: it.type, fields: getFieldsByTagType(it.type), cols: getColsByTagType(it.type), render: { 'id': (obj) => { return '<b>' + obj.id + '</b>'; }, 'name': (obj) => { return '<a>' + obj.name + '</a><br /><small>erstellt: ' + time.ago(obj.created_at) + '</small>'; }, 'default_on_blacklist': (obj) => { return obj.default_on_blacklist == "0" ? t('g.no') : t('g.yes') }, 'is_default': (obj) => { return obj.is_default == "0" ? t('g.no') : t('g.yes') } } });
    }
};

export default tags;
