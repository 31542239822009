import app from '../../app/app';;
import api from '../../app/api';;
import msg from '../../app/msg';

const admin_jobadvert = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12" id="admin-jobadvert"> </div> </div></div>'; return out;
    },
    js: function(it) {
           app.setTitle('Bewerbungsquellen'); app.setBreadcrumps({ 'Admin-Einstellungen': '/admin', 'Bewerbungsquellen': '/admin/jobadvert' }); let $jobadvert = $('#admin-jobadvert').adminScaffold({ title: 'Einträge für "Wie bist du auf uns aufmerksam geworden?"', table: ['jobadvert','jobadverts'], fields: { 'text': 'Text' }, cols: [{text:'#',name:'id',width:30}, 'text', 'enabled', 'ja_count'], render: { 'id': (obj) => { return `<b>${obj.id}</b>`; }, 'text': (obj) => { return `<span>${obj.text}</span>`; }, 'enabled': (obj) => { let status = ''; if (obj.enabled == 1) { status = ' checked="checked"'; } return `<input type="checkbox" class="admin-jobadvert" name="jobapply-enabled-${obj.id}" value="${obj.id}"${status} />`; }, 'ja_count': (obj) => { return `<span>${obj.ja_count}</span>`; } }, afterLoad: () => {  $('.admin-jobadvert').change((ev) => { let $ja = $(ev.target); api.post(`/jobadverts/toggle-enabled`, { id: $ja.val() }, { success: () => { msg.success(t('g.save_success')); } }); });  $('.admin-jobadvert').each((index, elem) => { let $tr = $(elem).parent().parent(); let count = parseInt($tr.find('td:nth-child(4) > span').html()); if (count > 0) { $tr.find('td:nth-child(5)').find('[name="btn-delete"]').off('click').on('click', (ev) => { ev.preventDefault(); alert(t('jobadvert.delete_not_possible')); }); } }); }, });
    }
};

export default admin_jobadvert;
