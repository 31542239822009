import api from '../api';
import modal from '../../_compiled/components/modal';
import msg from "../msg";

$.fn.uiFormObjectaccordion = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $list;
  let $modal, $add_group_btn, $add_item_btn, $editsave_btn, $save_btn;
  let $form = $('<form></form>');

  options = $.extend({},{
    i18n_prefix: '',
    url: '/' + options.name,
    url_list: '/resource/list/' + options.name,
    url_edit: '/' + options.name,
    enable_add_group: true,
    enable_add_item: true,
    cv_id: 0,
    optionButtons: [],
    onEditMode:()=>{},
    render:(item) => {
      let name = item.name.toLowerCase();
      return `<div class="card card-${name}">
                <div class="card-header">
                  <h4 class="card-title">
                    <a data-toggle="collapse" data-parent="#${options.name}-accordion" href="#collapse-${name}" aria-expanded="true">
                      ${item.name}
                    </a>
                  </h4>
                </div>
                <div id="collapse-${name}" class="panel-collapse in collapse show">
                  <div class="card-body">
                    ${item.name}
                  </div>
                </div>
                <div>
                    <button class="btn btn-primary">${t(`${options.$i18n_prefix}add_accordion_item`)}</button>
                </div>
              </div>`;
    }
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);

  /*
   * Wenn wir eine cv_id > 0 haben gehen wir davon aus, dass sich alle Daten auf einen
   * speziellen CV beziehen und somit erweitern wir die url_edit um die cv_id
   */
  if(parseInt(options.cv_id, 10) > 0) {
    options.url_edit = options.url_edit + '/' + options.cv_id;
  }

  let plugin = this;

  this.initModals = () => {

    $editsave_btn = $('<button class="btn btn-default">Speichern</button>');
    $save_btn = $('<button class="btn btn-default">Speichern</button>');

    $modal = modal.js({
      title: t(options.i18n_prefix + options.name + '_edit'),
      size: 'lg'
    });

    $modal.$footer.append($save_btn);
    $modal.$footer.append($editsave_btn);
  };

  this.refresh = () => {
    $list.empty();

    api.get(options.url_list, {
      success: (items) => {
        plugin.setValue(items);
      }
    });
  };

  this.initOptionButtons = () => {

    options.optionButtons.push({
      name: 'update',
      icon: 'fas fa-pencil-alt',
      class: 'btn-default',
      click: (id, $btn, $tr, callback) => {

        api.get(options.url_edit +  '/' + id,{
          success: (item) => {
            $form[0].reset();
            $form.setValues(item);
            $form.find('.addgroup').hide();

            $modal.modal({
              show: true,
              backdrop: 'static',
              keyboard: false
            });

            if(callback) {
              callback();
            }

          }
        });
      }
    });

    options.optionButtons.push({
      name: 'delete',
      icon: 'fas fa-trash-alt',
      class: 'btn-danger',
      click: (id, $btn, $tr, callback) => {

        if(!callback) {
          callback = () => {};
        }

        if(confirm(t(options.i18n_prefix + options.name + '_delete_confirm'))) {
          $tr.hide();
          api.delete(options.url_edit + '/' + id,{
            success: () => {
              $tr.remove();
            },
            error: () => {
              $tr.show();
            },
            complete: () => {
              callback();
            }
          });
        }
        else {
          callback();
        }

      }
    });

  };

  this.updateOptionsButtons = () => {
    options.optionButtons.forEach((btn) => {

      if (btn.click) {
        $('.oa-btn-' + btn.name + '-' + options.name).click((ev) => {
          ev.preventDefault();

          let $btn = $(ev.currentTarget);
          let $tr = $btn.parent().parent().parent();
          let id = $btn.data('id');
          btn.click(id, $btn, $tr);

        });
      }
    });
  };

  this.initView = () => {
    $list = $(`<div id="${options.name}-accordion"></div>`);

    $add_group_btn = $(`<button class="btn btn-primary">${t(`${options.i18n_prefix}add_accordion`)}</button>`);
    $add_item_btn = $(`<button class="btn btn-primary">${t(`${options.i18n_prefix}add_accordion_item`)}</button>`);

    $add_group_btn.click((ev) => {
      ev.preventDefault();

      $form[0].reset();
      $form.find('[name="' + options.field_group_id + '"]').val(null).trigger('change');
      $form.find('[name="id"]').val('');
      $form.find('[type="hidden"]').not('[name="cv_id"]').val('');
      $form.setAddMode();
      $form.find('.addgroup').show();

      $modal.modal({
        show: true,
        backdrop: 'static',
        keyboard: false
      });
    });

    plugin.initOptionButtons();
  };

  this.initForm = () => {
    $form = $('<form></form>').uiForm({
      success: () => {
        msg.success(t('g.add_success'));
        plugin.refresh();
        $modal.modal('hide');
      },
      error: (res) => {
        if (res && res.responseJSON && res.responseJSON.error) {
          msg.error(t(res.responseJSON.error));
        } else {
          msg.error(t('g.save_error'));
        }

      },
      url: options.url,
      url_overwrite: options.url_overwrite,
      i18n_prefix: options.i18n_prefix + options.name + '_',
      fields: options.fields,
      $el_save: $save_btn,
      $el_edit: $editsave_btn,
      onEditMode: (data) => {
        options.onEditMode(data);
      },
    });

    $modal.$body.append($form);
  };

  // public methods
  this.initialize = () => {

    $label = $('<label>' + options.label + '</label>');

    plugin.initView();
    plugin.initModals();
    plugin.initForm();

    $element.addClass('form-group form-group-' + options.name).append($label).append($list);

    if (options.enable_add_group) {
      $element.append($add_group_btn);
    }

    return plugin;

  };

  this.setValue = (value) => {

    if(value && value.length > 0) {
      let $item, $name, $html;

      value.forEach((item) => {
        $list.append(options.render(item));

        $.each(item[options.items_array_key], (i, entry) => {
          let $btngrp = $('<div class="col-md-2"></div>');
          let $btns = $('<div></div>');

          options.optionButtons.forEach((btn) => {

            if(!btn.class) {
              btn.class = 'btn-default';
            }

            $btns.append(`<button data-id="${entry.id}" type="button" class="btn btn-sm oa-btn-${btn.name}-${options.name} ${btn.class}"><i class="${btn.icon}"></i></button>`);
          });

          $btngrp.append($btns);
          $btngrp.appendTo($(this).find('#'+options.name+'-accordion').find('#collapse-'+item.name.toLowerCase().split(' ').join('-')).find('.row').eq(i));
        });

      });

      /*
       * Add-Button für item
       */
      if (options.enable_add_item) {

        $.each($list.find('.btn-oa-additem'), (i, btn) => {
          $(btn).click((ev) => {
            ev.preventDefault();

            let cv_id = $form.find('[name="cv_id"]').val();
            let skilltype_id = $(btn).data('id');

            api.get('/cvdata/skilltypes/' + cv_id + '/' + skilltype_id, {
              success: (skilltypes) => {
                $form[0].reset();
                $form.find('[name="id"]').val('');
                $form.find('[type="hidden"]').not('[name="cv_id"]').val('');
                $form.setAddMode();
                $form.find('.addgroup').hide();
                $form.getFormElement(options.field_group_id).setValue(skilltypes);

                $form.find('[name="' + options.field_group_id + '"]').val(skilltype_id).trigger('change');


                $modal.modal({
                  show: true,
                  backdrop: 'static',
                  keyboard: false
                });
              }
            });

          });
        });

      }

      /*
       * Nach zeichnen aller Tabellen Zeilen Button Events hinzufügen
       */
      plugin.updateOptionsButtons();
    }

    $label.text(options.label_edit);
    /*$input.val(value);
    $input.attr('placeholder', options.placeholder_edit);*/
  };

  this.resetValue = () => {
    $label.text(options.label);
    /*$input.val('');
    $input.attr('placeholder', options.placeholder);*/
  };

    return this.initialize();
};
