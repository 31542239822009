import media from '../media';
import api from '../api';
import msg from '../msg';
import helper from '../helper';
import time from '../time';
import routes from '../routes';

$.fn.uiMyJobs = function(options) {

  // default options
  let plugin = this;
  let $element = $(this);

  options = $.extend({}, {
    title: false,
    onLoading: () => {
    },
    onFinish: () => {
    }
  }, options);

  let $list_am, $list_r, $card_am, $card_r;

  plugin.renderListItem = (job) => {

    let bg = 'bg-info';

    if(job.prio > 0) {
      bg = 'bg-success';
    }
    else if(job.prio > 4) {
      bg = 'bg-warning';
    }
    else if(job.prio > 8) {
      bg = 'bg-danger';
    }

    return $(`<li class="nav-item">
                <a href="/job/` + job.id + `" class="nav-link router-link">
                  <span class="float-right badge `+bg+`">`+job.prio+`</span> ` + job.title + `
                </a>
              </li>`);
  };

  plugin.appendJobs = ($list, jobs) => {

    jobs.forEach((job) => {
      $list.append(plugin.renderListItem(job));
    });

    routes.initLinks($list.find('.router-link'));

  };

  plugin.initCard = () => {
    $card_am = $('<div class="p-0"></div>').uiCard({
      title: 'Du bist AM für'
    });
    $card_r = $('<div class="p-0"></div>').uiCard({
      title: 'Du bist Recruiter für'
    });

    $list_am = $('<ul class="nav flex-column"></ul>');
    $list_r = $('<ul class="nav flex-column"></ul>');

    $card_am.$body.addClass('p-0').append($list_am);
    $card_r.$body.addClass('p-0').append($list_r);

    $element.append($card_am).append($card_r);
  };

  this.initialize = () => {

    plugin.initCard();

    api.get('/jobs/user/dashboard',{
      success: (jobs) => {
        if(jobs.recruiter !== undefined) {
          plugin.appendJobs($list_r, jobs.recruiter);
        }
        if(jobs.am !== undefined) {
          plugin.appendJobs($list_am, jobs.am);
        }
      }
    });

    return plugin;
  };

  return this.initialize();

};
