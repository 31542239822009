import media from '../media';
import api from '../api';
import modal from "../../_compiled/components/modal";
import msg from "../msg";
import routes from "../routes";

$.fn.uiCardDuplicates = function(options) {

  let plugin = this;
  let $element = $(this);

  // default options

  options = $.extend({},{
    title: false,
    collapse: false,
    onStatusChange: () => {}
  }, options);

  let $modal;

  plugin.showLoading = () => {
    $element.showLoading();
  };

  plugin.hideLoading = () => {
    $element.hideLoading();
  };

  plugin.initModal = () => {

    $modal = modal.js({
      title: t('jobstatus.add'),
      size: 'md',
      show: false
    });

  };

  plugin.showModal = (duplicate) => {

    $modal.$body.empty();
    $modal.$footer.empty();
    $modal.$title.empty();

    let type = 'Kandidat';

    if (duplicate.type == 'contact') {
      type = 'Kontakt';
    }

    $modal.$title.text(`${type}: ${duplicate.name}`);

    let $avatar = $('<img class="float-right profile-user-img img-fluid img-circle" src="' + media.image(duplicate.avatar,'128x128', '/img/avatar5.png') + '" />');

    $modal.$body.append($avatar);

    if(duplicate.email) {
      $modal.$body.append(`<div><strong><i class="fas fa-at mr-1"></i> E-Mail Adresse</strong><p class="text-muted">` + duplicate.email + `</p></div>`);
    }

    if(duplicate.phone) {
      $modal.$body.append(`<div><strong><i class="fas fa-phone mr-1"></i> Telefon</strong><p class="text-muted">` + duplicate.phone + `</p></div>`);
    }

    if(duplicate.phone_mobile) {
      $modal.$body.append(`<div><strong><i class="fas fa-phone mr-1"></i> Mobil</strong><p class="text-muted">` + duplicate.phone_mobile + `</p></div>`);
    }

    if(duplicate.phone_private) {
      $modal.$body.append(`<div><strong><i class="fas fa-phone mr-1"></i> Privat</strong><p class="text-muted">` + duplicate.phone_private + `</p></div>`);
    }

    if(duplicate.address) {

      let parts = [];

      if(duplicate.address.street) {
        if(duplicate.address.number) {
          parts.push(duplicate.address.street + ' ' + duplicate.address.number);
        }
        else {
          parts.push(duplicate.address.street);
        }
      }

      if(duplicate.address.city) {
        if(duplicate.address.zip) {
          parts.push(duplicate.address.zip + ' ' + duplicate.address.city);
        }
        else {
          parts.push(duplicate.address.city);
        }
      }

      if(duplicate.address.country) {
        parts.push(duplicate.address.country);
      }
      if(parts.length > 0) {
        $modal.$body.append(`<div><strong><i class="fas fa-map-marker-alt mr-1"></i> Adresse</strong><p class="text-muted">` + parts.join(', ') + `</p></div>`);
      }
    }

    //$modal.$body.append(`<hr />`);

    /*
     * Profile Button
     */
    let link_profile;

    if (duplicate.type == 'candidate') {
      link_profile = '/candidate/' + duplicate.id;
    }
    else if (duplicate.type == 'contact') {
      link_profile = '/crm/contact/' + duplicate.id;
    }

    let $btn_profile = $(`<a href="${link_profile}" target="_blank" class="btn btn-default">Zum Profil</a>`);
    $btn_profile.on('click', (ev) => {
      $modal.modal('hide');
    });

    $modal.$footer.append($btn_profile);

    /*
     * no dup Button
     */
    let no_dupl_link = `/candidate/${options.person.id}/isnoduplicateof/${duplicate.id}`;

    if (duplicate.type == 'contact') {
        no_dupl_link = `/contact/${options.person.id}/isnoduplicateof/${duplicate.id}`;
    }

    if (options.hide_noduplbtn !== true) {
      let $btn_no_duplicate = $('<button class="btn btn-primary">Kein Duplikat</button>');
      $btn_no_duplicate.on('click', (ev) => {
        ev.preventDefault();
        api.post(no_dupl_link, {
          is_no_duplicate: 1
        }, {
          success: () => {
            $('#duplicate-' + duplicate.person_id).remove();
            $modal.modal('hide');
          }
        });
      });

      $modal.$footer.append($btn_no_duplicate);
    }

    $modal.modal('show');
  };

  /**
   * options.person kann ein candidate oder ein contact sein
   */
  this.initialize = () => {

    plugin.initModal();

    if(options.person.duplicates.length > 0) {

      let title = options.person.duplicates.length + ' mögliche Duplikate';
      if(options.person.duplicates.length === 1) {
        title = '1 mögliches Duplikat';
      }

      $element.uiCard({
        title: title
      });

      options.person.duplicates.forEach((d) => {

        let link = `/candidate/${d.id}`;
        let type = 'Kandidat';

        if (d.type == 'contact') {
          link = `/contact/${d.id}`;
          type = 'Kontakt';
        }

        let $avatar = $(`<a id="duplicate-${d.person_id}" title="${type}: ${d.name} (${d.email})" href="${link}" class="router-link"><img alt="Avatar" class="table-avatar img-circle" src="${media.image(d.avatar, '40x40', '/img/avatar5.png')}" /></a>`);
        if (d.type === "candidate") {
          $avatar.find("img").css("border", "2px solid green");
        } else if (d.type === "contact") {
          $avatar.find("img").css("border-color", "2px solid blue");
        }
        
        $avatar.on('click', (ev) => {

          ev.preventDefault();

          plugin.showLoading();
          api.get(link,{

            success: (duplicate) => {
              duplicate.type = d.type;
              duplicate.person_id = d.person_id;
              plugin.showModal(duplicate);
            },
            complete: () => {
              plugin.hideLoading();
            }

          });

        });

        $element.$body.append($avatar);

      });

    }
    else {
      $element.hide();
    }

    return plugin;

  };

  return this.initialize();

};
