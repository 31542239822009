import media from '../media';
import api from '../api';
import msg from '../msg';
import helper from '../helper';
import time from '../time';
import modal from "../../_compiled/components/modal";
import button_save from "../../_compiled/components/button_save";

$.fn.uiMessageComposer = function(options) {

  // default options
  let plugin = this;
  let $element = $(this);

  options = $.extend({},{
    title: false,
    onLoading: () => {},
    onFinish: () => {}
  }, options);

  let $input_subject,
    $input_subject_wrapper,
    $input_message,
    $input_message_wrapper,
    $modal_generator,
    $checkbox_form,
    $btn_submit,
    texts = {},
    $orig_html = null;

  let btn_disable = false;
  let cvheck = false;
  let $form;

  plugin.disable = () => {
    $input_message.trumbowyg('disable');
    $('.trumbowyg-editor').addClass('noselect');
  };

  plugin.initForm = () => {
    $form = $('<form></form>').uiForm({
      i18n_prefix: 'usermailings.',
      fields: {
        'mail_contact_ids': {
          type: 'TagSelect',
          table: 'contact',
          candidate: options.candidate
        },
        'mail_send_copy_to_me': {
          type: 'Switch',
          name: 'mail_send_copy_to_me'
        },
        'mail_subject': 'Text',
        'mail_body_html': 'Textarea',
        'send_time': {
          type: 'Datetime',
          drops: 'up',
          empty: true,
          onlyFuture: true
        },
        'send_as': {
          type: 'Select',
          table: 'crmemail',
          fieldname: 'send_as'
        },
        'cc_recruiter': {
          type: 'TagSelect',
          table: {
            name: 'user',
            type: 'worker_active'
          },
          autocomplete: "off",
        },
        'cc_extern': 'Multiemail',
        'mail_file_attachment': {
          type: 'Sortlist',
          ajax: {
            url: '/candidatedocs/' + options.candidate.id + '/listing'
          }
        }
      }
    });

    $element.append($form);
  };

  plugin.initSubject = () => {

    $input_subject_wrapper = $('<div class="form-group"></div>');
    $input_subject = $('<input class="form-control message-subject" placeholder="Betreff:" />');
    $input_subject_wrapper.append($input_subject);

    $element.append($input_subject_wrapper);

  };

  plugin.disableSendButton = () => {

    $btn_submit.attr('disabled', true);
    btn_disable = true;
  }

  plugin.enableSendButton = () => {

    if(btn_disable) {
      $btn_submit.removeAttr('disabled');
      btn_disable = false;
    }
  }

  plugin.initSubmitButton = () => {
    $btn_submit = $('<button class="btn btn-primary">Nachricht senden</button>');

    $btn_submit.click(() => {

      let cc_recruiter = [];
      cc_recruiter = jQuery('[name="cc_recruiter[]"]').val();

      let cc_extern = [];
      $element.find('[name="cc_extern[]"]').each((index, elem) => {
        cc_extern.push($(elem).val());
      });

      let elemFiles = $form.getFormElement('mail_file_attachment');
      let pdf_files = [];

      $.each(elemFiles.find('input'), (i, elem) => {
        if ($(elem).prop('checked')) {
          pdf_files.push($(elem).val());
        }
      });

      let mail_contact_ids_obj = $element.find('[name="mail_contact_ids[]"]');
      let mail_send_copy_to_me_obj = $element.find('[name="mail_send_copy_to_me"]');

      let mail_send_copy_to_me_input = $('input[type=checkbox]');

      let data = {
        mail_contact_ids: mail_contact_ids_obj.val(),
        mail_send_copy_to_me: mail_send_copy_to_me_obj.val(),
        mail_subject: $element.find('[name="mail_subject"]').val().trim(),
        mail_body_html: $element.find('[name="mail_body_html"]').val().trim(),
        send_time: $element.find('[name="send_time"]').val().trim(),
        send_as: $element.find('[name="send_as"]').val().trim(),
        cc_recruiter: cc_recruiter,
        cc_extern: cc_extern,
        mail_file_attachment: pdf_files
      };

      if(!options.candidate.email || options.candidate.email.indexOf('@') < 0) {
        msg.error('Der Kandidat hat keine E-Mail Adresse hinterlegt.');
      }
      else if(data.mail_subject === '') {
        msg.error('Bitte Betreff eingeben');
      }
      else if(data.mail_body_html.replace(/(<([^>]+)>)/ig,"").trim() === '') {
        msg.error('Deine Nachricht ist leer!');
      }
      else {
        plugin.disableSendButton();

        lcs_off(mail_send_copy_to_me_input);
        mail_contact_ids_obj.val(0).trigger("change");

        options.onLoading();

        if (cvheck) {
          api.candidateMail( options.candidate.id, data, {
            success: () => {
              api.post('/candidate/cvcheck/' + options.candidate.id, {},{
                success: () => {
                  if (helper.empty(data.send_time)) {
                    msg.success('E-Mail versendet!');
                  }
                  else {
                    msg.success('E-Mail wird zum angegebenen Zeitpunkt versendet!');
                  }
                  plugin.enableSendButton();
                  $form[0].reset();
                },
                complete: () => {
                  plugin.enableSendButton();
                  options.onFinish();
                }
              });
            },
            error: () => {
              msg.error('Nachricht konnte nicht versendet werden');
              plugin.enableSendButton();
              $form[0].reset();
            }
          });
        }
        else {
          api.candidateMail( options.candidate.id, data, {
            success: () => {
              msg.success('Nachricht versendet!');
              plugin.enableSendButton();
              $form[0].reset();
            },
            error: () => {
              msg.error('Nachricht konnte nicht versendet werden');
              plugin.enableSendButton();
              $form[0].reset();
            },
            complete: () => {
              plugin.enableSendButton();
              options.onFinish();
            }
          });
        }
      }
    });

    $element.append($btn_submit);
  };

  plugin.renderGroup = (group) => {
    let $card = $('<div></div>').uiCard({
      title: group.name
    });

    let type = 'checkbox';
    if(group.multiple === false) {
      type = 'radio';
    }

    let $checkboxes = $('<div></div>').uiFormCheckboxes({
      values: group.items,
      label: false,
      name: 'textgenerator[' + group.id + ']',
      type: type
    });

    $card.$body.append($checkboxes);

    return $card;

  };

  plugin.setTexts = (settedtextgroups) => {

    let editor_text = $input_message.trumbowyg('html');
    let $editor_html = $('<div>' + editor_text + '</div>');
    let $html_content = $('<div></div>');

    if ($orig_html === null) {
      $orig_html = $editor_html;
    }

    $input_message.trumbowyg('empty');

    settedtextgroups.sort(function(a, b){
      return a.order - b.order;
    });

    settedtextgroups.forEach((settedtextgroup) => {

      let settedtexts = settedtextgroup.items;

      settedtexts.sort(function(a, b){
        return a.order - b.order;
      });

      settedtexts.forEach((text) => {

        editor_text = $input_message.trumbowyg('html');
        $editor_html = $('<div>' + editor_text + '</div>');

        let is_multiple = ' not-multiple';
        if(text.multiple === true) {
          is_multiple = ' is-multiple';
        }

        $editor_html.find('.rgroup-' + text.group_id+'.not-multiple:not(.rtext-'+text.id+')').remove();

        let $editor_founds = $editor_html.find( '.rtext-' + text.id+'' );

        if($editor_founds.length > 0) {
          $html_content.append($editor_founds);
        }
        else {
          if(text.headline && $editor_html.find('.rgroup-' + text.group_id).length === 0 && $html_content.find('.rgroup-' + text.group_id).length === 0) {
            $html_content.append('<p class="group-order-' + text.group_order + ' text-order-' + text.order + ' rtext rgroup-' + text.group_id + ' rtext-' + text.id + is_multiple + ' is-headline"><strong>' + helper.nl2br(helper.tpl(text.headline, options.candidate)) + '</strong></p>');
          }
          $html_content.append('<p class="group-order-' + text.group_order + 'text-order-' + text.order + ' rtext rgroup-' + text.group_id + ' rtext-' + text.id + is_multiple + '">' + helper.nl2br(text.message) + '</p>');
        }

      });

    });

    $input_message.trumbowyg('html', $html_content[0].innerHTML + $orig_html.html());
  };

  plugin.initTextgenerator = () => {

    let $btn_save = button_save.js();

    $btn_save.click(() => {
      let data = $checkbox_form.serializeObject();
      let texts_to_set = [];

      let prepared_groups = [];

      data.textgenerator.forEach((groupids) => {
        groupids.forEach((id) => {

          let currentText = texts[parseInt(id)];

          if(prepared_groups[currentText.group_id] === undefined) {
            prepared_groups[currentText.group_id] = {
              order: currentText.group_order,
              items: []
            }
          }

          prepared_groups[currentText.group_id].items.push(currentText);
        });
      });

      plugin.setTexts(prepared_groups);

      $modal_generator.modal('hide');
    });

    $modal_generator = modal.js({
      title: 'Text-Vorlagen',
      size: 'lg'
    });

    $modal_generator.$dialog.addClass('modal-dialog-scrollable');

    $modal_generator.$footer.append($btn_save);

    api.get('/recruitingtext/listgrouped',{
      success: (response) => {

        $checkbox_form = $('<form></form>');

        response.forEach((group) => {
          let $group = plugin.renderGroup(group);
          $checkbox_form.append($group);
          group.items.forEach((item) => {
            item.headline = group.headline;
            item.multiple = group.multiple;
            item.group_id = group.id;
            texts[item.id] = item;
          });
        });

        $modal_generator.$body.append($checkbox_form);
      }
    });
  };

  plugin.openTextgenerator = () => {
    $modal_generator.modal({
      show: true,
      backdrop: 'static',
      keyboard: false
    });
  };

  plugin.initMessage = () => {
    /*$input_message_wrapper = $('<div class="form-group"></div>');
    $input_message = $('<textarea class="form-control" style="height: 300px"></textarea>');
    $input_message_wrapper.append($input_message);

    $element.append($input_message_wrapper);*/

    $input_message = $element.find('[name="mail_body_html"]');

    $input_message.trumbowyg({
      btns: [['viewHTML'],['undo','redo'],['strong', 'em',], ['unorderedList','orderedList'], 'text_generator', ['link']],
      autogrow: true,
      lang: 'de',
      btnsDef: {
        text_generator: {
          fn: () => {
            plugin.openTextgenerator();
          },
          tag: 'tagName',
          title: 'Text Bausteine',
          hasIcon: true
        }
      }
    }).on('tbwchange', (ev) => {

    });
    $input_message.trumbowyg('html','<p></p>');
  };

  this.initialize = () => {

    //plugin.initSubject();
    //plugin.initMessage();

    plugin.initForm();
    plugin.initMessage();
    plugin.initTextgenerator();
    plugin.initSubmitButton();

    let elemFiles = $form.getFormElement('mail_file_attachment');
    if (elemFiles) {
      elemFiles.loadByAjax();
    }

    if(!options.candidate.email || options.candidate.email.trim() === '') {
      $element.prepend(`<div class="alert alert-danger alert-dismissible">
                  <h5><i class="icon fas fa-ban"></i> Fehlende E-Mail Adresse!</h5>
                  `+options.candidate.firstname+` hat keine E-Mail Adresse hinterlegt, es kann also keine Nachricht gesendet werden
                </div>`);
    }



    return plugin;
  };

  return this.initialize();
};
