import api from '../api';

$.fn.uiFormQuittinginfo = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let input;

  options = $.extend({}, {
    i18n_prefix: '',
    values: {
      'quitting_amount': {
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
        7: 7,
        8: 8,
        9: 9,
        10: 10,
        11: 11,
        12: 12
      },
      'quitting_period': {
        1: 'Woche(n)',
        4: 'Monat(e)'
      },
      'quitting_span': {
        1: 'Monatsende',
        3: 'Quartalsende',
        6: 'Halbjahr'
      }
    }
  }, options);

  options.label = t(options.i18n_prefix + 'lb_' + options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_' + options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_' + options.name);


  let plugin = this;
  let current_value;
  let $quitting_amount, $quitting_period, $quitting_span;

  this.showError = (key, value) => {
    $element.find('select').addClass('is-invalid');
    $element.append(`<span class="error invalid-feedback" style="clear: both;">${t(value)}</span>`);
  };

  // public methods
  this.initialize = () => {

    $label = $('<label>' + options.label + '</label>');
    input = '';

    if (options.values !== undefined) {
      $.each(options.values, (quitting_part_name, quitting_part) => {

        input += '<select name="' + options.name + '[' + quitting_part_name + ']" class="form-control ' + quitting_part_name + '">';
        input += '<option value="0">Bitte wählen</option>';

        for (var index in quitting_part) {
          input += '<option value="' + index + '">' + quitting_part[index] + '</option>';
        }

        input += '</select>';
      });

    }

    $element.addClass('form-group form-group-'+options).addClass('quitting-info').append($label).append(input);

    $quitting_amount = $element.find('select[name="quitting_info[quitting_amount]"');
    $quitting_period = $element.find('select[name="quitting_info[quitting_period]"');
    $quitting_span = $element.find('select[name="quitting_info[quitting_span]"');

    this.$input = input;

    return plugin;

  };


  this.setValue = (value) => {

    if(value) {
      current_value = value;

      if (value.quitting_amount == null) {
        $quitting_amount.val(0);
      }
      else {
        $quitting_amount.val(value.quitting_amount);
      }

      if (value.quitting_period == null) {
        $quitting_period.val(0);
      }
      else {
        $quitting_period.val(value.quitting_period);
      }

      if (value.quitting_span == null) {
        $quitting_span.val(0);
      }
      else {
        $quitting_span.val(value.quitting_span);
      }

      $label.text(options.label_edit);
    }

  };

  this.resetValue = () => {

    current_value = null;
    $element.find('select').val('');
    $element.find('select').val(0);
    $label.text(options.label);
  };

  return this.initialize();

};
