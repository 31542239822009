import app from '../../app/app';

const button_save = {
    tpl: function(it) {
        var out='<button type="button" class="btn btn-'+(it.type)+'"><i class="'+(it.icon)+'"></i> '+(it.text)+'</button>'; return out;
    },
    js: function(it) {
         let options = $.extend({},{ type: 'primary', text: t('g.save'), icon: 'fas fa-save' }, it); let $element = $(button_save.tpl(options)); return $element;
    }
};

export default button_save;
