$.fn.uiFormInfotext = function(options) {

  let $element = $(this);
  let $container;

  options = $.extend({},{
    i18n_prefix: 'infotext.',
    format: 'callout',       /* alert, callout */
    style: 'info'            /* danger, info, warning, success, error */
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);

  let plugin = this;

  // public methods
  this.initialize = () => {

    $container = $(`<div class="${options.format} ${options.format}-${options.style}">${options.text}</div>`);

    $element.addClass('form-group form-group-' + options.name).append($container);

    return plugin;

  };

  this.setValue = (value) => {
    $container.empty().html(value.replace(/\n/g, "<br>"));
  };

  this.resetValue = () => {
  };

  return this.initialize();

};
