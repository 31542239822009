import media from '../media';
import api from '../api';
import helper from '../helper';
import msg from '../msg';
import time from '../time';
import modal from "../../_compiled/components/modal";
import button_save from "../../_compiled/components/button_save";
import candidate_ctrl from "../candidate_ctrl";

$.fn.uiCandidateJoblist = function(options) {

  // default options
  let plugin = this;
  let $element = $(this);
  let $joblist = $('<div></div>');
  let eventsInitiated = false;
  let $loader = $('<div class="overlay"><i class="fas fa-2x fa-sync-alt fa-spin"></i></div>');
  let status_id = null;
  let status_date = null;
  let status_comment = null;
  let application_infos = [];

  $loader.hide();

  options = $.extend({},{
    afterLoad: () => {},
    afterAppinfoSubmit: () => {}
  }, options);

  let $list, $form, $modal, $btn_save, $statusDropdown, $statusDate, $status_comment, selectedJobids;
  let $form_statusmail, $modal_statusmail, $btn_save_statusmail;

  let $appinfo_modal;
  let $appinfo_wrapper;
  let $appinfo_form;
  let $btn_next = $('<button class="btn btn-xs btn-primary" data-job_id="">'+t('candidate.appinfo_next')+' <i class="fas fa-angle-right"></i></button>');

  plugin.showLoader = () => {
    $loader.show();
  };

  plugin.hideLoader = () => {
    $loader.hide();
  };

  plugin.initForm = () => {
    $form = $('<form action="/jobstatus/' + options.candidate.id + '"></form>').uiForm({
      i18n_prefix: 'jobstatus.',
      fields: {
        'jobpool': {
          type: 'Select',
          ajax_url: '/teams/pools'
        },
        'job': {
          type: 'Selectgroup',
          table: 'job'
        },
        'status': {
          type: 'Select',
          table: 'jobstatus',
          select_same: 'Master'
        },
        'touchpoint': {
          type: 'Select',
          table: 'touchpoint',
          select_same: 'Slave'
        },
        'status_date': 'Date',
        'status_comment': 'Text'
      }
    });
  };
  plugin.initModal = () => {

    $btn_save = button_save.js();

    $modal = modal.js({
      title: t('jobstatus.add'),
      size: 'md'
    });

    $form.find('[name=status_date]').val(helper.getTodayAsDateForInput());

    $modal.$body.append($form);
    $modal.$footer.append($btn_save);

    $form.submit((ev) => {
      ev.preventDefault();
    });

    $btn_save.click(() => {

      status_id = $form.find('[name=status_id] option:selected').val();
      status_date = $form.find('[name=status_date]').val();
      status_comment = $form.find('[name=status_comment]').val();

      api.post('/jobstatus/' + options.candidate.id,{
        jobpool_id: $form.find('[name=jobpool_id] option:selected').val(),
        job_id: $form.find('[name=job_id] option:selected').val(),
        status_id: status_id,
        status_date: status_date,
        status_comment: status_comment,
        touchpoint_id: $form.find('[name=touchpoint_id] option:selected').val()
      },{
        success: () => {
          $form[0].reset();
          $form.getFormElement('job').resetValue();
          $form.find('[name=status_date]').val(helper.getTodayAsDateForInput());

          $modal.modal('hide');
          msg.success(t('g.save_success'));
          plugin.update();
          options.afterLoad();
        }
      });

    });

  };

  plugin.initFormStatusMail = () => {
    $form_statusmail = $('<form></form>').uiForm({
      i18n_prefix: 'jobstatus.',
      fields: {
        'mail_template': {
          text: 'Template',
          type: 'Select2',
          ajax: false,
          allowInput: false
        },
        'mail_subject': 'Text',
        'mail_body_html': {
          type: 'Richtext',
          height: 240,
          resize: true,
        },
        'send_time': {
          type: 'Datetime',
          drops: 'up',
          empty: true,
          onlyFuture: true
        },
        'send_as': {
          type: 'Select',
          table: 'crmemail',
          fieldname: 'send_as'
        },
        'cc_recruiter': {
          type: 'Multiselect',
          table: 'user',
          ajax: true
        },
        'cc_extern': 'Multiemail'
      }
    });
  };

  plugin.initModalStatusMail = () => {

    $btn_save_statusmail = button_save.js({
      text: t('g.send')
    });

    $modal_statusmail = modal.js({
      title: t('jobstatus.send_statusmail'),
      size: 'xl'
    });

    $modal_statusmail.$body.append($form_statusmail);
    $modal_statusmail.$footer.append($btn_save_statusmail);

    $form_statusmail.submit((ev) => {
      ev.preventDefault();
    });

    let $mail_select = $form_statusmail.find('[name="mail_template_id"]');

    $mail_select.on('select2:select', () => {
      let template_id = $mail_select.find(':selected').val();

      api.post('/jobstatus/template/' + template_id, {
        candidate_id: options.candidate.id,
        jobids: selectedJobids
      }, {
        success: (template) => {
          $form_statusmail.getFormElement('mail_subject').resetValue();
          $form_statusmail.getFormElement('mail_body_html').resetValue();
          $form_statusmail.setValues(template);
        }
      });
    });

    $mail_select.on('select2:clear', () => {
      $form_statusmail.resetValues();
    });

    $btn_save_statusmail.click(() => {
      // evtl. Fehlermeldungen wieder entfernen
      /*$modal_statusmail.find('input[name="mail_subject"]').removeClass('is-invalid');
      $modal_statusmail.find('.error.invalid-feedback').remove();
      $modal_statusmail.find('.ql-toolbar').removeClass('is-invalid-important');
      $modal_statusmail.find('.ql-container').removeClass('is-invalid-important-noicon');*/

      let data = $form_statusmail.serializeObject();
      data['status_id'] = status_id;

      api.post('/candidate/' + options.candidate.id + '/statusmail', {
        data: data
      }, {
        success: () => {
          $modal_statusmail.modal('hide');
          $form_statusmail.resetValues();

          if (helper.empty(data['send_time'])) {
            msg.success('E-Mail versendet!');
          }
          else {
            msg.success('E-Mail wird zum angegebenen Zeitpunkt versendet!');
          }

          options.afterLoad();
        },
        error: (res) => {
          msg.error('Beim Versenden der Status E-Mail ist ein Fehler aufgetreten!');
          $form_statusmail.handleErrors(res);
        }
      });

    });

  };

  plugin.renderItem = (job) => {

    /* comment */
    let status_comment = '';
    if (job.status_comment !== null) {
      status_comment = `<br /><em>"${job.status_comment}"</em></p>`;
    }

    /* orga important note */
    let important_note = '';
    if ((job.organisation_description_important !== '') && (job.organisation_description_important !== null)) {
      const title_text = t('organisation.important_notes') + ': ' + job.organisation_description_important;
      important_note = ` 
        <span class="badge badge-danger ml-1" title="${title_text}" onClick="alert('${title_text}')">
          <i class="fas fa-exclamation"></i>
        </span>
        `;
    }

    let $item = $(`
        <div>
            <a data-job_id="${job.job_id}" href="#" class="jobstatus-btn-delete float-right btn-tool"><i class="fas fa-times"></i></a>
            <strong><i class="fas fa-building"></i> ${job.organisation_name}</strong>
            ${important_note}
            <p class="text-muted">
              <a href="https://jungwild.io/jobs/${job.job_slug}-${job.job_id}" target="_blank">${job.job_title}</a>
              <label style="color:${helper.calcFontColor(job.status_color)};background-color:${job.status_color}" class="badge jobstatus has-checkbox">
                <span>${job.status_name} (${time.md(job.updated_at)})</span>
                <input value="${job.job_id}" class="jobstatus-editbox" name="jobstatus-editbox[]" type="checkbox" />
              </label>
              ${helper.getEmployeeBadges(job)}
              <br />
              ${status_comment}
              ${t('jobstatus.lb_touchpoint')}:
              <select data-job_id="${job.job_id}" class="form-control form-control-sm touchpoint-select" style="margin-top: 5px"><option value="0-0">${t('candidate.choose_application_source')}</option></select>
            </p>
            <div class="mb-1">
                <button data-job_id="${job.job_id}" class="btn btn-xs btn-primary w-100 generate-candidate-job-cv">
                    <i class="fas fa-file-pdf mr-2"></i>${t('candidate.create_job_dossier')}
                </button>
            </div>
            <div class="mb-1">
                <button data-job_id="${job.job_id}" class="btn btn-xs btn-primary w-100 application-info">
                    <i class="fas fa-share-square mr-2"></i>${t('candidate.create_application_info')}
                </button>
            </div>
          <hr>
        </div>
    `);
    
    let $touchpointDropdown = $item.find('.touchpoint-select');  
    let touchpoint_id = null;
    if(job.touchpoint_id) {
      touchpoint_id = job.touchpoint_is_active_source + '-' + job.touchpoint_id;
    }
    plugin.initTouchpointDropdown($touchpointDropdown, touchpoint_id);

    return $item;

  };

   plugin.update = () => {
    plugin.initialLoad();
    plugin.disableStatusSwitcher();
  };

  plugin.initEventsOnce = () => {

    $statusDropdown.change(() => {

      plugin.showLoader();

      status_id    = $statusDropdown.val();
      let $comment = $('#candidate-job-options').find('[name="status_comment"]').val();

      if($comment.length > 255)
      {
          $comment = $comment.substr(0, 255);
          msg.error(t('jobstatus.length_error'));
      }

      api.post('/jobstatus/multistatus/' + options.candidate.id,{
        jobids: selectedJobids,
        status: $statusDropdown.val(),
        status_date: $('#candidate-job-options').find('[name="status_date"]').val(),
        //status_comment: $('#candidate-job-options').find('[name="status_comment"]').val()
        status_comment: $comment
      }, {
        success: (response) => {

          if (response.send_mail && parseInt(response.send_mail) == 1) {
            candidate_ctrl.dialogJobstatusMail(options.candidate.id, {
              apply: () => {
                $form_statusmail.setValues(response);
                $modal_statusmail.modal({
                  show: true,
                  backdrop: 'static',
                  keyboard: false
                });
              }
            });
          }

          plugin.update();
          options.afterLoad();
        },
        complete: () => {
          plugin.hideLoader();
        }
      });

    });
  };

  plugin.initEvents = () => {
    $joblist.find('.jobstatus-btn-delete').click((ev) => {
      let $this = $(ev.currentTarget);
      api.delete('/jobstatus/' + options.candidate.id + '/' + $this.data('job_id'),{
        success: () => {
          plugin.update();
          options.afterLoad();
        }
      });
    });

    let $jobcbs = $('.jobstatus-editbox:checkbox');
    $jobcbs.change(() => {
      selectedJobids = [];
      $jobcbs.each((i, el) => {

        plugin.disableStatusSwitcher();

        if(el.checked) {
          let $el = $(el);
          selectedJobids.push(parseInt($el.val()));
        }

      });

      if(selectedJobids.length > 0) {
        plugin.enableStatusSwitcher();
      }

    });

    if(!eventsInitiated) {
      eventsInitiated = true;
      plugin.initEventsOnce();
    }

    /*
     * Stelleninfos
     */
    $joblist.find('.application-info').click((ev) => {
      let job_id = $(ev.currentTarget).data('job_id');

      api.get('/jobs/application-info/' + options.candidate.id, {
        success: (infos) => {
          plugin.buildAppInfo(infos, job_id);

          $btn_next.click((ev) => {
            let job_id = $(ev.currentTarget).data('job_id');

            plugin.buildAppInfo(infos, job_id);
          });

          $appinfo_modal.modal({
            show: true,
            backdrop: 'static',
            keyboard: false
          });

        },
        error: (response) => {
          msg.error(response);
        }
      });
    });

  };

  plugin.buildAppInfo = (infos, job_id) => {
    for (let i = 0; i < infos.length; i++) {

      let next;

      if (i == infos.length - 1) {
        next = infos[0];
      } else {
        next = infos[i + 1];
      }


      if (infos[i].job_id == job_id) {
        let contacts = [];

        if (Array.isArray(infos[i].contacts)) {
          infos[i].contacts.forEach((contact) => {
            contacts.push(contact.name);
          });
        }

        let $organame = $appinfo_modal.find('.appinfo-organame');
        let $jobtitle = $appinfo_modal.find('.appinfo-jobtitle');

        $organame.text(infos[i].orga_name);
        $jobtitle.text(infos[i].job_title);

        $appinfo_wrapper.find('.appinfo').remove();

        let $appinfo = $('<div class="col-md-7 appinfo" data-job_id="' + infos[i].job_id + '"></div>');
        $appinfo.append(this.applicationInfo('Ansprechpartner', contacts.join(', '), false));

        if (infos[i].orga_important_notes !== '') {
          $appinfo.append(this.applicationInfo(t('organisation.important_notes'), infos[i].orga_important_notes, false));
        }

        $appinfo.append(this.applicationInfo('Unternehmens-Infos', infos[i].orga_info, false));
        $appinfo.append(this.applicationInfo('Job-Infos', infos[i].job_info, false));
        $appinfo.append(this.applicationInfo('Job-Beschreibung', infos[i].description, true));

        $appinfo_wrapper.prepend($appinfo);
        $appinfo_form.getFormElement('evaluations').setValue(infos[i].evaluations);

        $btn_next.data('job_id', next.job_id);
        $organame.parent().append($btn_next);

      }
    }
  };

  plugin.applicationInfo = (header, text, collapsed) => {
    if (text == null) {
      text = '';
    }

    let icon = 'fas fa-minus';
    let card_css = '';
    let body_css = '';

    if (collapsed == true) {
      icon = 'fas fa-plus';
      card_css = ' collapsed-card';
      body_css = ' style="display:none;"';
    }

    return `<div class="card card-default${card_css}">
        <div class="card-header">
          <h3 class="card-title">${header}</h3>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="${icon}"></i></button>
          </div>
        </div>
        <div style="overflow: auto;" class="card-body"${body_css}>${text}</div>
      </div>`;
  };

  plugin.initModalApplicationInfo = () => {
    $appinfo_wrapper = $('<div class="row"></div>');
    let $notice = $('<div></div>');
    let $notice_scroller = $('<div></div>');

    let scroller_css_is_settet = false;
    let scroller_css = {
      'position': 'fixed',
      'width': 'calc(5/12 * 100vw - 19px)',
      'height': 'calc(100vh - 140px)',
      'overflow-y': 'auto',
      'max-width': '585px'
    };

    if(screen.width >= 1050) {
      $notice_scroller.css(scroller_css);
    }


    $(window).resize(() => {
      console.log(screen.width);
      if(screen.width < 1050 && scroller_css_is_settet === true){
        $notice_scroller.attr('style','');
      }
      else if(scroller_css_is_settet === false && screen.width >= 1050) {
        $notice_scroller.css(scroller_css);
      }
    });

    let $notice_wrapper = $('<div class="col-md-5"></div>');
    let $title = $(`<strong><i class="fas fa-building"></i> <span class="appinfo-organame"></span></strong>
        <br /><span class="appinfo-jobtitle"></span>`);

    let $btn_save = button_save.js();
    $appinfo_form = $('<form></form>').uiForm({
      i18n_prefix: 'candidate.',
      fields: {
        'evaluations': 'Multievaluation',
        'employed': 'Switch',
        'quitting_info': {
          text: 'Kündigungsfrist',
          type: 'Quittinginfo'
        },
        'free_after': 'Date',
        'quitting_date_immediatly': 'Switch',
        'workexperience': 'Text',
      },
    });

    $appinfo_form.setValues(options.candidate);

    $notice.append($appinfo_form).append($btn_save);
    $notice_scroller.append($notice);
    $notice_wrapper.append($notice_scroller);
    $appinfo_wrapper.append($notice_wrapper);

    $appinfo_modal = modal.js({
      size: 'xxl'
    });

    $appinfo_modal.$title.append($title);
    $appinfo_modal.$body.append($appinfo_wrapper);

    $btn_save.click((ev) => {
      ev.preventDefault();

      api.post('/jobs/application-info/', {
        candidate_id: options.candidate.id,
        evaluations: $appinfo_form.serializeObject()
      }, {
        success: () => {
          msg.success(t('g.save_success'));
          $appinfo_modal.modal('hide');
          options.afterAppinfoSubmit();
        },
        error: (err) => {
          msg.error(t('g.error'));
        }
      });
    });
  };

  plugin.initialLoad = () => {

    api.get('/jobstatus/' + options.candidate.id, {
      success: (jobs) => {

        $joblist.empty();

        jobs.forEach((job) => {
          let $item = plugin.renderItem(job);
          $joblist.append($item);
        });

        plugin.initEvents();

      }
    });

  };

  plugin.openForm = () => {
    $modal.modal({
      show: true,
      backdrop: 'static',
      keyboard: false
    });
  };

  plugin.initStatusswitcher = () => {
    $statusDropdown = $('<select class="form-control form-control-sm" name="multijobstatus" disabled><option value="0">Status wählen...</option></select>');

    let $now = new Date();
    let $today = $now.getFullYear() + '-' + ($now.getMonth() + 1) + '-' + $now.getDate();

    // Status Datum Input
    $statusDate = $('<span></span>').uiFormDate();
    $statusDate.find('label').remove();
    $statusDate.find('input')
      .attr('placeholder', '')
      .attr('name', 'status_date')
      .addClass('form-control')
      .addClass('form-control-sm')
      .val($today);

    // Status Kommentar Input
    $status_comment = $('<span></span>').uiFormText();
    $status_comment.find('label').remove();
    $status_comment.find('input')
      .attr('placeholder', 'Kommentar...')
      .attr('name', 'status_comment')
      .addClass('form-control')
      .addClass('form-control-sm')
      .attr('limit_length', '255');

    let $p = $('<p></p>');
    $p.append($statusDropdown);
    $p.append($statusDate);
    $p.append($status_comment);

    api.get('/resource/select/jobstatus', {
      success: (options) => {
        options.forEach((opt) => {
          $statusDropdown.append('<option value="' + opt.id + '">' + opt.name + '</option>');
        });
        $element.prepend($p);
      }
    });



  };

  plugin.disableStatusSwitcher = () => {
    $statusDropdown.val('0');
    $statusDropdown.prop('disabled', true);
  };

  plugin.enableStatusSwitcher = () => {
    $statusDropdown.val('0');
    $statusDropdown.prop('disabled', false);
  };

  plugin.initTouchpointDropdown = (touchpointDropdown, id) => {

    api.get('/resource/select/touchpoint', {
      success: (options) => {
        options.forEach((opt) => {
          if(id === opt.id) {
            touchpointDropdown.append('<option value="' + opt.id + '" selected>' + opt.name + '</option>');
          }
          else {
            touchpointDropdown.append('<option value="' + opt.id + '">' + opt.name + '</option>');
          }
        });
      }
    });

    touchpointDropdown.change((ev) => {
      let $this = $(ev.currentTarget);     

      let touchpoint_id = $this.val();
      let job_id = $this.data('job_id');

      api.post('/touchpoint/change/' + options.candidate.id,{
        job_id: job_id,
        touchpoint_id: touchpoint_id       
      }, {
        success: (response) => {
          msg.success(t('g.save_success'));
        },
        error: (response) => {
          msg.error(t('g.error'));
        }
      });
    });
  };

  this.initialize = () => {

    $element.append($joblist);

    plugin.initForm();
    plugin.initFormStatusMail();
    plugin.initModal();
    plugin.initModalStatusMail();
    plugin.initialLoad();
    plugin.initStatusswitcher();
    plugin.initModalApplicationInfo();

    $element.parent().append($loader);

    return plugin;
  };

  return this.initialize();
};



/*



 */

