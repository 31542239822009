import time from '../app/time';
import helper from '../app/helper';
import infosystem from '../app/infosystem';
import session from '../app/session';

const tasks_config_ex = (form_fields, additional_params ) => {

  function replaceLinkableText(inputText, fullText = '') {

    let replacedText, replacePattern1, replacePattern2, replacePattern3;

    //URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=*~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

    //Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    //if there exist an cut url string, replace cut url with full url
    let cut_end_pos = replacedText.lastIndexOf('</a> ...');
    if(cut_end_pos !== -1 ) {
      let cut_url_str = '';
      //find a cut url string
      let cut_begin_pos = replacedText.lastIndexOf('">');      
      if(cut_begin_pos !== -1 ) {
        cut_url_str = replacedText.substring(cut_begin_pos + 2, cut_end_pos);
      }

      //if there exist a cut url string, replace it with origin url string
      if(cut_url_str.length && fullText.length) {

        let origin_url_str = '';
        //find origin url string position
        let origin_begin_pos = inputText.lastIndexOf(cut_url_str, cut_end_pos);
        let origin_end_pos = fullText.indexOf(' ', origin_begin_pos);
        if(origin_end_pos === -1){
          origin_end_pos = fullText.length;
        }

        //get origin url string
        if(origin_begin_pos !== -1){
          origin_url_str = fullText.substring(origin_begin_pos, origin_end_pos);
        }

        //replace cut string with origin one
        if(origin_url_str.length) {
          let replace_begin_pos = replacedText.lastIndexOf(cut_url_str, cut_begin_pos);
          if(replace_begin_pos >= 0) {
            let first_str = replacedText.substring(0, replace_begin_pos);
            let second_str = replacedText.substring(replace_begin_pos, replacedText.length);
            second_str = second_str.replace(cut_url_str, origin_url_str);
            
            replacedText = first_str + second_str;
          }
        }
      }
    }

    return replacedText;
  };
  

  return {
    default_order: [3, 'asc'],
    i18n_prefix: 'task.',
    pageLength: 10,
    additionalTitle: 'Erweitert',
    additionalParams: additional_params,
    identify_option_btn: true,
    col_width_percent: true,
    cols: [
      { name: 'done', text: 'Fertig', width: 15 },
      { name: 'name', text: 'Aufgabe', width: 25 },
      { name: 'description', text: 'Notizen', width: 40 },
      /*{name: 'creator_id', text: 'Ersteller'},*/
      { name: 'due', text: 'fällig', width: 12 },
      { name: '___user', text: 'Zuständig', width: 15, order: false }
    ],
    render: {
      'done': (obj) => {
        let status = '';
        if (obj.done == 1) {
          status = ' checked="checked"';
        }

        return `
                <input type="checkbox" class="task-checkbox" name="task-done-${obj.id}" value="${obj.id}"${status} />
                <input type="hidden" id="task-checkbox-info-${obj.id}" value="${obj.done == 0 ? 1 : 0}" />
               `;
      },
      'name': (obj) => {

        let contactContent = '';

        if (obj.contact) {

          contactContent += `<a href='/crm/contact/${obj.contact.id}' target="_blank" rel="noopener noreferrer">${obj.contact.name}</a>`;

          /* contacts can be assigned to different organisations */
          if (obj.organisation) {
            for (const orga of obj.organisation) {
              contactContent += `<br /><a href='/crm/agency-organisation/` + orga.id + `' target="_blank" rel="noopener noreferrer">` + orga.name + `</a>`;
            }
          }

          contactContent += '<br>' + helper.getMailAndPhones(obj.contact);
        }

        if (obj.candidate) {
          contactContent += '<a href="/candidate/' + obj.candidate.id + '" target="_blank" rel="noopener noreferrer">' + obj.candidate.name + '</a>';
          contactContent += '<br>' + helper.getMailAndPhones(obj.candidate);
        }

        /* if no contact and no candidate but organization */
        if ((!obj.contact) && (!obj.candidate) && obj.organisation && (obj.organisation.length >= 1)) {
          for (const orga of obj.organisation) {
            contactContent += `<a href='/crm/agency-organisation/${orga.id}' target="_blank" rel="noopener noreferrer">${orga.name}</a>`;
            contactContent += '<br>' + helper.getMailAndPhones(orga);
          }
        }

        let is_done_css = '';
        if (obj.done == 1) {
          is_done_css = ' is-done';
        }

        return `
          <a 
            href="/crm/task/${obj.id}" 
            target="_blank" 
            rel="noopener noreferrer" 
            class="text-bold ${is_done_css}" 
            data-trigger="hover"
          >${obj.name}</a>
          <br />
          <small>${contactContent}</small>`;
      },
      /*'creator_id': (obj) => {
        if (obj.creator === null) {
          return '<span></span>';
        }
        else {
          return '<span>' + obj.creator.name + '</span>';
        }
      },*/
      'due': (obj) => {
        return '<span style="display:none;">' + obj.due + '</span><strong>' + time.md(obj.due) + '</strong><br /><small>' + time.time(obj.due) + '</small>';
      },
      'description': (obj) => {

        let $limit_desc_len = 100;
        let $desc_column_width = 1500;

        obj.description = helper.nl2br(obj.description);

        if (obj.description.length > $limit_desc_len) {
          let full_desc = obj.description;
          let mini_desc = obj.description.substr(0, $limit_desc_len) + ' ...';

          let full_description = replaceLinkableText(full_desc);
          let mini_description = replaceLinkableText(mini_desc, full_desc);

          let render_full_desc = "<div style='max-width: " + $desc_column_width + "px; display: none;' id='full-desc-" + obj.id + "'>" + full_description + "</div>";
          let render_mini_desc = "<div style='max-width: " + $desc_column_width + "px;' id='mini-desc-" + obj.id + "'>" + mini_description + "</div>";

          let show_more_less_btn = '<div class="task-desc-show" style="margin-top: 5px;">' +
            '<p data-id="' + obj.id + '" class="btn btn-primary btn-xs task-desc-show-more">mehr <i class="fas fa-caret-down"></i><p>' +
            '<p data-id="' + obj.id + '" class="btn btn-primary btn-xs task-desc-show-less" style="display: none;">weniger <i class="fas fa-caret-up"></i><p>' +
            '</div>';

          return render_mini_desc + render_full_desc + show_more_less_btn;
        }
        else {
          if (obj.description.length) {
            obj.description = replaceLinkableText(obj.description);
          }
          return "<div style='max-width: " + $desc_column_width + "px; '>" + obj.description + '</div>';
        }
      },

      '___user': (obj) => {
        if (obj.user && obj.user.length == 1) {
          let data_team = 0;
          if (obj.team_id !== null) {
            data_team = parseInt(obj.team_id, 10);
          }

          return '<a data-id="' + obj.user[0].id + '" data-name="' + obj.user[0].name + '" data-team="' + data_team + '" title="' + t('g.user_connectiontype_' + obj.user[0].connection, obj.user[0]) + '" href="#" class="avatar-circle mr-2 set-user-gap" title="' + obj.user[0].name + '" style="background-color:' + obj.user[0].color + '"><span class="initials">' + helper.initials(obj.user[0].name) + '</span></a>';
        } else if (obj.user && obj.user.length > 1) {
          let out = [];
          obj.user.forEach((u) => {
            if (u.name === undefined) {
              out.push('<a class="set-user-gap" data-id="' + u.id + '" data-name="??" href="#" title="' + t('g.user_connectiontype_' + u.connection, u) + '"><strong>??</strong></a>');
            }
            else {
              out.push('<a class="set-user-gap" data-id="' + u.id + '" data-name="' + u.name + '" href="#" title="' + t('g.user_connectiontype_' + u.connection, u) + '"><strong>' + helper.initials(u.name) + '</strong></a>');
            }
          });
          return out.join(', ');
        }
      }
    },
    table: ['task', 'tasks'],
    fields: form_fields,
    default: {
      user: {
        id: session.user.id,
        name: t('g.me')
      }
    },
    afterLoad: () => {
      infosystem.update();
    }
  }
};

export default tasks_config_ex;
