// eslint-disable-next-line func-style,no-unused-vars
import api from '../api';

let historyBox = function (options) {
  let plugin = {};
  let url = options.url;
  let fillDataFunction = options.fillDataFunction;

  plugin.title = options.title;
  plugin.name = 'changes';
  plugin.icon = 'fas fa-history';
  plugin.click = (id, $btn, $tr, ev) => {
    ev.preventDefault();

    api.get(url + id, {
      success: (items) => {

        let $content = $('<div></div>');
        fillDataFunction(items, $content);
        $content.find('hr').last().remove();

        $btn.popover({
          title: plugin.title,
          content: $content,
          placement: 'left',
          html: true,
          trigger: 'manual',
        });

        let eventListenerInterval = window.setInterval(function(){
          if(jQuery(".popover").length > 0){
            jQuery(window).click(function(){$(".popover").popover('hide')});
            jQuery(".popover").click(function(event){event.stopPropagation()});
            clearInterval(eventListenerInterval);
          }

        }, 500);

        $btn.popover('show');

        jQuery('.popover').css('max-width', '1000px');

        let expanderBinder = window.setInterval(function() {
            if(jQuery(".changelog-expander").length > 0) {
              jQuery('.changelog-expander').unbind('click');
              jQuery('.changelog-expander').click(function(){
              let elId = jQuery(this).attr('id').replace('changelog-expander-', '');
                if(!jQuery(this).hasClass('changelog-expanded')){
                  jQuery('#changelog-long-value1-' + elId).hide();
                  jQuery('#changelog-long-value2-' + elId).show();
                  jQuery(this).text(' [Weniger zeigen]');
                  jQuery(this).addClass('changelog-expanded');
                } else {
                  jQuery('#changelog-long-value2-' + elId).hide();
                  jQuery('#changelog-long-value1-' + elId).show();
                  jQuery(this).text('[Mehr zeigen]');
                  jQuery(this).removeClass('changelog-expanded');
                }

              });
              clearInterval(expanderBinder);
            }
          }, 500
        )

      }
    });
  }

  return plugin;
};

export default historyBox;
