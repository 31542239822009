import api from '../../app/api';
import msg from '../../app/msg';

$.fn.uiFollow = function(options) {
  // support multiple elements
  if (this.length > 1){
    this.each(() => { $(this).uiFollow(options) });
    return this;
  }

  // default options

  options = $.extend({},{
    model: 'candidate',
    state: null
  }, options);

  if(options.i18n_prefix === undefined) {
    options.i18n_prefix = options.model+'.';
  }

  // private variables
  let $loader = null;
  let follow_state = false;

  let plugin = this;

  let $element = $(this);
  let $btn_follow;
  let $btn_follow_text;
  let $btn_follow_icon;
  // ...

  plugin.showLoading = () => {
    $btn_follow_icon.removeClass('fa-bell').addClass('fa-sync fa-spin');
  };

  plugin.hideLoading = () => {
    $btn_follow_icon.removeClass('fa-sync fa-spin').addClass('fa-bell');
  };

  plugin.setState = (state) => {

    if(!state) {
      follow_state = false;
    }
    else if(state.followed !== undefined) {
      follow_state = state.followed;
    }

    if(!follow_state) {
      $btn_follow_text.text(t('g.follow'));
      $btn_follow.removeClass('btn-outline-secondary').addClass('btn-danger');
      $btn_follow_icon.removeClass('far').addClass('fas');
    }
    else {
      $btn_follow_text.text(t('g.followed'));
      $btn_follow.removeClass('btn-danger').addClass('btn-outline-secondary');
      $btn_follow_icon.removeClass('fas').addClass('far');
    }
  };

  plugin.initButton = () => {

    $btn_follow = $('<button class="btn btn-danger btn-xs w-100"><i class="fas fa-bell mr-2"></i><span>' + t('g.follow') + '</span></button>');
    $btn_follow_icon = $btn_follow.find('i');
    $btn_follow_text = $btn_follow.find('span');

    $btn_follow.on('click', (ev) => {

      ev.preventDefault();
      plugin.showLoading();

      let uri = '/resource/follow/' + options.model + '/' + options.id;
      let success_msg = t(options.i18n_prefix + 'follow_success');
      if(follow_state === true){
        uri = '/resource/unfollow/' + options.model + '/' + options.id;
        success_msg = t(options.i18n_prefix + 'unfollow_success');
      }

      api.get(uri, {
        success: (state) => {
          plugin.setState(state);
          msg.info(success_msg);
        },
        complete: () => {
          plugin.hideLoading();
        }
      });

    });

    $element.append($btn_follow);

  };

  plugin.update =() => {
    api.get('/resource/getfollower/' + options.model + '/' + options.id, {
      success: (follows) => {
        plugin.setState(follows);
      }
    });
  };

  // public methods
  this.initialize = () => {

    plugin.initButton();

    plugin.update();

    return plugin;
  };

  return this.initialize();
};


