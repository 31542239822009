//const io = require('socket.io-client');
import session from './session';
import msg from './msg';
import suckit_actions from './suckit_actions';

const suckit = {

  io: null,

  init: () => {
    suckit.io = io({
      path: '/suckit',
      autoConnect: false
    });

    suckit.io.on('broadcast', (data) => {
      console.log('receive broadcast!');
      console.log(data);
    });

    suckit.io.on('action', (data) => {
      console.log('receive action!');
      if(data.action !== undefined && suckit_actions[data.action] !== undefined) {
        suckit_actions[data.action]();
      }
      console.log(data);
    });

    suckit.io.on('connect',() => {
      suckit.io.emit('set_client', {
        uid: session.user.id,
        aid: session.user.agency_id
      });
    });

    suckit.io.on('msg', (data) => {
      msg.handleSuckit(data);
    });
  },

  connect: () => {
    suckit.io.open();
  },

  disconnect: () => {

    console.log('disconnect socket.io!');

    suckit.io.disconnect();
  }

};

export default suckit;
