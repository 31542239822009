import api from '../api';
import session from '../session';
import routes from '../routes';
import time from '../time';
import helper from '../helper';
import modal from '../../_compiled/components/modal';
import button_save from '../../_compiled/components/button_save';
import { g } from '../../i18n/de/g';
import tasks_result from "../../_compiled/components/search_result_tasks";

$.fn.uiTaskTableMobile = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);  

  options = $.extend({},{
    add: true,
    edit: true,
    delete: true,
    hide_search: false,
    i18n_prefix: null,
    table_options: {},
    onFinishLoading: () => {},
    render: {},
    success: (data) => {},
    afterLoad: () => {},
    uri: '',
    addSuccess: (res) => {},
    editSuccess: (new_data, old_data) => {},
    onAddMode:()=>{},
    onEditMode:(data)=>{},
    pageLength: 15,
    optionButtons: [],
    class: ' table-striped',
    additionalTitle: t('g.advanced'),
    resetFilters: false,
    resetFiltersUrl: null,
    autosearch: true
  }, options);

  if(!options.i18n_prefix) {
    options.i18n_prefix = options.table[0] + '.';
  }

  if(options.model === undefined) {
    options.model = options.table[1].substring(0,1).toUpperCase()+options.table[1].substring(1);
  }

  let plugin = this;
  let $card = null;
  let $card_tools = null;
  let $card_title = null;
  let $loader = null;
  let additional_query = {};  
  let $form = null;
  let $modal = null;
  let $btn_new = null;
  let $btn_save = null;
  let $btn_save_edit = null;
  let $box_result_count = null;
  let $search_field = null;
  let $btn_search = null;
  let $btn_reset_filter = null;      
  let $search_results = null;
  let $page_no = 1;
  let $search_results_count = 0;
  let $data_ready_to_update = false;

  plugin.options = options;

  plugin.setQuery = (key, value) => {
    additional_query[key] = value;
  };

  plugin.scrollBottomReached = () => {
    return $(window).scrollTop() >= plugin.offset().top + plugin.outerHeight() - window.innerHeight;
  };

  plugin.addEventHandlers = () => {
    $(window).on('scroll', function() {
      if (plugin.scrollBottomReached() && $data_ready_to_update) {
        $data_ready_to_update = false;
        $page_no++;
        plugin.update();
      }
    });
  };

  plugin.makeOptionButtons = (obj) => {
    let btngrp = '';
    let $btnTooltip = '';

    options.optionButtons.forEach((btn) => {

      if(!btn.class) {
        btn.class = 'btn-default';
      }

      $btnTooltip = '';
      if(btn.title) {
        $btnTooltip = 'title="' + btn.title + '" '; 
      }
      
      if(btn.render) {
        btngrp += btn.render(obj,btn);
      }
      else {
        btngrp += '<button data-id="' + obj.id + '" type="button" class="btn btn-sm dt-btn-' + btn.name + ' ' + btn.class + '" ' +  $btnTooltip + '><i class="' + btn.icon + '"></i></button>';        
      }
    }); 

    return '<div class="btn-group btn-group-datatable" role="group">' + btngrp + '</div>'; 
  };

  plugin.placeData = (data, append) => {
    if(append === false)
      $search_results.html('');     

    data.forEach((obj) => {
      
      let $task_el = tasks_result.js(obj);

      let status = '';
      if (obj.done == 1) {
        status = ' checked="checked"';
      }

      let $done_item = `<input type="checkbox" class="task-checkbox" name="task-done-${obj.id}" value="${obj.id}"${status} />
                <input type="hidden" id="task-checkbox-info-${obj.id}" value="${obj.done == 0 ? 1 : 0}" />`;
      let $user_area = $task_el.find('#done-area');
      $user_area.append($done_item);     

      let popover_content = '';

      if (!helper.empty(obj.description)) {
        popover_content = '<p>' + obj.description + '</p>';
      }

      let targets = [];

      if (obj.contact) {

        let cname = `<a href='/crm/contact/${obj.contact.id}' class='router-link'>${obj.contact.name}</a>`;

        popover_content += `<p>`;
        popover_content += `<strong><a href='/crm/contact/` + obj.contact.id + `' class='router-link'>` 
          + obj.contact.name + `</a></strong>`;

        /*
          * Es könnte sein, dass ein Kontakt mehreren Unternehmen zugeordnet ist. Wir nehmen aber hier
          * aber erstmal nur die Infos aus dem ersten Array-Element.
          */
        if (obj.organisation && obj.organisation.length >= 1) {
          popover_content += `<br /><small><a href='/crm/agency-organisation/` + obj.organisation[0].id + 
            `' class='router-link'>` + obj.organisation[0].name + `</a></small>`;
        }
        popover_content += `</p>`;

        if (obj.contact.phone) {
          cname += ' <a class="copy-to-clip" data-text="' + obj.contact.phone + '" href="#"><i class="fas fa-phone"></i></a>';
          popover_content += `<i class='fas fa-phone'></i> &nbsp;<a class='copy-to-clip' href='#` + 
            obj.contact.phone + `'>` + obj.contact.phone + `</a><br />`;
        }
        if (obj.contact.email) {
          cname += ' <a href="mailto:' + obj.contact.email + '"><i class="fas fa-envelope"></i></a>';
          popover_content += `<i class='fas fa-envelope'></i> &nbsp;<a href='mailto:` + obj.contact.email + `'>` 
            + obj.contact.email + `</a>`;
        }

        if (obj.organisation && obj.organisation.length >= 1) {
          cname += `<br /><a href='/crm/agency-organisation/` + obj.organisation[0].id + `' class='router-link'>` + obj.organisation[0].name + `</a>`;
        }
        targets.push(cname);
      }

      if(obj.candidate) {
        let cname = '<a href="/candidate/' + obj.candidate.id + '" class="router-link">' + obj.candidate.name + '</a>';

        if (obj.candidate.phone) {
          cname += ' <a class="copy-to-clip" data-text="' + obj.candidate.phone + '" href="#"><i class="fas fa-phone"></i></a>';
          popover_content += `<i class='fas fa-phone'></i> &nbsp;<a class='copy-to-clip' href='#` + obj.candidate.phone + `'>` + obj.candidate.phone + `</a><br />`;
        }
        if (obj.candidate.email) {
          cname += ' <a href="mailto:' + obj.candidate.email + '"><i class="fas fa-envelope"></i></a>';
          popover_content += `<i class='fas fa-envelope'></i> &nbsp;<a href='mailto:` + obj.candidate.email + `'>` + obj.candidate.email + `</a>`;
        }

        targets.push(cname);
      }

      let is_done_css = '';
      if (obj.done == 1) {
        is_done_css = ' is-done';
      }

      // let $name_item = `<a href="/crm/task/${obj.id}" role="button" data-toggle="popover" data-id="${obj.id}" data-content="${popover_content}" data-html="true" class="text-bold router-link${is_done_css}" data-trigger="hover">${obj.name}</a><br /><small>${targets.join(', ')}</small>`;
      let $name_item = `<a href="/crm/task/${obj.id}" role="button" data-id="${obj.id}" data-html="true" class="text-bold router-link${is_done_css}" data-trigger="hover">${obj.name}</a><br /><small>${targets.join(', ')}</small>`;
      let $name_area = $task_el.find('#name-area');
      $name_area.append($name_item); 

      let $creator_id_item = '';
      if (obj.creator === null) {
        $creator_id_item = '<span></span>';
      }
      else {
        $creator_id_item = '<span>' + obj.creator.name + '</span>';
      }
      
      let $creator_id_area = $task_el.find('#creator-id-area');
      $creator_id_area.append($creator_id_item); 

      let $due_item = '<span style="display:none;">' + obj.due + '</span><strong>' + time.md(obj.due) + '</strong><br /><small>' + time.time(obj.due) + '</small>';
      let $due_area = $task_el.find('#due-area');
      $due_area.append($due_item); 

      let $user_item = '';
      if (obj.user && obj.user.length == 1) {
        let data_team = 0;
        if (obj.team_id !== null) {
          data_team = parseInt(obj.team_id, 10);
        }

        $user_item = '<a data-id="' + obj.user[0].id + '" data-name="' + obj.user[0].name + '" data-team="' + data_team + '" title="' + t('g.user_connectiontype_' + obj.user[0].connection, obj.user[0]) + '" href="#" class="avatar-circle mr-2 set-user-gap" title="' + obj.user[0].name + '" style="background-color:' + obj.user[0].color + '"><span class="initials">' + helper.initials(obj.user[0].name) + '</span></a>';
      } else if(obj.user && obj.user.length > 1){
        let out = [];
        obj.user.forEach((u) => {
          if (u.name === undefined) {
            out.push('<a class="set-user-gap" data-id="' + u.id + '" data-name="??" href="#" title="' + t('g.user_connectiontype_' + u.connection, u) + '"><strong>??</strong></a>');
          }
          else {
            out.push('<a class="set-user-gap" data-id="' + u.id + '" data-name="' + u.name + '" href="#" title="' + t('g.user_connectiontype_' + u.connection, u) + '"><strong>' + helper.initials(u.name) + '</strong></a>');
          }
        });
        $user_item = out.join(', ');
      }

      let $user1_area = $task_el.find('#___user-area');
      $user1_area.append($user_item); 

      let $button_area = $task_el.find('#button-area');      
      let $btn_task_list = plugin.makeOptionButtons(obj);
      $button_area.append($btn_task_list);

      options.optionButtons.forEach((btn) => {
        if(btn.click) {
          $task_el.find('.dt-btn-' + btn.name).on('click', (ev) => {
            let $btn = $(ev.currentTarget);            
            let $tr = $btn.parent().parent().parent().parent().parent().parent().parent().parent();
            let id = $btn.data('id');

            btn.click(id, $btn, $tr, null);  
          });
        }      
      });
    
      $search_results.append($task_el);
    });
  };

  plugin.update = () => {
    let tmp_data = {
        "draw": 1,
        "columns": [
            {
                "data": "done",
                "name": "",
                "searchable": true,
                "orderable": true,
                "search": {
                    "value": "",
                    "regex": false
                }
            },
            {
                "name": "",
                "searchable": true,
                "orderable": true,
                "search": {
                    "value": "",
                    "regex": false
                }
            },
            {
                "data": "name",
                "name": "",
                "searchable": true,
                "orderable": true,
                "search": {
                    "value": "",
                    "regex": false
                }
            },
            {
                "data": "creator_id",
                "name": "",
                "searchable": true,
                "orderable": true,
                "search": {
                    "value": "",
                    "regex": false
                }
            },
            {
                "data": "due",
                "name": "",
                "searchable": true,
                "orderable": true,
                "search": {
                    "value": "",
                    "regex": false
                }
            },
            {
                "data": "___user",
                "name": "",
                "searchable": true,
                "orderable": false,
                "search": {
                    "value": "",
                    "regex": false
                }
            },
            {
                "data": "__options",
                "name": "",
                "searchable": true,
                "orderable": false,
                "search": {
                    "value": "",
                    "regex": false
                }
            }
        ],
        "order": [
            {
                "column": 4,
                "dir": "asc"
            }
        ],
        "start": ($page_no - 1) * options.pageLength,
        "length": options.pageLength,
        "search": {
          // "value": "",
          "value": $search_field.val(),
          "regex": false
        }
    };

    // let tmp_data = {
    //   "draw": 1,
    //   "columns": [
    //     {
    //       "data": "name",
    //       "name": "",
    //       "searchable": true,
    //       "orderable": true,
    //       "search": {
    //         "value": "",
    //         "regex": false
    //       }
    //     },
    //     {
    //       "data": "user_id",
    //       "name": "",
    //       "searchable": true,
    //       "orderable": true,
    //       "search": {
    //         "value": "",
    //         "regex": false
    //       }
    //     },
    //     {
    //       "data": "created_at",
    //       "name": "",
    //       "searchable": true,
    //       "orderable": true,
    //       "search": {
    //         "value": "",
    //         "regex": false
    //       }
    //     },
    //     {
    //       "data": "__options",
    //       "name": "",
    //       "searchable": true,
    //       "orderable": false,
    //       "search": {
    //         "value": "",
    //         "regex": false
    //       }
    //     }
    //   ],
    //   "order": [
    //     {
    //       "column": 0,
    //       "dir": "asc"
    //     }
    //   ],
    //   "start": ($page_no - 1) * options.pageLength,
    //   "length": options.pageLength,
    //   "search": {
    //     // "value": "",
    //     "value": $search_field.val(),
    //     "regex": false
    //   }
    // };
    
    let req_data = $.extend( {}, tmp_data, additional_query);

    let datatable_url1 = '/resource/datatable/' + options.table[0];

    api.post(datatable_url1, req_data, {
      success: (result) => {
        // let data_count = Object.keys(data).length;
        $search_results_count = result.recordsFiltered;
        $box_result_count.text(t('g.result_count', {count: $search_results_count}));   

        let data_count = Object.keys(result.data).length;
           
        if($page_no > 1) {
          plugin.placeData(result.data, true);
        } 
        else {
          plugin.placeData(result.data, false);

          // if(data_count > 0) {
          //   options.afterLoad();
          // }   
        }   

        if(data_count > 0) {
          $data_ready_to_update = true;
          options.afterLoad();
        }        
      },
      error: () => {
        // msg.error('Konnte Kandidat nicht hinzufügen.');
      },
      complete: () => {
        
      }
    });

    if (options.afterDataUpdate !== undefined) {
      options.afterDataUpdate();
    }
  };

  plugin.addOptionButton = (btn) => {
    options.optionButtons.push(btn);
  };

  this.initCard = () => {
    $card = $(`
      <div class="card">
        <div class="card-header border-bottom-0">
          <div class="card-title">
            <div class="input-group input-group-sm">
              <input type="text" class="form-control float-right card-tool-search" placeholder="` + t(options.i18n_prefix + 'ph_search') + `">
              <div class="input-group-append">
                <button type="submit" class="btn btn-default"><i class="fas fa-search"></i></button>
              </div>
            </div> 
            <small class="box-result-count text-small"></small>          
          </div>

          <div class="card-tools" id="candidate-card-tools">
                            
          </div>
        </div>
        
        <div class="card-body p-0 border-top-0">
          <div id="result-search" class="row d-flex align-items-stretch"></div>
        </div>
        
        <div class="loading-indicator overlay" style="display: none">
            <i class="fas fa-2x fa-sync-alt"></i>
        </div>

        <div class="card-footer clearfix">  
        </div>

      </div>`);

    $search_results = $card.find('#result-search');
    $loader = $card.find('.loading-indicator');
    $card_tools = $card.find('.card-tools');
    $card_title = $card.find('.card-title');
    $box_result_count = $card.find('.box-result-count');
    $search_field = $card.find('.card-tool-search');

    $element.append($card);

    if (options.hide_search && options.hide_search == true) {
      $('.card-tool-search, .input-group-append').hide();
    }
  };

  this.beautify = () => {
    /*
      search field
     */ 
    $search_field.keyup( (ev) => {
      $page_no = 1;
      plugin.update();
    });    
  };

  this.initAddButton = () => {
    $btn_new = $('<button type="button" class="btn btn-primary btn-sm"><i class="fas fa-plus"></i> ' + t(options.i18n_prefix + 'add_' + options.table[0]) + '</button>');

    $btn_new.click(() => {
      $form.resetValues();
      $modal.modal({
        show: true,
        backdrop: 'static',
        keyboard: false
      });

      if (options.loadContent) {
        options.loadContent();
      }
    });

    $card_tools.append($btn_new);

  };

  this.initModal = () => {

    $btn_save = button_save.js();
    $btn_save_edit = button_save.js();

    /*
     * Add New Modal
     */
    $modal = modal.js({
      title: t(options.i18n_prefix + 'add_' + options.table[0]),
      size: 'lg'
    });


    $modal.$footer.append($btn_save);
    $modal.$footer.append($btn_save_edit);

    plugin.$modal = $modal;

  };

  this.initForm = () => {

    $form = $('<form action="' + '/' + options.table[0] + '"></form>').uiForm({
      fields: options.fields,
      $el_save: $btn_save,
      $el_edit: $btn_save_edit,
      url: '/' + options.table[0],
      i18n_prefix: options.i18n_prefix,
      additionalTitle: options.additionalTitle,
      addSuccess: (res) => {
        options.addSuccess(res);
      },
      editSuccess: (new_data, old_data) => {
        options.editSuccess(new_data, old_data);
      },
      success: (res) => {
        $page_no = 1;
        plugin.update();
        $modal.modal('hide');
        $form.resetValues();
      },
      onAddMode: () => {
        $modal.$title.text(t(options.i18n_prefix +'add_' + options.table[0]));
        options.onAddMode();
      },
      onEditMode: (data) => {
        $modal.$title.text(t(options.i18n_prefix +'edit_' + options.table[0], data));
        options.onEditMode(data);
      },
      complete: () => {
        options.onFinishLoading();
        $btn_save_edit.find('i').removeClass('fa-spin fa-cog').addClass('fa-save');
        $btn_save.find('i').removeClass('fa-spin fa-cog').addClass('fa-save');
      },
      start: () => {
        $btn_save_edit.find('i').removeClass('fa-save').addClass('fa-spin fa-cog');
        $btn_save.find('i').removeClass('fa-save').addClass('fa-spin fa-cog');
        if(typeof options.inLoading === 'function') {
          options.onLoading();
        }

      },
      default: options.default
    });

    $modal.$body.append($form);

  };

  this.initFilter = () => {

    if(options.filter !== undefined) {

      let i18n_prefix = options.i18n_prefix;
      if(options.filter.i18n_prefix) {
        i18n_prefix = options.filter.i18n_prefix;
      }

      let $filter_form;

      if (options.autosearch == false) {

        $filter_form = $('<form></form>').uiForm({
          i18n_prefix: i18n_prefix,
          fields: options.filter.fields
        });

      }
      else {

        $filter_form = $('<form></form>').uiForm({
          i18n_prefix: i18n_prefix,
          change: (data) => {

            let pararms = routes.getParams();

            let new_params = helper.purgeObject(pararms, ['query', 'sort_col', 'sort_type', 'page', 'mark_unseen']);

            let final_params = helper.purgeObjectArrays($.extend({}, new_params, data));

            additional_query = {};

            for (var property in options.filter.fields) {
              if (options.filter.fields.hasOwnProperty(property)) {
                if (typeof final_params[property] === 'object') {

                  for (const [key, value] of Object.entries(final_params[property])) {
                    let key_name = property + '[' + key + ']';
                    plugin.setQuery('filter[' + property + '][' + key + ']', final_params[property][key]);
                  }

                } else if (final_params[property] !== undefined) {
                  plugin.setQuery('filter[' + property + ']', final_params[property]);
                } else if (final_params[property + '_id'] !== undefined) {
                  plugin.setQuery('filter[' + property + '_id]', final_params[property + '_id']);
                } else {
                  plugin.setQuery('filter[' + property + ']', null);
                }
              }
            }

            routes.replaceParams(final_params);
            
            $page_no = 1;
            plugin.update();
          },
          fields: options.filter.fields
        });

      }

      /*
       * Suchen Button
       */
      if (options.autosearch == false) {
        $btn_search = $('<button class="btn btn-primary dosearch">Suchen</button>');
        $filter_form.append($btn_search);

        $btn_search.off('click').on('click', (ev) => {
          ev.preventDefault();

          additional_query = {};

          let data = $filter_form.serializeObject();

          let pararms = routes.getParams();

          let new_params = helper.purgeObject(pararms, ['query', 'sort_col', 'sort_type', 'page', 'mark_unseen']);

          let final_params = helper.purgeObjectArrays($.extend({}, new_params, data));

          for (var property in options.filter.fields) {
            if (options.filter.fields.hasOwnProperty(property)) {
              if (typeof final_params[property] === 'object') {

                for (const [key, value] of Object.entries(final_params[property])) {
                  let key_name = property + '[' + key + ']';
                  plugin.setQuery('filter[' + property + '][' + key + ']', final_params[property][key]);
                }

              } else if (final_params[property] !== undefined) {
                plugin.setQuery('filter[' + property + ']', final_params[property]);
              } else if (final_params[property + '_id'] !== undefined) {
                plugin.setQuery('filter[' + property + '_id]', final_params[property + '_id']);
              } else {
                plugin.setQuery('filter[' + property + ']', null);
              }
            }
          }

          routes.replaceParams(final_params);

          $page_no = 1;
          plugin.update();
        });

      }

      /*
       * add filter reset button when the option flag is set
       */
      if (options.resetFilters && options.resetFiltersUrl != null) {
        $btn_reset_filter = $('<a class="btn btn-danger" href="' + options.resetFiltersUrl + '">Filter zurücksetzen</a> ');
        $filter_form.append($btn_reset_filter);
      }

      plugin.$filter_form = $filter_form;

      options.filter.$element.append($filter_form);

      /*
       * init form params
       */
      let params = routes.getParams();

      let check = false;
      for (var property in options.filter.fields) {
        if (options.filter.fields.hasOwnProperty(property)) {

          if(params[property] !== undefined || params[property+'[]'] !== undefined) {
            check = true;
          }
        }
      }

      if(check) {
        //params = helper.purgeObjectArrays(params);
        $filter_form.setValues(params);
        setTimeout(() => {
          //$filter_form.fireChange();

          if(options.filter.onLoad) {
            options.filter.onLoad();
          }

        },2000);
      }
      else if(options.filter.default) {
        params = helper.purgeObjectArrays(params);
        $filter_form.setValues(options.filter.default);
        setTimeout(() => {
          //$filter_form.fireChange();

          if(options.filter.onLoad) {
            options.filter.onLoad();
          }

        },200);
      }
    }

  };

  this.initFields = () => {

    /*
     * falls acl abhängigkeit da ist ggf. entfernen
     */
    for (var property in options.fields) {
      if (options.fields.hasOwnProperty(property)) {
        if(options.fields[property].acl && !acl(options.fields[property].acl)) {
          delete options.fields[property];
        }
      }
    }
  };

  // public methods
  this.initialize = () => {

    this.initFields();

    if (options.edit) {
      options.optionButtons.push({
        name: 'update',
        icon: 'fas fa-pencil-alt',
        title: t('g.edit'),
        class: 'btn-default',
        click: (id, $btn, $tr, callback) => {

          api.get(options.uri + '/resource/get/' + options.table[0] + '/' + id, {
            success: (item) => {
              $form.resetValues();
              $form.setValues(item);
              $modal.modal({
                show: true,
                backdrop: 'static',
                keyboard: false
              });

            }
          });
        }
      });
    }

    if (options.delete) {
      options.optionButtons.push({
        name: 'delete',
        icon: 'fas fa-trash-alt',
        title: t('g.delete'),
        class: 'btn-danger',
        click: (id, $btn, $tr, callback) => {

          if (!callback) {
            callback = () => {
            };
          }         

          if (confirm(t(options.i18n_prefix + 'delete_confirm'))) {
            $tr.hide();
            api.delete(options.uri + '/resource/del/' + options.table[0] + '/' + id, {
              success: () => {
                $tr.remove();
              },
              error: () => {
                $tr.show();
              },
              complete: () => {
                callback();
              }
            });
          } else {
            callback();
          }

        }
      });
    }

    plugin.initCard();
    plugin.beautify();

    plugin.initModal();
    plugin.initForm();

    if (options.add) {
      plugin.initAddButton();
    }

    plugin.$form = $form;

    plugin.initFilter();

    // plugin.update();

    plugin.addEventHandlers();  

    return plugin;

  };

  return this.initialize();
};
