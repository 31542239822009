const job = {
  lb_title: 'Titel',
  ph_title: '',
  lb_recruiter: 'Recruiter*innen',
  ph_recruiter: '',
  lb_account_manager: 'Account-Manager*innen',
  ph_account_manager: '',
  lb_responsible_person: 'Zuständige Personen',
  ph_responsible_person: '',
  lb_organisation: 'Unternehmen',
  ph_organisation: '',
  lb_description: 'Beschreibung',
  ph_description: '',
  lb_description_old: 'Alte Beschreibung zur KONTROLLE, Format bitte oben nachbauen. Dieses Feld wird nicht gespeichert!',
  ph_description_old: '',
  add_job: 'Job hinzufügen',
  lb_status: 'Status',
  lb_external_sync: 'mit Job-Börsen synchronisieren',
  ph_search: 'Titel, Arbeitgeber...',
  edit_job: 'Job bearbeiten',
  lb_employment: 'Anstellungsart',
  lb_placementstatus: 'Job-Status',
  lb_prio: 'Active-Sourcing Priorität',
  lb_graduationtype: 'Mindestabschluss',
  ph_graduationtype: '',
  lb_notes: 'Sonstige Angaben',
  ph_notes: 'geforderter Typ,must have skills etc.',
  lb_jobstatus: 'Status',
  ph_jobstatus: '',
  lb_tags: 'Tags',
  ph_tags: '',
  candidate_matching: 'Passende Kandidaten (Beta)',
  lb_location_type_default: 'Normal',
  lb_location_type_homeoffice: 'Homeoffice möglich',
  lb_location_type_telecommute: 'Ortsunabhängiger Arbeitsplatz',
  lb_slug: 'Slug',
  ph_slug: '',
  lb_meta_description: 'Meta Description',
  ph_meta_description: '',
  lb_location_type: 'Arbeitsort',
  lb_sitemap: 'Sitemap',
  lb_priority: 'Priority',
  ph_priority: '0.8',
  lb_date_posted: 'vom Arbeitgeber veröffentlicht am',
  ph_date_posted: 'YYYY-MM-DD',
  lb_date_start: 'Start',
  ph_date_start: 'YYYY-MM-DD',
  lb_valid_through: 'Bewerbungsfrist',
  ph_valid_through: 'YYYY-MM-DD',
  lb_salary_min: 'Gehalt Min.',
  ph_salary_min: '',
  lb_salary_max: 'Gehalt Max.',
  ph_salary_max: '',
  lb_salary_unit: 'Gehaltsangaben-Typ',
  lb_salary_currency: 'Gehalts-Währung',
  lb_salary_unit_type_hour: 'Stundenlohn',
  lb_salary_unit_type_day: 'Tageslohn',
  lb_salary_unit_type_week: 'Wochenlohn',
  lb_salary_unit_type_month: 'monatliches Gehalt',
  lb_salary_unit_type_year: 'Jahresgehalt',
  lb_salary_currency_eur: 'Euro',
  lb_salary_currency_usd: 'Dollar',
  lb_application_info_orga: 'Unternehmensinformationen',
  ph_application_info_orga: '',
  lb_application_info: 'Stelleninformationen',
  ph_application_info: '',
  lb_organisationtypes: 'Branche',
  ph_organisationtypes: '',
  lb_subjects: 'Gesuchte Fachrichtungen',
  ph_subjects: '',
  lb_jobsubjects: 'Gesuchte Fachrichtungen',
  ph_jobsubjects: '',
  lb_employments: 'Anstellungsarten',
  ph_employments: '',
  lb_organisationbranches: 'Niederlassung',
  ph_organisationbranches: '',
  delete_confirm: 'Job wirklich löschen?',
  lb_activestatus: 'Aktiv/Inaktiv',
  organisationbranches_infotext: '<h5>Hinweis für landesweite Stellen</h5><p>keine Niederlassung und als Arbeitsort "ortsunabhängig" auswählen</p>',
  fastapply_infotext: '<h5>Hinweis für "Bewerbung ohne CV"</h5><p>Die Formularfelder <b>Vorname, Nachname, E-Mail und Telefon</b> müssen nicht angelegt werden!</p>',
  metadescription_infotext: '<h5>Hinweis für Meta Description</h5><p>Dieser Text wird in der Googlesuche unter dem Link angezeigt und ist wichtig für die SEO Performance. Darf maximal 160 Zeichen lang sein.</p>',
  lb_first_interview_tips: 'Weiterführende Infos',
  lb_matchings: 'Job-Matchings',
  lb_home_office: 'Homeoffice in %',
  ph_home_office: '',
  lb_travel_willingness: 'Reisebereitschaft in %',
  ph_travel_willingness: '',
  lb_current_status: 'Job-Status',
  ph_current_status: '',
  lb_jobtype: 'Job-Art',
  ph_jobtype: '',

  title_required: 'Titel ist ein Pflichtfeld',
  title_stringlengthmin: 'Titel ist zu kurz',
  title_stringlengthmax: 'Titel ist zu lang',
  title_normalinput: 'Titel enthält ein ungültiges Zeichen',
  organisation_id_required: 'Unternehmen ist ein Pflichtfeld',
  organisation_id_outofbounds: 'Ungültiges Unternehmen',
  employment_id_outofbounds: 'Ungültige Anstellungsart',
  placementstatus_id_outofbounds: 'Ungültiger Status',
  placementstatus_id_required: 'Job-Status ist ein Pflichtfeld',
  graduationtype_id_outofbounds: 'Ungültiger Mindestabschluss',
  description_required: 'Beschreibung ist ein Pflichtfeld',
  description_stringlengthmin: 'Beschreibung ist zu kurz',
  description_stringlengthmax: 'Beschreibung ist zu lang',
  prio_outofbounds: 'Ungültige Priorität',
  recruiter_outofbounds: 'Ungültige*r Recruiter*innen',
  responsible_person_outofbounds: 'Ungültige*r Zuständige Personen',
  notes_stringlengthmin: 'Sonstige Angaben sind zu kurz',
  notes_stringlengthmax: 'Sonstige Angaben sind zu lang',
  tags_outofbounds: 'ungültige Tags',
  slug_invalid: 'üngültiger Slug',
  slug_stringlengthmax: 'Slug ist zu lang',
  meta_description_required: 'Meta Description ist ein Pflichtfeld',
  meta_description_stringlengthmin: 'Meta Description ist zu kurz',
  meta_description_stringlengthmax: 'Meta Description darf max. 160 Zeichen lang sein',
  priority_required: 'Priority ist ein Pflichtfeld',
  priority_outofbounds: 'Ungültige Priority',
  date_posted_required: 'Pflichtfeld',
  date_posted_datevalidator: 'Ungültiges Datum',
  date_start_required: 'Pflichtfeld',
  date_start_datevalidator: 'Ungültiges Datum',
  valid_through_datevalidator: 'Ungültiges Datum',
  salary_min_numericality: 'Gehalt Min. muss numerisch sein',
  salary_max_numericality: 'Gehalt Max. muss numerisch sein',
  home_office_numericality: 'Homeoffice in % muss numerisch sein',
  travel_willingness_office_numericality: 'Reisebereitschaft in % muss numerisch sein',
  salary_unit_required: 'Gehaltsangaben-Typ ist ein Pflichtfeld',
  salary_currency: 'Gehalts-Währung ist ein Pflichtfeld',
  salary_unit_id_outofbounds: 'Ungültiger Gehaltsangaben-Typ',
  salary_currency_id_outofbounds: 'Ungültige Gehalts-Währung',
  location_type_id_required: 'Arbeitsort ist ein Pflichtfeld',
  location_type_id_outofbounds: 'Ungültiger Arbeitsort',
  location_type_id_telecommute: 'Bei diesem Arbeitsort darf keine Niederlassung ausgewählt sein',
  location_type_id_default: 'Bei diesem Arbeitsort muss eine Niederlassung ausgewählt sein',
  current_status_required: 'Bitte einen Status auswählen',
  lb_teams: 'Zugeordnete Job-Pools',
  ph_teams: '',
  no_teams_selected: 'Bitte wähle mindestens einen Job-Pool aus.',
  characters: 'Zeichen',
  no_organisation_selected: 'Es muss ein Unternehmen ausgewählt sein!',
  add_orgabranch: 'Niederlassung hinzufügen',

  copy_confirm: 'Diesen Job wirklich kopieren?',
  lb_radius: 'Umkreissuche',

};

exports.job = job;
