import media from '../media';
import api from '../api';
import msg from '../msg';
import helper from '../helper';
import time from '../time';
import routes from '../routes';

$.fn.uiJobCandidatematching = function(options) {

  // default options
  let plugin = this;
  let $element = $(this);

  options = $.extend({}, {
    title: false,
    onLoading: () => {
    },
    onFinish: () => {
    }
  }, options);

  this.renderItem = (candidate) => {

    let email = '';

    if (candidate.email && candidate.email !== undefined) {
      email = candidate.email;
    }

    let $item = `
        <div class="jobmatch">
            <a href="/candidate/${candidate.id}"><strong>${candidate.name}</strong></a>
            <p class="text-muted">${email}</p>
            <ul class="jobmatch-taglist">
    `;

    candidate.tags.forEach((tag) => {
      let cssClass = 'no-match';

      if (tag.match) {
        cssClass = 'match';
      }

      $item += `<li class="${cssClass}">${tag.name}</li>`;
    });

    $item += `</ul>
          <hr>
        </div>
    `;

    return $item;

  };

  this.initialLoad = () => {

    api.get('/jobmatching/job/' + options.job.id, {
      success: (candidates) => {

        candidates.forEach((candidate) => {
          let $item = plugin.renderItem(candidate);
          $element.append($item);
        });

      }
    });

  };

  this.initialize = () => {

    plugin.initialLoad();

    return plugin;
  };

  return this.initialize();

};
