import api from '../api';
import app from '../app';

$.fn.uiFormCheckboxes = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $input;

  options = $.extend({},{
    i18n_prefix: 'g.',
    onChange: (value, checkbox_state) => {},
    type: 'checkbox'
  }, options);

  if(options.label !== false) {
    options.label = t(options.i18n_prefix + 'lb_'+options.name);
  }

  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);

  let element_id;

  let plugin = this;

  plugin.tpl = (obj) => {
    let wrapper_class = 'form-check';
    if(options.inline === true) {
      wrapper_class += ' form-check-inline';
    }
    wrapper_class += ' form-check-'+options.name;

    
    let value = obj.id;

    if (obj.value) {
      console.log('checkboxes obj value:', obj.value);
      value = obj.value;
    }

    return `<div class="${wrapper_class}">
        <input name="${options.name}[]" class="form-check-input" type="checkbox" id="${element_id}-cb-${obj.id}" value="${value}">
        <label class="form-check-label" for="${element_id}-cb-${obj.id}">${obj.name}</label>
    </div>`;
  };

  // public methods
  this.initialize = () => {

    if(options.table === undefined) {
      options.table = options.name;
    }

    element_id = app.getDomId($element, options.name);

    if (element_id === undefined) {
      element_id = options.name
    }

    if(!Array.isArray(options.table)) {
      options.table = [options.table, options.table];
    }

    $label = $('<label>' + options.label + '</label>');
    $input = $('<div></div>');

    /*
     * Values
     */
    if(options.values !== undefined) {
      $.each(options.values, (i, obj) => {
        $input.append(plugin.tpl(obj));
      });

      if(options.type === 'radio') {

        let $btn = $('<button class="btn btn-default btn-sm">Keine Option</button>');
        $btn.click((ev) => {
          ev.preventDefault();
          $input.find('input').prop('checked', false);
        });
        $input.append($btn);
      }
    }
    /*
     * Daten aus DB
     */
    else if(options.table !== undefined && options.ajax === undefined) {

      let url = '/' + options.table[1];

      // Sonderfall für die PDF Generierung eins Job Kandidaten
      if (options.table[0] == 'cv_skill') {
        url = '/cv/' + options.candidate.cv_id + '/skills';
        element_id = 'job-candidate-pdf';
      }

      api.get(url, {
        success: (response) => {
          $.each(response.listing, (i, obj) => {

            $input.append(plugin.tpl(obj));

          });
        }
      });
    }

    $input.bind('change', (ev) => {
      let $checkbox = $(ev.target);
      let checkbox_state;

      if ($checkbox.prop('checked') === true) {
        checkbox_state = 1;
      }
      else {
        checkbox_state = 0;
      }

      options.onChange($checkbox.val(), checkbox_state);
    });

    $element.addClass('form-group form-group-' + options.name);
    if(options.label !== false) {
      $element.append($label);
    }
    $element.append($input);

    this.$input = $input;

    return plugin;

  };

  /*
   * Lädt die Checkbox Daten per Ajax Request nach
   */
  this.loadByAjax = ($elemResult) => {

    if ($elemResult.children().length == 0) {

      $elemResult.addClass('fas fa-spinner fa-spin');

      api.get(options.ajax.url, {
        success: (response) => {
          $elemResult.removeClass('fas fa-spinner fa-spin');

          if (response.listing.length == 0) {
            $($elemResult).html(t('g.' + options.name + '_listing_empty'));
          } else {
            $.each(response.listing, (i, obj) => {
              $elemResult.append(plugin.tpl(obj));
            });
          }
        }
      });

    }

  };

  this.setValue = (value) => {
    if(value) {
      if(typeof value === 'object') {
        $input.val(value.id);
      }
      else if (parseInt(value) > 0) {
        $input.find('input').each((idx, elem) => {
          if ($(elem).val() == value) {
            $(elem).prop('checked', true);
          }
        });
      }

      $label.text(options.label_edit);
      $input.attr('placeholder', options.placeholder_edit);
    }
  };

  this.resetValue = () => {
    $input.val('');
    $label.text(options.label);
    $input.attr('placeholder', options.placeholder);
  };

  return this.initialize();
};


