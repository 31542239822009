import app from '../../app/app';;
import time from '../../app/time';;
import helper from '../../app/helper';;
import msg from '../../app/msg';;
import api from '../../app/api';

const user_rights = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12" id="role-management"> </div> </div></div>'; return out;
    },
    js: function(it) {
             app.setTitle('Rechte-Management'); app.setBreadcrumps({ 'Administration': '/admin', 'Benutzer-Rollen': '/admin/user/rights' }); let $role_management = $('#role-management').uiDataTableScroll({ title: 'Benutzer Rechte', table: ['permission','permissions'], fields: { 'key': 'Text', 'table_name': 'Text', 'role': { type: 'TagSelect' } }, onAddMode: () => { if($role_management) { $role_management.$modal.find('.form-group-key').hide(); } }, onEditMode: () => { if($role_management) { $role_management.$modal.find('.form-group-key').show(); } }, cols: [{text:'#',name:'id',width:30}, 'name'], render: { 'id': (obj) => { return '<b>' + obj.id + '</b>'; }, 'name': (obj) => { return '<a>' + obj.key + '</a><br /><small>' + (obj.table_name || '&nbsp;') + '</small>'; } } }); let $info = $(`<div class="addmode form-group"><div class="alert alert-dismissible alert-info"><h5><i class="icon fas fa-info"></i> Folgende Rechte werden angelegt!</h5> <ul class="rights-info"></ul></ul></div></div>`); let $rights_info = $info.find('.rights-info'); let $table_input = $role_management.$modal.find('input[name="table_name"]'); $role_management.$modal.find('.form-group-table_name').after($info); $table_input.keyup(() => { let val = $table_input.val(); $rights_info.html(` <li>browse_` + val + `</li> <li>read_` + val + `</li> <li>edit_` + val + `</li> <li>add_` + val + `</li> <li>delete_` + val + `</li> `); });
    }
};

export default user_rights;
