import api from '../api';
import msg from '../msg';
import session from '../session';
import helper from '../helper';
import time from '../time';
import routes from '../routes';
import modal from "../../_compiled/components/modal";

$.fn.uiCandidateJobmatching = function(options) {

  // default options
  let plugin = this;
  let $element = $(this);
  let $statusDropdown;
  let $loader = $('<div class="overlay"><i class="fas fa-2x fa-sync-alt fa-spin"></i></div>');

  $loader.hide();

  options = $.extend({}, {
    title: false,
    onLoading: () => {
    },
    onFinish: () => {
    }
  }, options);

  plugin.renderItem = (job) => {

    $statusDropdown = $('<select class="form-control form-control-sm" name="jobmatch_to_job"><option value="0">Status wählen...</option></select>');

    let $item = $(`
        <div>
            <a data-job_id="` + job.job_id + `" href="#" class="jobstatus-btn-delete float-right btn-tool"></a>
            <strong><i class="fas fa-building"></i> ` + job.organisation_name + `</strong>
            <p class="text-muted">
              <a href="https://jungwild.io/jobs/${job.job_slug}-${job.job_id}" target="_blank">${job.job_title}</a>
            </p>
            <div class="mb-1">
                <button data-job_id="${job.job_id}" class="btn btn-xs btn-primary w-100 jobmatch-to-job">
                    <i class="fas fa-random mr-2"></i>${t('candidate.use_job_match')}
                </button>
            </div>
          <hr>
        </div>
  `);

    let $p = $('<p></p>');
    $p.append($statusDropdown).append($item);
    $element.append($p);

    return $p;

  };

  this.initialLoad = () => {

    plugin.showLoader();

    api.get('/matching/jobs/' + options.candidate.id, {
      success: (jobs) => {

        jobs.forEach((job) => {
          let $item = plugin.renderItem(job);
          $element.append($item);
        });

        let $switches = $element.find('[name="jobmatch_to_job"]');

        api.get('/resource/select/jobstatus', {
          success: (jobstatus) => {
            jobstatus.forEach((opt) => {
              $switches.each((i, item) => {
                $(item).append('<option value="' + opt.id + '">' + opt.name + '</option>');
              });
            });

            plugin.hideLoader();

            $element.find('.jobmatch-to-job').on('click', (ev) => {
              ev.preventDefault();

              let $switch = $(ev.currentTarget).parent().parent().parent().find('select');
              let value = $switch.val();
              let $job_id = $(ev.currentTarget).parent().parent().find('a').data('job_id');

              if (value > 0) {
                api.post(`/jobstatus/jobmatch/${options.candidate.id}/${$job_id}`, {
                  'status': value
                }, {
                  success: () => {
                    msg.success(t('g.save_success'));
                    options.onFinish();
                  },
                  error: () => {
                    msg.error(t('g.error'));
                  }
                })
              }
              else {
                msg.error(t('matching.invalid_jobstatus'));
              }
            });
          }
        });

      }
    });

  };

  plugin.showLoader = () => {
    $loader.show();
  };

  plugin.hideLoader = () => {
    $loader.hide();
  };

  plugin.update = () => {
    $element.empty();
    plugin.initialLoad();
  };

  this.initialize = () => {

    plugin.initialLoad();

    $element.parent().append($loader);

    return plugin;
  };

  return this.initialize();

};
