import api from '../api';
import helper from '../helper';

$.fn.uiFormSummernote = function(options) {


  $.extend($.summernote.options.icons ,  {
    'align': 'fa fa-align',
    'alignCenter': 'fa fa-align-center',
    'alignJustify': 'fa fa-align-justify',
    'alignLeft': 'fa fa-align-left',
    'alignRight': 'fa fa-align-right',
    'indent': 'fa fa-indent',
    'outdent': 'fa fa-outdent',
    'arrowsAlt': 'fa fa-arrows-alt',
    'bold': 'fa fa-bold',
    'caret': 'fa fa-caret-down',
    'circle': 'fa fa-circle',
    'close': 'fa fa fa-close',
    'code': 'fa fa-code',
    'eraser': 'fa fa-eraser',
    'font': 'fa fa-font',
    'italic': 'fa fa-italic',
    'link': 'fa fa-link',
    'unlink': 'fa fa-chain-broken',
    'magic': 'fa fa-magic',
    'menuCheck': 'fa fa-check',
    'minus': 'fa fa-minus',
    'orderedlist': 'fa fa-list-ol',
    'pencil': 'fa fa-pencil',
    'picture': 'fa fa-picture-o',
    'question': 'fa fa-question',
    'redo': 'fa fa-repeat',
    'square': 'fa fa-square',
    'strikethrough': 'fa fa-strikethrough',
    'subscript': 'fa fa-subscript',
    'superscript': 'fa fa-superscript',
    'table': 'fa fa-table',
    'textHeight': 'fa fa-text-height',
    'trash': 'fa fa-trash',
    'underline': 'fa fa-underline',
    'undo': 'fa fa-undo',
    'unorderedlist': 'fa fa-list-ul',
    'video': 'fa fa-video-camera'
  });

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $input;

  options = $.extend({},{
    i18n_prefix: '',
    wrapper: true,
    height: 200,
    content: null,
    toolbar: [
      // [groupName, [list of button]]
      ['style', ['bold', 'italic', 'clear']],
      /*['view', ['codeview']],*/
      [['table']],
      [['codeview']]
    ]

  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);


  let plugin = this;

  // public methods
  this.initialize = () => {

    $label = $('<label>' + options.label + '</label>');
    $input = $(`<textarea name="${options.name}" class="form-control" placeholder="${options.placeholder}"></textarea>`);

    if(options.wrapper) {
      $element.addClass('form-group form-group-' + options.name).append($label);
    }

    if (options.infotext) {
      let $infotext = $(`<div class="summernote-infotext">${options.infotext}</div>`);
      $element.append($infotext);
    }

    $element.append($input);

    // sorgt für bessere Zeilenumbrüche!
    $.summernote.dom.emptyPara = '<br>';

    $input.summernote({
      toolbar: options.toolbar,
      height: options.height,
      spellCheck: false
    });

    this.$input = $input;

    return plugin;

  };

  this.getHtml = () => {
    return $input.summernote('code');
  };

  this.setValue = (value) => {
    $input.summernote('code', value);
    $label.text(options.label_edit);
    $input.attr('placeholder', options.placeholder_edit);
  };

  this.resetValue = () => {
    $input.summernote('reset');
    $input.val('');

    if (options.content != null) {
      $input.summernote('code', options.content);
    }

    $label.text(options.label);
    $input.attr('placeholder', options.placeholder);
  };

  return this.initialize();
};


