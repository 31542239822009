import api from '../api';
import dialog from "../dialog";
import helper from "../helper";

$.fn.uiFormSlug = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $input;
  let $previewWrapper;
  let $previewValue;
  let $previewEditBtn;
  let $previewRebuildBtn;
  let $warningInfo;

  options = $.extend({},{
    i18n_prefix: '',
    reference: null,
    getForm: () => {},
    slugify: true
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);


  let plugin = this;

  // public methods
  this.initialize = () => {

    $label = $('<label>' + options.label + '</label>');
    $input = $('<input name="' + options.name + '" class="form-control" type="text" placeholder="' + options.placeholder + '" />');
    $input.hide();
    $previewWrapper = $('<div></div>');
    $previewValue = $('<span></span>');
    $previewEditBtn = $('<a href="#" class="text-muted ml-1" title="bearbeiten"><i class="fas fa-pen"></i></a>');
    $previewRebuildBtn = $('<a href="#" class="text-muted ml-1" title="Seite neu generieren"><i class="fas fa-sync-alt"></i></a>');
    $warningInfo = $(`<div style="display: none;" class="callout callout-danger"><h5>Warnung!</h5><p>Die URL zu bearbeiten birgt immer ein Risiko, bestehende Links im Internet zu dieser Seite funktionieren möglicherweise nicht mehr.</p></div>`);

    /*
     * Init Edit Button
     */
    $previewEditBtn.on('click', () => {

      if(confirm('Willst Du den URL-Slug wirklich bearbeiten? Alte Links sind möglicherweise dann nicht mehr erreichbar.')) {
        plugin.showInput();
        $input[0].focus();
      }

      /*
      dialog.confirm({
        title: 'Slug wirklich bearbeiten?',
        body: 'Die URL zu bearbeiten birgt immer ein Risiko, bestehende Links im Internet zu dieser Seite funktionieren möglicherweise nicht mehr.',
        apply: () => {
          $input.show();
          $input.select();
        }
      });
       */

    });

    $previewRebuildBtn.on('click', () => {
      if (options.rebuild) {
        let slug = $previewRebuildBtn.parent().find('span > a').text();
        let loader = $previewRebuildBtn.children('i');
        options.rebuild(slug, loader);
      }
    });

    $previewWrapper.append($previewValue).append($previewEditBtn);

    if (options.url && options.url !== undefined) {
      $previewWrapper.append($previewRebuildBtn);
    }

    let timeout = null;

    if(options.slugify) {
      $input.on('keyup',() => {
        $input.val(helper.slugify($input.val()));
      });

      $input.on('blur',() => {
        $input.val(helper.slugify($input.val()));
      });
    }

    /*
     * referenz input
     */
    if(options.reference) {

      setTimeout(() => {
        let $form = options.getForm();
        let $input = $form.getFormElement(options.reference);

        let $reference = $input.find('input');

        $reference.on('input', () => {    
          if($form.getMode() === 'add') {
            if (options.url && options.url !== undefined) {
              plugin.setValue(helper.slugify($reference.val().trim())); 
            }
            else {
              plugin.setValue(helper.slugify($reference.val().trim()), true);
            }            
          }
        });

      },1000);

    }

    $element.addClass('form-group form-group-'+options.name)
      .append($label)
      .append($warningInfo)
      .append($previewWrapper)
      .append($input);

    return plugin;

  };

  this.hideInput = () => {
    $warningInfo.show();
    $previewWrapper.show();
    $input.hide();
  };

  this.showInput = () => {
    $previewWrapper.hide();
    $input.show();
    $warningInfo.hide();
  };

  this.setValue = (value, empty_flag=false) => {

    $label.text(options.label_edit);
    $input.attr('placeholder', options.placeholder_edit);

    plugin.hideInput();

    if(value || empty_flag) {
      $input.val(value);

      if (options.url && options.url !== undefined) {
        $previewValue.html('<a href="' + options.url + value + '" target="_blank">' + value + '</a>');
      }
      else {
        $previewValue.text(value);
      }

      let form = options.getForm();
      if(value+''.trim() === '' || (form && form.getMode() === 'add')) {
        plugin.showInput();
      }
    }
    else {
      plugin.showInput();
    }
  };

  this.resetValue = () => {
    $input.val('');
    $previewValue.empty();
    $label.text(options.label);
    $input.attr('placeholder', options.placeholder);
    plugin.showInput();
  };

  return this.initialize();
};


