import api from '../api';

$.fn.uiFormRange = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $input;

  options = $.extend({},{
    i18n_prefix: '',
    step: 1,
    min:-10,
    max:10
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);


  let plugin = this;
  let $value;

  // public methods
  this.initialize = () => {

    $label = $('<label>' + options.label + '</label>');
    $input = $('<input name="' + options.name + '" class="custom-range" type="range" min="'+options.min+'" max="'+options.max+'" value="0" step="'+options.step+'" />');

    $input.bind('change input', () => {
      let label = $label.text();
      label = label.split('(')[0].trim();
      $label.text(label + ' ('+$input.val()+')');
    });

    $element.addClass('form-group form-group-'+options.name).append($label).append($input);

    this.$input = $input;

    return plugin;

  };

  this.setValue = (value) => {
    $input.val(value);
    $label.text(options.label_edit + ' ('+value+')');
  };

  this.resetValue = () => {
    $input.val(0);
    $label.text(options.label + ' (0)');
  };

  return this.initialize();
};


