import api from "./api";
import msg from "./msg";
import media from "./media";
import button_save from "../_compiled/components/button_save";
import modal from "../_compiled/components/modal";
import helper from "./helper";
import cvdataform from "./cvdataform";

const cvimport = {

  $modal_thumbnails: null,
  $modal_persondata: null,
  $modal_edupos_choice: null,
  $modal_education: null,
  $modal_position: null,
  $form_education: null,
  $form_position: null,

  parsed_document_id: null,
  parsed_data: null,
  step: 0,

  showLoading: (options) => {
    $('.table-filemanager').find('.header-loader').show();
    $('.table-filemanager').find('.header-icon').hide();
    options.onLoad();
  },

  hideLoading: (options) => {
    options.onLoadComplete();
    $('.table-filemanager').find('.header-loader').hide();
    $('.table-filemanager').find('.header-icon').show();
  },

  defaultcvFile: (document_id) => {
    api.get('/cvdata/defaultcv/' + document_id, {
      success: () => {
        msg.success(t('g.add_success'));
      },
      error: () => {
        msg.error(t('g.error'));
      }
    });
  },

  parseFile: (document_id, options) => {
    cvimport.showLoading(options);
    cvimport.parsed_document_id = document_id;
    cvimport.parsed_data = null;
    cvimport.step = 0;

    let url = '/cvdata/document/preview/' + cvimport.parsed_document_id;
    api.get(url, {
      success: (response) => {
        msg.success(t('cvdata.preview_success'));

        if (response && response.length > 0) {
          cvimport.initThumbnailModal();
          cvimport.showThumbnails(response);
        }
      },
      complete: () => {
        cvimport.hideLoading(options);
      },
      error: () => {
        msg.error('g.parse_error');
      }
    });
  },

  initThumbnailModal: () => {
    let $btn_parse = button_save.js({text: t('cvdata.thumbnails_btn_parse')});
    let $btn_loader = $('<span class="btn btn-primary" style="display:none;"><i class="header-loader fas fa-circle-notch fa-spin"></i></span>');

    cvimport.$modal_thumbnails = modal.js({
      title: t('cvdata.thumbnails_page_selection'),
      size: 'md'
    });

    cvimport.$modal_thumbnails.$footer.append($btn_parse);
    cvimport.$modal_thumbnails.$footer.append($btn_loader);

    $btn_parse.on('click', () => {
      $btn_parse.hide();
      $btn_loader.show();

      let pages = [];

      $('.modal.show').find('.thumbnails').find('img.thumb-selected').each((i, page) => {
        pages.push({number: $(page).data('page'), ident: $(page).data('ident')});
      });

      api.post('/cvdata/document/parse', {
        pages: pages,
        document_id: cvimport.parsed_document_id
      }, {
        success: (response) => {
          msg.success(t('cvdata.parse_success'));

          cvimport.defaultcvFile(cvimport.parsed_document_id);

          cvimport.$modal_thumbnails.modal('hide');
          $btn_parse.show();
          $btn_loader.hide();

          // durch Änderungen von education/practice auf lifesteps nicht mehr aktuell
          //cvimport.showParsedDataPerson();
        },
        error: (response) => {
          msg.error(t('cvdata.parse_error'));
        }
      });

    });
  },

  showThumbnails: (thumbnails) => {
    let thumbs = [];

    $(thumbnails).each((i, thumb) => {
      thumbs.push(`<div class="thumbnails">
        <div class="ribbon-wrapper"><div class="ribbon bg-success"><i class="fas fa-check-circle"></i></div></div>
        <img src="${media.image('thumbnails/' + thumb.image, '121x170')}" data-ident="${thumb.ident}" data-page="${thumb.id}">
        <div class="desc">Seite ${i + 1}</div></div>`);
    });

    cvimport.$modal_thumbnails.$body.append(thumbs.join(''));
    cvimport.$modal_thumbnails.modal({
      show: true,
      backdrop: 'static',
      keyboard: false
    });

    $('.thumbnails').on('click', (ev) => {

      let $target = $(ev.target);
      let $container = $target.parent();

      $container.find('.ribbon-wrapper').toggle();

      // description wurde angeklickt
      if ($target.hasClass('desc')) {
        $container.find('img').toggleClass('thumb-selected');
      }
      // Thumbnail wurde angeklickt
      else {
        $target.toggleClass('thumb-selected');
      }

    })
  },

  processFile: (document_id) => {
    cvimport.parsed_document_id = document_id;
    cvimport.showParsedDataPerson();
  },

  showParsedDataPerson: () => {

    // wir holen die geparsten Daten
    api.get('/cvdata/document/process/' + cvimport.parsed_document_id, {
      success: (response) => {
        cvimport.parsed_data = response;

        // zuerst zeigen wir die persönlichen Daten an
        cvimport.initParsedDataPersonModal();
        cvimport.$modal_persondata.modal({
          show: true,
          backdrop: 'static',
          keyboard: false
        });

      },
      error: (response) => {
        msg.error(t('cvdata.parse_error'));
      }
    });

  },

  initParsedDataPersonModal: () => {
    let $btn_save = button_save.js({text: t('cvdata.thumbnails_btn_save')});

    cvimport.$modal_persondata = modal.js({
      title: t('cvdata.data_check'),
      size: 'lg'
    });

    let formoptions_person = {
      i18n_prefix: 'cvdata.',
      fields: cvdataform.getFieldsPersonalPerson()
    };

    let formoptions_final = {
      i18n_prefix: 'cvdata.',
      fields: cvdataform.getFieldsPersonalFinal()
    };

    let formoptions_other = {
      i18n_prefix: 'cvdata.',
      fields: cvdataform.getFieldsPersonalOther()
    };

    /*
     * Formulare erstellen
     */
    let layout = `<div class="container">
        <div class="row">
            <div class="col-sm-4"><h5>Gespeichert</h5><form id="datacomp-person"></form></div>
            <div class="col-sm-4"><h5>Daten zur Übernahme</h5><form id="datacomp-final"></form></div>
            <div class="col-sm-4"><h5>Eingelesen</h5><form id="datacomp-other"></form></div>
        </div>
    </div>`;

    cvimport.$modal_persondata.$body.append(layout);
    cvimport.$modal_persondata.$footer.append($btn_save);

    let $datacomp_final = cvimport.$modal_persondata.$body.find('#datacomp-final');
    let $datacomp_person = cvimport.$modal_persondata.$body.find('#datacomp-person');
    let $datacomp_other = cvimport.$modal_persondata.$body.find('#datacomp-other');

    $datacomp_final.uiForm(formoptions_final);
    $datacomp_person.uiForm(formoptions_person);
    $datacomp_other.uiForm(formoptions_other);

    /*
     * und Formulare mit Daten füllen
     */
    if (cvimport.parsed_data.person) {

      if (cvimport.parsed_data.person.address != null) {
        let address = cvimport.formatAddressAsText(cvimport.parsed_data.person.address);
        cvimport.parsed_data.person.person_address = address;
      }

      $datacomp_person.setValues(cvimport.parsed_data.person);
      $datacomp_person.find('.form-group-person_address').find('button').addClass('comp-addr');
    }

    if (cvimport.parsed_data.other) {

      if (cvimport.parsed_data.other.address != null) {
        let address = cvimport.formatAddressAsText(cvimport.parsed_data.other.address);
        cvimport.parsed_data.other.other_address = address;
      }

      $datacomp_other.setValues(cvimport.parsed_data.other);
      $datacomp_other.find('.form-group-other_address').find('button').addClass('comp-addr');
    }

    /*
     * add events to left and right arrow buttons
     */
    cvimport.$modal_persondata.$body.find('.comp-left, .comp-right').not('.comp-addr').on('click', (ev) => {
      let $field = $(ev.target).parent().parent().find('input');

      if ($field) {
        let data = $field.val();

        let name_parts = $field.attr('name').split('_');
        name_parts.shift();
        name_parts = name_parts.join('_');

        let $final_input = cvimport.$modal_persondata.find('[name="final_' + name_parts + '"]');
        $final_input.val(data);
        $final_input.addClass('comp-data-selected');
      }

    });

    /*
     * add address to form in the middle
     */
    cvimport.$modal_persondata.$body.find('.comp-addr').on('click', (ev) => {
      let $form_group = $(ev.target).parent().parent().parent();
      let final_address = $datacomp_final.getFormElement('final_address');

      if ($form_group.hasClass('form-group-other_address')) {
        if (cvimport.parsed_data.other.address != null) {
          final_address.setValue(cvimport.parsed_data.other.address);
        }
      }

      if ($form_group.hasClass('form-group-person_address')) {
        if (cvimport.parsed_data.person.address != null) {
          final_address.setValue(cvimport.parsed_data.person.address);
        }
      }
    });

    /*
     * Insert parsed data automatically into form to submit
     */
    cvimport.$modal_persondata.$body.find('.comp-right').click();

    /*
     * save data from final_ form
     */
    $btn_save.on('click', (ev) => {
      let formdata = {};

      cvimport.$modal_persondata.$body.find('#datacomp-final').find('.form-group').each((i, elem) => {

        // Adresse speziell behandeln
        if ($(elem).hasClass('form-group-final_address')) {
          formdata['final_address'] = $datacomp_final.getFormElement('final_address').getAddress();
        }
        else {
          let $input = $(elem).find('input');
          formdata[$input.attr('name')] = $input.val();
        }

      });

      api.post('/cvdata/document/save/personal', {
        data: formdata,
        document_id: cvimport.parsed_document_id
      }, {
        success: () => {
          msg.success(t('cvdata.personal_data_saved'));
          cvimport.$modal_persondata.modal('hide');

          cvimport.initEduposChoiceModal();
          cvimport.showEduposChoiceModal();
        }
      });
    });
  },

  formatAddressAsText: (address) => {

    let address_parts = [];

    if (address.street != null) {
      let street = address.street;

      if (address.number != null) {
        street += ' ' + address.number;
      }
      address_parts.push(street);
    }

    if (address.city != null) {
      let city;

      if (address.zip != null) {
        city = address.zip + ' ' + address.city;
      }
      else {
        city = address.city;
      }
      address_parts.push(city);
    }

    if (address.country != null) {
      address_parts.push(address.country);
    }

    return address_parts.join(', ');

  },

  initEduposChoiceModal: () => {

    cvimport.initParsedDataPositionModal();
    cvimport.initParsedDataEducationModal();

    cvimport.$modal_edupos_choice = modal.js({
      title: t('cvdata.headline_edupos_choice') + ' ' + (cvimport.step+1) + ' von ' + cvimport.parsed_data.other.edu_pos.length,
      size: 'md'
    });

  },

  showEduposChoiceModal: () => {

    // wir haben keinen weiteren edupos Eintrag
    if (cvimport.step < 0 || cvimport.step >= cvimport.parsed_data.other.edu_pos.length) {
      return false;
    }

    let $btn_edu = $('<button class="btn btn-primary btn-edu">'+t('cvdata.button_education')+'</button>');
    let $btn_pos = $('<button class="btn btn-primary btn-pos">'+t('cvdata.button_position')+'</button>');
    let $btn_back = $('<button class="btn btn-default btn-pos">'+t('cvdata.button_back')+'</button>');
    let $btn_skip = $('<button class="btn btn-default btn-pos">'+t('cvdata.button_skip')+'</button>');

    cvimport.$modal_edupos_choice.$footer.empty();

    if (cvimport.step > 0) {
      cvimport.$modal_edupos_choice.$footer.append($btn_back).append($btn_edu).append($btn_pos).append($btn_skip);
    }
    else {
      cvimport.$modal_edupos_choice.$footer.append($btn_edu).append($btn_pos).append($btn_skip);
    }

    /*
     * Eintrag zurück springen
     */
    $btn_back.on('click', () => {
      cvimport.$modal_edupos_choice.modal('hide');
      cvimport.$modal_edupos_choice.$body.find('p').remove();

      cvimport.step--;
      cvimport.showEduposChoiceModal();
    });

    /*
     * Eintrag überspringen
     */
    $btn_skip.on('click', () => {
      cvimport.$modal_edupos_choice.modal('hide');
      cvimport.$modal_edupos_choice.$body.find('p').remove();

      cvimport.step++;
      cvimport.showEduposChoiceModal();
    });

    /*
     * Eintrag ist Praxiserfahrung
     */
    $btn_pos.on('click', () => {
      let item = cvimport.parsed_data.other.edu_pos[cvimport.step];

      // Der Eintrag scheint nicht als Praxiserfahrung ausgelesen worden zu sein
      if (helper.empty(item.organisation) && helper.empty(item.name)) {

        if (!helper.empty(item.school)) {
          item.organisation = item.school;
        }

        if (!helper.empty(item.degree)) {
          item.name = item.degree;
        }

        if (!helper.empty(item.subject)) {
          if (helper.empty(item.name)) {
            item.name = item.subject;
          }
          else {
            item.name += ' ' + item.subject;
          }
        }

      }

      cvimport.$modal_edupos_choice.modal('hide');
      cvimport.$modal_edupos_choice.$body.find('p').remove();
      cvimport.$form_position.setValues(item);

      $(cvimport.$form_position).find('[name="group"]').val(0);
      $(cvimport.$form_position).find('[name="group"]').trigger('change');

      cvimport.step++;
      cvimport.$modal_position.modal({
        show: true,
        backdrop: 'static',
        keyboard: false
      });
    });

    /*
     * Eintrag ist Bildungsweg
     */
    $btn_edu.on('click', () => {
      let item = cvimport.parsed_data.other.edu_pos[cvimport.step];

      // Der Eintrag scheint nicht als Bildungsweg ausgelesen worden zu sein
      if (helper.empty(item.school) && helper.empty(item.degree) && helper.empty(item.subject)) {

        if (!helper.empty(item.organisation)) {
          item.school = item.organisation;
        }

        if (!helper.empty(item.name)) {
          item.subject = item.name;
        }

      }

      cvimport.$modal_edupos_choice.modal('hide');
      cvimport.$modal_edupos_choice.$body.find('p').remove();
      cvimport.$form_education.setValues(item);

      $(cvimport.$form_education).find('[name="educationtype_id"]').val(0);
      $(cvimport.$form_education).find('[name="educationtype_id"]').trigger('change');

      cvimport.step++;
      cvimport.$modal_education.modal({
        show: true,
        backdrop: 'static',
        keyboard: false
      });
    });

    let edupos_title = cvimport.getNextEduposTitle();

    let $edupos_choice_text = $(`<p>Handelt es sich beim nächsten Eintrag <strong>"${edupos_title}"</strong> um einen Bildungsweg oder um Praxiserfahrung?</p>`);

    cvimport.$modal_edupos_choice.find('.modal-title').text(t('cvdata.headline_edupos_choice') + ' ' + (cvimport.step + 1) + ' von ' + cvimport.parsed_data.other.edu_pos.length);
    cvimport.$modal_edupos_choice.$body.append($edupos_choice_text);
    cvimport.$modal_edupos_choice.modal({
      show: true,
      backdrop: 'static',
      keyboard: false
    });
  },

  getNextEduposTitle: () => {
    let edupos_title = '';
    let item = cvimport.parsed_data.other.edu_pos;

    if (item && item.length > 0) {

      if (cvimport.step < item.length) {
        if (item[cvimport.step].school) {
          edupos_title = item[cvimport.step].school;
        }
        else if (item[cvimport.step].organisation) {
          edupos_title = item[cvimport.step].organisation;
        }
        else if (item[cvimport.step].degree) {
          edupos_title = item[cvimport.step].degree;
        }
      }

    }

    return edupos_title;
  },

  initParsedDataPositionModal: () => {

    let $btn_pos_save = button_save.js({text: t('cvdata.button_position_save')});

    cvimport.$modal_position = modal.js({
      title: t('cvdata.headline_position'),
      size: 'md'
    });

    cvimport.$form_position = $('<form></form>').uiForm({
      i18n_prefix: 'cvdata.',
      fields: cvdataform.getFieldsPractice(),
      success: () => {
        msg.success(t('g.save_success'));
      },
      error: (res) => {
        if(res && res.responseJSON && res.responseJSON.error) {
          msg.error(t(res.responseJSON.error));
        }
        else {
          msg.error(t('g.save_error'));
        }
      }
    });

    cvimport.$modal_position.$body.append(cvimport.$form_position);
    cvimport.$modal_position.$footer.append($btn_pos_save);

    $btn_pos_save.on('click', () => {
      let formdata = cvimport.collectFormData(cvimport.$form_position);
      formdata['document_id'] = cvimport.parsed_document_id;

      api.post('/cvdata/practice', formdata, {
        success: () => {
          cvimport.$form_position.resetValues();

          msg.success(t('g.add_success'));
          cvimport.$modal_position.modal('hide');

          if (cvimport.step < cvimport.parsed_data.other.edu_pos.length) {
            cvimport.showEduposChoiceModal();
          }
        }
      });
    });
  },

  initParsedDataEducationModal: () => {

    let $btn_edu_save = button_save.js({text: t('cvdata.button_education_save')});

    cvimport.$modal_education = modal.js({
      title: t('cvdata.headline_education'),
      size: 'md'
    });

    cvimport.$form_education = $('<form></form>').uiForm({
      i18n_prefix: 'cvdata.',
      fields: cvdataform.getFieldsEducation(),
      success: () => {
        msg.success(t('g.save_success'));
      },
      error: (res) => {
        if(res && res.responseJSON && res.responseJSON.error) {
          msg.error(t(res.responseJSON.error));
        }
        else {
          msg.error(t('g.save_error'));
        }
      }
    });

    cvimport.$modal_education.$body.append(cvimport.$form_education);
    cvimport.$modal_education.$footer.append($btn_edu_save);

    $btn_edu_save.on('click', () => {
      let formdata = cvimport.collectFormData(cvimport.$form_education);
      formdata['document_id'] = cvimport.parsed_document_id;

      api.post('/cvdata/education', formdata, {
        success: () => {
          cvimport.$form_education.resetValues();

          msg.success(t('g.add_success'));
          cvimport.$modal_education.modal('hide');

          if (cvimport.step < cvimport.parsed_data.other.edu_pos.length) {
            cvimport.showEduposChoiceModal();
          }
        }
      });
    });
  },

  collectFormData: (form) => {
    let formdata = {};
    let $form = $(form);

    $form.find('.form-group').each((i, elem) => {
      let $input;

      if ($(elem).find('input').length > 0) {
        $input = $(elem).find('input');
        formdata[$input.attr('name')] = $input.val();
      }

      if ($(elem).find('select').length > 0) {
        $(elem).find('select').each((i, el) => {
          $input = $(el).find(':selected');
          formdata[$(el).attr('name')] = $input.val();
        });
      }

      if ($(elem).find('textarea').length > 0) {
        $input = $(elem).find('textarea');
        formdata[$input.attr('name')] = $input.val();
      }

    });

    if (formdata.group) {
      formdata.practicetype = $form.find('[name="group"]').find(':selected').text();
    }

    if ($form.find('.address-wrapper').length > 0) {
      formdata.address = {
        street: $form.find('.address-field-route').val(),
        number: $form.find('.address-field-street_number').val(),
        zip: $form.find('.address-field-postal_code').val(),
        city: $form.find('.address-field-locality').val(),
        country: $form.find('.address-field-country').val(),
        lat: $form.find('.address-field-lat').val(),
        long: $form.find('.address-field-long').val()
      }
    }

    return formdata;
  }

};

export default cvimport;
