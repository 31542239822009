import media from '../media';
import api from '../api';
import time from '../time';
import modal from '../../_compiled/components/modal';

$.fn.uiStatusSettings = function(options) {

  // default options
  let plugin = this;
  let $element = $(this);

  options = $.extend({},{
    table: false,
    optionButtons: [],
    submit: (msg) => {}
  }, options);

  if(options.i18n_prefix === undefined) {
    options.i18n_prefix = options.table + '.';
  }


  let $infobox = (`
    <div class="alert alert-warning">
        <h5><i class="icon fas fa-info"></i> Info!</h5>
        Du bist dabei eine Status-Option zu löschen! Elemente, die diesen Status hatten, bekommen jenen Status, den Du hier auswählst.<br />
        Die Kandidaten-Datenbank muss anschließend auch aktualisiert werden, bitte habe dann ein bisschen Geduld!
    </div>`);

  let $modal_new_jobstatus = null;
  let $form_new_jobstatus = null;
  let $btn_del_jobstatus = null;

  plugin.initNewStatus = () => {

    $btn_del_jobstatus = $('<button type="button" class="btn btn-danger"><i class="fas fa-skull-crossbones"></i> jetzt löschen</button>');

    $form_new_jobstatus = $('<form></form>').uiForm({
      i18n_prefix: 'statusmerge.',
      fields: {
        'status': {
          text: t(options.i18n_prefix+'select_new_status'),
          type: 'Select',
          table: options.table
        }
      }
    });

    $modal_new_jobstatus = modal.js({
      size: 'md'
    });

    $modal_new_jobstatus.$footer.append($btn_del_jobstatus);

    $modal_new_jobstatus.$body.append($infobox);
    $modal_new_jobstatus.$body.append($form_new_jobstatus);

  };

  plugin.initAdminScaffold = () => {

    let fields = {
      'color': 'Colorpicker',
      'name': 'Text',
      'description': 'Textarea'
    };

    if (options.additional_fields !== undefined) {
      for (const [key, value] of Object.entries(options.additional_fields)) {
        fields[key] = value;
      };
    }

    let cols = [
      {text:'#',name:'id',width:30},
      {name:'color',width:30, text:'Farbe'},
      'name'
    ];

    let renders = {
      'id': (obj) => {
        return '<b>' + obj.id + '</b>';
      },
      'name': (obj) => {
        return '<a>' + obj.name + '</a><br /><small>Hex: ' + obj.color + '</small>';
      },
      'color': (obj) => {
        let style = ' style=""';
        let status = '0';
        if(obj.id) {
          status = obj.id;
          style = ' style="color:' + obj.color + '"';
        }

        return '<span style="display:none">' + status + '</span><i class="fas fa-circle fa-2x"' + style + '></i> ';
      }
    };

    if(options.default_state !== undefined) {
      cols = [
        {text:'#',name:'id',width:30},
        {name:'color',width:30, text:'Farbe'},
        'name', 
        {text: t('g.lb_is_default'), width:80, name:'default'}
      ];
  
      renders = {
        'id': (obj) => {
          return '<b>' + obj.id + '</b>';
        },
        'name': (obj) => {
          return '<a>' + obj.name + '</a><br /><small>Hex: ' + obj.color + '</small>';
        },
        'color': (obj) => {
          let style = ' style=""';
          let status = '0';
          if(obj.id) {
            status = obj.id;
            style = ' style="color:' + obj.color + '"';
          }
  
          return '<span style="display:none">' + status + '</span><i class="fas fa-circle fa-2x"' + style + '></i> ';
        },
        'default': (obj) => {
          if(obj.is_default) {
            return '<p>' + t('g.yes') + '</p>';
          }
          else {
            return '';
          }            
        }
      };     
    }

    $element.adminScaffold({
      onTriggerEdit: (id) => {
        options.onTriggerEdit(id);
      },
      beforeDelete: (item, callback) => {
        $modal_new_jobstatus.$title.text(item.name + ' löschen');
        $modal_new_jobstatus.modal({
          show: true,
          backdrop: 'static',
          keyboard: false
        });

        let $select = $form_new_jobstatus.find('select[name=status_id]');
        $select.find('option').show();
        $select.find('option[value='+item.id+']').hide();

        $btn_del_jobstatus.unbind('click').click(() => {

          api.post('/resource/status/' + options.table + '/merge',{
            old_status: item.id,
            new_status: $select.val()
          },{
            success: () => {
              $form_new_jobstatus[0].reset();
              $modal_new_jobstatus.modal('hide');
              callback();
            }
          });
        });
      },
      title: t(options.i18n_prefix+'title'),
      table: [options.table,options.table],
      fields: fields,
      cols: cols,
      optionButtons: options.optionButtons,
      render: renders
    });

    plugin.$element = $element;
  };

  this.initialize = () => {

    plugin.initNewStatus();

    plugin.initAdminScaffold();

    return plugin;
  };

  return this.initialize();
};



/*



 */

