import modal from '../_compiled/components/modal';

const dialog = {
  $modal: null,
  $btn_abort: null,
  $btn_apply: null,
  confirm: (options) => {

    dialog.resetModal();
    dialog.initConfirm();

    dialog.$modal.$title.append(options.title);
    dialog.$modal.$body.append(options.body);

    dialog.$btn_apply.unbind('click');
    dialog.$btn_apply.click((ev) => {
      ev.preventDefault();
      options.apply();
      if(options.complete) {
        options.complete();
      }
      dialog.$modal.modal('hide');
    });

    dialog.$btn_abort.click((ev) => {
      ev.preventDefault();
      dialog.$modal.modal('hide');
      if(options.complete) {
        options.complete();
      }
    });

    dialog.$modal.$footer.append(dialog.$btn_abort);
    dialog.$modal.$footer.append(dialog.$btn_apply);

    dialog.$modal.modal({
      show: true,
      backdrop: 'static',
      keyboard: false
    });
  },
  textOnly: (options) => {
    dialog.$modal = modal.js({
      size: 'xl'
    });

    dialog.resetModal();

    dialog.$modal.$title.append(options.title);
    dialog.$modal.$body.append(options.body);

    dialog.$modal.modal({
      show: true,
      backdrop: 'static',
      keyboard: false
    });
  },
  init: () => {
    dialog.$modal = modal.js({
      size: 'md'
    });
    dialog.resetModal();

    dialog.$btn_abort = $('<button type="button" class="btn btn-default">' + t('g.no') + '</button>');
    dialog.$btn_apply = $('<button type="button" class="btn btn-primary">' + t('g.apply') + '</button>');

    dialog.$btn_abort.click((ev) => {
      ev.preventDefault();
      dialog.$modal.modal('hide');
    });
  },
  initConfirm: () => {

  },
  resetModal: () => {
    dialog.$modal.$title.empty();
    dialog.$modal.$body.empty();
    dialog.$modal.$footer.empty();
  }
};

export default dialog;
