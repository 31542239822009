import app from '../../app/app';;
import time from '../../app/time';;
import api from '../../app/api';

const am_agencies = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12" id="agency-management"> </div> </div></div>'; return out;
    },
    js: function(it) {
           app.setTitle('Recruiting-Agency Management'); app.setBreadcrumps({ 'Administration': '/admin/', 'Agencies': '/admin/agencies' }); let $agency_management = $('#agency-management').adminScaffold({ title: 'Recruiting-Agencies', table: ['agency','agencies'], fields: { 'name': 'Text', 'alluser': 'TagSelect', 'logo': { type: 'Text' }, 'font_color': { type: 'Colorpicker' }, 'background_color': { type: 'Colorpicker' }, 'highlight_color': { type: 'Colorpicker' }, 'a_record': { type: 'Text' },  }, onTriggerEdit(id){ api.get("/resource/get/agency/" + id, { success: (response) => { let adr = response.billing_address; jQuery("#info-invoice-address").html( adr.street + ", " + adr.number + "<br>" + adr.city  + ", " + adr.zip + "<br>" + adr.country ); } }); }, cols: [{text:'#',name:'id',width:30}, 'name'], render: { 'id': (obj) => { return '<b>' + obj.id + '</b>'; }, 'name': (obj) => { return '<a>' + obj.name + '</a><br /><small>erstellt: ' + time.ago(obj.created_at) + '</small>'; } } });
    }
};

export default am_agencies;
