import api from '../api';

$.fn.uiFormPhone = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $input;
  let $input_group;

  options = $.extend({},{
    i18n_prefix: '',
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);


  let plugin = this;

  // public methods
  this.initialize = () => {

    $label = $('<label>' + options.label + '</label>');
    $input = $('<input name="' + options.name + '" class="form-control" type="text" />');

    $input_group = $('<div class="input-group"><div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-phone"></i></span></div></div>');
    $input_group.append($input);

    $element.addClass('form-group form-group-'+options.name).append($label).append($input_group);

    this.$input = $input;

    return plugin;

  };

  this.setValue = (value) => {
    $input.val(value);
    $label.text(options.label_edit);
    $input.attr('placeholder', options.placeholder_edit);
  };

  this.resetValue = () => {
    $input.val('');
    $label.text(options.label);
    $input.attr('placeholder', options.placeholder);
  };

  return this.initialize();
};


