import app from '../../app/app';;
import api from '../../app/api';;
import time from '../../app/time';;
import msg from '../../app/msg';

const admin_organisationblacklist = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12 admin-organisationblacklist"> <div id="organisationblacklist-list"></div> </div> </div></div>'; return out;
    },
    js: function(it) {
            app.setTitle('Unternehmen Blacklist verwalten'); app.setBreadcrumps({ 'Administration': '/agency/admin', 'Unternehmen Blacklist': '/agency/admin/organisation-blacklist' }); let $orgablacklist = $('#organisationblacklist-list').uiDataTableScroll({ add: true, edit: false, delete: true, hide_search: true, default_order: [0, 'asc'], i18n_prefix: 'organisationblacklist.', pageLength: 12, cols: [ {name: 'id', text:'#', width: 10, hidden: true}, {name: 'name', text: 'Unternehmen'}, {name: 'shortname', text: 'Kurzname'}, {name: 'updated_at', hidden: true} ], render:{ 'id': (obj) => { return '<b>#' + obj.id + '</b>'; }, 'name': (obj) => { return obj.name; }, 'shortname': (obj) => { return obj.shortname; }, 'created_at': (obj) => { return '<span style="display:none;">' + obj.created_at + '</span><strong>' + time.md(obj.created_at) + '</strong>'; } }, table: ['agency_organisation_blacklist', 'agency_organisation_blacklist'], fields: { 'agency_organisation': 'Select2' } });
    }
};

export default admin_organisationblacklist;
