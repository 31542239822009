import app from '../../app/app';;
import api from '../../app/api';;
import time from '../../app/time';;
import msg from '../../app/msg';

const admin_organisations = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12 admin-organisations"> <div id="org-list"></div> </div> </div></div>'; return out;
    },
    js: function(it) {
            app.setTitle('Unternehmen verwalten'); app.setBreadcrumps({ 'Administration': '/admin', 'Unternehmen': '/admin/organisations' }); let $orgs = $('#org-list').uiDataTableScroll({ add: false, edit: true, delete: false, default_order: [0, 'asc'], i18n_prefix: 'organisation.', pageLength: 12, cols: [ {name: 'id', text:'#', width: 10, hidden: true}, {name: 'name', text: 'Name'}, {name: 'shortname', text: 'Kurzname'}, {name: 'approved', text: 'freigegeben'}, {name:'created_at', text: 'Erstellt', width: 70}, {name:'updated_at', hidden: true} ], render:{ 'id': (obj) => { return '<b>#' + obj.id + '</b>'; }, 'name': (obj) => { return '<a href="/crm/agency-organisation/' + obj.id + '" class="router-link text-bold">' + obj.name + '</a>'; }, 'shortname': (obj) => { return '<span>' + obj.shortname + '</span>'; }, 'approved': (obj) => { let status = ''; if (obj.approved == 1) { status = ' checked="checked"'; } return `<input type="checkbox" class="admin-orga-checkbox" name="orga-approved-${obj.id}" value="${obj.id}"${status} />`; }, 'created_at': (obj) => { return '<span style="display:none;">' + obj.created_at + '</span><strong>' + time.md(obj.created_at) + '</strong>'; } }, afterLoad: () => {  $('.admin-orga-checkbox').change((ev) => { let $cb = $(ev.target); api.post(`/organisation/toggle-approved/${$cb.val()}`, { success: () => { msg.success(t('g.save_success')); } }); }); }, table: ['organisation', 'organisations'], fields: { 'name': 'Text', 'shortname': 'Text', 'organisationtype': { text: 'Branche', type: 'Select', table: 'organisationtype', validation: 'required' }, 'parent': { text: 'Tochter-Unternehmen von', type: 'Select2', table: 'organisation', ajax: true } }, });
    }
};

export default admin_organisations;
