import app from '../../app/app';

const modal = {
    tpl: function(it) {
        var out='<div class="modal" role="dialog"> <div class="modal-dialog modal-'+(it.size)+'" role="document"> <div class="modal-content"> <div class="modal-header"> <h5 class="modal-title">'+(it.title)+'</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> </div> <div class="modal-body"> '+(it.body)+' </div> <div class="modal-footer"> '+(it.footer)+' </div> </div> </div></div>'; return out;
    },
    js: function(it) {
         let options = $.extend({},{ size: 'lg', title: '', footer: '', body: '' }, it); let $element =$(modal.tpl(options)); $element.$dialog = $element.find('.modal-dialog'); $element.$body = $element.find('.modal-body'); $element.$footer = $element.find('.modal-footer'); $element.$title = $element.find('.modal-title'); return $element;
    }
};

export default modal;
