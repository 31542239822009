import app from '../../app/app';;
import api from '../../app/api';;
import routes from '../../app/routes';;
import session from '../../app/session';

const logging = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div id="system-logs" class="col-md-12"> </div> </div></div>'; return out;
    },
    js: function(it) {
            app.setTitle('Systemprotokolle'); let $logs = $('#system-logs').uiDataTableScroll({ add: false, edit: false, delete: false,  default_order: [1, 'asc'], i18n_prefix: 'systemlogs.', pageLength: 15, limit_length: 200, maxmium_meta_width: 500, cols: [ {name: 'id', text:'#', width: 10, hidden: true}, {name: 'type', text: 'Type'}, {name: 'code', text: 'Code'}, {name: 'meta', text: 'Meta'}, {name: 'info', text: 'Information'}, {name:'created_at', text: 'Erstellt', width: 70}, {name:'updated_at', hidden: true} ], render:{ 'id': (obj) => { return obj.id; }, 'type': (obj) => { return obj.type; }, 'code': (obj) => { return obj.code; }, 'meta': (obj) => { let meta_max_wdith = $logs.options.maxmium_meta_width;         let limit_len = $logs.options.limit_length;  if( obj.meta.length >  limit_len ) {           let render_full_meta = "<div style='max-width: " + meta_max_wdith + "px; display: none;' id='full-meta-" + obj.id + "'>"; let render_mini_meta = "<div style='max-width: " + meta_max_wdith + "px;' id='mini-meta-" + obj.id + "'>";  render_mini_meta += obj.meta.substr(0, limit_len); render_full_meta += obj.meta; render_full_meta += "</div>";           render_mini_meta += "</div>";  return render_mini_meta + render_full_meta + '<br><a data-id="' + obj.id + '" "' + ' class="set-show-more-less" style="cursor: pointer;">mehr</a>'; } else { return obj.meta; } }, 'info': (obj) => { return obj.info; }, }, afterLoad: () => {       $logs.find('.set-show-more-less').click((ev) => {         let $el = $(ev.currentTarget); let $div_full_meta = $logs.find('#full-meta-' + $el.data('id').toString()); let $div_mini_meta = $logs.find('#mini-meta-' + $el.data('id').toString()); if ($el.text() == "mehr") { $el.text("weniger");           $div_mini_meta.hide(); $div_full_meta.show(); } else { $el.text("mehr"); $div_mini_meta.show(); $div_full_meta.hide(); } }); }, table: ['logging', 'loggings'], fields: { 'type': 'Text', 'info': 'Text' } });
    }
};

export default logging;
