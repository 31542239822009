import api from '../api';
import msg from '../msg';
import routes from '../routes';
import cvdataform from "../cvdataform";

$.fn.uiCvdata = function(options) {

  // default options
  let plugin = this;
  let $element = $(this);

  options = $.extend({}, {
    title: false,
    onLoading: () => {
    },
    onFinish: () => {
    }
  }, options);

  let $form;
  let fields_lifestep;

  cvdataform.setCvId(options.cv_id);

  fields_lifestep = Object.assign({}, cvdataform.getFieldsLifestep());

  Object.assign(fields_lifestep, cvdataform.getFieldsEducation());
  Object.assign(fields_lifestep, cvdataform.getFieldsPractice());

  /*let extra_fields_education = {
    'cv_id': {
      type: 'Hidden',
      value: options.cv_id
    },
    'id': {
      type: 'Hidden',
      class: 'editmode'
    }
  };
  let extra_fields_practice = Object.assign({}, extra_fields_education);

  let fields_education = Object.assign(extra_fields_education, cvdataform.getFieldsEducation());
  let fields_practice = Object.assign(extra_fields_practice, cvdataform.getFieldsPractice());*/

  plugin.initForm = () => {
    $form = $('<form></form>').uiForm({
      success: () => {
        msg.success(t('g.save_success'));

        if (!options.cv_id) {
          routes.refresh();
        }
      },
      error: (res) => {
        if(res && res.responseJSON && res.responseJSON.error) {
          msg.error(t(res.responseJSON.error));
        }
        else {
          msg.error(t('g.save_error'));
        }

      },
      url: '/cvdata/update',
      url_overwrite: true,
      i18n_prefix: 'cvdata.',
      fields: {
        'candidate_id': 'Hidden',
        'cv_id': {
          type: 'Hidden',
          value: options.cv_id
        },
        'lifesteps': {
          type: 'Objectlist',
          url: '/cvdata/lifesteps',
          url_list: '/cvdata/lifesteps/list/' + options.cv_id,
          url_edit: '/cvdata/lifesteps',
          url_overwrite: true,
          columns: ['Eintrag', 'Zeitraum', 'Info'],
          firstFieldDependencies: true,
          firstFieldSrc: 'type',
          fields: fields_lifestep,
          render: (item) => {
            let org = '';

            if (item.organisation != null) {
              org = item.organisation;
            }

            let exp_date = item.start + ' - ' + item.end;
            if(item.start.length === 0 && item.end.length === 0) {
              exp_date = '';
            }
            else if(item.start.length === 0) {
              exp_date = item.end;
            }
            else if(item.end.length === 0) {
              exp_date = 'Seit ' + item.start;
            }

            let view = `<tr>
                  <td class="cv-list-name">${item.education_name}</td>
                  <td class="cv-list-period">${exp_date}</td>
                  <td class="cv-list-info">${org}</td>
                </tr>`;

            return view;
          }
        },
        /*
        'education': {
          type: 'Objectlist',
          url: '/cvdata/education',
          url_list: '/cvdata/education/list/' + options.cv_id,
          url_edit: '/cvdata/education',
          url_overwrite: true,
          columns: ['Art', 'Zeitraum', 'Info'],
          firstFieldDependencies: true,
          firstFieldSrc: 'educationtype_id',
          fields: fields_education,
          render: (item) => {
            let view = `<tr>
                  <td>${item.education_name}</td>
                  <td>${item.start} - ${item.end}</td>
                  <td>${item.school}</td>
                </tr>`;

            return view;
          }
        },
        'practice': {
          type: 'Objectlist',
          url: '/cvdata/practice',
          url_list: '/cvdata/practice/list/' + options.cv_id,
          url_edit: '/cvdata/practice',
          url_overwrite: true,
          columns: ['Art', 'Zeitraum', 'Info'],
          firstFieldDependencies: true,
          firstFieldSrc: 'practicetype_group',
          fields: fields_practice,
          render: (item) => {
            if (item.group == 'award') {
              item.practicetype = t('practice.award');
            }

            let view = `<tr>
                  <td>${item.practicetype}</td>
                  <td>${item.start} - ${item.end}</td>
                  <td>${item.name}</td>
                </tr>`;

            return view;
          }
        },
        */
        'skilltypes': {
          type: 'Objectaccordion',
          url: '/cvdata/skills',
          url_edit: '/cvdata/skills',
          url_list: '/cvdata/skills/list/' + options.cv_id,
          url_overwrite: true,
          enable_add_group: true,
          enable_add_item: true,
          field_group_id: 'skilltype',   // database field with the group_id for an accordion
          items_array_key: 'skills',        // name of the array key for the items of an accordion
          cv_id: options.cv_id,
          fields: {
            'cv_id': {
              type: 'Hidden',
              value: options.cv_id
            },
            /*'skilltype_id': {
              type: 'Hidden',
              class: 'editmode'
            },*/
            'skilltype': {
              type: 'Select2',
              ajax: false,
              allowInput: true,
              fieldname: 'skilltype',
              /*class: 'addgroup'*/
            },
            'skill_id': {
              type: 'Hidden',
              class: 'editmode'
            },
            /*'skilltype': {
              type: 'Text',
              class: 'addgroup'
            },*/
            'name': {
              type: 'Text',
              /*class: 'editreadonly'*/
            },
            'rate': {
              type: 'Number',
              min: 0,
              max: 7,
              step: 1
            },
          },
          render:(item) => {
            let name = item.name.toLowerCase().split(' ').join('-');
            let $item = $(`<div class="card card-${name}"></div>`);
            let $header = $(`<div class="card-header">
                  <h4 class="card-title">
                    <a data-toggle="collapse" data-parent="#skilltypes-accordion" href="#collapse-${name}" aria-expanded="true">
                      ${item.name}
                    </a>
                  </h4>
                </div>`);

            $item.append($header);

            if(item.skills && item.skills.length > 0) {

              let $skills = `<div id="collapse-${name}" class="panel-collapse in collapse show">
                <div class="card-body">`;

              item.skills.forEach((skill) => {
                $skills += `<div class="row">
                              <div class="progress-group col-md-8">
                                <b>${skill.name}</b>
                                <span class="float-right"><b>${skill.rate}</b></span>
                                <div class="progress progress-sm">
                                  ${plugin.renderProgressBar(skill.rate)}
                                </div>
                              </div>
                              <div class="progress-group col-md-2"></div>
                            </div>`;
              });

              $skills += `</div>
                <div>
                    <button data-id="${item.id}" class="btn btn-primary btn-oa-additem">${t('cvdata.add_accordion_item')}</button>
                </div>
              </div>`;

              $item.append($skills);

            }

            return $item;
          }
        },
        'interests': {
          type: 'Objectlist',
          i18n_prefix: 'cvdata.',
          url: '/cvdata/interests',
          url_list: '/cvdata/interests/list/' + options.cv_id,
          url_edit: '/cvdata/interests',
          url_overwrite: true,
          cv_id: options.cv_id,
          columns: ['Interesse/Hobby', 'Details'],
          fields: {
            'cv_id': {
              type: 'Hidden',
              value: options.cv_id
            },
            'skill_id': {
              type: 'Hidden',
              class: 'editmode'
            },
            'name': {
              type: 'Text',
              class: 'edittext'
            },
            'details': 'Text'
          },
          render: (item) => {
            let details = item.details;

            if (!details || details === undefined) {
              details = '';
            }

            let view = `<tr>
                  <td>${item.name}</td>
                  <td>${details}</td>
                </tr>`;

            return view;
          }
        },
        'submitted': 'Submit'
      }
    });

    if (options.cv_id && options.cv_id !== undefined) {
      api.get('/cvdata/' + options.cv_id, {
        success: (response) => {
          $form.setValues(response);
        }
      });
    }
    $element.append($form);
  };

  this.renderProgressBar = (rate) => {
    let $progress_bar;

    if (rate == 0) {
      $progress_bar = `<div class="progress-bar bg-danger" style="width: 0%"></div>`;
    }
    else if (rate == 1 || rate == 2 || rate == 3) {
      $progress_bar = `<div class="progress-bar bg-danger" style="width: ${rate * 15}%"></div>`;
    }
    else if(rate == 4 || rate == 5) {
      $progress_bar = `<div class="progress-bar bg-warning" style="width: ${rate * 15}%"></div>`;
    }
    else if (rate == 6) {
      $progress_bar = `<div class="progress-bar bg-success" style="width: ${rate * 15}%"></div>`;
    }
    else {
      $progress_bar = `<div class="progress-bar bg-success" style="width: 100%"></div>`;
    }

    return $progress_bar;
  };

  this.initCandidateId = () => {
    let url_parts = window.location.href.split('/');
    let candidate_id = url_parts.pop();
    let $elem = $element.find('input[name="candidate_id"]');

    if (parseInt(candidate_id, 10) > 0) {
      $elem.val(candidate_id);
    }
    else {
      $elem.val('null');
    }
  };

  this.initialize = () => {

    plugin.initForm();
    plugin.initCandidateId();

    if (!options.cv_id || options.cv_id === undefined) {
      $element.find('.form-group-education').hide();
      $element.find('.form-group-practice').hide();
      $element.find('.form-group-skilltypes').hide();
      $element.find('.form-group-interests').hide();
    }

    return plugin;
  };

  return this.initialize();

};
