import media from '../media';
import api from '../api';
import modal from "../../_compiled/components/modal";
import msg from "../msg";

$.fn.uiCardAvatar = function(options) {
  // support multiple elements
  if (this.length > 1) {
    this.each(() => { $(this).uiCardAvatar(options) });
    return this;
  }

  // default options

  options = $.extend({},{
    title: false,
    collapse: false,
    onStatusChange: () => {}
  }, options);

  let $avatar, $indicator;
  let candidate = options.candidate;

  let $userinfos = $(`
    <div>
      <div class="text-center">
        <img class="profile-user-img img-fluid img-circle" src="` + media.image(candidate.avatar,'128x128', '/img/avatar5.png') + `"><a style="margin-left: -28px;position: relative;top: 36px;" href="#" title="" onclick="return false;"><i class="fas fa-circle fa-2x"></i></a>
      </div>
      <h3 class="profile-username text-center" id="candidate_name">${candidate.firstname} ${candidate.lastname}</h3>
    </div>
    `);
  $avatar = $userinfos.find('.profile-user-img');
  $indicator = $userinfos.find('.profile-user-img + a');

  let plugin = this;
  let statuslist = {};

  let $element = $(this);
  let $form;
  let $modal, $save_btn;

  plugin.updateColorIndicator = () => {

    if(candidate.status_id) {
      let status = statuslist[candidate.status_id];

      //$element.css('border-color', status.color);
      //$avatar.css('border-color', status.color);
      $indicator.css('color', status.color).attr('title', status.name);
    }

  };

  plugin.showLoading = () => {
    $element.showLoading();
  };

  plugin.hideLoading = () => {
    $element.hideLoading();
  };

  plugin.showModal = () => {
    $modal.modal({
      show: true,
      backdrop: 'static',
      keyboard: false
    });
  };

  this.initModal = () => {

    $save_btn = $('<button class="btn btn-default">Speichern</button>');

    $modal = modal.js({
      title: t(options.i18n_prefix + 'task_add_entry'),
      size: 'lg'
    });

    $modal.$footer.append($save_btn);
  };

  this.initForm = () => {
    $form = $('<form></form>').uiForm({
      success: () => {
        msg.success(t('g.add_success'));
        $modal.modal('hide');
      },
      error: (res) => {
        if (res && res.responseJSON && res.responseJSON.error) {
          msg.error(t(res.responseJSON.error));
        } else {
          msg.error(t('g.save_error'));
        }

      },
      url: options.url,
      i18n_prefix: options.i18n_prefix,
      fields: options.fields,
      $el_save: $save_btn
    });

    $modal.$body.append($form);
  };

  this.initialize = () => {
    $element.uiCard({
      header: false
    });
    $element.addClass('card-primary card-outline');

    let $status_select = $('<div></div>').uiFormSelect({
      name: 'candidatestatus',
      table: 'candidatestatus',
      wrapper: false,
      success: (response) => {
        $status_select.$input.val(candidate.status_id);
        let status;

        for (status of response) {
          statuslist[status.id] = status;
        }
        plugin.updateColorIndicator();

      }
    });

    $status_select.$input.change(() => {
      $element.showLoading();
      let new_status_id = $status_select.$input.val();
      api.post('/candidate/status/' + candidate.id,{
        status_id: new_status_id
      },{
        complete: () => {

          candidate.status_id = new_status_id;
          plugin.updateColorIndicator();
          $element.hideLoading();
          options.onStatusChange(statuslist[new_status_id]);

        }
      });
    });

    $status_select.$input.addClass('form-control-sm');

    plugin.initModal();
    plugin.initForm();

    if (candidate.user_verified && candidate.user_verified.verified == true) {
      $avatar.addClass('optin');
    }
    else {
      $avatar.addClass('nooptin');
    }

    $userinfos.append($status_select.$input);

    $element.$body.addClass('box-profile').append($userinfos);

    return plugin;

  };

  return this.initialize();

};

