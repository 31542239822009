import api from '../api';

$.fn.uiFormSelect = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $input;
  let values = {};

  options = $.extend({},{
    i18n_prefix: '',
    wrapper: true,
    default: false,
    onChange: (value, $select, $form) => {

    }
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);

  if(options.fieldname === undefined) {
    options.fieldname = options.name + '_id';
  }

  let plugin = this;
  let current_value;
  let url;

  // public methods
  this.initialize = () => {

    $label = $('<label>' + options.label + '</label>');
    $input = $('<select name="' + options.fieldname + '" class="form-control"></select>');

    url = '/resource/select/' + options.table;

    if(options.table !== undefined) {

      if (options.table.name && options.table.name !== undefined && options.table.type && options.table.type !== undefined) {
        url = '/grouped-resource/' + options.table.name + '/select/' + options.table.type;
      }

      api.get(url, {
        success: (response) => {

          let listing = response.listing;
          if(listing === undefined) {
            listing = response;
          }

          plugin.initValues(listing);

          if(options.success !== undefined) {
            options.success(response);
          }
        }
      });

    }
    else if(options.values !== undefined) {
      plugin.initValues(options.values);
    }
    else if(options.ajax_url !== undefined) {

      api.get(options.ajax_url, {
        success: (response) => {

          let listing = response.listing;
          if(listing === undefined) {
            listing = response;
          }

          plugin.initValues(listing);

          if(options.success !== undefined) {
            options.success(response);
          }
        }
      });

    }

    if(options.wrapper) {
      $element.addClass('form-group form-group-' + options.name).append($label).append($input);
    }
    else {
      $element.append($input);
    }

    $input.bind('change', (ev) => {
      options.onChange($input.val(), $input, $element.parent('form'));
    });


    this.$input = $input;

    return plugin;

  };

  this.initValues = (values) => {

    this.values = values;

    values.unshift({id:0,name:t('g.please_choose')});

    $.each(values, (i, obj) => {

      let text = obj.text;
      if(text === undefined) {
        text = obj.name;
      }
      if(text === undefined) {
        text = obj.title;
      }

      $input.append('<option value="' + obj.id + '">' + text + '</option>');

      if(current_value) {
        plugin.setValue(current_value);
      }
      else if(options.default !== false) {
        $input.val(''+options.default);
      }
      else {
        $input.val(0);
      }

    });
  };

  this.setValue = (value) => {

    if(value) {

      let val = value;
      if(typeof value === 'object') {
        val = value.id;
      }

      current_value = val;

      $input.val(val);
      $label.text(options.label_edit);
      $input.attr('placeholder', options.placeholder_edit);
    }

  };

  /*
  this.showError = (fieldname, value) => {
    let $input = $element.find(`[name="${fieldname}"]`);
    //$input.addClass('is-invalid');
    $input.css('border', '1px solid blue');
    $input.parent().append(`<span class="error invalid-feedback">${t(value)}</span>`);
  };
 */

  this.resetValue = () => {

    current_value = null;
    $input.val('');
    $input.val(0);

    if(options.default !== false) {
      $input.val(''+options.default);
    }

    $label.text(options.label);
    $input.attr('placeholder', options.placeholder);
  };

  return this.initialize();
};


