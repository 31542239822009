import app from '../../app/app';;
import time from '../../app/time';;
import helper from '../../app/helper';;
import msg from '../../app/msg';;
import api from '../../app/api';

const jobpools = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12" id="jobpool-management"> </div> </div></div>'; return out;
    },
    js: function(it) {
             app.setTitle('Job-Pools Management'); app.setBreadcrumps({ 'Account-Verwaltung': '/account-management/', 'Agencies': '/account-management/agencies' }); let $jobpool_management = $('#jobpool-management').adminScaffold({ title: 'Job-Pools', table: ['jobpool','jobpools'], fields: { 'name': 'Text', 'description': 'Textarea', 'job': { type: 'TagSelect', ajax: true } }, cols: [{text:'#',name:'id',width:30}, 'name', {text:'Caching', name:'prerender', width: 30}], render: { 'id': (obj) => { return '<b>' + obj.id + '</b>'; }, 'prerender': (obj) => { return '<button data-slug="' + obj.slug + '" class="prerender-link btn btn-default btn-sm"><i class="fas fa-sync-alt"></i></button>'; }, 'name': (obj) => { return '<a class="pool-link" target="_blank" data-slug="' + obj.slug + '" href="https://jungwild.io/jobs/pool/' + obj.slug + '">' + obj.name + '</a><br /><small>' + obj.job.map((a) => {return a.name}).join(', ') + '</small>'; } }, onRefresh: (response) => { $jobpool_management.find('.prerender-link').click((ev) => { let $btn = $(ev.currentTarget); let $icon = $btn.find('i'); let slug = $btn.data('slug'); $icon.addClass('fa-spin'); api.get('/hooks/prerender/jobs/pool/' + slug,{ complete: () => { $icon.removeClass('fa-spin'); }, success: () => { msg.success('/jobs/pool/' + slug + ' wurde gecached!'); }, error: () => { msg.error('Konnte `/jobs/pool/' + slug + '` nicht cachen, eventuell läuft gerade schon ein prerender prozess, versuche es später noch einmal.'); } }); });  } });
    }
};

export default jobpools;
