import api from './api';
import app from './app';
import session from "./session";
import routes from './routes';
import user_dropdown_menu from '../_compiled/components/user_dropdown_menu';

var login = {

  $login_box: null,
  $login_message: null,
  $member_page: null,
  $body: null,
  $form: null,
  $loader: null,
  login_route: false,
  $login_msg: null,
  $inputs: null,
  $header_menu: null,
  loginDelayInterval: null,
  login_delay_secs: 0,

  init: () => {

    login.$body = $('body');
    login.$login_box = $('#login-box');
    login.$login_message = $('#login-message');
    login.$member_page = $('#member-page');
    login.$form = $('#form-login');
    login.$loader = $('#loginbox-loader');
    login.$login_msg = $('#login-msg-fail');
    login.$inputs = login.$form.find('input');
    login.$header_menu = $('#header-menu');
    login.$fail_message = $('#login-fail-message');
    login.$submit = $('#login-submit');

    if(session.isLoggedIn()) {
      login.hide();
    }
    else {
      if(app.getUri().substr(0,6) !== '/login') {
        login.setLoginRoute(app.getUri());
      }
      login.show();
    }

    login.$form.submit((ev) => {
      ev.preventDefault();

      let data = login.$form.serializeObject();

      if(data.email !== '' && data.password !== '') {
        login.$loader.show();

        api.post('/login', data, {
          success: (auth_response) => {

            /* check ACL hunter_login */
            const hl = auth_response.user.acl.hunter_login;
            if (hl === undefined || hl === false) {
              console.log("acl: no hunter login!");
              login.$inputs.addClass('is-invalid');
              login.$login_box.effect('shake');
              return;
            }

            login.$inputs.val('');

            login.$inputs.removeClass('is-invalid');

            session.start(auth_response);

            /*
             * verstecke login Fenster
             */
            login.hide();
            login.hideMessage();

            if(login.login_route !== false) {
              routes.router.navigate(login.login_route);
              //routes.refresh();
              login.login_route = false;
            }
            else {
              routes.navigate('/');
            }
          },

          complete: () => {
            login.$loader.hide();
          },

          error: (result) => {
            const errors = result.responseJSON.errors;

            if (errors.result === 'wrong_pw') {
              if (errors.waiting_secs > 0) {
                login.startLoginDelay(errors.waiting_secs);
              }
            } else if (errors.result === 'wait for next attempt') {
              login.startLoginDelay(errors.waiting_secs);
            }
            login.$inputs.addClass('is-invalid');
            login.$login_box.effect('shake');
          }

        });

      }
      else {
        login.$login_box.effect('shake');
      }


    });
  },

  show: () => {
    login.$body.removeAttr('style');
    login.$member_page.hide();
    login.$body.addClass('hold-transition login-page');
    login.$login_box.show();
  },

  hide: () => {
    session.generateAclCss(() => {

      login.setUserTemplate();

      login.$login_box.hide();
      login.$body.removeClass('hold-transition login-page');
      login.$member_page.show();

    });
  },

  setLoginRoute: (url) => {
    login.login_route = url;
  },

  setUserTemplate: () => {
    $('#user-dropdown-menu').remove();
    let $user_menu = user_dropdown_menu.js(session.user);
    login.$header_menu.append($user_menu);
  },

  setMessage: (text) => {
    login.$login_message.text(text);
    login.$login_message.show();
  },

  hideMessage: () => {
    login.$login_message.hide();
  },

  startLoginDelay: (delay) => {
    clearInterval(login.loginDelayInterval);
    login.$submit.attr('disabled','disabled');
    login.login_delay_secs = delay;
    login.$fail_message.html('Zu viele Versuche. Bitte warten...');
    login.updateLoginDelayDisplay(login.login_delay_secs);
    login.loginDelayInterval = setInterval(login.updateLoginDelayDisplay, 1000);
  },

  updateLoginDelayDisplay: () => {
    login.$submit.html(login.login_delay_secs);
    login.login_delay_secs--;
    if (login.login_delay_secs < 0) {
        login.enableLoginForm();
        clearInterval(login.loginDelayInterval);
    }
  },

  enableLoginForm: () => {
    login.$fail_message.html('');
    login.$inputs.removeClass('is-invalid');
    login.$submit.html('Login');
    login.$submit.removeAttr('disabled');
  }
};

export default login;
