import api from '../api';
import msg from '../msg';

$.fn.uiFormTextarea = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $input;
  let $chars;

  options = $.extend({},{
    i18n_prefix: '',
    wrapper: true,
    del_button: {
      show: false
    }
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);


  let plugin = this;

  // public methods
  this.initialize = () => {

    $label = $('<label>' + options.label + '</label>');
    $input = $('<textarea name="' + options.name + '" class="form-control" placeholder="' + options.placeholder + '"></textarea>');
    $chars = $('<div class="chars"><span class="chars-current">0</span> / <span class="chars-max"></span> ' + t('job.characters') + '</div>');

    if(options.wrapper) {
      $element.addClass('form-group form-group-' + options.name).append($label).append($input);
    }
    else {
      $element.append($input);
    }

    if (options.del_button.show !== undefined) {
      if (options.del_button.show) {
        let $del_button = $(`<button type="button" class="btn btn-primary del-${options.name}"><i class="fas fa-trash-alt"></i>Löschen</button>`);
        $element.append($del_button);
      }
    }

    /* Anzahl Buchstaben anzeigen */
    if (options.maxlength && options.maxlength !== undefined) {
      $chars.find('.chars-max').text(options.maxlength);
      $element.append($chars);

      $input.on('keyup', () => {
        plugin.updateCharCount();
      });
    }

    /* Event auf Löschen Button */
    if (options.del_button.table !== undefined && options.del_button.attr !== undefined) {
      $element.find('.del-'+options.name).click((ev) => {

        if (confirm(t('g.sure_delete'))) {
          api.post(`/resource/update/attr/${options.del_button.table}/${options.del_button.id}`, {
            attr: options.del_button.attr,
            value: null
          }, {
            success: (response) => {
              $input.val('');
              msg.success(t('g.delete_success'));
            },
            error: (response) => {
              msg.error(t('g.error'));
            }
          })
        }
      });

    }

    this.$input = $input;

    return plugin;

  };

  this.updateCharCount = () => {
    let $char_div = $element.find('.chars');
    let $contents = $input.val();
    if($contents.length){
      $contents = $contents.replace(/(\r\n|\n|\r)/g,'  ');
    }
    let chars_curr = $contents.length;
    $element.find('.chars-current').text(chars_curr);

    if (chars_curr > options.maxlength) {
      $char_div.addClass('form-input-error');
    }
    else {
      $char_div.removeClass('form-input-error');
    }
  };

  this.setValue = (value) => {
    $input.val(value);
    $label.text(options.label_edit);
    $input.attr('placeholder', options.placeholder_edit);
    plugin.updateCharCount();
  };

  this.resetValue = () => {
    $input.val('');
    $label.text(options.label);
    $input.attr('placeholder', options.placeholder);
    plugin.updateCharCount();
  };

  return this.initialize();
};


