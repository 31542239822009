import propicons from '../propicons';

$.fn.uiInfoBox = function(options) {

  // default options
  let plugin = this;
  let $element = $(this);

  options = $.extend({}, {
    title: false,
    i18n_prefix: 'g.',
    wrapper: true
  }, options);

  plugin.addRow = (title, content, icon, markAsImportant) => {

    if(content === undefined) {
      return;
    }

    // handle content arrays
    if (Array.isArray(content)) {
      let values = [];
      for (const cont of content) {
        values.push(cont.text.trim());
      }
      content = values.join("<br />");
    }

    // eslint-disable-next-line valid-typeof
    if(typeof content === 'string') {
      if(content.trim() === '') {
        return;
      }
      if (title === 'LinkedIn' || title === 'Xing') {
        content = `<p class="text-muted">
                    <a href="${content}" target="_blank">${content}</a>
                  </p>
                  `; 
      } else {
        content = '<p class="text-muted">' + content + '</p>';
      }
    }
    else {
      if(content.text().trim() === '') {
        return;
      }
      let $p = $('<p class="text-muted"></p>');
      $p.append(content);
      content = $p;
    }

    if(icon !== undefined) {
      let color;
      if (markAsImportant !== undefined && markAsImportant == true) {
        color = ' style="color:#D6963A";';
      }
      title = '<i class="' + icon + ' mr-1"' + color + '></i> ' + title;
    }

    let $content = $('<div></div>');

    $content.append('<strong>' + title + '</strong>');
    $content.append(content);
    $content.append('<hr />');

    if(options.wrapper) {
      $element.$body.append($content);
    }
    else {
      $element.append($content);
    }

  };

  plugin.addProps = (object, props) => {
    props.forEach((prop) => {
      if (object[prop]) {
        let value = object[prop];

        /* hack for users/AccountManagers */
        if (prop === 'users') {
          let accountManagers = [];
          for (const user of object[prop]) {
            accountManagers.push(user.text.split('(')[0].trim());
          }
          value = accountManagers.join("<br />");
        }

        plugin.addRow(t(options.i18n_prefix + 'lb_' + prop), value, propicons[prop]);
      }
    });
  };

  plugin.addPlaintext = (text, keepFormat) => {
    let $content = $('<div></div>');

    if (text !== null) {
      if (keepFormat) {
        $content.append(text.replace(/\n/g, "<br />"));
      } else {
        $content.append(text);
      }
    }

    if(options.wrapper) {
      $element.$body.append($content);
    }
    else {
      $element.append($content);
    }
  };

  plugin.removeAllProps = () => {
    if(options.wrapper) {
      $element.$body.empty();
    }
    else {
      $element.empty();
    }
  };

  plugin.addTool = ($el) => {
    $element.addTool($el);
  };

  plugin.showLoading = () => {
    $element.showLoading();
  };

  plugin.hideLoading = () => {
    $element.hideLoading();
  };

  this.initialize = () => {

    if(options.wrapper) {
      $element.uiCard(options);
    }

    return plugin;
  };

  return this.initialize();

};
