import api from '../api';
import modal from '../../_compiled/components/modal';
import button_save from '../../_compiled/components/button_save';
import time from '../time';
import msg from "../msg";

$.fn.adminScaffold = function(options) {

    // private variables
    let $wrapper = null;
    let $table = null;
    let $modal = null;
    let $form = null;

    let url_list_orig = null;

    /*
    HANDLE OPTIONS
     */
    options = $.extend({},{
      title: '&nbsp',
      render: {},
      card: true,
      wrapper: true,
      quick_add: false,
      optionButtons: [],
      onRefresh: () => {},
      afterLoad: () => {},
      usersettings: false,
      disable_option_buttons: false,
      beforeDelete: (item, callback) => {
        if(confirm('Sicher?')) {
          callback();
        }
      },
      prepareListing: (response) => {
        return response;
      }
    }, options);

    if(options.usersettings) {
      options.table = ['usersetting', 'usersettings'];
    }

    if(options.wrapper && options.onLoading === undefined) {
      options.onLoading = () => {
        $wrapper.showLoading();
      };
    }
    else if(!options.wrapper) {
      options.onLoading = () => {};
    }

    if(options.wrapper && options.onFinishLoading === undefined) {
      options.onFinishLoading = () => {
        $wrapper.hideLoading();
      };
    }
    else if(!options.wrapper) {
      options.onFinishLoading = () => {};
    }

    if(Array.isArray(options.table)) {
      options.table_plural = options.table[1];
      options.table = options.table[0];
    }
    else {
      options.table_plural = options.table+'s';
    }

    if(options.i18n_prefix === undefined) {
      options.i18n_prefix = options.table + '.';
    }

    if(!options.url_list) {
      options.url_list = '/resource/list/' + options.table;
    }

    if (options.type && options.type !== undefined) {
      options.url_list = '/grouped-resource/' + options.table + '/list/' + options.type;
    }

    /*
    HANDLE OPTIONS END
     */

    let plugin = this;

    let $element = $(this);

    this.refresh = () => {
      options.onLoading();
      api.get(options.url_list,{
        success: (response) => {
          response = options.prepareListing(response);
          $table.setData(response);
          options.onRefresh(response);
        },
        complete: () => {
          options.onFinishLoading();
        }
      });
    };

  plugin.filterByUser = (user_id) => {
    if (url_list_orig === null) {
      url_list_orig = options.url_list;
    }
    options.url_list = url_list_orig + '/' + user_id;
  };

  plugin.resetFilter = () => {
    options.url_list = url_list_orig;
  }

  plugin.triggerAddItem = () => {
    $form.resetValues();
    $modal.modal({
      show: true,
      backdrop: 'static',
      keyboard: false
    });
  };

  plugin.triggerEdit = (id) => {
    options.onLoading();
    api.get('/resource/get/' + options.table + '/' + id,{
      success: (item) => {
        $form.setValues(item);
        $modal.modal({
          show: true,
          backdrop: 'static',
          keyboard: false
        });
        options.onFinishLoading();
        if (options.onTriggerEdit) {
          options.onTriggerEdit(id);
        }
      }
    });
  };

  plugin.triggerNew = () => {
    $form.resetValues();
    $modal.modal({
      show: true,
      backdrop: 'static',
      keyboard: false
    });
  };

  plugin.initOptionButtons = () => {
    options.optionButtons.push({
      name: 'edit',
      icon: 'fas fa-pencil-alt',
      class: 'btn-default'
    });

    options.optionButtons.push({
      name: 'delete',
      icon: 'fas fa-trash-alt',
      class: 'btn-danger'
    });
  };


    // public methods
    this.initialize = () => {

      let $btn_save = button_save.js();
      let $btn_save_edit = button_save.js();

      /*
       * Add New Modal
       */
      $modal = modal.js({
        title: t(options.i18n_prefix + 'add'),
        size: 'lg'
      });


      $modal.$footer.append($btn_save);
      $modal.$footer.append($btn_save_edit);

      plugin.$modal = $modal;

      if(options.wrapper) {
        $wrapper = $('<div></div>').uiCard({
          title: options.title
        });
      }
      else {
        $wrapper = $('<div></div>');
      }

      if (options.disable_option_buttons === false || options.disable_option_buttons === undefined) {
        plugin.initOptionButtons();
      }

      /*
       * FORM
       */
      $form = $('<form action="/' + options.table + '"></form>').uiForm({
        fields: options.fields,
        $el_save: $btn_save,
        $el_edit: $btn_save_edit,
        i18n_prefix: options.i18n_prefix,
        url: '/' + options.table,
        success: () => {
          plugin.refresh();
          $modal.modal('hide');
          $form.resetValues();
          if (options.onAddSuccess) {
            options.onAddSuccess();
          }
        },
        onAddMode: () => {
          $modal.$title.text(t(options.i18n_prefix +'add'));
        },
        onEditMode: (data) => {
          $modal.$title.text(t(options.i18n_prefix +'edit', data));
        },
        complete: () => {
          options.onFinishLoading();
        },
        start: () => {
          options.onLoading();
        },
        default: options.default
      });

      plugin.$form = $form;
      /*
       * FORM END
       */


      /*
       * NEW OBJECT
       */
      if (!options.disable_add || options.disable_add !== true) {
        plugin.$btn_new = $('<button type="button" class="btn btn-primary btn-sm"><i class="fas fa-plus"></i> ' + t(options.i18n_prefix + 'add') + '</button>');
      }


      /*
       * NEW OBJECT END
       */

      let cols = options.cols;
      cols.push({
        name: 'options',
        text: ' ',
        width: 150
      });

      let deleteItem = (obj, $td) => {

        options.beforeDelete(obj, () => {

          $td.parent().hide();
          api.delete('/resource/del/' + options.table + '/' + obj.id,{
            success: () => {
              $td.parent().remove();
              if (options.afterDelete) {
                options.afterDelete();
              }
            },
            error: (res) => {
              $td.parent().show();

              if(res && res.responseJSON) {
                msg.error(t(res.responseJSON.message));
              }
            }
          });

        });
      };

      options.render.options = (obj) => {

        let $td = $('<td class="project-actions text-right"></td>');
        let btngrp = '';

        options.optionButtons.forEach((btn) => {

          if(!btn.class) {
            btn.class = 'btn-default';
          }

          if(btn.render) {
            btngrp += btn.render(obj,btn);
          }
          else {
            btngrp += '<button type="button" name="btn-' + btn.name + '" class="btn ' + btn.class + ' btn-sm"><i class="' + btn.icon + '"></i></button>';
          }

          return '<div class="btn-group" role="group">' + btngrp + '</div>';
        });

        $td.append($(btngrp));

        $td.find('[name="btn-delete"]').on('click', () => {
          deleteItem(obj, $td);
        });


        $td.find('[name="btn-edit"]').on('click', () => {
          plugin.triggerEdit(obj.id);
        });

        return $td;
      };


      /*
       * TABLE
       */
      let table_options = {
        i18n_prefix: options.i18n_prefix,
        cols: cols,
        render: options.render,
        onRowClick: options.onRowClick
      };

      if(options.wrapper) {
        $table = $wrapper.table(table_options);
      }
      else {
        $table = $('<table></table>');
        $table.uiTable(table_options);
      }

      $table.addClass('projects table-striped');


      /*
       * QUICK ADD
       */
      if(options.quick_add) {

        let $row = $('<tr></tr>');
        let data_fields = [];
        let i18n_prefix = '';

        $.each(options.cols, (i, field) => {

          let $col = $('<td></td>');

          if(i < (options.cols.length-1)) {
            i18n_prefix = options.fields[field].i18n_prefix;

            let $form_el = $('<div></div>')['uiForm' + options.fields[field].type](options.fields[field]);
            $form_el.$input.addClass('form-control-sm');
            $col.append($form_el.$input);
            data_fields.push($form_el.$input);


            $row.append($col);
          }

        });

        let $btn_col = $('<td></td>');
        let $btn_add_quick = $('<button class="btn btn-default btn-sm" title="' + t(options.i18n_prefix + 'qa_add') + '"><i class="fas fa-plus-circle"></i></button>');

        $btn_add_quick.click((ev) => {
          ev.preventDefault();
          let data = {};

          $.each(data_fields, (i, $el) => {

            data[$el.attr('name')] = $el.val();

          });

          options.onLoading();
          api.post('/resource/add/' + options.table, data,{
            complete: () => {
              plugin.refresh();
            },
            success: () => {
              $.each(data_fields, (i, $el) => {

                $el.val('');

              });
            }
          });

        });

        $btn_col.append($btn_add_quick);
        $row.append($btn_col);

        $table.$body.prepend($row);
      }

      if (!options.disable_add || options.disable_add !== true) {
        plugin.$btn_new.click(() => {
          plugin.triggerNew();
        });
      }

      if(options.wrapper) {
        $wrapper.$body.append($table);
        $wrapper.addTool(plugin.$btn_new);
      }
      else {
        $wrapper.append($table);
      }


      $element.append($wrapper);

      /*
       MAKE FORM
       */

      $modal.$body.append($form);
      /*
      FORM END
       */


      this.refresh();

      plugin.$table = $table;

      /*
       * fix gaps
       */
      if(options.wrapper === false) {
        plugin.$btn_new.html('<i class="fas fa-plus"></i>').removeClass('btn-primary').addClass('btn-default btn-tool');
        plugin.$table.addClass('m-0').find('th').css('border-top', '0px').last().append(plugin.$btn_new).addClass('text-right');
      }

      /* @todo dirty */
      setTimeout(() => {
        options.afterLoad();
      },800);

      return plugin;

    };

    return this.initialize();
};


