import store from './store';
import api from './api';
import login from './login';
import helper from "./helper";
import routes from './routes';

var session = {

  logged_in: false,
  auth: null,
  user: null,
  after_login_callback: null,

  on_start_callbackstack: [],
  on_end_callbackstack: [],

  onStart: (cb) => {
    session.on_start_callbackstack.push(cb);
  },

  onEnd: (cb) => {
    session.on_end_callbackstack.push(cb);
  },

  init: () => {
    if(session.loadSession()) {
      session.logged_in = true;
      api.setToken(session.auth.access_token);
      //session.refresh();
    }
  },

  start: (auth_response) => {
    session.logged_in = true;
    session.auth = auth_response.auth;
    session.user = auth_response.user;

    let loggedInConfirmRetries = 0;

    api.setToken(session.auth.access_token);

    //@todo agency login
    if(!session.user.agencies || session.user.agencies.length === 0) {
      session.user.agencies = [{
        id: 1,
        name: 'jungwild'
      }];
    }

    if(session.user.active_agency_index === undefined) {
      session.user.active_agency_index = 0;
    }

    session.saveSession();

    if(session.after_login_callback) {
      session.after_login_callback();
      session.after_login_callback = null;
    }

    /* add redirect to login to end callbacks*/
    session.onEnd(() => {    
      routes.navigate('/login');
    });

    /* periodically check if user is still logged in */
    let sessionCheckTimer = setInterval(() => {

      /* ask api if user is still logged in */
      api.get('/confirm/' + session.auth.access_token, {
        
        success: (data) => {
          loggedInConfirmRetries = 0;
        },

        error: (data) => {

          const u = session.user;
          const webpackHash = __webpack_hash__.substr(0, 8);

          /* logout user only if we get a response telling us the token is invalid. Not best security,
             but that makes least trubble for users */
          if ((data.status === 400) && (data.responseJSON.errors.message === 'token is not valid')) {
            loggedInConfirmRetries++;

            helper.sendMattermost(
              `Failed confirm request\nuser: #${u.id} - ${u.firstname} ${u.lastname} (${u.email}), ` +  
              `wpHash: ${webpackHash}, ` +   
              `retry: ${loggedInConfirmRetries}, ` +   
              `status: ${data.status}, ` +  
              `error: ${data.responseText}`, 
              "hiauchpwf3bjtn5g1tdsnnx7no"
            );
          }

          if (loggedInConfirmRetries >= 3) {

            /* log via mattermost */
            helper.sendMattermost(
              `Autologout of user #${u.id} - ${u.firstname} ${u.lastname} (${u.email}), wpHash: ${webpackHash}`, 
              "hiauchpwf3bjtn5g1tdsnnx7no");

            /* kill timer */
            clearInterval(sessionCheckTimer);

            /* logout */
            session.destroy();
          }
        }

      });
    }, 3 * 60 * 1000)

    session.on_start_callbackstack.forEach((cb) => {
      cb();
    });

  },

  isLoggedIn: () => {
    return session.logged_in;
  },

  saveSession: () => {
    store.set('session',{
      auth: session.auth,
      user: session.user
    });

    store.get('session');
  },

  loadSession: () => {
    let sess = store.get('session');

    if(sess && sess.auth && sess.user)
    {
      session.start(sess);

      return true;
    }

    return false;
  },

  hasRole: (role) => {

    if(session.isLoggedIn() && session.user.roles[role]) {
      return true;
    }

    return false;
  },

  destroy: () => {

    api.get('/logout', {
      complete: () => {
        session.unload();
      }
    });
  },

  unload: () => {
    session.auth = null;
    session.user = null;
    store.remove('session');
    api.removeToken();
    login.show();
    session.on_end_callbackstack.forEach((cb) => {
      cb();
    });
  },

  refresh: (options) => {

    options = $.extend({},{
      success: () => {}
    }, options);

    api.get('/refresh', {
      success: (auth_response) => {

        //session.start(auth_response);
        login.setUserTemplate();
        options.success();
      },
      error: () => {
        session.destroy();
      }
    });
  },

  getToken: () => {
    return session.auth.access_token;
  },

  generateAclCss: (callback) => {

    helper.removeAllCssRules();

    api.get('/acl/css',{
      success: (rules) => {

        rules.forEach((rule) => {

          helper.addCssRules(rule);

        });
        if(callback !== undefined) {
          callback();
        }

      },
      error: () => {
        if(callback !== undefined) {
          callback();
        }
      }
    });
  },
  hasAcl: (level) => {
    if(session.user.acl[level]) {
      return true;
    }
    return false;
  }
};

export default session;
