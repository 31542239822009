let contact_form_fields = {
  'important': 'Switch',
  'salutation': {
    type: 'Select',
    table: 'salutation'
  },
  'title': 'Text',
  'firstname': 'Text',
  'lastname': 'Text',
  'email': 'Email',
  'emails': 'Multiemail',
  'phone': 'Phone',
  'phone_mobile': 'Phone',
  'phone_private': 'Phone',
  'users': {
    type: 'TagSelect',
    table: {
      name: 'user',
      type: 'worker_active'
    },
  },
  'employments': {
    name: 'employments',
    type: 'TagSelect',
    table: 'employment'
  },
  'position': 'Text',
  'social_linkedin': 'Text',
  'social_xing': 'Text',
  'description': 'Textarea'
};

export default contact_form_fields;
