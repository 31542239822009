import routes from '../routes';

$.fn.uiCardList = function(options) {
  // support multiple elements
  if (this.length > 1) {
    this.each(() => { $(this).uiCardAvatar(options) });
    return this;
  }

  // default options

  options = $.extend({},{
    items: [],
    title: false,
    collapse: false,
    onStatusChange: () => {}
  }, options);

  let plugin = this;

  let $element = $(this);

  plugin.showLoading = () => {
    $element.showLoading();
  };

  plugin.hideLoading = () => {
    $element.hideLoading();
  };

  this.initialize = () => {
    $element.uiCard({
      title: options.title
    });
    $element.$body.addClass('p-0');

    let $list = $('<ul class="nav flex-column"></ul>');

    options.items.forEach((item) => {
      $list.append( '<li class="nav-item p-3">' + options.render(item) + '</li>' );
    });

    $element.$body.append($list);

    routes.initLinks($element.find('.router-link'));

    return plugin;

  };

  return this.initialize();
};



