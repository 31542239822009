import app from '../../app/app';;
import time from '../../app/time';

const agency_contacttypes = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12" id="agency-contacttypes"> </div> </div></div>'; return out;
    },
    js: function(it) {
          app.setTitle('Kontakt-Typen-Einstellungen'); app.setBreadcrumps({ 'Admin-Einstellungen': '/admin', 'Kontakt-Typen': '/admin/contacttypes' }); let $org_types = $('#agency-contacttypes').adminScaffold({ i18n_prefix: 'g.', title: 'Kontakt-Typen', table: ['contacttype','contacttypes'], fields: { 'name': 'Text' }, cols: [{text:'#',name:'id',width:30}, 'name'], render: { 'id': (obj) => { return '<b>' + obj.id + '</b>'; }, 'name': (obj) => { return '<a>' + obj.name + '</a><br /><small>erstellt: ' + time.ago(obj.created_at) + '</small>'; } } });
    }
};

export default agency_contacttypes;
