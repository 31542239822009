import msg from './msg';

let prerender = {
    prerenderUrl: "/prerender",

    prerender_single_slug: (slug, $btn, $icon) => {

        /* update gui if everything went fine */
        const updateGuiOnSuccess = ($btn) => {
            if ($btn) {
                $btn.removeClass('btn-default');
                $btn.removeClass('btn-danger');
                $btn.addClass('btn-success');
            }
        };

        /* update gui if something went wrong */
        const updateGuiOnError = ($btn, error) => {
            if ($btn) {
                $btn.removeClass('btn-default');
                $btn.addClass('btn-danger');
            }
            msg.error(`Es gab einen Fehler: ${error}`);
        };

        /* update gui finally */
        const guiFinally = ($icon) => {
            if ($icon) {
                $icon.removeClass('fa-spin');
            }
        };

        let responseStatus = -1;

        fetch(prerender.prerenderUrl + '/prerender/' + slug, { method: 'GET' })
            .then(res => {
                responseStatus = res.status;
                return res.json();
            })
            .then(data => {
                if (responseStatus === 200) {
                    updateGuiOnSuccess($btn);

                    if (data.result === 'created') {
                        msg.success(slug + ' ist online!');
                    }

                    if (data.result === 'removed') {
                        msg.success(slug + ' ist geschlossen und wurde gelöscht!');
                    }

                } else {
                    throw `Status: ${responseStatus}, message: ${JSON.stringify(data)}`;
                }
            })
            .catch(error => {
                prerender.handle_error(error);
                updateGuiOnError($btn, error);
            })
            .finally(() => {
                guiFinally($icon);
            });
    },

    prerender_remove_slug: (slug) => {
        let responseStatus = -1;

        fetch(prerender.prerenderUrl + '/prerender/' + slug, { method: 'DELETE' })
            .then(res => {
                responseStatus = res.status;
                return res.json();
            })
            .then(data => {
                if (responseStatus === 200) {

                    if (data.result === 'removed') {
                        msg.success(slug + ' wurde gelöscht!');
                    }

                } else {
                    throw `Status: ${responseStatus}, message: ${JSON.stringify(data)}`;
                }
            })
            .catch(error => {
                prerender.handle_error(error);
            });
    },

    handle_error: (error) => {
        if (responseStatus === 502) {
            error = 'Prerender Server wurde nicht gefunden!';
            console.log(error);
            msg.error(error)
        }
    }
};

export default prerender;