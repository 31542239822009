import session from './session';

const acl = (level) => {
  if(session.hasAcl(level)) {
    return true;
  }
  return false;
};

export default acl;
