import api from '../api';
import time from '../time';
import session from "../session";

$.fn.uiFormMultievaluation = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label, $label_wrapper;
  let $input_label;
  let $input_salary;
  let $input_hourly_rate;
  let $input_eval;
  let $input_group;
  let $btn_add;
  let $btn_del;

  options = $.extend({},{
    i18n_prefix: '',
    toolbar: [  // angezeigt Toolbar Buttons
      [{'header': [1, 2, false]}],
      ['bold', 'italic', 'underline'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['link'],
      ['clean']
    ],
    formats: ['header', 'bold', 'italic', 'underline', 'list', 'link'],  // erlaubte Formate!!
    height: 150,
    filterValue: (obj) => {
      return obj.name;
    }
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);

  if(options.fieldname === undefined) {
    options.fieldname = options.name;
  }

  let plugin = this;
  let url;
  let listing = null;

  // public methods
  this.initialize = () => {

    $btn_add = $('<a href="#" class="btn btn-default ml-3 btn-xs">+</a>');
    $btn_del = $('<button type="button" class="btn btn-primary"><i class="fas fa-trash-alt"></i>Löschen</button>');
    $label = $('<span>' + options.label + '</span>');
    $label_wrapper = $('<label></label>');

    $input_label = $(`<div><label>${t(`${options.i18n_prefix}lb_${options.name}_label`)}</label><input autocomplete="${helper.randomString()}" name="${options.name}_label[]" class="form-control" type="text" placeholder="${options.placeholder}" /></div>`);

    $input_salary = $('<div></div>').uiFormNumber({
      i18n_prefix: options.i18n_prefix,
      name: options.name + '_salary',
    });

    $input_hourly_rate = $('<div></div>').uiFormNumber({
      i18n_prefix: options.i18n_prefix,
      name: options.name + '_hourly_rate',
    });

    let salary_name = $input_salary.find('input').attr('name') + '[]';
    $input_salary.find('input').attr('name', salary_name);

    let hourly_rate_name = $input_hourly_rate.find('input').attr('name') + '[]';
    $input_hourly_rate.find('input').attr('name', hourly_rate_name);

    $input_eval = $('<div></div>').uiFormRichtext({
      i18n_prefix: options.i18n_prefix,
      name: options.name + '_evaluation',
      height: 150,
      formats: ['bold', 'italic', 'underline', 'list', 'indent'],
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        ['clean']
      ]
    });

    let eval_name = $input_eval.find('input').last().attr('name') + '[]';
    $input_eval.find('input').last().attr('name', eval_name);

    $input_group = $('<div class="subform"></div>');
    $input_group.append($input_label);
    $input_group.append($input_salary);
    $input_group.append($input_hourly_rate);
    $input_group.append($input_eval);

    $label_wrapper.append($label);
    $label_wrapper.append($btn_add);


    $element.addClass('vertical-input-group');
    $element.addClass('form-group form-group-'+options.name).append($label_wrapper).append($input_group);

    /*
     * Neue Ersteinschätzung hinzufügen
     */
    $btn_add.click(() => {
      $btn_del = $('<button type="button" class="btn btn-primary"><i class="fas fa-trash-alt"></i>Löschen</button>');
      let $new_input_group = $('<div class="subform input-group-additional"></div>');

      let $new_input_id = $(`<input type="hidden" class="eval-id" name="${options.name}_id[]" value="" />`);

      let $new_input_label = $(`<div><label>${t(`${options.i18n_prefix}lb_${options.name}_label`)}</label><input autocomplete="${helper.randomString()}" name="${options.name}_label[]" class="form-control" type="text" placeholder="${options.placeholder}" /></div>`);

      let $new_input_salary = $('<div></div>').uiFormNumber({
        i18n_prefix: options.i18n_prefix,
        name: options.name + '_salary',
      });

      let salary_name = $new_input_salary.find('input').attr('name') + '[]';
      $new_input_salary.find('input').attr('name', salary_name);

      let $new_input_hourly_rate = $('<div></div>').uiFormNumber({
        i18n_prefix: options.i18n_prefix,
        name: options.name + '_hourly_rate',
      });
      
      let hourly_rate_name = $new_input_hourly_rate.find('input').attr('name') + '[]';
      $new_input_hourly_rate.find('input').attr('name', hourly_rate_name);

      let $new_input_eval = $('<div></div>').uiFormRichtext({
        i18n_prefix: options.i18n_prefix,
        name: options.name + '_evaluation',
        height: 150,
        formats: ['bold', 'italic', 'underline', 'list', 'indent'],
        toolbar: [
          ['bold', 'italic', 'underline'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'indent': '-1'}, { 'indent': '+1' }],
          ['clean']
        ]
      });

      let eval_name = $new_input_eval.find('input').last().attr('name') + '[]';
      $new_input_eval.find('input').last().attr('name', eval_name);

      $new_input_group.append($new_input_id).append($new_input_label).append($new_input_salary).append($new_input_hourly_rate).append($new_input_eval).append($btn_del);

      $element.append($new_input_group);

      return false;
    });

    /*
     * Ersteinschätzung löschen
     */
    $element.on('click', '.btn-primary', (ev) => {
      if (confirm(t(options.i18n_prefix+'evaluation_delete'))) {
        let id = $(ev.currentTarget).parent().find('.eval-id').val();

        api.delete('/resource/del/candidate_evaluation/' + id, {
          success: (response) => {
            $(ev.currentTarget).parent().remove();
          }
        });
      }
    });

    //this.$input = $input;

    return plugin;

  };

  this.setValue = (value) => {
    $element.find('.subform').first().remove();
    $element.find('.input-group-additional').remove();

    if(Array.isArray(value)) {
      value.forEach((item) => {
        plugin.addItem( item );
      });
    }
  };

  this.resetValue = () => {
    $element.find('.subform').remove();
  };

  /*
   * Gespeicherte Ersteinschätzung beim Profil-Aufruf einfügen
   */
  this.addItem = (item) => {

    $btn_del = $('<button type="button" class="btn btn-primary"><i class="fas fa-trash-alt"></i>Löschen</button>');
    let $new_input_group = $('<div class="subform input-group-additional"></div>');

    let $new_input_id = $(`<input type="hidden" class="eval-id" name="${options.name}_id[]" value="${item.id}" />`);

    let $new_input_label = $(`<div>
        <label>${t(`${options.i18n_prefix}lb_${options.name}_label`)}</label>
        <input autocomplete="${helper.randomString()}" name="${options.name}_label[]" class="form-control" type="text" placeholder="${options.placeholder}" />
    </div>`);

    $new_input_label.find('input').val(item.label);

    let $new_input_salary = $('<div></div>').uiFormNumber({
      i18n_prefix: options.i18n_prefix,
      name: options.name + '_salary',
    });

    let salary_name = $new_input_salary.find('input').attr('name') + '[]';
    $new_input_salary.find('input').attr('name', salary_name);
    $new_input_salary.find('input').val(item.salary_max);


    let $new_input_hourly_rate = $('<div></div>').uiFormNumber({
      i18n_prefix: options.i18n_prefix,
      name: options.name + '_hourly_rate',
    });

    let hourly_rate_name = $new_input_hourly_rate.find('input').attr('name') + '[]';
    $new_input_hourly_rate.find('input').attr('name', hourly_rate_name);
    $new_input_hourly_rate.find('input').val(item.hourly_rate);

    let $new_input_eval = $('<div></div>').uiFormRichtext({
      i18n_prefix: options.i18n_prefix,
      name: options.name + '_evaluation',
      formats: ['bold', 'italic', 'underline', 'list', 'indent'],
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        ['clean']
      ],
      overwrite_label: time.md(item.updated_at) + ' ' + time.time(item.updated_at)
    });

    let eval_name = $new_input_eval.find('input').last().attr('name') + '[]';
    $new_input_eval.find('input').last().attr('name', eval_name);
    $new_input_eval.setValue(item.evaluation);

    $new_input_group.append($new_input_id).append($new_input_label).append($new_input_salary).append($new_input_hourly_rate).append($new_input_eval).append($btn_del);

    $element.append($new_input_group);
  };

  return this.initialize();
};
