let cache = {

  data: {},

  context: '',

  set: (key, value, context) => {

    if(context === undefined) {
      context = cache.context;
    }

    cache.data[context+':'+key] = value;

  },

  get: (key, callback) => {
    let out = cache.data[cache.context+':'+key];
    if(out !== undefined) {
      callback(out);
    }
  },

  del: (key) => {
    cache.data[cache.context+':'+key] = undefined;
  },

  setContext: (context) => {
    cache.context = context;
  }
};

export default cache;
