import app from '../../app/app';;
import time from '../../app/time';

const platforms = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12" id="platform-management"> </div> </div></div>'; return out;
    },
    js: function(it) {
          app.setTitle('Recruiting-Platformen verwalten'); app.setBreadcrumps({ 'Administration': '/admin', 'Recruiting-Platformen': '/admin/platforms' }); let $platform_management = $('#platform-management').adminScaffold({ title: 'Recruiting-Platformen', table: ['platform','platforms'], fields: { 'name': 'Text', 'slug': 'Slug', 'icon': 'Text' }, cols: [{text:'#',name:'id',width:30}, 'name'], render: { 'id': (obj) => { return '<b>' + obj.id + '</b>'; }, 'name': (obj) => { return '<a>' + obj.name + '</a><br /><small>erstellt: ' + time.ago(obj.created_at) + '</small>'; } } });
    }
};

export default platforms;
