import api from '../api';

$.fn.uiFormAdditionalCollapsetitle = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $btn_collapse;
  options = $.extend({},{
    i18n_prefix: '',
    default: '',
    name: 'additionalCollapse'
  }, options);

  let plugin = this;

  // public methods
  this.initialize = () => {

    $btn_collapse = $('<button class="btn btn-default btn-collapse" type="button"><i name="icon-collapse" class="fas fa-chevron-down"></i>&nbsp&nbsp'
    + '<strong>' + options.label + '</strong></button>');
    $element.addClass('form-group form-group-' + options.name).append($btn_collapse);

    $btn_collapse.click((ev) => {
      if($element.parent().find('.is-additional-collapse').hasClass('is-hidden')) {
        $element.parent().find('.is-additional-collapse').removeClass('is-hidden');
        $element.find('[name="icon-collapse"]').removeClass('fa-chevron-down');
        $element.find('[name="icon-collapse"]').addClass('fa-chevron-up');
      }
      else {
        $element.parent().find('.is-additional-collapse').addClass('is-hidden');
        $element.find('[name="icon-collapse"]').removeClass('fa-chevron-up');
        $element.find('[name="icon-collapse"]').addClass('fa-chevron-down');
      }
    });

    this.$btn_collapse = $btn_collapse;

    return plugin;

  };

  this.setValue = (value) => {

  };

  this.resetValue = () => {

  };

  return this.initialize();
};
