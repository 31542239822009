import login from './login';
import app from './app';
import msg from './msg';
import session from './session';

var api = {

  default_options: {
    dataType: 'json',
    headers: {}
  },

  browser_proxy: {},

  merge_options: (url, type, options, data) => {

    if(options === undefined) {
      options = {};
    }
    options.type = type;
    options.url = api.prepareUrl(url);

    if(data !== undefined) {
      options.data = data;
    }

    let oerrfunc = options.error;
    options.error = (err) => {
      if(oerrfunc !== undefined) {
        oerrfunc(err);
      }

      if(err.responseJSON !== undefined && err.responseJSON.session_expired !== undefined){
        login.setMessage(t('g.session_expired'));
        login.setLoginRoute(app.getUri());
        session.unload();
        login.show();
      }

    };

    return $.extend({},api.default_options, options)
  },

  get: (url, options) => {
    $.ajax(api.merge_options(url, 'GET', options));
  },

  getData: (url, data, options) => {
    $.ajax(api.merge_options(url, 'GET', options, data));
  },

  post: (url, data, options) => {
    $.ajax(api.merge_options(url, 'POST', options, data));
  },

  delete: (url, options) => {
    $.ajax(api.merge_options(url,  'DELETE', options));
  },

  put: (url, data, options) => {
    $.ajax(api.merge_options(url, 'PUT', options, data));
  },

  upload: (url, $file_inputs, options) => {

    let $form = $('<form method="post" enctype="multipart/form-data"></form>');
    $form.append($file_inputs);

    let form_data = new FormData($form[0]);

    options = $.extend({}, {
      data: form_data,
      contentType: false,
      cache: false,
      processData:false
    }, options);

    let comp = () => {};
    if(options.complete !== undefined) {
      comp = options.complete;
    }

    options.complete = () => {
      comp();
      $form[0].reset();
    };

    $.ajax(api.merge_options(url, 'POST', options, form_data));
  },

  prepareUrl: (url) => {
    return '/api/v1' + url;
  },

  setToken: (token) => {
    api.default_options.headers.Authorization = 'Bearer ' + token;
  },

  removeToken: () => {
    api.default_options.headers = {};
  },

  getBrowserProxy: (account_id, options) => {
    api.get('/browser-proxy/getbrowser/' + account_id, options);
  },
  
  browserProxyPost: (port, command, data, options) => {
    api.post('/browser-proxy/command/' + port + '/' + command, data, options);
  },

  browserProxyGet: (port, command, options) => {
    api.get('/browser-proxy/command/' + port + '/' + command, options);
  },

  browserProxyWatchdogGet: (uri, options) => {
    api.get('/browser-proxy/watchdog' + uri, options);
  },

  candidateMailTpl: (candidate_id, template, options) => {
    api.post('/mail/candidate_tpl/' + template + '/' + candidate_id,{}, options);
  },

  candidateMail: (candidate_id, data, options) => {

    if(options !== undefined) {
      if(options.body_text !== undefined) {
        data.mail_body_text = options.body_text;
      }
      if(options.theme !== undefined) {
        data.theme = options.theme;
      }
      if(options.jobids !== undefined) {
        data.jobids = options.jobids;
      }
    }

    api.post('/mail/candidate/' + candidate_id, data, options)
  },

  handleError: (xhr) => {

    /*
     * acl user dont have right permission
     */
    if(xhr.responseJSON && xhr.responseJSON.message === 'auth.acl_error') {
      api.handleErrorNoPermission(xhr);
    }

    /*
     * Elasticsearch error
     */
    if(xhr.responseJSON && xhr.responseJSON.error !== undefined) {
      let err = JSON.parse(xhr.responseJSON.error);

      if (err.error.root_cause !== undefined) {
        api.handleErrorElasticsearch(err.error);
      }
    }

    /*
     * Elasticsearch query error
     */
    if(xhr.responseJSON && xhr.responseJSON.error_query !== undefined) {
      let err = xhr.responseJSON.error_query;
      api.handleErrorQuery(err);
    }
  },

  handleErrorNoPermission: (xhr) => {
    xhr.responseJSON.errors.forEach((error) => {
      msg.error(t(error));
    });
  },

  handleErrorElasticsearch: (err) => {
    msg.error('Fehler in Suche: ' + err.root_cause[0].reason);
  },

  handleErrorQuery: (err) => {
    msg.error('Dies ist kein gültiger Suchstring. Bitte korrigieren oder an den Support wenden. [' + err + ']');
  }

};

export default api;
