import app from '../../app/app';;
import api from '../../app/api';;
import session from '../../app/session';;
import time from '../../app/time';;
import routes from '../../app/routes';;
import helper from '../../app/helper';

const status_details = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <!-- /.col --> <div class="col-md-12" id="status-templates"> </div> <!-- /.col --> </div> <!-- /.row --></div><!-- /.container-fluid -->'; return out;
    },
    js: function(it) {
              app.setTitle('Kandidaten-Jobstatus Details'); app.setBreadcrumps({ 'Recruiting': '/recruiting', 'Kandidaten-Jobstatus': '/agency/admin/status/' + it.status_id }); api.get('/resource/get/jobstatus/' + it.status_id, { success: (status) => { $('#status-templates').adminScaffold({ title: t('jobstatus.templates_for') + status.name, table: ['jobstatus_template', 'jobstatus_template'], type: it.status_id, disable_add: true, fields: { 'name': 'Text' }, cols: [{text:'#',name:'id',width:30}, 'name'], render: { 'id': (obj) => { return '<b>' + obj.id + '</b>'; }, 'name': (obj) => { return '<a>' + obj.name + '</a>'; } } }); } });
    }
};

export default status_details;
