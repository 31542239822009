import api from '../api';

$.fn.uiFormSelectgroup = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label, $label_wrapper;
  let $input;
  let $btn_add;
  let $checkbox;
  let random_id = Math.floor(Math.random() * 100000);

  options = $.extend({},{
    i18n_prefix: '',
    group_maxlength: null,
    text_maxlength: null,
    searchable: true,
    multiple: false,
    multiple_add: true,
    /* Checkbox that shows or hides active or inactive entities (if implemented in backend)*/
    inactive_checkbox: true,
    inactive_checkbox_checked: false
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);


  let plugin = this;

  this.loadData = () => {
    if(options.table !== undefined) {

      // durchsuchbares optgroup select
      if (options.searchable === true) {
        let url = '/resource/selectgroup-search/' + options.table
        if(options.inactive_checkbox &&
          (jQuery('#form_selectgroup_checkbox_' + random_id).length > 0 &&
            document.getElementById('form_selectgroup_checkbox_' + random_id).checked)){
          url = url + "/?show_inactive=true";
          console.log("---");
        }
        api.get(url, {
          success: (response) => {

            if (response) {
              /* mögliche zusätzliche Optionen:
               * https://stackoverflow.com/questions/26964909/selectable-optgroup-using-select2
               */
              $input.select2({
                theme: 'bootstrap4',
                multiple: false,
                data: response,
                matcher: matcherOptgroupAndChildren
              });

              $input.prepend('<option value="0">' + t('g.please_choose') + '</option>');
              $input.val(0).trigger('change');
            }

          }
        });

      }

      // normales optgroup select
      else {

        api.get('/resource/selectgroup/' + options.table, {
          success: (response) => {

            let listing = response.listing;
            if (listing === undefined) {
              listing = response;
            }

            for (let prop in listing) {
              if (Object.prototype.hasOwnProperty.call(listing, prop)) {

                let group_maxlength = parseInt(options.group_maxlength);
                let $optgroup = $(`<optgroup label="${prop}"></optgroup>`);

                if (options.group_maxlength !== null && prop.length > group_maxlength) {
                  let prop_label = prop.substr(0, group_maxlength) + '...';
                  $optgroup = $(`<optgroup label="${prop_label}" title="${prop}"></optgroup>`);
                }

                $.each(listing[prop], (i, obj) => {

                  let text = obj.name;
                  if (text === undefined) {
                    text = obj.title;
                  }

                  let text_maxlength = parseInt(options.text_maxlength);

                  if (options.text_maxlength !== null && text.length > text_maxlength) {
                    let option_label = text.substr(0, text_maxlength) + '...';
                    $optgroup.append(`<option value="${obj.id}" title="${text}">${option_label}</option>`);
                  } else {
                    $optgroup.append(`<option value="${obj.id}">${text}</option>`);
                  }

                });

                $input.append($optgroup);

              }
            }

            $input.prepend('<option value="0">' + t('g.please_choose') + '</option>');
            $input.val(0);

          }

        });

      }
    }
  }

  // public methods
  this.initialize = () => {

    if (options.multiple) {
      $label = $('<span>' + options.label + '</span>');
      $label_wrapper = $('<label></label>');

      $btn_add = $('<a href="#" class="btn btn-default ml-3 btn-xs">+</a>');
      $input = $('<select name="' + options.name + '_id[]" class="form-control"></select>');
    }
    else {
      $label = $('<label style="display:flex; justify-content: space-between">' + options.label + '</label>');
      if(options.inactive_checkbox || 1 === 1) {
        $checkbox = $('<div>' +
          '<input id="form_selectgroup_checkbox_' + random_id + '" type="checkbox" name="show-inactive">' +
          '<label for="show-inactive" style="margin-left:7px; margin-bottom:0px">Zeige auch geschlossene</label>' +
          '</div>');
        $label.append($checkbox);
        let checkboxInterval = window.setInterval(() => {
          if(jQuery('#form_selectgroup_checkbox_' + random_id).length > 0){
            jQuery('#form_selectgroup_checkbox_' + random_id).off('click');
            jQuery('#form_selectgroup_checkbox_' + random_id).click(function(){
              $input.html('').select2({data: [{id: '', text: ''}]});
              plugin.loadData();
            });
            window.clearInterval(checkboxInterval);
          }
        }, 500);

      }

      $input = $('<select name="' + options.name + '_id" class="form-control"></select>');
    }

    this.loadData();

    if (options.multiple) {
      $label_wrapper.append($label);

      if (options.multiple_add) {
        $label_wrapper.append($btn_add);
        $element.addClass('vertical-input-group');
      }

      $element.addClass('form-group form-group-' + options.name).append($label_wrapper).append($input);

      $btn_add.click(() => {
        let new_options = Object.assign({}, options);
        new_options.multiple_add = false;

        let $new_input = $('<div></div>').uiFormSelectgroup(new_options);
        $new_input.find('label').remove();

        $element.append($new_input.children());
      });
    }
    else {
      $element.addClass('form-group form-group-' + options.name).append($label).append($input);
    }

    this.$input = $input;
    let self = this;
    jQuery('#form_selectgroup_checkbox_' + random_id).click(function(){
      self.initialize();
    });

    return plugin;
  };

  this.setValue = (value) => {
    if(typeof value === 'object') {
      $input.val(value.id);
    }
    else {
      $input.val(value);
    }

    $label.text(options.label_edit);
    $input.attr('placeholder', options.placeholder_edit);

  };

  this.resetValue = () => {
    $input.val('');
    $input.val(0);
    $label.text(options.label);
    $input.attr('placeholder', options.placeholder);
    $input.trigger('change');
  };

  return this.initialize();
};

/*
 * Diese Funktion wird für die Suche im select2 genutzt.
 * Wenn der Suchstring auf eine optgroup matched, dann zeigen wir diese mit allen children an.
 * Zusätzlich zeigen wir auch noch die einzelnen Children, die gematched werden.
 */
function matcherOptgroupAndChildren(params, data) {

  let matchedData;
  let modifiedOptgroup = {};
  let modifiedData = {};
  let filteredOptgroups = [];
  var filteredChildren = [];
  let matched_search_parts = [];


  // If there are no search terms, return all of the data
  if ($.trim(params.term) === '') {
    return data;
  }

  let searched_terms = $.trim(params.term).split(" ");
  let add_group_term = false;

  $.each(searched_terms, function(idtt,searchedtTerm){
    if (data.text.toUpperCase().search(searchedtTerm.toUpperCase()) > -1) {
      add_group_term = true;
      if(!matched_search_parts.includes(searchedtTerm)) {
        matched_search_parts.push(searchedtTerm)
      }
    }
  });
  if (add_group_term) {
    filteredOptgroups.push(data);
  }

  // `data.children` contains the actual options that we are matching against
  $.each(searched_terms, function(idtt,searchedtTerm){
    $.each(data.children, function (idx, child) {
      if (child.text.toUpperCase().search(searchedtTerm.toUpperCase()) > -1) {
        if(!filteredChildren.includes(child)) {
          filteredChildren.push(child);
        }
        if(!matched_search_parts.includes(searchedtTerm)) {
          matched_search_parts.push(searchedtTerm)
        }
      }
    });
  });

  if(matched_search_parts.length !==  searched_terms.length) {
    return null
  }

  // If we matched any group we show the whole group regardless on the child matching
  if (filteredOptgroups.length) {
    modifiedOptgroup = $.extend({}, data, true);
  } else if (filteredChildren.length) {
    modifiedData = $.extend({}, data, true);
    modifiedData.children = filteredChildren;
  }

  if (filteredOptgroups.length || filteredChildren.length) {
    matchedData = $.extend({}, modifiedOptgroup, modifiedData);
    return matchedData;
  }

  // Return `null` if the term should not be displayed
  return null;

}
