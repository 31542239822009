import app from '../../app/app';

const nullpage = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-12"> <div> </div> </div> </div></div>'; return out;
    },
    js: function(it) {
         app.setTitle('');
    }
};

export default nullpage;
