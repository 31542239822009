import api from '../api';

/*  This element represents positive integers and 0. It's not using a number element 
    to prevent changes on mousewheel scrolling.
*/

$.fn.uiFormNumber2 = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $input;

  options = $.extend({},{
    i18n_prefix: '',
    min: 0,
    max: 99999999,
    readonly: false
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);

  let plugin = this;

  // public methods
  this.initialize = () => {

    let value = '';
    let readonly = '';

    if (options.value !== undefined) {
      value = ` value="${options.value}"`;
    }

    if (options.readonly == true) {
      readonly = ' readonly="readonly"';
    }

    $label = $(`<label>${options.label}</label>`);
    $input = $(`<input name="${options.name}" class="form-control" type="text" min="${options.min}" max="${options.max}" step="${options.step}" placeholder="${options.placeholder}" autocomplete="off"${value}${readonly} />`);

    $element.addClass('form-group form-group-' + options.name).append($label).append($input);

    /* allowing only numbers in range between options.min and options.max */
    $input.on('keyup', () => {
      let val = $input.val();
      let int_val = parseInt(val.replace(/\D/g, ""));

      if (isNaN(int_val)) {
        $input.val('');
      } else {
        int_val = (int_val > options.max) ? options.max : int_val;
        int_val = (int_val < options.min) ? options.min : int_val;
        $input.val(int_val);
      }
    });

    this.$input = $input;

    return plugin;

  };

  this.setValue = (value) => {
    $input.val(value);
    $label.text(options.label_edit);
    $input.attr('placeholder', options.placeholder_edit);
  };

  this.resetValue = () => {
    $input.val('');
    $label.text(options.label);
    $input.attr('placeholder', options.placeholder);
  };

  return this.initialize();
};


