import app from '../../app/app';;
import time from '../../app/time';

const admin_selectors = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12" id="admin-selectors"> </div> </div></div>'; return out;
    },
    js: function(it) {
          app.setTitle('Selectors für Active Sourcing Plugin'); let $org_types = $('#admin-selectors').adminScaffold({ i18n_prefix: 'selectors.', title: 'Selectors', table: ['selector','selectors'], fields: { 'name': 'Text', 'domains': 'Text', 'selectors': 'Textarea'  }, cols: [ { text: '#', name: 'id', width: 30 },       'name', 'domains', 'updated_at' ], render: { 'id': (obj) => { return '<b>' + obj.id + '</b>'; }, 'name': (obj) => { return  obj.name; }, 'domains': (obj) => { return  obj.domains; }, 'updated_at': (obj) => { return  obj.updated_at; } } });
    }
};

export default admin_selectors;
