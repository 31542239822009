import api from '../api';
import msg from '../msg';
import session from '../session';
import routes from '../routes';
import helper from '../helper';
import modal from '../../_compiled/components/modal';
import button_save from '../../_compiled/components/button_save';

$.fn.uiTreeEditor = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  options = $.extend({},{
    i18n_prefix: null,
    icon: 'fas fa-folder',
    render: {},
    uri: '',
    addSuccess: (res) => {},
    editSuccess: (new_data, old_data) => {},
    onAddMode:()=>{},
    onEditMode:(data)=>{},
    pageLength: 10,
    optionButtons: [],
    class: ' table-striped',
    additionalTitle: t('g.advanced')
  }, options);

  if(!options.i18n_prefix) {
    options.i18n_prefix = options.table + '.';
  }

  let plugin = this;
  let $tree = null;
  let $form = null;
  let $card_tree = null;
  let $card_form = null;
  let $btn_new = null;
  let $btn_save = null;
  let $btn_delete = null;

  this.initForm = () => {
      /*
       * FORM
       */
    $form = $('<form action="/' + options.table + '"></form>').uiForm({
      fields: options.fields,
      i18n_prefix: options.i18n_prefix,
      url: '/' + options.model,
      additionalTitle: options.additionalTitle,
      success: () => {
        $form.resetValues();
      },
      editSuccess: (new_data, old_data) => {
        msg.success(t(options.i18n_prefix + 'edit_success') );
        $tree.jstree('refresh');
        if (options.editSuccess !== null) {
          options.editSuccess(new_data, old_data);
        }
      },
      onAddMode: () => {
      },
      onEditMode: (data) => {
      },
      complete: () => {
        $card_form.hideLoading();
      },
      start: () => {
        options.onLoading();
        $card_form.showLoading();
      }
    });

    plugin.$form = $form;
    /*
     * FORM END
     */
  };

  this.initHtml = () => {

    $element.append(`
      <div class="row">
        <div class="col-md-4 colcard-tree"><div></div></div>
        <div class="col-md-8 colcard-form"><div></div></div>
      </div>
    `);

    $card_tree = $element.find('.colcard-tree > div');
    $card_form = $element.find('.colcard-form > div');

    $card_tree.uiCard({
      title: t(options.i18n_prefix + 'tree_title')
    });

    $card_form.uiCard({
      title: t(options.i18n_prefix + 'form_title')
    });

    $tree = $('<div></div>');

    $btn_save = $('<button class="btn btn-primary btn-xs">' + t('g.save') + '<i class="ml-1 fas fa-save"></i></button>');
    $btn_save.on('click', (ev) => {
      ev.preventDefault();
      $form.submit();
    });

    $btn_delete = $('<button class="ml-1 btn btn-danger btn-xs"><i class="fas fa-trash"></i></button>');
    $btn_new = $('<button class="btn btn-success btn-xs">' + t('g.add') + '<i class="ml-1 fas fa-plus-circle"></i></button>');
    $btn_delete.on('click', (ev) => {
      if(confirm( t(options.i18n_prefix + 'confirm_delete'))) {
        ev.preventDefault();
        let id = $tree.jstree().get_selected(true)[0].id;
        api.delete('/resource/del/' + options.model + '/' + id, {
          success: () => {
            $tree.jstree().deselect_all(true);
            $tree.jstree('refresh');
          }
        });
      }
    });
    $btn_new.on('click', (ev) => {
      ev.preventDefault();
      api.post('/resource/add/' + options.model,{name: t(options.i18n_prefix + 'new_item')},{
        success: (res) => {
          $tree.jstree().create_node('#' ,  { id: res.id, text: res.name }, 'last', () => {
            $tree.jstree().deselect_all(true);
            $tree.jstree('select_node', res.id);
          });
        }
      });

    });

    $card_tree.addTool($btn_new);
    $card_tree.addTool($btn_delete);
    $card_form.addTool($btn_save);
    $card_tree.$body.addClass('pt-2 pl-0 pr-0 pb-2');
    $card_tree.$body.append($tree);
    $card_form.$body.append($form);
  };

  this.initTree = () => {

      $tree.jstree({

        core: {
          check_callback: true,
          data: {
            type: 'POST',
            url: '/api/v1/resource/tree/team',
            data: (node) => {
              return { 'id': node.id };
            }
          }
        },
        plugins: [ 'dnd', 'types'],
        types : {
          root: {
            icon: 'fas fa-cloud'
          },
          default: {
            icon: options.icon
          }
        }
      });

      $tree.on('select_node.jstree', function(evt, data){
        //selected node object: data.node;

        $form.resetValues();

        api.get('/resource/get/' + options.model + '/' + data.node.id,{
          success: (res) => {
            $form.setValues(res);
            
            let $checkbox = $card_form.$body.find("input[name='description_additional']");
            
            if(res.description !== '' || res.slug !== ''){
              $checkbox.prop("checked", true);
            }
            else{
              $checkbox.prop("checked", false);
            }
            
            $checkbox.trigger("change");
          }
        });

      });

      $tree.on('move_node.jstree', function (e, data) {
        //data.node, data.parent, data.old_parent is what you need

        api.get('/resource/tree/move/' + options.model + '/' + data.node.id + '/' + data.parent,{
          error: (e) => {
            console.error(e);
          }
        });

    });
  };


  // public methods
  this.initialize = () => {

    plugin.initForm();
    plugin.initHtml();
    plugin.initTree();

    return plugin;

  };

  return this.initialize();
};


