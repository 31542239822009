import api from '../api';

$.fn.uiFormReminderSelect = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $input_wrapper;

  options = $.extend({},{
    i18n_prefix: '',
    wrapper: true,
    default: false,
    onChange: (value, $select, $form) => {

    }
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);

  if(options.fieldname === undefined) {
    options.fieldname = options.name;
  }

  let plugin = this;

  // public methods
  this.initialize = () => {

    $label = $('<label>' + options.label + '</label>');
    $input_wrapper = $('<div></div>');

    $input_wrapper.append(plugin.createInput());

    $element.addClass('form-group form-group-' + options.name).append($label).append($input_wrapper);




    this.$input_wrapper = $input_wrapper;

    return plugin;

  };

  this.createInput = () => {
    let $input = $('<select name="' + options.fieldname + '[]" class="form-control"><option value="0">' + t('g.please_choose') + '</option></select>');

    let values = {
      min: [5,15,30],
      hour: [1,2,12],
      day: [1,2,3],
      week: [1,2,3]
    }

    for (let key in values){
      if(values.hasOwnProperty(key)){
        let $optgrp = $('<optgroup label="' + t('g.'+key) + '"></optgroup>');
        values[key].forEach((val) => {
          $optgrp.append('<option value="'+key+':'+val+'">'+val+' '+t('g.before_'+key)+'</option>');
        });
        $input.append($optgrp);
      }
    }

    $input.on('change', (ev) => {
      let $el = $(ev.currentTarget);

      $input_wrapper.find('select').each((i, el) => {
        let $this = $(el);
        console.log($this.val());
        if($this.val() == '0') {
          $this.remove();
        }
      });
      $input_wrapper.append(plugin.createInput());

    });

    return $input;
  };

  this.setValue = (value) => {
    $label.text(options.label_edit);
  };

  this.resetValue = () => {

    $input_wrapper.empty();
    $input_wrapper.append(plugin.createInput())

    $label.text(options.label);
  };

  return this.initialize();
};


