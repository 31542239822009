import api from '../api';
import msg from '../msg';

$.fn.uiFormText = function(options) {

  /*
  HANDLE OPTIONS
   */
  let $element = $(this);
  let $label;
  let $input;

  options = $.extend({},{
    i18n_prefix: '',
    default: ''
  }, options);

  options.label = t(options.i18n_prefix + 'lb_'+options.name);
  options.label_edit = t(options.i18n_prefix + 'lb_' + options.name);

  options.placeholder = t(options.i18n_prefix + 'ph_'+options.name);
  options.placeholder_edit = t(options.i18n_prefix + 'ph_'+options.name);


  let plugin = this;
  /*
  this.showErrors = (name, error) => {
    if (options.name == name) {
      $input.addClass('is-invalid');
      $element.find(`input[name="${name}"]`).parent().append(`<span class="error invalid-feedback">${error}</span>`);
    }
  };
  */

  // public methods
  this.initialize = () => {
    if (options.datacomp === undefined) {
      $label = $('<label>' + options.label + '</label>');
      $input = $('<input autocomplete="' + helper.randomString() + '" name="' + options.name + '" class="form-control" type="text" placeholder="' + options.placeholder + '" value="' + options.default + '" />');

      $element.addClass('form-group form-group-' + options.name).append($label).append($input);
    }
    else {
      plugin.inputForDataComparison();
    }

    $input.on('keyup', () => {
      this.limitInputLength($input);
    });
    $input.on('change', () => {
      this.limitInputLength($input);
    });

    if(options.onKeyUp) {
      $input.on('keyup', () => {
        options.onKeyUp($input);
      });
    }

    this.$input = $input;

    return plugin;

  };

  this.limitInputLength = ($input) => {

    let $limit_length = $input.attr('limit_length');
    
    if($limit_length > 0) {
      let val = $input.val();
      if(val.length > $limit_length) {
        $input.val(val.substring(0, $limit_length));

        let $err_msg = 'Die Kommentarlänge sollte kleiner als ' + $limit_length + ' Zeichen sein!'
         msg.error(t($err_msg));
      }
    }
  }

  plugin.inputForDataComparison = () => {
    let $html;

    // es geht um die Spalte, in der das Formular steht, nicht die Seite an der der Button ist
    if (options.datacomp === 'right') {
      $html = $(`<label>${options.label}</label>
          <div class="input-group mb-4">
              <div class="input-group-prepend">
                  <button type="button" class="btn btn-block btn-default btn-sm input-group-btn comp-right"> << </button>            
              </div>
              <input name="${options.name}" class="form-control" type="text" placeholder="${options.placeholder}" value="${options.default}" />
          </div>`);

      $element.addClass('form-group form-group-' + options.name).append($html);
    }
    else if (options.datacomp === 'left') {
      $html = $(`<label>${options.label}</label>
          <div class="input-group mb-4">
              <input name="${options.name}" class="form-control" type="text" placeholder="${options.placeholder}" value="${options.default}" />
              <div class="input-group-append">
                  <button type="button" class="btn btn-block btn-default btn-sm input-group-btn comp-left"> >> </button>            
              </div>
          </div>`);

      $element.addClass('form-group form-group-' + options.name).append($html);
    }
    else if (options.datacomp === 'none') {
      $html = $(`<label>${options.label}</label>
          <div class="input-group mb-4">
              <input name="${options.name}" class="form-control" type="text" placeholder="${options.placeholder}" value="${options.default}" />
              <div class="input-group-append"></div>
          </div>`);

      $element.addClass('form-group form-group-' + options.name).append($html);
    }

    $input = $html.find('input');
    $label = $html.find('label');
    this.$input = $input;
  };

  this.setValue = (value) => {
    $input.val(value);
    $label.text(options.label_edit);
    $input.attr('placeholder', options.placeholder_edit);
  };

  this.resetValue = () => {
    $input.val(options.default);
    $label.text(options.label);
    $input.attr('placeholder', options.placeholder);
  };

  return this.initialize();
};


