import app from '../../app/app';;
import time from '../../app/time';

const admin_employments = {
    tpl: function(it) {
        var out='<div class="container-fluid"> <div class="row"> <div class="col-md-12" id="admin-employments"> </div> </div></div>'; return out;
    },
    js: function(it) {
          app.setTitle('Anstellungsarten'); app.setBreadcrumps({ 'Admin-Einstellungen': '/admin', 'Anstellungsarten': '/admin/employments' }); let $org_types = $('#admin-employments').adminScaffold({ i18n_prefix: 'g.', title: 'Anstellungsarten', table: ['employment','employments'], fields: { 'name': 'Text', 'type': 'Text' }, cols: [ { text: '#', name: 'id', width: 30 },       'name', { text: 'Google-Type', name: 'type' } ], render: { 'id': (obj) => { return '<b>' + obj.id + '</b>'; }, 'name': (obj) => { return '<a>' + obj.name + '</a><br /><small>erstellt: ' + time.ago(obj.created_at) + '</small>'; }, 'type': (obj) => { return obj.type } } });
    }
};

export default admin_employments;
