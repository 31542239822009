const audio = {

  soundContext: null,
  files: null,
  supported: true,

  init: () => {
    audio.files = {};

    /*
     * preload files
     */
  },

  preload: () => {
    ['gong.mp3','dead.wav','hohoho.mp3','coin.mp3'].forEach((file) => {
      audio.loadSound(file);
    });
  },

  playSound: (file) => {

    if(!audio.supported) {
      return false;
    }

    if(file.substring(0,1) !== '/') {
      file = '/audio/' + file;
    }

    if(audio.files[file] === undefined) {
      audio.loadSound(file);
    }

    if(audio.files[file].interval) {
      clearInterval(audio.files[file].interval);
    }

    audio.files[file].interval = setInterval(() => {

      if(audio.files[file].buffer) {
        audio.startPlay(file);
        clearInterval(audio.files[file].interval);
      }

    },200);
  },

  loadSound: (file) => {

    if(!audio.supported) {
      return false;
    }

    if(file.substring(0,1) !== '/') {
      file = '/audio/' + file;
    }

    if(audio.files[file] === undefined) {
      audio.files[file] = {
        url: file
      }
    }

    if(!audio.soundContext) {
      const AC = window.AudioContext // Default
        || window.webkitAudioContext // Safari and old versions of Chrome
        || false;

      if(AC === false) {
        audio.supported = false;
        return false;
      }
      else {
        audio.soundContext = new AC();
      }
    }

    let request = new XMLHttpRequest();
    request.open('GET', audio.files[file].url, true);
    request.responseType = 'arraybuffer';

    request.onload = () => {
      audio.soundContext.decodeAudioData(request.response, (newBuffer) => {
        audio.files[file].buffer = newBuffer;
      });
    };

    request.send();
  },

  startPlay: (file) => {
    let source = audio.soundContext.createBufferSource();
    source.buffer = audio.files[file].buffer;
    let volume = audio.soundContext.createGain();
    volume.gain.value = 1;
    volume.connect(audio.soundContext.destination);
    source.connect(volume);
    source.start(0);
  }
};

export default audio;
